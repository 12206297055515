import React, { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { Switch, Route, BrowserRouter, Redirect } from 'react-router-dom';
import store from './store';
import { TOKEN_NAME } from './constants/actionTypes'
import { Cookies } from 'react-cookie'
import 'react-datepicker/dist/react-datepicker.css'
//import './styles/App.css'

import PageNotFound from './components/PageNotFound'

const Login = lazy(() => import('./components/Login'));
const Home = lazy(() => import('./components/Home'));
const Learning = lazy(() => import('./components/Learning'));

const Schedule = lazy(() => import('./components/Schedule'));
const Resources = lazy(() => import('./components/Resources'));
const ResourcesModule = lazy(() => import('./components/Resources/couse'));
const Progresstracking = lazy(() => import('./components/Progresstracking/current'));
const CurrentProgresstrackin = lazy(() => import('./components/Progresstracking'));
const ViewLearningPathST = lazy(() => import('./components/Progresstracking/ViewLearningPath'));
const EnvironmentalScience = lazy(() => import('./components/Learning/environmentalScience'));
const Students = lazy(() => import('./components/Students'));
const Setting = lazy(() => import('./components/Students/setting'));
const Messages = lazy(() => import('./components/Students/messages'));
const Discussions = lazy(() => import('./components/Messages/discussions'));
const DiscussionThread = lazy(() => import('./components/Admin/DiscussionThread'));
const EditDiscussionThread = lazy(() => import('./components/Admin/EditDiscussionThread'));
const DiscussionResponse = lazy(() => import('./components/Admin/DiscussionResponse'));

const MessagesInbox = lazy(() => import('./components/Messages/inbox'));
const MessagesSent = lazy(() => import('./components/Messages/sent'));
const Messagestrash = lazy(() => import('./components/Messages/trash'));
const Messagesstarred = lazy(() => import('./components/Messages/starred'));
const MessagesIndividual = lazy(() => import('./components/Messages/messagesIndividual'));
const MessagesTags = lazy(() => import('./components/Messages/tags'));
const NewMessage = lazy(() => import('./components/Messages/newMessage'));
const ReplyMessage = lazy(() => import('./components/Messages/replyMessage'));
const ReplyMessageAll = lazy(() => import('./components/Messages/replyMessageAll'));
const Forward = lazy(() => import('./components/Messages/forward'));
const SendMailtoUser = lazy(() => import('./components/Home/SendMailtoUser'));

const ChangePassword = lazy(() => import('./components/Login/changePassword'));
const ForgotPassword = lazy(() => import('./components/Login/forgotPassword'));
const ResetPassword = lazy(() => import('./components/Login/resetPassword'));

const Header = lazy(() => import('./components/Header'));
const Footer = lazy(() => import('./components/Footer'));
const HomePR = lazy(() => import('./components/HomePR'));
const CalendarPR = lazy(() => import('./components/CalendarPR'));
const ProgresstrackingPR = lazy(() => import('./components/ProgresstrackingPR'));
const CurrentCourseProgress = lazy(() => import('./components/ProgresstrackingPR/current'));
const HeaderPR = lazy(() => import('./components/HeaderPR'));
const FooterPR = lazy(() => import('./components/FooterPR'));
const ChatBox = lazy(() => import('./components/chat/ChatBox'));
const DiscussionsPR = lazy(() => import('./components/MessagesPR/discussions'));
const MessagesInboxPR = lazy(() => import('./components/MessagesPR/inbox'));
const PaymentTermsOfUse = lazy(() => import('./components/Home/PaymentTermsOfUse'));

const MessagesSentPR = lazy(() => import('./components/MessagesPR/sent'));
const MessagesstarredPR = lazy(() => import('./components/MessagesPR/starred'));
const MessagestrashPR = lazy(() => import('./components/MessagesPR/trash'));
const MessagesIndividualPR = lazy(() => import('./components/MessagesPR/messagesIndividual'));
const MessagesTagsPR = lazy(() => import('./components/MessagesPR/tags'));
const NewMessagePR = lazy(() => import('./components/MessagesPR/newMessage'));
const ReplyMessagePR = lazy(() => import('./components/MessagesPR/replyMessage'));
const ReplyMessageAllPR = lazy(() => import('./components/MessagesPR/replyMessageAll'));
const ForwardPR = lazy(() => import('./components/MessagesPR/forward'));
const ClassInfo = lazy(() => import('./components/ProgresstrackingPR/environmentalScience'));

const ResourcesPR = lazy(() => import('./components/ResourcesPR'));
const ResourcesModulePR = lazy(() => import('./components/ResourcesPR/couse'));
const Parent = lazy(() => import('./components/Parents'));
const StudentInfo = lazy(() => import('./components/HomePR/studentInfo'));
const SettingPR = lazy(() => import('./components/Parents/setting'));
const HeaderTeacher = lazy(() => import('./components/HeaderTeacher'));
const FooterTeacher = lazy(() => import('./components/FooterTeacher'));
const HomeTeacher = lazy(() => import('./components/Teacher'));
const SectionDetail = lazy(() => import('./components/Teacher/environmentalScience'));
const Roster = lazy(() => import('./components/Teacher/rosterModu'));

const StudentsInTeacher = lazy(() => import('./components/Teacher/students'));
const Section = lazy(() => import('./components/Teacher/section'));
const InfoStudent = lazy(() => import('./components/Teacher/studentInfo'));
const InfoStudentCur = lazy(() => import('./components/Teacher/studentInfoCurNew'));

const DiscussionsTea = lazy(() => import('./components/Inbox/discussions'));
const MessagesInboxTea = lazy(() => import('./components/Inbox/inbox'));
const MessagesSentTea = lazy(() => import('./components/Inbox/sent'));
const MessagesstarredTea = lazy(() => import('./components/Inbox/starred'));
const MessagestrashTea = lazy(() => import('./components/Inbox/trash'));
const MessagesIndividualTea = lazy(() => import('./components/Inbox/messagesIndividual'));
const MessagesTagsTea = lazy(() => import('./components/Inbox/tags'));
const NewMessageTea = lazy(() => import('./components/Inbox/newMessage'));
const ReplyMessageTea = lazy(() => import('./components/Inbox/replyMessage'));
const ReplyMessageAllTea = lazy(() => import('./components/Inbox/replyMessageAll'));
const ForwardTea = lazy(() => import('./components/Inbox/forward'));

const Attendances = lazy(() => import('./components/Teacher/attendances'));
const ScheduleTea = lazy(() => import('./components/Teacher/schedule'));
const Resourcelist = lazy(() => import('./components/Teacher/resource-list'));
const Gradebook = lazy(() => import('./components/Teacher/gradebook'));
const ScheduleMonth = lazy(() => import('./components/Teacher/scheduleMonth'));
const SettingTea = lazy(() => import('./components/Teacher/setting'));
const Teacher = lazy(() => import('./components/Teacher/teacher'));
const AddUnit = lazy(() => import('./components/Teacher/addUnit'));
const EditUnit = lazy(() => import('./components/Teacher/editUnit'));
const ScheduleOfStudentInteacher = lazy(() => import('./components/ScheduleOfStudentInteacher'));
const OtherGrades = lazy(() => import('./components/Teacher/otherGrades'));
const CommentDocument = lazy(() => import('./components/Teacher/commentDocument'));
const AcademicRecords = lazy(() => import('./components/Teacher/academicRecords'));
const Communications = lazy(() => import('./components/Teacher/communications'));
const StudentComplianceLog = lazy(() => import('./components/Teacher/studentComplianceLog'));
const ViewUnofficialTranscript = lazy(() => import('./components/Teacher/ViewUnofficialTranscript'));
const ViewEventDetail = lazy(() => import('./components/Teacher/eventDetail'));
const CommunicationLogTea = lazy(() => import('./components/Teacher/CommunicationLog'));
const SendMailtoUserTea = lazy(() => import('./components/Teacher/SendMailtoUser'));

const HeaderAdmin = lazy(() => import('./components/HeaderAdmin'));
const FooterAdmin = lazy(() => import('./components/FooterAdmin'));
const HomeAd = lazy(() => import('./components/Admin'));
const SectionAd = lazy(() => import('./components/Admin/section'));
const SectionStatus = lazy(() => import('./components/Admin/SectionStatus'));
const AddSection = lazy(() => import('./components/Admin/addSection'));
const EnvironmentalScienceAd = lazy(() => import('./components/Admin/environmentalScience'));
const StudentsInAd = lazy(() => import('./components/Admin/students'));
const InfoStudentInAd = lazy(() => import('./components/Admin/studentInfo'));
const InfoStudentCurInAd = lazy(() => import('./components/Admin/studentInfoCurNew'));
const Teachers = lazy(() => import('./components/Admin/teachers'));
const TeacherDetail = lazy(() => import('./components/Admin/teacherDetail'));
const TeacherProfileNew = lazy(() => import('./components/Admin/TeacherProfileNew'));
const TeacherScheduleofAvailability = lazy(() => import('./components/Admin/teacherScheduleofAvailability'));
const EnrollIntoSec = lazy(() => import('./components/Admin/enrollIntoSec'));
const Applications = lazy(() => import('./components/Admin/applications'));
const RosterAD = lazy(() => import('./components/Admin/rosterModu'));
const ResourcelistAd = lazy(() => import('./components/Admin/resource-list'));
const AttendancesAd = lazy(() => import('./components/Admin/attendances'));
const EditStudent = lazy(() => import('./components/Admin/editStudent'));
const ScheduleOfStudentInAdmin = lazy(() => import('./components/ScheduleOfStudentInAdmin'));
const TranferGrades = lazy(() => import('./components/Admin/tranferGrades'));
const AcademicRecordsInAD = lazy(() => import('./components/Admin/academicRecords'));
const CommentDocumentInAD = lazy(() => import('./components/Admin/commentDocument'));
const CommunicationsInAD = lazy(() => import('./components/Admin/communications'));
const StudentComplianceLogInAD = lazy(() => import('./components/Admin/studentComplianceLog'));
const AddStudent = lazy(() => import('./components/Admin/addStudent'));
const Requests = lazy(() => import('./components/Admin/requests'));
const EditOtherGrade = lazy(() => import('./components/Admin/editOtherGrade'));
const AddOtherGrade = lazy(() => import('./components/Admin/addOtherGrade'));
const EditAllGrade = lazy(() => import('./components/Admin/editAllGrade'));
const UserDirectory = lazy(() => import('./components/Admin/userDirectory'));
const EditSection = lazy(() => import('./components/Admin/editSection'));
const Course = lazy(() => import('./components/Admin/course'));
const GradebookForAd = lazy(() => import('./components/Admin/gradebook'));
const AddUnitForAd = lazy(() => import('./components/Admin/addUnit'));
const EditUnitForAd = lazy(() => import('./components/Admin/editUnit'));
const EditUserTag = lazy(() => import('./components/Admin/editUserTag'));
const UserDirectorySchoolEdit = lazy(() => import('./components/Admin/userDirectorySchoolEdit'));
const UserDirectoryPasswxpiration = lazy(() => import('./components/Admin/userDirectoryPasswxpiration'));
const AddCourse = lazy(() => import('./components/Admin/addCourse'));
const EditCourse = lazy(() => import('./components/Admin/editCourse'));
const TeacherGeneralAvailability = lazy(() => import('./components/Admin/teacherGeneralAvailability'));
const ScheduleOfTeachertInAdmin = lazy(() => import('./components/ScheduleOfTeachertInAdmin'));
const StudentOfTeacherDetail = lazy(() => import('./components/Admin/studentOfTeacherDetail'));
const CommunicationsTeacherDetail = lazy(() => import('./components/Admin/CommunicationsTeacherDetail'));
const TeacherCommentAndDocument = lazy(() => import('./components/Admin/TeacherCommentAndDocument'));
const EditLog = lazy(() => import('./components/Admin/editLog'));
const ApplicationStudentDetail = lazy(() => import('./components/Admin/ApplicationStudentDetail'));
const TranscriptRequest = lazy(() => import('./components/Admin/TranscriptRequest'));
const EditRequests = lazy(() => import('./components/Admin/EditRequests'));
const AddApplication = lazy(() => import('./components/Admin/addApplication'));
const EditApplication = lazy(() => import('./components/Admin/editApplication'));
const AddTeacher = lazy(() => import('./components/Admin/addTeacher'));
const EditCommentInTeacher = lazy(() => import('./components/Admin/editCommentInTeacher'));
const EditCredentialInTeacher = lazy(() => import('./components/Admin/editCredentialInTeacher'));
const EditCredential = lazy(() => import('./components/Teacher/editCredential'));
const TeacherQualification = lazy(() => import('./components/Admin/TeacherQualification'));
const TeacherEditLog = lazy(() => import('./components/Admin/TeacherEditLog'));
const ComplianceLog = lazy(() => import('./components/Admin/ComplianceLog'));
const AuditLog = lazy(() => import('./components/Admin/AuditLog'));
const AutoPromote = lazy(() => import('./components/Admin/AutoPromote'));
const AccountSetting = lazy(() => import('./components/Admin/setting'));
const CloneSection = lazy(() => import('./components/Admin/CloneSection'));
const AddParent = lazy(() => import('./components/Admin/addParent'));
const Parents = lazy(() => import('./components/Admin/parents'));
const ProfileAdmin = lazy(() => import('./components/Admin/profile'));
const EditProfile = lazy(() => import('./components/Admin/editProfile'));
const EditParent = lazy(() => import('./components/Admin/editParent'));
const AddUserSchool = lazy(() => import('./components/Admin/addUserSchool'));
const EditTeacher = lazy(() => import('./components/Admin/editTeacher'));
const UserDirectoryOverview = lazy(() => import('./components/Admin/userDirectoryOverview'));
const EnrollStudentToSection = lazy(() => import('./components/Admin/EnrollStudentToSection'));
const ReportingCenter = lazy(() => import('./components/Admin/ReportingCenter'));
const StudentMedical = lazy(() => import('./components/Admin/StudentMedical'));
const ARDashboard = lazy(() => import('./components/Admin/ARDashboard'));
const ARConfiguration = lazy(() => import('./components/Admin/ARConfiguration'));
const ARProcessing = lazy(() => import('./components/Admin/ARProcessing'));
const ARPaymentConfiguration = lazy(() => import('./components/Admin/ARPaymentConfiguration'));
const B2BContract = lazy(() => import('./components/Admin/B2BContract'));
const CommentDocumentForContract = lazy(() => import('./components/Admin/CommentDocumentForContract'));
const AddCommentDocumentForContract = lazy(() => import('./components/Admin/AddCommentDocumentForContract'));
const MasterContract = lazy(() => import('./components/Admin/MasterContract'));
const ARContracts = lazy(() => import('./components/Admin/ARContracts'));
const ARContractTemplates = lazy(() => import('./components/Admin/ARContractTemplates'));
const ARInvoices = lazy(() => import('./components/Admin/ARInvoices'));
const ARProducts = lazy(() => import('./components/Admin/ARProducts'));
const ARPaymentHistory = lazy(() => import('./components/Admin/ARPaymentHistory'));
const AgingReport = lazy(() => import('./components/Reports/AgingReport'));

const StudentInvoiceAD = lazy(() => import('./components/Admin/StudentInvoice'));
const StudentInvoicePR = lazy(() => import('./components/Parents/StudentInvoice'));
const StudentInvoice = lazy(() => import('./components/Students/StudentInvoice'));
const StudentPaymentHistory = lazy(() => import('./components/Admin/StudentPaymentHistory'));
const StudentPaymentApplication = lazy(() => import('./components/Admin/StudentPaymentApplication'));
const StudentPaymentApplicationPR = lazy(() => import('./components/Parents/StudentPaymentApplication'));
const StudentPaymentApplicationSt = lazy(() => import('./components/Students/StudentPaymentApplication'));
const EditStudentMedical = lazy(() => import('./components/Admin/editStudentMedical'));
const StudentEditInvoice = lazy(() => import('./components/Admin/StudentEditInvoice'));
const ViewContractPage = lazy(() => import('./components/Admin/ViewContractPage'));
const StudentContractAD = lazy(() => import('./components/Admin/StudentContract'));
const StudentContractPR = lazy(() => import('./components/Parents/StudentContract'));
const StudentContract = lazy(() => import('./components/Students/StudentContract'));
const ProgressReport = lazy(() => import('./components/Reports/ProgressReport'));
const ApplicationReport = lazy(() => import('./components/Reports/ApplicationReport'));
const TeacherReport = lazy(() => import('./components/Reports/TeacherReport'));
const StudentReport = lazy(() => import('./components/Reports/StudentReport'));
const ParentReport = lazy(() => import('./components/Reports/ParentReport'));
const RosterReport = lazy(() => import('./components/Reports/RosterReport'));
const SectionReport = lazy(() => import('./components/Reports/SectionReport'));
const CourseReport = lazy(() => import('./components/Reports/CourseReport'));
const FullProgressReport = lazy(() => import('./components/Reports/FullProgressReport'));
const LogInReport = lazy(() => import('./components/Reports/LogInReport'));
const LogInCredentialsReport = lazy(() => import('./components/Reports/LogInCredentialsReport'));
const EnrollmentReport = lazy(() => import('./components/Reports/EnrollmentReport'));
const EnrollmentSummaryReport = lazy(() => import('./components/Reports/EnrollmentSummaryReport'));
const StudentContractReport = lazy(() => import('./components/Reports/StudentContractReport'));
const SchoolInformationReport = lazy(() => import('./components/Reports/SchoolInformationReport'));
const CommunicationSummaryReport = lazy(() => import('./components/Reports/CommunicationSummaryReport'));
const CommentandDocumentSummaryReport = lazy(() => import('./components/Reports/CommentandDocumentSummaryReport'));
const SchoolCalendarReport = lazy(() => import('./components/Reports/SchoolCalendarReport'));
const BellScheduleReport = lazy(() => import('./components/Reports/BellScheduleReport'));
const TeacherAddQualification = lazy(() => import('./components/Admin/teacherAddQualification'));
const CourseDetail = lazy(() => import('./components/Admin/CourseDetail'));
const LearningPathTemplate = lazy(() => import('./components/Admin/LearningPathTemplate'));
const CourseStudents = lazy(() => import('./components/Admin/CourseStudents'));
const CourseCommentDocument = lazy(() => import('./components/Admin/CourseCommentDocument'));
const EditCommentInCourse = lazy(() => import('./components/Admin/editCommentInCourse'));
const CoursePrerequisite = lazy(() => import('./components/Admin/CoursePrerequisite'));
const AttendanceReport = lazy(() => import('./components/Reports/AttendanceReport'));
const AttendanceSummaryReport = lazy(() => import('./components/Reports/AttendanceSummaryReport'));
const StudentEnrollmentSummaryReport = lazy(() => import('./components/Reports/StudentEnrollmentSummaryReport'));
const GradeReport = lazy(() => import('./components/Reports/GradeReport'));
const TranscriptReport = lazy(() => import('./components/Reports/TranscriptReport'));
const TranscriptRequestReport = lazy(() => import('./components/Reports/TranscriptRequestReport'));

const InvoiceReport = lazy(() => import('./components/Reports/InvoiceReport'));
const DiscussionsAd = lazy(() => import('./components/InboxAdmin/discussions'));
const MessagesInboxAd = lazy(() => import('./components/InboxAdmin/inbox'));
const MessagesSentAd = lazy(() => import('./components/InboxAdmin/sent'));
const MessagesstarredAd = lazy(() => import('./components/InboxAdmin/starred'));
const MessagestrashAd = lazy(() => import('./components/InboxAdmin/trash'));
const MessagesIndividualAd = lazy(() => import('./components/InboxAdmin/messagesIndividual'));
const MessagesTagsAd = lazy(() => import('./components/InboxAdmin/tags'));
const NewMessageAd = lazy(() => import('./components/InboxAdmin/newMessage'));
const ReplyMessageAd = lazy(() => import('./components/InboxAdmin/replyMessage'));
const ReplyMessageAllAd = lazy(() => import('./components/InboxAdmin/replyMessageAll'));
const ForwardAd = lazy(() => import('./components/InboxAdmin/forward'));
const SignatureBlock = lazy(() => import('./components/InboxAdmin/signatureBlock'));
const GlobalMessage = lazy(() => import('./components/InboxAdmin/globalMessage'));
//const Test = lazy(() => import('./components/Hooks/test4'));
const EditRequestList = lazy(() => import('./components/Admin/EditRequestList'));
const RequestList = lazy(() => import('./components/Admin/RequestList'));
const SchoolInformation = lazy(() => import('./components/Admin/SchoolInformation'));
const SchoolMiscConfiguration = lazy(() => import('./components/Admin/SchoolMiscConfiguration'));
const CommunicationUserRules = lazy(() => import('./components/Admin/CommunicationUserRules'));
const CommunicationRules = lazy(() => import('./components/Admin/CommunicationRules'));
const SchoolAdminList = lazy(() => import('./components/Admin/SchoolAdminList'));
const FeatureConfig = lazy(() => import('./components/Admin/FeatureConfig'));
const SchoolConfig = lazy(() => import('./components/Admin/SchoolConfig'));
const AdditionalFields = lazy(() => import('./components/Admin/AdditionalFields'));
const StudentContractRules = lazy(() => import('./components/Admin/StudentContractRules'));
const FacilityManagement = lazy(() => import('./components/Admin/FacilityManagement'));
const Department = lazy(() => import('./components/Admin/Department'));
const GradingScales = lazy(() => import('./components/Admin/GradingScales'));
const BellSchedule = lazy(() => import('./components/Admin/BellSchedule'));
const GradingPeriods = lazy(() => import('./components/Admin/GradingPeriods'));
const AcademicTracks = lazy(() => import('./components/Admin/AcademicTracks'));
const AcademicTerms = lazy(() => import('./components/Admin/AcademicTerms'));
const LearningPathTemplateSetup = lazy(() => import('./components/Admin/LearningPathTemplateSetup'));
const LearningPathDetail = lazy(() => import('./components/Admin/LearningPathDetail'));
const LearningPathUserDetail = lazy(() => import('./components/Admin/LearningPathUserDetail'));
const FeatureSettings = lazy(() => import('./components/Admin/FeatureSettings'));
const GradeBookActivityDescriptions = lazy(() => import('./components/Admin/GradeBookActivityDescriptions'));
const WithdrawalRequestDetail = lazy(() => import('./components/Admin/WithdrawalRequestDetail'));
const EditRequestDetail = lazy(() => import('./components/Admin/EditRequestDetail'));
const EditRequestView = lazy(() => import('./components/Admin/EditRequestView'));
const ExtensionRequest = lazy(() => import('./components/Admin/ExtensionRequest'));
const ExtensionRequestDetail = lazy(() => import('./components/Admin/ExtensionRequestDetail'));
const StudentExtensionRequest = lazy(() => import('./components/Students/StudentExtensionRequest'));
const EnrollmentRequestDetail = lazy(() => import('./components/Admin/EnrollmentRequestDetail'));
const TranscriptRequestDetail = lazy(() => import('./components/Admin/TranscriptRequestDetail'));
const EnrollmentRules = lazy(() => import('./components/Admin/EnrollmentRules'));
const CommentDocumentReview = lazy(() => import('./components/Admin/CommentDocumentReview'));
const Products = lazy(() => import('./components/Admin/Products'));
const NotificationTemplates = lazy(() => import('./components/Admin/NotificationTemplates'));
const NotificationRuleTemplate = lazy(() => import('./components/Admin/NotificationRuleTemplate'));
const TranscriptViewerOptions = lazy(() => import('./components/Admin/TranscriptViewerOptions'));
const AddTeacherToSection = lazy(() => import('./components/Admin/AddTeacherToSection'));
const RemoveTeacherToSection = lazy(() => import('./components/Admin/RemoveTeacherToSection'));
const Sectionenroll = lazy(() => import('./components/Admin/Sectionenroll'));
const EditUserPassword = lazy(() => import('./components/Admin/EditUserPassword'));
const Uploadsection = lazy(() => import('./components/Admin/Uploadsection'));	
const SavePrerequisite = lazy(() => import('./components/Admin/SavePrerequisite'));
const AllicationStatus = lazy(() => import('./components/Admin/AllicationStatus'));
const ParentDetail = lazy(() => import('./components/Admin/ParentDetail'));
const TeacherChangeStatus = lazy(() => import('./components/Admin/TeacherChangeStatus'));
const UploadApplications = lazy(() => import('./components/Admin/UploadApplications'));
const StudentChangeStatusEnroll = lazy(() => import('./components/Admin/StudentChangeStatusEnroll'));
const TransferUserSchool = lazy(() => import('./components/Admin/TransferUserSchool'));
const StudentEditEnroll = lazy(() => import('./components/Admin/StudentEditEnroll'));
const RecipientTranscriptUn = lazy(() => import('./components/Admin/RecipientTranscriptUn'));
const Studenttranscript = lazy(() => import('./components/Admin/Studenttranscript'));
const RecipientTranscriptOf = lazy(() => import('./components/Admin/RecipientTranscriptOf'));
const UploadStudent = lazy(() => import('./components/Admin/UploadStudent'));
const Mergestudent = lazy(() => import('./components/Admin/Mergestudent'));
const CommunicationLog = lazy(() => import('./components/Admin/CommunicationLog'));
const ObjectState = lazy(() => import('./components/Admin/ObjectState'));
const Status = lazy(() => import('./components/Admin/Status'));
const Badge = lazy(() => import('./components/Admin/Badge'));
const SchoolComment = lazy(() => import('./components/Admin/SchoolComment'));
const LinkStudents = lazy(() => import('./components/Admin/LinkStudents'));

const AddAdmin = lazy(() => import('./components/Admin/addAdmin'));

const ActiveEnrollmentsPerTerm = lazy(() => import('./components/Reports/ActiveEnrollmentsPerTerm'));
const ActiveStudentEnrollmentsWithAs = lazy(() => import('./components/Reports/ActiveStudentEnrollmentsWithAs'));
const ActiveStudentEnrollments = lazy(() => import('./components/Reports/ActiveStudentEnrollments'));
const AllStudentsGPA = lazy(() => import('./components/Reports/AllStudentsGPA'));
const OldAllStudentsGPA = lazy(() => import('./components/Reports/AllStudentsGPAOld'));
const BillingGroupEngagementType = lazy(() => import('./components/Reports/BillingGroupEngagementType'));
const EnrollmentConfirmation = lazy(() => import('./components/Reports/EnrollmentConfirmation'));
const EOSCollegeMatriculations = lazy(() => import('./components/Reports/EOSCollegeMatriculations'));
const StudentEnrollmentHistoryToDateWTerm = lazy(() => import('./components/Reports/StudentEnrollmentHistoryToDateWTerm'));
const StudentInformation = lazy(() => import('./components/Reports/StudentInformation'));
const ReconcileStudent = lazy(() => import('./components/Admin/ReconcileStudent'));
const StudentChangeStatus = lazy(() => import('./components/Admin/StudentChangeStatus'));
const AllStudentChangeStatus = lazy(() => import('./components/Admin/AllStudentChangeStatus'));
const DownloadingOfStudnetProgress = lazy(() => import('./components/Admin/DownloadingOfStudnetProgress'));
const StudentAssignLearningPath = lazy(() => import('./components/Admin/StudentAssignLearningPath'));
const RosterList = lazy(() => import('./components/Admin/Roster'));
const RosterForASI = lazy(() => import('./components/Admin/RosterForASI'));
const EnrollmentTranfer = lazy(() => import('./components/Admin/EnrollmentTranfer'));
const RosterListForTea = lazy(() => import('./components/Teacher/RosterList'));
const RosterListForTeaASI = lazy(() => import('./components/Teacher/RosterListForASI'));
const StudentRequestEnrollment = lazy(() => import('./components/Admin/StudentRequestEnrollment'));
const StudentRequestEnrollmentTea = lazy(() => import('./components/Teacher/StudentRequestEnrollment'));
const StudentRequestEnrollmentSt = lazy(() => import('./components/Students/StudentRequestEnrollment'));
const AddCommentsDocumentsForRoster = lazy(() => import('./components/Admin/AddCommentsDocumentsForRoster'));
const ContentAccessDialog = lazy(() => import('./components/Admin/ContentAccessDialog'));
const AddCommentsDocumentsForRosterTea = lazy(() => import('./components/Teacher/AddCommentsDocumentsForRoster'));
const FeatureAccessArchitecture = lazy(() => import('./components/Admin/FeatureAccessArchitecture'));
const SchoolBasedTags = lazy(() => import('./components/Admin/SchoolBasedTags'));
const AddMultiCommentsDocumentsForRoster = lazy(() => import('./components/Admin/AddMultiCommentsDocumentsForRoster'));
const AddMultiCommentsDocumentsForRosterForTea = lazy(() => import('./components/Teacher/AddMultiCommentsDocumentsForRoster'));
const ViewLearningPathSTInAd = lazy(() => import('./components/Admin/ViewLearningPath'));
const IDCard = lazy(() => import('./components/Admin/IDCard'));
const UploadProgressFile = lazy(() => import('./components/Admin/UploadProgressFile'));
const AddGlobalSection = lazy(() => import('./components/Admin/addGlobalSection'));
const UploadProgressFileTea = lazy(() => import('./components/Teacher/UploadProgressFile'));
const ExtensionRequestTea = lazy(() => import('./components/Teacher/ExtensionRequest'));
const ChangeStatusEnrollOfStudent = lazy(() => import('./components/Admin/ChangeStatusEnrollOfStudent'));
const ConnectionClassToSectionSyn = lazy(() => import('./components/Admin/ConnectionClassToSectionSyn'));
const UploadStudentBulk = lazy(() => import('./components/Admin/UploadStudentBulk'));
const AdminDetail = lazy(() => import('./components/Admin/AdminDetail'));
const SecurityTags = lazy(() => import('./components/Admin/SecurityTags'));
const LinkParents = lazy(() => import('./components/Admin/LinkParents'));
const RecipientBulkTranscript = lazy(() => import('./components/Admin/RecipientBulkTranscript'));
const GradebookOfStudent = lazy(() => import('./components/Admin/gradebookOfStudent'));
const Uploadothergrade = lazy(() => import('./components/Admin/Uploadothergrade'));
const UploadEnrollments = lazy(() => import('./components/Admin/UploadEnrollments'));
const StudentCertificate = lazy(() => import('./components/Admin/StudentCertificate'));
const SendMailtoUserAd = lazy(() => import('./components/Admin/SendMailtoUser'));
const EditMultipleGrade = lazy(() => import('./components/Admin/editMultipleGrade'));
const LinkAdvisor = lazy(() => import('./components/Admin/LinkAdvisor'));
const TransferSchool = lazy(() => import('./components/Admin/TransferSchool'));
const AllicationChangeSchool = lazy(() => import('./components/Admin/AllicationChangeSchool'));
const StudentsBulkAssignment = lazy(() => import('./components/Admin/StudentsBulkAssignment'));
const UploadParticipationGradeBonusPoin = lazy(() => import('./components/Admin/UploadParticipationGradeBonusPoin'));
const StudentRequestEnrollmentAddCommentDocument = lazy(() => import('./components/Admin/StudentRequestEnrollmentAddCommentDocument'));
const DashboardChart = lazy(() => import('./components/Admin/dashboardChart'));
const TylerSynchronizationforAZSchools = lazy(() => import('./components/Admin/TylerSynchronizationforAZSchools'));
const SchoolTags = lazy(() => import('./components/Admin/SchoolTags'));
const EventReassignments = lazy(() => import('./components/Admin/EventReassignments'));
const StaffReassignments = lazy(() => import('./components/Admin/StaffReassignments'));
const StaffTransfersResources = lazy(() => import('./components/Admin/StaffTransfersResources'));
const StaffTransfersSections = lazy(() => import('./components/Admin/StaffTransfersSections'));
const TeacherAdvisees = lazy(() => import('./components/Admin/TeacherAdvisees'));
const TeacherSections = lazy(() => import('./components/Admin/TeacherSections'));
const AdminDetailCommentAndDocument = lazy(() => import('./components/Admin/AdminDetailCommentAndDocument'));
const AdminQualification = lazy(() => import('./components/Admin/AdminQualification'));
const AdminEditLog = lazy(() => import('./components/Admin/AdminEditLog'));
const AdminCommunications = lazy(() => import('./components/Admin/AdminCommunications'));
const UploadBatchDataUpdate = lazy(() => import('./components/Admin/UploadBatchDataUpdate'));
const CopyCourses = lazy(() => import('./components/Admin/CopyCourses'));
const CourseOpenAi = lazy(() => import('./components/Admin/CourseOpenAi'));
const UpdateAICourse = lazy(() => import('./components/Admin/UpdateAICourse'));
const AIUnitlist = lazy(() => import('./components/Admin/AIUnitlist'));
const CourseOpenAiLesson = lazy(() => import('./components/Admin/CourseOpenAiLesson'));
const ViewContentLesson = lazy(() => import('./components/Admin/ViewContentLesson'));
const AllParentsChangeStatus = lazy(() => import('./components/Admin/AllParentsChangeStatus'));
const AddUserSchoolGroup = lazy(() => import('./components/Admin/addUserSchoolGroup'));
const AssigningMultipleUserToSchool = lazy(() => import('./components/Admin/AssigningMultipleUserToSchool'));
const Uploadsectionstatus = lazy(() => import('./components/Admin/Uploadsectionstatus'));
const ApplicationStatus = lazy(() => import('./components/Admin/ApplicationStatus'));
const AdminList = lazy(() => import('./components/Admin/AdminList'));
const ViewGradeDetail = lazy(() => import('./components/Admin/ViewGradeDetail'));

const HeaderSuperAdmin = lazy(() => import('./components/HeaderSuperAdmin'));
const FooterSuperAdmin = lazy(() => import('./components/FooterSuperAdmin'));
const HomeSuper = lazy(() => import('./components/Superadmin'));
const SchoolList = lazy(() => import('./components/Superadmin/schoolList'));
const Schoolgroup = lazy(() => import('./components/Superadmin/schoolgroup'));
const ConfigurationSchoolGroup = lazy(() => import('./components/Superadmin/ConfigurationSchoolGroup'));
const AddSchool = lazy(() => import('./components/Superadmin/AddSchool'));
const EditSchool = lazy(() => import('./components/Superadmin/EditSchool'));
const AddSchoolGroup = lazy(() => import('./components/Superadmin/AddSchoolGroup'));
const EditSchoolGroup = lazy(() => import('./components/Superadmin/EditSchoolGroup'));
const ZoomRedirect = lazy(() => import('./components/Login/ZoomRedirect'));
const GlobalUserDirectory = lazy(() => import('./components/Superadmin/GlobalUserDirectory'));
const EditUserSchoolTag = lazy(() => import('./components/Superadmin/editUserSchoolTag'));
const EditUserRoleTag = lazy(() => import('./components/Superadmin/editUserRoleTag'));
const UserRoles = lazy(() => import('./components/Superadmin/UserRoles'));
const ContentIntegration = lazy(() => import('./components/Superadmin/ContentIntegration'));
const UploadCourse = lazy(() => import('./components/Superadmin/UploadCourse'));
const Eventqueue = lazy(() => import('./components/Superadmin/eventqueue'));
const NotificationRule = lazy(() => import('./components/Superadmin/notificationRule'));
const CopyNotificationRules = lazy(() => import('./components/Superadmin/CopyNotificationRules'));
const Addnotificationsrules = lazy(() => import('./components/Superadmin/Addnotificationsrules'));
const Editnotificationsrules = lazy(() => import('./components/Superadmin/Editnotificationsrules'));
const Addeventqueue = lazy(() => import('./components/Superadmin/Addeventqueue'));
const Editnotificationqueue = lazy(() => import('./components/Superadmin/Editeventqueue'));
const Notificationsqueue = lazy(() => import('./components/Superadmin/Notificationsqueue'));
const ADSL = lazy(() => import('./components/Superadmin/adsl'));
const Viewnotificationqueue = lazy(() => import('./components/Superadmin/viewnotificationqueue'));
const EditCustomRoleTag = lazy(() => import('./components/Superadmin/EditCustomRoleTag'));
const ViewConsolidatedTag = lazy(() => import('./components/Superadmin/ViewConsolidatedTag'));
const StudentDetail = lazy(() => import('./components/Superadmin/StudentDetail'));
const ParentDetailSUP = lazy(() => import('./components/Superadmin/ParentDetail'));
const TeacherDetailSUP = lazy(() => import('./components/Superadmin/teacherDetail'));
const SecurityTagDictionary = lazy(() => import('./components/Superadmin/SecurityTagDictionary'));
const EditSecurityTagsDictionary = lazy(() => import('./components/Superadmin/EditSecurityTagsDictionary'));
const AddSecurityTagsDictionary = lazy(() => import('./components/Superadmin/AddSecurityTagsDictionary'));
const MultiLingual = lazy(() => import('./components/Superadmin/MultiLingual'));
const Translations = lazy(() => import('./components/Superadmin/Translations'));
const AddLingual = lazy(() => import('./components/Superadmin/AddLingual'));
const UpdateTranlation = lazy(() => import('./components/Superadmin/UpdateTranlation'));
const EditAllTranlation = lazy(() => import('./components/Superadmin/EditAllTranlation'));
const UploadTranlation = lazy(() => import('./components/Superadmin/UploadTranlation'));
const MasterCourseCatalog = lazy(() => import('./components/Superadmin/MasterCourseCatalog'));
const AddMasterCourseCatalog = lazy(() => import('./components/Superadmin/AddMasterCourseCatalog'));
const EditMasterCourseCatalog = lazy(() => import('./components/Superadmin/EditMasterCourseCatalog'));
const GlobalDeparment = lazy(() => import('./components/Superadmin/GlobalDeparment'));
const UpdateGlobalDepartments = lazy(() => import('./components/Superadmin/UpdateGlobalDepartments'));
const LanguageList = lazy(() => import('./components/Superadmin/LanguageList'));
const UpdateLanguese = lazy(() => import('./components/Superadmin/UpdateLanguese'));
const CopyNotificationRulesToSchoolGroup = lazy(() => import('./components/Superadmin/CopyNotificationRulesToSchoolGroup'));
const ObjectStateNotificationRule = lazy(() => import('./components/Superadmin/ObjectStateNotificationRule'));
const TestOpenAi = lazy(() => import('./components/Superadmin/TestOpenAi'));
const RequestType = lazy(() => import('./components/BasicStructureOfGoogle/RequestType'));
const RequestTemplate = lazy(() => import('./components/BasicStructureOfGoogle/RequestTemplate'));
const RequestUser = lazy(() => import('./components/BasicStructureOfGoogle/RequestUser'));
const Callback = lazy(() => import('./components/Login/Callback'));
const RequestRule = lazy(() => import('./components/BasicStructureOfGoogle/RequestRule'));
const RequestQueue = lazy(() => import('./components/BasicStructureOfGoogle/RequestQueue'));



const Router = () =>{ 
	const cookies = new Cookies()
	//const token = cookies.get(TOKEN_NAME);
	const token=window.localStorage.getItem('TOKEN_NAME');
	let role_login=window.localStorage.getItem('last_role');
	let USERLOG=(parseInt(role_login)===1)?'USERLOGSUP':'USERLOG';
	let userInfo=cookies.get('');
	let userInfoN=window.localStorage.getItem(USERLOG);
	let last_role=parseInt(role_login)

	let adlsadmin = false
	let adlsinvisible = false

	let setting_config = JSON.parse(window.localStorage.getItem('user_settings'));
    if (setting_config != undefined && setting_config != null && setting_config != '') {      
      adlsadmin = setting_config.hasOwnProperty('adlsadmin') ? setting_config.adlsadmin : false;
	  //adlsinvisible = setting_config.hasOwnProperty('adlsinvisible') ? setting_config.adlsinvisible : false;
    }

	//let last_role=4;
	if(userInfo!=='' && userInfo!==undefined){
		 //last_role=parseInt(userInfo.last_role);
	}
	if(userInfoN!=undefined && userInfoN!=null && userInfoN!=''){
		window.sessionStorage.setItem('previous_href', '');
		userInfoN=JSON.parse(userInfoN);
		//last_role=parseInt(userInfoN.last_role);
	} else {
		let href = window.location.href.includes('/login') ? '' : window.location.href;
		window.sessionStorage.setItem('previous_href', href);
	}

	
	//console.log(token,last_role)
	let hosts=window.location.hostname
	hosts= hosts.split(".")
	let hostis=(hosts!==undefined && hosts!==null && hosts[0]!==undefined)?hosts[0]:'';
	return (            
        <div>	
	
			<Suspense fallback={<div>Loading...</div>}>	
		    <Switch>
				<Route exact path='/' render={() => (
					(token && token!==undefined  && token!==null && token!=='' && last_role!=undefined && last_role >0)?(
						<Redirect to="/home" />
					):
					<Redirect to="/login" />
				)} /> 
				<Route path='/home' render={() => (
					(token && token!==undefined  && token!==null && token!=='')?(
						
						(last_role===4)?
							<div className="student-page">
								<ChatBox />
								<Header />
								<Home />  
								<Footer />
							</div>:
						(last_role===5)?
							<div className="parent-page">
								<ChatBox />
								<HeaderPR />
								<HomePR />
								<FooterPR />
							</div>	:
						(last_role===3)?
							<div className="teacher-page">
								<HeaderTeacher />
								<HomeTeacher />  
								<FooterTeacher />
							</div>  :(last_role===2)?
							<div className="admin-page">
								<HeaderAdmin />
								<HomeAd />  
								<FooterAdmin />
							</div> :(last_role===1)?
							<div className="admin-page">
								<HeaderSuperAdmin />
								<HomeSuper />  
								<FooterSuperAdmin />
							</div>:<Redirect to="/login" />
							
						):<Redirect to="/login" />
                      
                )} />
				
				<Route path='/learning' render={() => (
					(token && token!==undefined  && token!==null && token!=='')?(
							<div className="student-page">
								<ChatBox />
								<Header />
								<Learning />  
								<Footer />
							</div>
						):<Redirect to="/login" />
                      
                )} />
				<Route path='/schedule' render={() => (
					(token && token!==undefined  && token!==null && token!=='')?(
							(last_role===3)?
							<div className="teacher-page">
								<HeaderTeacher />
								<ScheduleTea />  
								<FooterTeacher />
							</div>:
							(last_role===4)?
							<div className="student-page">
								<ChatBox />
								<Header />
								<Schedule />  
								<Footer />
							</div>:<Redirect to="/login" />
						):<Redirect to="/login" />
                      
                )} />
				<Route path='/progress-tracking' render={() => (
					(token && token!==undefined  && token!==null && token!=='')?(
							(last_role===4)?
								<div className="student-page">
									<ChatBox />
									<Header />
									<Progresstracking />  
									<Footer />
								</div>:
								<div className="parent-page">
									<ChatBox />
									<HeaderPR />
									<ProgresstrackingPR />
									<FooterPR />
								</div>	
								
						):<Redirect to="/login" />
                      
                )} />
				<Route path='/progress-tracking-current' render={() => (
					(token && token!==undefined  && token!==null && token!=='')?(
							(last_role===4)?
								<div className="student-page">
									<ChatBox />
									<Header />
									<CurrentProgresstrackin />  
									<Footer />
								</div>:
								<div className="parent-page">
									<ChatBox />
									<HeaderPR />
									<CurrentCourseProgress />
									<FooterPR />
								</div>	
								
						):<Redirect to="/login" />
                      
                )} />
				<Route path='/resources' render={() => (
					(token && token!==undefined  && token!==null && token!=='')?(
							(last_role===2)?
							<div className="admin-page">
								<HeaderAdmin />
								<ResourcelistAd />  
								<FooterAdmin />
							</div>:(last_role===3)?
							<div className="teacher-page">
								<HeaderTeacher />
								<Resourcelist />  
								<FooterTeacher />
							</div>:(last_role===4)?
							<div className="student-page">
								<ChatBox />
								<Header />
								<Resources />  
								<Footer />
							</div>:(last_role===5)?
							<div className="parent-page">
								<ChatBox />
								<HeaderPR />
								<ResourcesPR />  
								<FooterPR />
							</div>:<Redirect to="/login" />
						):<Redirect to="/login" />
                      
                )} />
				<Route path='/resources-module' render={() => (
					(token && token!==undefined  && token!==null && token!=='')?(
							(last_role===4)?
							<div className="student-page">
								<ChatBox />
								<Header />
								<ResourcesModule />  
								<Footer />
							</div>:
							<div className="parent-page">
								<HeaderPR />
								<ResourcesModulePR />  
								<FooterPR />
							</div>
						):<Redirect to="/login" />
                      
                )} />
				<Route path='/students' render={() => (
					(token && token!==undefined  && token!==null && token!=='')?(
							<div>
								<ChatBox />
								<Header />
								<Students />  
								<Footer />
							</div>
						):<Redirect to="/login" />
                      
                )} />
				<Route path='/parent' render={() => (
					(token && token!==undefined  && token!==null && token!=='')?(
							<div className="parent-page">
								<HeaderPR />
								<Parent />  
								<FooterPR />
							</div>
						):<Redirect to="/login" />
                      
                )} />
				<Route path='/messages' render={() => (
					(token && token!==undefined  && token!==null && token!=='')?(
							<div>
								<ChatBox />
								<Header />
								<Messages />  
								<Footer />
							</div>
						):<Redirect to="/login" />
                      
                )} />
				<Route path='/gradebook' render={() => (
					(token && token!==undefined  && token!==null && token!=='')?(
							(last_role===2)?
							<div className="admin-page">
								<HeaderAdmin />
								<GradebookForAd />  
								<FooterAdmin />
							</div>:(last_role===3)?
							<div className="teacher-page">
								<HeaderTeacher />
								<Gradebook />  
								<FooterTeacher />
							</div>:<Redirect to="/login" />	
						):<Redirect to="/login" />
                      
                )} />
				<Route path='/teacher' render={() => (
					(token && token!==undefined  && token!==null && token!=='')?(
							(last_role===3)?
							<div className="teacher-page">
								<HeaderTeacher />
								<Teacher />  
								<FooterTeacher />
							</div>:<Redirect to="/login" />	
						):<Redirect to="/login" />
                      
                )} />
				<Route path='/addUnit' render={() => (
					(token && token!==undefined  && token!==null && token!=='')?(
							(last_role===2)?
							<div className="admin-page">
								<HeaderAdmin />
								<AddUnitForAd />  
								<FooterAdmin />
							</div>:(last_role===3)?
							<div className="teacher-page">
								<HeaderTeacher />
								<AddUnit />  
								<FooterTeacher />
							</div>:<Redirect to="/login" />	
						):<Redirect to="/login" />
                      
                )} />
				<Route path='/schedule-month' render={() => (
					(token && token!==undefined  && token!==null && token!=='')?(
							(last_role===3)?
							<div className="teacher-page">
								<HeaderTeacher />
								<ScheduleMonth />  
								<FooterTeacher />
							</div>:<Redirect to="/login" />	
						):<Redirect to="/login" />
                      
                )} />
				<Route path='/messages-inbox' render={() => (
					(token && token!==undefined  && token!==null && token!=='')?(
							(last_role===1)?
								<div className="admin-page">
									<HeaderSuperAdmin />
									{(adlsinvisible==false || adlsadmin == true)?<MessagesInboxAd />:'Access denied'}  
									<FooterSuperAdmin />
								</div>:
							(last_role===2)?
							<div className="admin-page">
								<HeaderAdmin />								  
								{(adlsinvisible==false || adlsadmin == true)?<MessagesInboxAd />:'Access denied'} 
								<FooterAdmin />
							</div>:	
							(last_role===3)?
								<div className="teacher-page">
									<HeaderTeacher />									
									{(adlsinvisible==false || adlsadmin == true)?<MessagesInboxTea />:'Access denied'}  
									<FooterTeacher />
								</div>:
							(last_role===4)?
								<div className="student-page">
									<ChatBox />
									<Header />									
									{(adlsinvisible==false || adlsadmin == true)?<MessagesInbox />:'Access denied'}  
									<Footer />
								</div>:
								(last_role===5)?
								<div className="parent-page">
									<HeaderPR />									 
									{(adlsinvisible==false || adlsadmin == true)?<MessagesInboxPR />:'Access denied'} 
									<FooterPR />
								</div>:<Redirect to="/login" />								
						):<Redirect to="/login" />
                      
                )} />
				<Route path='/messages-unread' render={() => (
					(token && token!==undefined  && token!==null && token!=='')?(
						(last_role===1)?
							<div className="admin-page">
								<HeaderSuperAdmin />								  
								{(adlsinvisible==false || adlsadmin == true)?<MessagesInboxAd />:'Access denied'}
								<FooterSuperAdmin />
							</div>:
							(last_role===2)?
								<div className="admin-page">
									<HeaderAdmin />									 
									{(adlsinvisible==false || adlsadmin == true)?<MessagesInboxAd />:'Access denied'} 
									<FooterAdmin />
								</div>:
							(last_role===3)?
								<div className="teacher-page">
									<HeaderTeacher />									  
									{(adlsinvisible==false || adlsadmin == true)?<MessagesInboxTea />:'Access denied'}
									<FooterTeacher />
								</div>:
							(last_role===4)?
								<div className="student-page">
									<ChatBox />
									<Header />									  
									{(adlsinvisible==false || adlsadmin == true)?<MessagesInbox />:'Access denied'}
									<Footer />
								</div>:
								(last_role===5)?
								<div className="parent-page">
									<HeaderPR />									  
									{(adlsinvisible==false || adlsadmin == true)?<MessagesInboxPR />:'Access denied'}
									<FooterPR />
								</div>:<Redirect to="/login" />								
						):<Redirect to="/login" />
                      
                )} />
				<Route path='/messages-sent' render={() => (
					(token && token!==undefined  && token!==null && token!=='')?(
						(last_role===1)?
							<div className="admin-page">
								<HeaderSuperAdmin />								  
								{(adlsinvisible==false || adlsadmin == true)?<MessagesSentAd />:'Access denied'}
								<FooterSuperAdmin />
							</div>:
						(last_role===2)?
							<div className="admin-page">
								<HeaderAdmin />								  
								{(adlsinvisible==false || adlsadmin == true)?<MessagesSentAd />:'Access denied'}
								<FooterAdmin />
							</div>:
						(last_role===3)?
							<div className="teacher-page">
								<HeaderTeacher />								  
								{(adlsinvisible==false || adlsadmin == true)?<MessagesSentTea />:'Access denied'}
								<FooterTeacher />
							</div>:
						(last_role===4)?
							<div>
								<ChatBox />
								<Header />								  
								{(adlsinvisible==false || adlsadmin == true)?<MessagesSent />:'Access denied'}
								<Footer />
							</div>:(last_role===5)?
							<div className="parent-page">
								<HeaderPR />								  
								{(adlsinvisible==false || adlsadmin == true)?<MessagesSentPR />:'Access denied'}
								<FooterPR />
							</div>:<Redirect to="/login" />
						):<Redirect to="/login" />
                      
                )} />
				<Route path='/messages-trash' render={() => (
					(token && token!==undefined  && token!==null && token!=='')?(
						(last_role===1)?
							<div className="admin-page">
								<HeaderSuperAdmin />								 
								{(adlsinvisible==false || adlsadmin == true)?<MessagestrashAd />:'Access denied'} 
								<FooterSuperAdmin />
							</div>:
						(last_role===2)?
							<div className="admin-page">
								<HeaderAdmin />								  
								{(adlsinvisible==false || adlsadmin == true)?<MessagestrashAd />:'Access denied'}
								<FooterAdmin />
							</div>:
						(last_role===3)?
							<div className="teacher-page">
								<HeaderTeacher />								  
								{(adlsinvisible==false || adlsadmin == true)?<MessagestrashTea />:'Access denied'}
								<FooterTeacher />
							</div>:
						(last_role===4)?
							<div>
								<ChatBox />
								<Header />								  
								{(adlsinvisible==false || adlsadmin == true)?<Messagestrash />:'Access denied'}
								<Footer />
							</div>:(last_role===5)?
							<div className="parent-page">
								<HeaderPR />								  
								{(adlsinvisible==false || adlsadmin == true)?<MessagestrashPR />:'Access denied'}
								<FooterPR />
							</div>:<Redirect to="/login" />
						):<Redirect to="/login" />
                      
                )} />
				<Route path='/messages-starred' render={() => (
					(token && token!==undefined  && token!==null && token!=='')?(
						(last_role===1)?
							<div className="admin-page">
								<HeaderSuperAdmin />								  
								{(adlsinvisible==false || adlsadmin == true)?<MessagesstarredAd />:'Access denied'}
								<FooterSuperAdmin />
							</div>:
						(last_role===2)?
							<div className="admin-page">
								<HeaderAdmin />								  
								{(adlsinvisible==false || adlsadmin == true)?<MessagesstarredAd />:'Access denied'}
								<FooterAdmin />
							</div>:
						(last_role===3)?
							<div className="teacher-page">
								<HeaderTeacher />					  
								{(adlsinvisible==false || adlsadmin == true)?<MessagesstarredTea />:'Access denied'}
								<FooterTeacher />
							</div>:
						(last_role===4)?
							<div>
								<ChatBox />
								<Header />								
								{(adlsinvisible==false || adlsadmin == true)?<Messagesstarred />:'Access denied'}  
								<Footer />
							</div>:(last_role===5)?
							<div className="parent-page">
								<HeaderPR />								  
								{(adlsinvisible==false || adlsadmin == true)?<MessagesstarredPR />:'Access denied'}
								<FooterPR />
							</div>:<Redirect to="/login" />
						):<Redirect to="/login" />
                      
                )} />
				
				<Route path='/discussions' render={() => (
					(token && token!==undefined  && token!==null && token!=='')?(
						(last_role===2)?
							<div className="admin-page">
								<HeaderAdmin />
								<DiscussionsAd />  
								<FooterAdmin />
							</div>:
						(last_role===3)?
							<div className="teacher-page">
								<HeaderTeacher />
								<DiscussionsTea />  
								<FooterTeacher />
							</div>:
						(last_role===4)?
							<div>
								<Header />
								<Discussions />  
								<Footer />
							</div>:(last_role===5)?
							<div className="parent-page">
								<HeaderPR />
								<DiscussionsPR />  
								<FooterPR />
							</div>:<Redirect to="/login" />
						):<Redirect to="/login" />
                      
                )} />
				<Route path='/edit-discussion-thread' render={() => (
					(token&&token!==undefined&&token!==null&&token!=='')? (
						(last_role===2)?
							<div className="admin-page">
								<HeaderAdmin />
								<EditDiscussionThread />
								<FooterAdmin />
							</div>:
							(last_role===3)?
								<div className="teacher-page">
									<HeaderTeacher />
									<EditDiscussionThread />
									<FooterTeacher />
								</div>:<Redirect to="/login" />
					):<Redirect to="/login" />

				)} />
				<Route path='/discussion-thread' render={() => (
					(token&&token!==undefined&&token!==null&&token!=='')? (
						(last_role===2)?
							<div className="admin-page">
								<HeaderAdmin />
								<DiscussionThread />
								<FooterAdmin />
							</div>:
							(last_role===3)?
								<div className="teacher-page">
									<HeaderTeacher />
									<DiscussionThread />
									<FooterTeacher />
								</div>:
							(last_role===4)?
							<div>
								<Header />
								<DiscussionThread />
								<Footer />
							</div>:<Redirect to="/login" />
					):<Redirect to="/login" />

				)} />
				<Route path='/setting' render={() => (
					(token && token!==undefined  && token!==null && token!=='')?(
						(last_role===2)?
							<div className="admin-page">
								<HeaderAdmin />
								<AccountSetting />  
								<FooterAdmin/>
							</div>:
						(last_role===3)?
						<div className="teacher-page">
							<HeaderTeacher />
							<SettingTea />  
							<FooterTeacher />
						</div>:
						(last_role===4)?
							<div>
								<ChatBox />
								<Header />
								<Setting />  
								<Footer />
							</div>:
							<div className="parent-page">
								<ChatBox />
								<HeaderPR />
								<SettingPR />  
								<FooterPR />
							</div>
						):<Redirect to="/login" />
                      
                )} />
				<Route path='/payment-terms-of-use' render={() => (
					(token && token !== undefined && token !== null && token !== '') ? (
						(last_role === 2) ?
							<div className="admin-page">
								<HeaderAdmin />
								<PaymentTermsOfUse />
								<FooterAdmin />
							</div> :
							(last_role === 3) ?
								<div className="teacher-page">
									<HeaderTeacher />
									<PaymentTermsOfUse />
									<FooterTeacher />
								</div> :
								(last_role === 4) ?
									<div>
										<Header />
										<PaymentTermsOfUse />
										<Footer />
									</div> : (
										<div className="parent-page">
											<HeaderPR />
											<PaymentTermsOfUse />
											<FooterPR />
										</div>
									)
					) : <Redirect to="/login" />

				)} />
				<Route path='/new-message' render={() => (
					(token && token!==undefined  && token!==null && token!=='')?(
							(last_role===2)?
							<div className="admin-page">
								<HeaderAdmin />								  
								{(adlsinvisible==false || adlsadmin == true)?<NewMessageAd />:'Access denied'}
								<FooterAdmin />
							</div>:
							(last_role===3)?
							<div className="teacher-page">
								<HeaderTeacher />								 
								{(adlsinvisible==false || adlsadmin == true)?<NewMessageTea /> :'Access denied'}
								<FooterTeacher />
							</div>:
							(last_role===4)?
								<div className="student-page">
									<ChatBox />
									<Header />									 
									{(adlsinvisible==false || adlsadmin == true)?<NewMessage />:'Access denied'}
									<Footer />
								</div>:(last_role===5)?
								<div className="parent-page">
									<HeaderPR />
									{(adlsinvisible==false || adlsadmin == true)?<NewMessagePR />:'Access denied'}
									<FooterPR />
								</div>:<Redirect to="/login" />
						):<Redirect to="/login" />
                      
                )} />
				<Route path='/global-message' render={() => (
					(token && token!==undefined  && token!==null && token!=='')?(
							(last_role===1)?
							<div className="admin-page">
								<HeaderSuperAdmin />
								<GlobalMessage />  
								<FooterSuperAdmin />
							</div>:
							(last_role===2)?
							<div className="teacher-page">
								<HeaderAdmin />
								<GlobalMessage />  
								<FooterAdmin />
							</div>:<Redirect to="/login" />
						):<Redirect to="/login" />
                      
                )} />
					<Route path='/signature-block' render={() => (
					(token && token!==undefined  && token!==null && token!=='')?(
						(last_role===1)?
							<div className="admin-page">
								<HeaderSuperAdmin />
								<SignatureBlock />  
								<FooterSuperAdmin />
							</div>:
							(last_role===2)?
							<div className="admin-page">
								<HeaderAdmin />
								<SignatureBlock />  
								<FooterAdmin />
							</div>:
							(last_role===3)?
							<div className="teacher-page">
								<HeaderTeacher />
								<SignatureBlock />  
								<FooterTeacher />
							</div>:
							<Redirect to="/login" />
							):<Redirect to="/login" />
                )} />
				
				<Route path='/calendar' render={() => (
					(token && token!==undefined  && token!==null && token!=='')?(
							<div className="parent-page">
								<ChatBox />
								<HeaderPR />
								<CalendarPR />
								<FooterPR />
							</div>	
							
						):<Redirect to="/login" />
                      
                )} />
				
				<Route path='/student-in-teacher' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===3)?(
							<div className="teacher-page">
								<HeaderTeacher />
								<StudentsInTeacher />
								<FooterTeacher />
							</div>	
							
						):<Redirect to="/login" />
                      
                )} />
				<Route path='/student-in-admin' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2)?(
							<div className="admin-page">
								<HeaderAdmin />
								<StudentsInAd />
								<FooterAdmin />
							</div>	
							
						):<Redirect to="/login" />
                      
                )} />
				<Route path='/teachers' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2)?(
							<div className="admin-page">
								<HeaderAdmin />
								<Teachers />
								<FooterAdmin />
							</div>	
							
						):<Redirect to="/login" />
                      
                )} />
				<Route path='/applications' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2)?(
							<div className="admin-page">
								<HeaderAdmin />
								<Applications />
								<FooterAdmin />
							</div>	
							
						):<Redirect to="/login" />
                      
                )} />
				<Route path='/requests' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2)?(
							<div className="admin-page">
								<HeaderAdmin />
								<RequestList />
								<FooterAdmin />
							</div>	
							
						):<Redirect to="/login" />
                      
                )} />				
				
				<Route path='/add-student' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1)?(
							<div className="admin-page">
								<HeaderSuperAdmin />
								<AddStudent />
								<FooterSuperAdmin />
							</div>	
							
						):((token && token!==undefined  && token!==null && token!=='') && last_role===2)?(
							<div className="admin-page">
								<HeaderAdmin />
								<AddStudent />
								<FooterAdmin />
							</div>	
							
						):<Redirect to="/login" />
                      
                )} />
				<Route path='/user-directory' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2)?(
							<div className="admin-page">
								<HeaderAdmin />
								<UserDirectory />
								<FooterAdmin />
							</div>	
							
						):<Redirect to="/login" />
                      
                )} />
				
				<Route path='/section' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2)?(
							<div className="admin-page">
								<HeaderAdmin />
								<SectionAd />
								<FooterAdmin />
							</div>	
							
						):((token && token!==undefined  && token!==null && token!=='') && last_role===3)?(
							<div className="teacher-page">
								<HeaderTeacher />
								<Section />
								<FooterTeacher />
							</div>	
							
						):<Redirect to="/login" />
                      
                )} />
				<Route path='/section-change-status' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2)?(
							<div className="admin-page">
								<HeaderAdmin />
								<SectionStatus />
								<FooterAdmin />
							</div>	
							
						):((token && token!==undefined  && token!==null && token!=='') && last_role===3)?(
							<div className="teacher-page">
								<HeaderTeacher />
								<Section />
								<FooterTeacher />
							</div>	
							
						):<Redirect to="/login" />
                      
                )} />
				<Route path='/attendances' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<AttendancesAd />  
							<FooterAdmin />
						</div>   
					
					) :((token && token!==undefined  && token!==null && token!=='') && last_role===3) ? (
						<div className="teacher-page">
							<HeaderTeacher />
							<Attendances />  
							<FooterTeacher />
						</div>   
					
					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/courses' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<Course />  
							<FooterAdmin />
						</div>   
					
					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/add-course' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<AddCourse />  
							<FooterAdmin />
						</div>   
					
					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/edit-request' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							{/* <EditRequests />   */}
							<RequestList />
							<FooterAdmin />
						</div>   
					
					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/enrollment-request' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<RequestList />
							<FooterAdmin />
						</div>   
					
					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/edit-request-detail' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditRequestDetail />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
					)} />
				<Route path='/edit-request-view' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditRequestView />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/withdrawal-request-detail' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<WithdrawalRequestDetail />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
					)} />
				<Route path='/extension-request' render={() => (
					((token && token !== undefined && token !== null && token !== '') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<ExtensionRequest />
							<FooterAdmin />
						</div>
					) : ((token && token !== undefined && token !== null && token !== '') && last_role === 3) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<ExtensionRequestTea />
							<FooterAdmin />
						</div>
					) : ((token && token !== undefined && token !== null && token !== '') && last_role === 4) ? (
						<div className="student-page">
							<Header />
							<StudentExtensionRequest />
							<Footer />
						</div>
					) : ((token && token !== undefined && token !== null && token !== '') && last_role === 5) ? (
						<div className="parent-page">
							<HeaderPR />
							<StudentExtensionRequest />
							<FooterPR />
						</div>) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/extension-request-detail' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<ExtensionRequestDetail />
							<FooterAdmin />
						</div>
					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/enrollment-request-detail' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EnrollmentRequestDetail />
							<FooterAdmin />
						</div>
					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/transcript-request-detail' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<TranscriptRequestDetail />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/enrollment-rule' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<EnrollmentRules />
							<FooterSuperAdmin />
						</div>
					) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EnrollmentRules />
							<FooterAdmin />
						</div>
					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/messages-tags/:id/' render={() => (
					(token && token!==undefined  && token!==null && token!=='')?(
							(last_role===2)?
							<div className="admin-page">
								<HeaderAdmin />
								<MessagesTagsAd />  
								<FooterAdmin />
							</div>:
							(last_role===3)?
							<div className="teacher-page">
								<HeaderTeacher />
								<MessagesTagsTea />  
								<FooterTeacher />
							</div>:
							(last_role===4)?
							<div>
								<ChatBox />
								<Header />
								<MessagesTags />  
								<Footer />
							</div>:(last_role===5)?
							<div className="parent-page">
								<HeaderPR />
								<MessagesTagsPR />  
								<FooterPR />
							</div>:<Redirect to="/login" />
						):<Redirect to="/login" />
                      
                )} />
				<Route path='/add-other-grade/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2)?(
							<div className="admin-page">
								<HeaderAdmin />
								<AddOtherGrade />
								<FooterAdmin />
							</div>	
							
						):<Redirect to="/login" />
                      
                )} />
				<Route path='/add-other-grade' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2)?(
							<div className="admin-page">
								<HeaderAdmin />
								<AddOtherGrade />
								<FooterAdmin />
							</div>	
							
						):<Redirect to="/login" />
                      
                )} />
				
				<Route path='/environmental-science/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='')) ? (
						<div>
							<ChatBox />
							<Header />
							<EnvironmentalScience />  
							<Footer />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/environmental-science' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='')) ? (
						<div>
							<ChatBox />
							<Header />
							<EnvironmentalScience />  
							<Footer />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/class-info/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===5) ? (
						<div className="parent-page">
							<HeaderPR />
							<ClassInfo />  
							<FooterPR />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/class-info' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===5) ? (
						<div className="parent-page">
							<HeaderPR />
							<ClassInfo />  
							<FooterPR />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/student-info/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===5) ? (
						<div className="parent-page">
							<HeaderPR />
							<StudentInfo />  
							<FooterPR />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/student-info' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===5) ? (
						<div className="parent-page">
							<HeaderPR />
							<StudentInfo />  
							<FooterPR />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/individual-section/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EnvironmentalScienceAd />  
							<FooterAdmin />
						</div>   
					
                      ) :((token && token!==undefined  && token!==null && token!=='') && last_role===3) ? (
						<div className="teacher-page">
							<HeaderTeacher />
							<SectionDetail />  
							<FooterTeacher />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/individual-section' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EnvironmentalScienceAd />  
							<FooterAdmin />
						</div>   
					
                      ) :((token && token!==undefined  && token!==null && token!=='') && last_role===3) ? (
						<div className="teacher-page">
							<HeaderTeacher />
							<SectionDetail />  
							<FooterTeacher />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				
				<Route path='/roster/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<RosterAD />  
							<FooterAdmin/>
						</div>   
					
                      ) :((token && token!==undefined  && token!==null && token!=='') && last_role===3) ? (
						<div className="teacher-page">
							<HeaderTeacher />
							<Roster />  
							<FooterTeacher />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/roster' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<RosterAD />  
							<FooterAdmin/>
						</div>   
					
                      ) :((token && token!==undefined  && token!==null && token!=='') && last_role===3) ? (
						<div className="teacher-page">
							<HeaderTeacher />
							<Roster />  
							<FooterTeacher />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				
				<Route path='/student-full-path/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<InfoStudentInAd />  
							<FooterAdmin />
						</div>   
					
                      ) :((token && token!==undefined  && token!==null && token!=='') && last_role===3) ? (
						<div className="teacher-page">
							<HeaderTeacher />
							<InfoStudent />  
							<FooterTeacher />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/student-full-path' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<InfoStudentInAd />  
							<FooterAdmin />
						</div>   
					
                      ) :((token && token!==undefined  && token!==null && token!=='') && last_role===3) ? (
						<div className="teacher-page">
							<HeaderTeacher />
							<InfoStudent />  
							<FooterTeacher />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/student-certificate/:id/' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StudentCertificate />  
							<FooterAdmin />
						</div>   
					
							) :((token && token!==undefined  && token!==null && token!=='') && last_role===3) ? (
						<div className="teacher-page">
							<HeaderTeacher />
							<StudentCertificate />  
							<FooterTeacher />
						</div>   
					
							) :((token && token!==undefined  && token!==null && token!=='') && last_role===4) ? (
						<div className="student-page">
							<Header />
							<StudentCertificate />  
							<Footer />
						</div>   
					
							)  :((token && token!==undefined  && token!==null && token!=='') && last_role===5) ? (
						<div className="parent-page">
							<HeaderPR />
							<StudentCertificate />  
							<FooterPR />
						</div>   
					
							) : (
								<Redirect to="/login" />
							)
					)} />
				<Route path='/student-current-course/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<InfoStudentCurInAd />  
							<FooterAdmin />
						</div>   
					
                      ) :((token && token!==undefined  && token!==null && token!=='') && last_role===3) ? (
						<div className="teacher-page">
							<HeaderTeacher />
							<InfoStudentCur />  
							<FooterTeacher />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/student-current-course' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<InfoStudentCurInAd />  
							<FooterAdmin />
						</div>   
					
                      ) :((token && token!==undefined  && token!==null && token!=='') && last_role===3) ? (
						<div className="teacher-page">
							<HeaderTeacher />
							<InfoStudentCur />  
							<FooterTeacher />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/comment-document/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<CommentDocumentInAD />  
							<FooterAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===3) ? (
						<div className="teacher-page">
							<HeaderTeacher />
							<CommentDocument />  
							<FooterTeacher />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/comment-document' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<CommentDocumentInAD />  
							<FooterAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===3) ? (
						<div className="teacher-page">
							<HeaderTeacher />
							<CommentDocument />  
							<FooterTeacher />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				
				<Route path='/comment-document-review/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<CommentDocumentReview />  
							<FooterAdmin />
						</div>   

					) :(
						<Redirect to="/login" />
					)
				)} />
					<Route path='/products/' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
							<div className="admin-page">
								<HeaderSuperAdmin />
								<Products />
								<FooterSuperAdmin />
							</div>

						) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
							<div className="admin-page">
								<HeaderAdmin />
								<Products />
								<FooterAdmin />
							</div>

						) : (
							<Redirect to="/login" />
						)
					)} />
					<Route path='/notification-templates/' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
							<div className="admin-page">
								<HeaderSuperAdmin />
								<NotificationTemplates />
								<FooterSuperAdmin />
							</div>

						) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
							<div className="admin-page">
								<HeaderAdmin />
								<NotificationTemplates />
								<FooterAdmin />
							</div>

						) : (
							<Redirect to="/login" />
						)
					)} />
					<Route path='/notification-rule-template/' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
							<div className="admin-page">
								<HeaderSuperAdmin />
								<NotificationRuleTemplate />
								<FooterSuperAdmin />
							</div>

						) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
							<div className="admin-page">
								<HeaderAdmin />
								<NotificationRuleTemplate />
								<FooterAdmin />
							</div>

						) : (
							<Redirect to="/login" />
						)
					)} />
					<Route path='/object-state-rules/' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
							<div className="admin-page">
								<HeaderSuperAdmin />
								<ObjectStateNotificationRule />
								<FooterSuperAdmin />
							</div>

						) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
							<div className="admin-page">
								<HeaderAdmin />
								<ObjectStateNotificationRule />
								<FooterAdmin />
							</div>

						) : (
							<Redirect to="/login" />
						)
					)} />
					
					<Route path='/transcript-viewer-options/' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
							<div className="admin-page">
								<HeaderSuperAdmin />
								<TranscriptViewerOptions />
								<FooterSuperAdmin />
							</div>

						) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
							<div className="admin-page">
								<HeaderAdmin />
								<TranscriptViewerOptions />
								<FooterAdmin />
							</div>

						) : (
							<Redirect to="/login" />
						)
					)} />
				<Route path='/academic-records/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<AcademicRecordsInAD />  
							<FooterAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===3) ? (
						<div className="teacher-page">
							<HeaderTeacher />
							<AcademicRecords />  
							<FooterTeacher />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/academic-records' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<AcademicRecordsInAD />  
							<FooterAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===3) ? (
						<div className="teacher-page">
							<HeaderTeacher />
							<AcademicRecords />  
							<FooterTeacher />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/communications' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<CommunicationsInAD />  
							<FooterAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===3) ? (
						<div className="teacher-page">
							<HeaderTeacher />
							<Communications />  
							<FooterTeacher />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/communication-log/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<CommunicationLog />  
							<FooterAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===3) ? (
						<div className="teacher-page">
							<HeaderTeacher />
							<CommunicationLogTea />  
							<FooterTeacher />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/st-compliance-log' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StudentComplianceLogInAD />  
							<FooterAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===3) ? (
						<div className="teacher-page">
							<HeaderTeacher />
							<StudentComplianceLog />  
							<FooterTeacher />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/edit-request-list' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditRequestList />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/progress-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<ProgressReport />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<ProgressReport />
							<FooterAdmin />
						</div>

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 3) ? (
						<div className="admin-page">
							<HeaderTeacher />
							<ProgressReport />
							<FooterTeacher />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/application-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<ApplicationReport />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<ApplicationReport />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/teacher-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<TeacherReport />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<TeacherReport />
							<FooterAdmin />
						</div>

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 3) ? (
						<div className="admin-page">
							<HeaderTeacher />
							<TeacherReport />
							<FooterTeacher />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/student-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<StudentReport />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StudentReport />
							<FooterAdmin />
						</div>

					)  :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 3) ? (
						<div className="admin-page">
							<HeaderTeacher />
							<StudentReport />
							<FooterTeacher />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/parent-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<ParentReport />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<ParentReport />
							<FooterAdmin />
						</div>

					)  :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 3) ? (
						<div className="admin-page">
							<HeaderTeacher />
							<ParentReport />
							<FooterTeacher />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/roster-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<RosterReport />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<RosterReport />
							<FooterAdmin />
						</div>

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 3) ? (
						<div className="admin-page">
							<HeaderTeacher />
							<RosterReport />
							<FooterTeacher />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/section-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<SectionReport />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<SectionReport />
							<FooterAdmin />
						</div>

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 3) ? (
						<div className="admin-page">
							<HeaderTeacher />
							<SectionReport />
							<FooterTeacher />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/course-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<CourseReport />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<CourseReport />
							<FooterAdmin />
						</div>

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 3) ? (
						<div className="admin-page">
							<HeaderTeacher />
							<CourseReport />
							<FooterTeacher />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/full-progress-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<FullProgressReport />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<FullProgressReport />
							<FooterAdmin />
						</div>

					)  :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 3) ? (
						<div className="admin-page">
							<HeaderTeacher />
							<FullProgressReport />
							<FooterTeacher />
						</div>

					): (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/login-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<LogInReport />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<LogInReport />
							<FooterAdmin />
						</div>

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 3) ? (
						<div className="admin-page">
							<HeaderTeacher />
							<LogInReport />
							<FooterTeacher />
						</div>

					): (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/login-credential-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<LogInCredentialsReport />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<LogInCredentialsReport />
							<FooterAdmin />
						</div>

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 3) ? (
						<div className="admin-page">
							<HeaderTeacher />
							<LogInCredentialsReport />
							<FooterTeacher />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/enrollment-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<EnrollmentReport />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EnrollmentReport />
							<FooterAdmin />
						</div>

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 3) ? (
						<div className="admin-page">
							<HeaderTeacher />
							<EnrollmentReport />
							<FooterTeacher />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/enrollment-summary-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<EnrollmentSummaryReport />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EnrollmentSummaryReport />
							<FooterAdmin />
						</div>

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 3) ? (
						<div className="admin-page">
							<HeaderTeacher />
							<EnrollmentSummaryReport />
							<FooterTeacher />
						</div>

					): (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/student-contract-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<StudentContractReport />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StudentContractReport />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/school-information-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<SchoolInformationReport />  
							<FooterSuperAdmin />
						</div>   

					) :
					 (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/communication-summary-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<CommunicationSummaryReport />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<CommunicationSummaryReport />
							<FooterAdmin />
						</div>

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 3) ? (
						<div className="admin-page">
							<HeaderTeacher />
							<CommunicationSummaryReport />
							<FooterTeacher />
						</div>

					): (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/comment-and-document-summary-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<CommentandDocumentSummaryReport />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<CommentandDocumentSummaryReport />
							<FooterAdmin />
						</div>

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 3) ? (
						<div className="admin-page">
							<HeaderTeacher />
							<CommentandDocumentSummaryReport />
							<FooterTeacher />
						</div>

					): (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/school-calendar-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<SchoolCalendarReport />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<SchoolCalendarReport />
							<FooterAdmin />
						</div>

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 3) ? (
						<div className="admin-page">
							<HeaderTeacher />
							<SchoolCalendarReport />
							<FooterTeacher />
						</div>

					): (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/bell-schedule-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<BellScheduleReport />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<BellScheduleReport />
							<FooterAdmin />
						</div>

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 3) ? (
						<div className="admin-page">
							<HeaderTeacher />
							<BellScheduleReport />
							<FooterTeacher />
						</div>

					): (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/attendance-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<AttendanceReport />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<AttendanceReport />
							<FooterAdmin />
						</div>

					)  :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 3) ? (
						<div className="admin-page">
							<HeaderTeacher />
							<AttendanceReport />
							<FooterTeacher />
						</div>

					): (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/attendance-summary-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<AttendanceSummaryReport />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<AttendanceSummaryReport />
							<FooterAdmin />
						</div>

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 3) ? (
						<div className="admin-page">
							<HeaderTeacher />
							<AttendanceSummaryReport />
							<FooterTeacher />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/student-enrollment-summary' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<StudentEnrollmentSummaryReport />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StudentEnrollmentSummaryReport />
							<FooterAdmin />
						</div>

					)  :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 3) ? (
						<div className="admin-page">
							<HeaderTeacher />
							<StudentEnrollmentSummaryReport />
							<FooterTeacher />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/grade-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<GradeReport />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<GradeReport />
							<FooterAdmin />
						</div>

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 3) ? (
						<div className="admin-page">
							<HeaderTeacher />
							<GradeReport />
							<FooterTeacher />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/transcript-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<TranscriptReport />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<TranscriptReport />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/transcript-request-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<TranscriptRequestReport />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<TranscriptRequestReport />
							<FooterAdmin />
						</div>
					): (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/invoice-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<InvoiceReport />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<InvoiceReport />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/request-list' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<RequestList />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
					<Route path='/school-information' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
							<div className="admin-page">
								<HeaderSuperAdmin />
								<SchoolInformation />
								<FooterSuperAdmin />
							</div>

						) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
							<div className="admin-page">
								<HeaderAdmin />
								<SchoolInformation />
								<FooterAdmin />
							</div>
						) : (
							<Redirect to="/login" />
						)
					)} />
					<Route path='/school-misc-config' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
							<div className="admin-page">
								<HeaderSuperAdmin />
								<SchoolMiscConfiguration />
								<FooterSuperAdmin />
							</div>

						) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
							<div className="admin-page">
								<HeaderAdmin />
								<SchoolMiscConfiguration />
								<FooterAdmin />
							</div>
						) : (
							<Redirect to="/login" />
						)
					)} />
					<Route path='/communication-user-rules' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
							<div className="admin-page">
								<HeaderSuperAdmin />
								<CommunicationUserRules />
								<FooterSuperAdmin />
							</div>

						) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
							<div className="admin-page">
								<HeaderAdmin />
								<CommunicationUserRules />
								<FooterAdmin />
							</div>

						) : (
							<Redirect to="/login" />
						)
					)} />
					<Route path='/communication-rules' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
							<div className="admin-page">
								<HeaderSuperAdmin />
								<CommunicationRules />
								<FooterSuperAdmin />
							</div>

						) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
							<div className="admin-page">
								<HeaderAdmin />
								<CommunicationRules />
								<FooterAdmin />
							</div>

						) : (
							<Redirect to="/login" />
						)
					)} />
					<Route path='/school-admins' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
							<div className="admin-page">
								<HeaderSuperAdmin />
								<SchoolAdminList />
								<FooterSuperAdmin />
							</div>

						) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
							<div className="admin-page">
								<HeaderAdmin />
								<SchoolAdminList />
								<FooterAdmin />
							</div>

						) : (
							<Redirect to="/login" />
						)
					)} />
					<Route path='/feature-config' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
							<div className="admin-page">
								<HeaderSuperAdmin />
								<FeatureConfig />
								<FooterSuperAdmin />
							</div>

						) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
							<div className="admin-page">
								<HeaderAdmin />
								<FeatureConfig />
								<FooterAdmin />
							</div>

						) : (
							<Redirect to="/login" />
						)
					)} />
					<Route path='/feature-settings' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
							<div className="admin-page">
								<HeaderSuperAdmin />
								<FeatureSettings />
								<FooterSuperAdmin />
							</div>

						) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
							<div className="admin-page">
								<HeaderAdmin />
								<FeatureSettings />
								<FooterAdmin />
							</div>

						) : (
							<Redirect to="/login" />
						)
					)} />
					<Route path='/gradebook-activity-descriptions' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
							<div className="admin-page">
								<HeaderSuperAdmin />
								<GradeBookActivityDescriptions />
								<FooterSuperAdmin />
							</div>

						) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
							<div className="admin-page">
								<HeaderAdmin />
								<GradeBookActivityDescriptions />
								<FooterAdmin />
							</div>

						) : (
							<Redirect to="/login" />
						)
					)} />
					<Route path='/school-config' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
							<div className="admin-page">
								<HeaderSuperAdmin />
								<SchoolConfig />
								<FooterSuperAdmin />
							</div>

						) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
							<div className="admin-page">
								<HeaderAdmin />
								<SchoolConfig />
								<FooterAdmin />
							</div>

						) : (
							<Redirect to="/login" />
						)
					)} />
					<Route path='/additional-field' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
							<div className="admin-page">
								<HeaderSuperAdmin />
								<AdditionalFields />
								<FooterSuperAdmin />
							</div>

						) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
							<div className="admin-page">
								<HeaderAdmin />
								<AdditionalFields />
								<FooterAdmin />
							</div>

						) : (
							<Redirect to="/login" />
						)
					)} />
					<Route path='/student-contract-rules' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
							<div className="admin-page">
								<HeaderSuperAdmin />
								<StudentContractRules />
								<FooterSuperAdmin />
							</div>

						) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
							<div className="admin-page">
								<HeaderAdmin />
								<StudentContractRules />
								<FooterAdmin />
							</div>

						) : (
							<Redirect to="/login" />
						)
					)} />
					<Route path='/facility-management' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
							<div className="admin-page">
								<HeaderSuperAdmin />
								<FacilityManagement />
								<FooterSuperAdmin />
							</div>

						) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
							<div className="admin-page">
								<HeaderAdmin />
								<FacilityManagement />
								<FooterAdmin />
							</div>

						) : (
							<Redirect to="/login" />
						)
					)} />
					<Route path='/object-state/' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
							<div className="admin-page">
								<HeaderSuperAdmin />
								<ObjectState />  
								<FooterSuperAdmin />
							</div>   
	
						) : ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
							<div className="admin-page">
								<HeaderAdmin />
								<ObjectState />  
								<FooterAdmin />
							</div>   
	
						) : (
							<Redirect to="/login" />
						)
					)} />
					<Route path='/school-comment/' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
							<div className="admin-page">
								<HeaderSuperAdmin />
								<SchoolComment />  
								<FooterSuperAdmin />
							</div>   
	
						) : ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
							<div className="admin-page">
								<HeaderAdmin />
								<SchoolComment />  
								<FooterAdmin />
							</div>   
	
						) : (
							<Redirect to="/login" />
						)
					)} />
					<Route path='/badge/' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
							<div className="admin-page">
								<HeaderSuperAdmin />
								<Badge />  
								<FooterSuperAdmin />
							</div>   
	
						) : ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
							<div className="admin-page">
								<HeaderAdmin />
								<Badge />  
								<FooterAdmin />
							</div>   
	
						) : (
							<Redirect to="/login" />
						)
					)} />
					<Route path='/status/' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
							<div className="admin-page">
								<HeaderSuperAdmin />
								<Status />  
								<FooterSuperAdmin />
							</div>   
	
						) : ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
							<div className="admin-page">
								<HeaderAdmin />
								<Status />  
								<FooterAdmin />
							</div>   
	
						) : (
							<Redirect to="/login" />
						)
					)} />
					<Route path='/departments' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
							<div className="admin-page">
								<HeaderSuperAdmin />
								<Department />
								<FooterSuperAdmin />
							</div>

						) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
							<div className="admin-page">
								<HeaderAdmin />
								<Department />
								<FooterAdmin />
							</div>

						) : (
							<Redirect to="/login" />
						)
					)} />
					<Route path='/grading-scales' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
							<div className="admin-page">
								<HeaderSuperAdmin />
								<GradingScales />
								<FooterSuperAdmin />
							</div>

						) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
							<div className="admin-page">
								<HeaderAdmin />
								<GradingScales />
								<FooterAdmin />
							</div>

						) : (
							<Redirect to="/login" />
						)
					)} />
					<Route path='/bell-schedule' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
							<div className="admin-page">
								<HeaderSuperAdmin />
								<BellSchedule />
								<FooterSuperAdmin />
							</div>

						) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
							<div className="admin-page">
								<HeaderAdmin />
								<BellSchedule />
								<FooterAdmin />
							</div>

						) : (
							<Redirect to="/login" />
						)
					)} />
					<Route path='/grading-periods' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
							<div className="admin-page">
								<HeaderSuperAdmin />
								<GradingPeriods />
								<FooterSuperAdmin />
							</div>

						) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
							<div className="admin-page">
								<HeaderAdmin />
								<GradingPeriods />
								<FooterAdmin />
							</div>

						) : (
							<Redirect to="/login" />
						)
					)} />
					<Route path='/academic-tracks' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
							<div className="admin-page">
								<HeaderSuperAdmin />
								<AcademicTracks />
								<FooterSuperAdmin />
							</div>

						) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
							<div className="admin-page">
								<HeaderAdmin />
								<AcademicTracks />
								<FooterAdmin />
							</div>

						) : (
							<Redirect to="/login" />
						)
					)} />
					<Route path='/academic-terms' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
							<div className="admin-page">
								<HeaderSuperAdmin />
								<AcademicTerms />
								<FooterSuperAdmin />
							</div>

						) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
							<div className="admin-page">
								<HeaderAdmin />
								<AcademicTerms />
								<FooterAdmin />
							</div>

						) : (
							<Redirect to="/login" />
						)
					)} />
					<Route path='/ilp' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
							<div className="admin-page">
								<HeaderSuperAdmin />
								<LearningPathTemplateSetup />
								<FooterSuperAdmin />
							</div>

						) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
							<div className="admin-page">
								<HeaderAdmin />
								<LearningPathTemplateSetup />
								<FooterAdmin />
							</div>

						) : (
							<Redirect to="/login" />
						)
					)} />
					<Route path='/ilp-detail' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
							<div className="admin-page">
								<HeaderSuperAdmin />
								<LearningPathDetail />
								<FooterSuperAdmin />
							</div>

						) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
							<div className="admin-page">
								<HeaderAdmin />
								<LearningPathDetail />
								<FooterAdmin />
							</div>

						) : (
							<Redirect to="/login" />
						)
					)} />
					<Route path='/ilpu-detail' render={() => (
						((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
							<div className="admin-page">
								<HeaderSuperAdmin />
								<LearningPathUserDetail />
								<FooterSuperAdmin />
							</div>

						) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
							<div className="admin-page">
								<HeaderAdmin />
								<LearningPathUserDetail />
								<FooterAdmin />
							</div>

						) : (
							<Redirect to="/login" />
						)
					)} />
				
				<Route path='/schedule-student/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<ScheduleOfStudentInAdmin />  
							<FooterAdmin />
						</div>   
					
                      ) :((token && token!==undefined  && token!==null && token!=='') && last_role===3) ? (
						<div className="teacher-page">
							<HeaderTeacher />
							<ScheduleOfStudentInteacher />  
							<FooterTeacher />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/schedule-student' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<ScheduleOfStudentInAdmin />  
							<FooterAdmin />
						</div>   
					
                      ) :((token && token!==undefined  && token!==null && token!=='') && last_role===3) ? (
						<div className="teacher-page">
							<HeaderTeacher />
							<ScheduleOfStudentInteacher />  
							<FooterTeacher />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/other-grades/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<TranferGrades />  
							<FooterAdmin />
						</div>   
					
                      ) : ((token && token!==undefined  && token!==null && token!=='') && last_role===3) ? (
						<div className="teacher-page">
							<HeaderTeacher />
							<OtherGrades />  
							<FooterTeacher />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/other-grades' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<TranferGrades />  
							<FooterAdmin />
						</div>   
					
                      ) : ((token && token!==undefined  && token!==null && token!=='') && last_role===3) ? (
						<div className="teacher-page">
							<HeaderTeacher />
							<OtherGrades />  
							<FooterTeacher />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/edit-other-grades/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditOtherGrade />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/edit-other-grades' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditOtherGrade />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/student-unofficial-transcript/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===3) ? (
						<div className="teacher-page">
							<HeaderTeacher />
							<ViewUnofficialTranscript />  
							<FooterTeacher />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/student-unofficial-transcript' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===3) ? (
						<div className="teacher-page">
							<HeaderTeacher />
							<ViewUnofficialTranscript />  
							<FooterTeacher />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/teacher-profile/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<TeacherProfileNew />  
							<FooterAdmin />
						</div>   
					
                      ) :((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<TeacherDetailSUP />  
							<FooterSuperAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/teacher-profile/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<TeacherProfileNew />  
							<FooterAdmin />
						</div>   
					
                      ) :((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<TeacherDetailSUP />  
							<FooterSuperAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				
				<Route path='/teacher-schedule/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<TeacherScheduleofAvailability />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/teacher-schedule' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<TeacherScheduleofAvailability />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/teacher-general-availability/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<TeacherGeneralAvailability />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/teaching-schedule/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<ScheduleOfTeachertInAdmin />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/student-of-teacher/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StudentOfTeacherDetail />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/student-of-teacher' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StudentOfTeacherDetail />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				
				<Route path='/teacher-communications/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<CommunicationsTeacherDetail />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/teacher-communications' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<CommunicationsTeacherDetail />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />

				<Route path='/teacher-comment-document/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<TeacherCommentAndDocument />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/teacher-comment-document' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<TeacherCommentAndDocument />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				
				<Route path='/edit-student/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditStudent />  
							<FooterAdmin />
						</div>   
					
                      ) :((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<EditStudent />  
							<FooterSuperAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/edit-student' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditStudent />  
							<FooterAdmin />
						</div>   
					
                      ) :((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<EditStudent />  
							<FooterSuperAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/edit-log/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditLog />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/edit-log' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditLog />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/id-card/:id/' render={() => (
					(token && token!==undefined  && token!==null && token!=='')? (
						<div className="admin-page">
							{ (last_role === 2)?(<HeaderAdmin />):(last_role === 3)?(<HeaderTeacher />):(last_role === 4)?(<Header />):(last_role === 5)?(<HeaderPR />):<Header />}
							<IDCard />							
							{ (last_role === 2)?(<FooterAdmin />):(last_role === 3)?(<FooterTeacher />):(last_role === 4)?(<Footer />):(last_role === 5)?(<FooterPR />):<Footer />}
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/id-card' render={() => (
					(token && token!==undefined  && token!==null && token!=='')? (
						<div className="admin-page">
							{ (last_role === 2)?(<HeaderAdmin />):(last_role === 3)?(<HeaderTeacher />):(last_role === 4)?(<Header />):(last_role === 5)?(<HeaderPR />):<Header />}
							<IDCard />							
							{ (last_role === 2)?(<FooterAdmin />):(last_role === 3)?(<FooterTeacher />):(last_role === 4)?(<Footer />):(last_role === 5)?(<FooterPR />):<Footer />}
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				
				<Route path='/application-student-detail/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<ApplicationStudentDetail />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/application-student-detail' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<ApplicationStudentDetail />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/application-change-status/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<ApplicationStatus />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/application-change-status' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<ApplicationStatus />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/messages-individual/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='')) ? (
						(last_role===1)?
								<div className="admin-page">
									<HeaderSuperAdmin />
									<MessagesIndividualAd />  
									<FooterSuperAdmin />
								</div>:
						(last_role===2)?
							<div className="admin-page">
								<HeaderAdmin />
								<MessagesIndividualAd />  
								<FooterAdmin />
							</div>:
						(last_role===3)?
							<div className="teacher-page">
								<HeaderTeacher />
								<MessagesIndividualTea />  
								<FooterTeacher />
							</div>:
						(last_role===4)?
							<div className="student-page">
								<ChatBox />
								<Header />
								<MessagesIndividual />  
								<Footer />
							</div>:(last_role===5)?
							<div className="parent-page">
								<HeaderPR />
								<MessagesIndividualPR />  
								<FooterPR />
							</div>:<Redirect to="/login" />   		
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/messages-reply/:id/' render={() => (
                    (token && token!==undefined  && token!==null && token!=='') ? (
						(last_role===2)?
							<div className="admin-page">
								<HeaderAdmin />
								<ReplyMessageAd />  
								<FooterAdmin />
							</div>:
							(last_role===3)?
							<div className="teacher-page">
								<HeaderTeacher />
								<ReplyMessageTea />  
								<FooterTeacher />
							</div>:
						(last_role===4)?
							<div className="student-page">
								<ChatBox />
								<Header />
								<ReplyMessage />  
								<Footer />
							</div>:(last_role===5)?
							<div className="parent-page">
								<HeaderPR />
								<ReplyMessagePR />  
								<FooterPR />
							</div>:<Redirect to="/login" />  						
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/print-learning-path/:id/' render={() => (
                    (token && token!==undefined  && token!==null && token!=='') ? (						
							(last_role===2)?
							<div className="admin-page">
								<HeaderAdmin />
								<ViewLearningPathSTInAd />  
								<FooterAdmin />
							</div>:
							(last_role===3)?
							<div className="teacher-page">
								<HeaderTeacher />
								<ViewLearningPathST />  
								<FooterTeacher />
							</div>:
						(last_role===4)?
							<div className="student-page">
								<ChatBox />
								<Header />
								<ViewLearningPathST />  
								<Footer />
							</div>:(last_role===5)?
							<div className="parent-page">
								<HeaderPR />
								<ViewLearningPathST />  
								<FooterPR />
							</div>:<Redirect to="/login" />  						
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/messages-reply-all/:id/' render={() => (
                    (token && token!==undefined  && token!==null && token!=='')? (
						(last_role===2)?
							<div className="admin-page">
								<HeaderAdmin />
								<ReplyMessageAllAd />  
								<FooterAdmin />
							</div>:
						(last_role===3)?
							<div className="teacher-page">
								<HeaderTeacher />
								<ReplyMessageAllTea />  
								<FooterTeacher />
							</div>:
						(last_role===4)?
							<div className="student-page">
								<ChatBox />
								<Header />
								<ReplyMessageAll />  
								<Footer />
							</div>:
							<div className="parent-page">
								<HeaderPR />
								<ReplyMessageAllPR />  
								<FooterPR />
							</div>					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/forward/:id/' render={() => (
                    (token && token!==undefined  && token!==null && token!=='') ? (
						(last_role===2)?
							<div className="admin-page">
								<HeaderAdmin />
								<ForwardAd />  
								<FooterAdmin />
							</div>:
						(last_role===3)?
							<div className="teacher-page">
								<HeaderTeacher />
								<ForwardTea />  
								<FooterTeacher />
							</div>:
						(last_role===4)?
							<div className="student-page">
								<ChatBox />
								<Header />
								<Forward />  
								<Footer />
							</div>:(last_role===5)?
							<div className="parent-page">
								<HeaderPR />
								<ForwardPR />  
								<FooterPR />
							</div>: <Redirect to="/login" />  
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />	
				<Route path='/view-event/:id/' render={() => (
                    (token && token!==undefined  && token!==null && token!=='') ? (
						(last_role===3)?
							<div className="teacher-page">
								<HeaderTeacher />
								<ViewEventDetail />  
								<FooterTeacher />
							</div>: <Redirect to="/login" />  
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />	
				
				<Route path='/add-section' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<AddSection />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/transcript-request' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							{/* <TranscriptRequest />   */}
							<RequestList />
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/add-application' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<AddApplication />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/add-teacher' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<AddTeacher />  
							<FooterSuperAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<AddTeacher />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/compliance-log' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<ComplianceLog />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/audit-log' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<AuditLog />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/auto-promote' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<AutoPromote />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/add-parent' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<AddParent />  
							<FooterSuperAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<AddParent />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/parents' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<Parents />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/profile' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<ProfileAdmin />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				
				<Route path='/edit-profile' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<EditProfile />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/enroll-student-to-section' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<EnrollStudentToSection />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/add-teacher-to-section' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<AddTeacherToSection />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/remove-teacher-to-section' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<RemoveTeacherToSection />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/report-center' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<ReportingCenter />  
							<FooterSuperAdmin />
						</div>   

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<ReportingCenter />  
							<FooterAdmin />
						</div>   

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role===3) ? (
						<div className="admin-page">
							<HeaderTeacher/>
							<ReportingCenter />  
							<FooterTeacher />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/upload-section' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<Uploadsection />  
							<FooterSuperAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<Uploadsection />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/upload-student' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<UploadStudent />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/application-change-status' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<AllicationStatus />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/application-change-school' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<AllicationChangeSchool />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/school-admin-list' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<AdminList />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />

				<Route path='/teacher-change-status' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<TeacherChangeStatus />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/upload-application' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<UploadApplications />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/student-change-status-enroll/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StudentChangeStatusEnroll />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/student-change-status-enroll' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StudentChangeStatusEnroll />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/tranfer-user-school' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<TransferUserSchool />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/tranfer-school' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<TransferSchool />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/add-admin' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<AddAdmin />
							<FooterSuperAdmin />
						</div>

					) :((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<AddAdmin />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/enroll-into-section/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EnrollIntoSec />  
							<FooterAdmin />
						</div>   
					
                      )  : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/enroll-into-section' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EnrollIntoSec />  
							<FooterAdmin />
						</div>   
					
                      )  : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/edit-all-grade/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditAllGrade />  
							<FooterAdmin />
						</div>   
					
                      )  : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/edit-all-grade' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditAllGrade />  
							<FooterAdmin />
						</div>   
					
                      )  : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/edit-section/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditSection />  
							<FooterAdmin />
						</div>   
					
                      )  : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/edit-section' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditSection />  
							<FooterAdmin />
						</div>   
					
                      )  : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/clone-section/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<CloneSection />  
							<FooterAdmin />
						</div>   
					
                      )  : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/clone-section' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<CloneSection />  
							<FooterAdmin />
						</div>   
					
                      )  : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/edit-unit/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditUnitForAd />  
							<FooterAdmin/>
						</div>   
					
                      )  :((token && token!==undefined  && token!==null && token!=='') && last_role===3) ? (
						<div className="teacher-page">
							<HeaderTeacher />
							<EditUnit />  
							<FooterTeacher/>
						</div>   
					
                      )  : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/edit-user-tag/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<EditUserTag />  
							<FooterSuperAdmin/>
						</div>   
					
                      )  : ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditUserTag />  
							<FooterAdmin/>
						</div>   
					
                      )  : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/edit-user-tag' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<EditUserTag />  
							<FooterSuperAdmin/>
						</div>   
					
                      )  : ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditUserTag />  
							<FooterAdmin/>
						</div>   
					
                      )  : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/user-school-edit/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<UserDirectorySchoolEdit />  
							<FooterSuperAdmin/>
						</div>   
					
                      )  :((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<UserDirectorySchoolEdit />  
							<FooterAdmin/>
						</div>   
					
                      )  : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/user-school-edit' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<UserDirectorySchoolEdit />  
							<FooterSuperAdmin/>
						</div>   
					
                      )  :((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<UserDirectorySchoolEdit />  
							<FooterAdmin/>
						</div>   
					
                      )  : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/user-overview-edit/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<UserDirectoryOverview />  
							<FooterSuperAdmin/>
						</div>   
					
                      )  :((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<UserDirectoryOverview />  
							<FooterAdmin/>
						</div>   
					
                      )  : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/user-overview-edit' render={() => (
					 ((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<UserDirectoryOverview />  
							<FooterSuperAdmin/>
						</div>   
					
                      )  :
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<UserDirectoryOverview />  
							<FooterAdmin/>
						</div>   
					
                      )  : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/user-pass-expiration/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<UserDirectoryPasswxpiration />  
							<FooterSuperAdmin/>
						</div>   
					
                      )  :((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<UserDirectoryPasswxpiration />  
							<FooterAdmin/>
						</div>   
					
                      )  : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/user-pass-expiration' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<UserDirectoryPasswxpiration />  
							<FooterSuperAdmin/>
						</div>   
					
                      )  :((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<UserDirectoryPasswxpiration />  
							<FooterAdmin/>
						</div>   
					
                      )  : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/edit-course/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditCourse />  
							<FooterAdmin/>
						</div>   
					
                      )  : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/edit-course/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditCourse />  
							<FooterAdmin/>
						</div>   
					
                      )  : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/edit-application/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditApplication />  
							<FooterAdmin/>
						</div>   
					
                      )  : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/edit-application' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditApplication />  
							<FooterAdmin/>
						</div>   
					
                      )  : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/teacher-edit-comment/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditCommentInTeacher />  
							<FooterAdmin/>
						</div>   
					
                      )  : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/teacher-edit-comment' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditCommentInTeacher />  
							<FooterAdmin/>
						</div>   
					
                      )  : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/teacher-edit-credential/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditCredentialInTeacher />  
							<FooterAdmin/>
						</div>   
					
                      )  :((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<EditCredentialInTeacher />  
							<FooterSuperAdmin />
						</div>   
					
                      ) :((token && token!==undefined  && token!==null && token!=='') && last_role===3) ? (
								<div className="admin-page">
									<HeaderTeacher />
									<EditCredential />  
									<FooterTeacher />
								</div>   
							
							 )  : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/teacher-edit-credential' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditCredentialInTeacher />  
							<FooterAdmin/>
						</div>   
					
                      )  :((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<EditCredentialInTeacher />  
							<FooterSuperAdmin />
						</div>   
					
                      ) :((token && token!==undefined  && token!==null && token!=='') && last_role===3) ? (
								<div className="admin-page">
									<HeaderTeacher />
									<EditCredential />  
									<FooterTeacher />
								</div>   
							
							 )  : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/teacher-qualification/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<TeacherQualification />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/teacher-edit-log/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<TeacherEditLog />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/teacher-edit-log' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<TeacherEditLog />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/edit-parent/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditParent />  
							<FooterAdmin />
						</div>   
					
                      ) :((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<EditParent />  
							<FooterSuperAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/edit-parent' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditParent />  
							<FooterAdmin />
						</div>   
					
                      ) :((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<EditParent />  
							<FooterSuperAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/add-school-user/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<AddUserSchool />
							<FooterSuperAdmin />
						</div>

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<AddUserSchool />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/add-school-group-user/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<AddUserSchoolGroup />
							<FooterSuperAdmin />
						</div>

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<AddUserSchoolGroup />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/add-school-group-user' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<AddUserSchoolGroup />
							<FooterSuperAdmin />
						</div>

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<AddUserSchoolGroup />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/edit-teacher/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditTeacher />  
							<FooterAdmin />
						</div>   
					
                      ) :((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<EditTeacher />
							<FooterSuperAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/edit-teacher' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditTeacher />  
							<FooterAdmin />
						</div>   
					
                      ) :((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<EditTeacher />
							<FooterSuperAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/student-medial/:id' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StudentMedical />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/student-invoice/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StudentInvoiceAD />
							<FooterAdmin />
						</div>

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 4) ? (
						<div className="student-page">
							<Header />
							<StudentInvoice />
							<Footer />
						</div>

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 5) ? (
						<div className="parent-page">
							<HeaderPR />
							<StudentInvoicePR />
							<FooterPR />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/student-invoice' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StudentInvoiceAD />
							<FooterAdmin />
						</div>

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 4) ? (
						<div className="student-page">
							<Header />
							<StudentInvoice />
							<Footer />
						</div>

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 5) ? (
						<div className="parent-page">
							<HeaderPR />
							<StudentInvoicePR />
							<FooterPR />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				
				
				<Route path='/student-payment-history/:id' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StudentPaymentHistory />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/student-payment-application/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<StudentPaymentApplication />
							<FooterSuperAdmin />
						</div>

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StudentPaymentApplication />
							<FooterAdmin />
						</div>

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 4) ? (
						<div className="student-page">
							<Header />
							<StudentPaymentApplicationSt />
							<Footer />
						</div>

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 5) ? (
						<div className="parent-page">
							<HeaderPR />
							<StudentPaymentApplicationPR />
							<FooterPR />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/make-payment-application/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<StudentPaymentApplication />
							<FooterSuperAdmin />
						</div>

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StudentPaymentApplication />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/edit-student-medical/:id' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditStudentMedical />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/save-qualification-teacher/:id' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<TeacherAddQualification />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/save-qualification-teacher' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<TeacherAddQualification />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/student-edit-invoice/:id' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StudentEditInvoice />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/student-contract' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StudentContractAD />
							<FooterAdmin />
						</div>

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 4) ? (
						<div className="student-page">
							<Header />
							<StudentContract />
							<Footer />
						</div>

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 5) ? (
						<div className="parent-page">
							<HeaderPR />
							<StudentContractPR />
							<FooterPR />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/view-contract/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<ViewContractPage />
							<FooterSuperAdmin />
						</div>

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<ViewContractPage />
							<FooterAdmin />
						</div>

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 3) ? (
						<div className="admin-page">
							<HeaderTeacher />
							<ViewContractPage />
							<FooterTeacher />
						</div>

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 4) ? (
						<div className="student-page">
							<Header />
							<ViewContractPage />
							<Footer />
						</div>

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 5) ? (
						<div className="parent-page">
							<HeaderPR />
							<ViewContractPage />
							<FooterPR />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/individual-course/:id' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<CourseDetail />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/individual-course' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<CourseDetail />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/student-in-course/:id' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<CourseStudents />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/student-in-course' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<CourseStudents />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/comment-document-in-course/:id' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<CourseCommentDocument />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/prerequisite-course/:id' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<CoursePrerequisite />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />	
				<Route path='/prerequisite-course' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<CoursePrerequisite />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />	
				
				<Route path='/update-comment-document-in-course/:id' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditCommentInCourse />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />

				<Route path='/learning-path-template/:id' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<LearningPathTemplate />
							<FooterSuperAdmin />
						</div>

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<LearningPathTemplate />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/learning-path-template' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<LearningPathTemplate />
							<FooterSuperAdmin />
						</div>

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<LearningPathTemplate />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/sectionenroll/:id' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<Sectionenroll />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/sectionenroll' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<Sectionenroll />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/edit-user-pass/:id' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<EditUserPassword />
							<FooterSuperAdmin />
						</div>

					) :((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditUserPassword />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/edit-user-pass' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<EditUserPassword />
							<FooterSuperAdmin />
						</div>

					) :((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EditUserPassword />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				
				<Route path='/add-prerequisite/:id' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<SavePrerequisite />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/add-prerequisite' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<SavePrerequisite />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/parent-profile/:id' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<ParentDetail />
							<FooterAdmin />
						</div>

					) :((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<ParentDetailSUP  />
							<FooterSuperAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/parent-profile' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<ParentDetail />
							<FooterAdmin />
						</div>

					) :((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<ParentDetailSUP  />
							<FooterSuperAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				
				<Route path='/edit-enroll/:id' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StudentEditEnroll />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/edit-enroll' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StudentEditEnroll />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/student-un-recipient-transript/:id' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<RecipientTranscriptUn />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/student-un-recipient-transript' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<RecipientTranscriptUn />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/student-of-recipient-transript/:id' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<RecipientTranscriptOf />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/student-of-recipient-transript' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<RecipientTranscriptOf />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/student-transcript/:id' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<Studenttranscript />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/student-transcript' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<Studenttranscript />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/merge-student/:id' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<Mergestudent />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/merge-student' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<Mergestudent />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/link-student/:id' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<LinkStudents />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/link-student' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<LinkStudents />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />

				<Route path='/active-enrollments-per-term-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<ActiveEnrollmentsPerTerm />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<ActiveEnrollmentsPerTerm />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />

				<Route path='/active-student-enrollments-with-as-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<ActiveStudentEnrollmentsWithAs />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<ActiveStudentEnrollmentsWithAs />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/active-student-enrollments-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<ActiveStudentEnrollments />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<ActiveStudentEnrollments />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/all-students-gpa-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<AllStudentsGPA />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<AllStudentsGPA />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/old-all-students-gpa-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<OldAllStudentsGPA />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<OldAllStudentsGPA />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/billing-group-engagement-type-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<BillingGroupEngagementType />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<BillingGroupEngagementType />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/enrollment-confirmation-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<EnrollmentConfirmation />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EnrollmentConfirmation />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/eos-college-matriculations-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<EOSCollegeMatriculations />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EOSCollegeMatriculations />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/student-enrollment-history-todate-w-term-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<StudentEnrollmentHistoryToDateWTerm />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StudentEnrollmentHistoryToDateWTerm />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/student-information-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<StudentInformation />  
							<FooterSuperAdmin />
						</div>   

					) :
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StudentInformation />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/reconcile-student/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<ReconcileStudent />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/reconcile-student' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<ReconcileStudent />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				
				<Route path='/student-assign-learning-path' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StudentAssignLearningPath />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/rosters' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<RosterList />
							<FooterAdmin />
						</div>

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 3) ? (
						<div className="teacher-page">
							<HeaderTeacher />
							<RosterListForTea />
							<FooterTeacher />
						</div>

					) :(
						<Redirect to="/login" />
					)
				)} />
				<Route path='/asi-rosters' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<RosterForASI />
							<FooterAdmin />
						</div>

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 3) ? (
						<div className="teacher-page">
							<HeaderTeacher />
							<RosterListForTeaASI />
							<FooterTeacher />
						</div>

					) :(
						<Redirect to="/login" />
					)
				)} />
				<Route path='/student-change-status/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StudentChangeStatus />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/student-change-status' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StudentChangeStatus />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/all-student-change-status/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<AllStudentChangeStatus />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/downloading-of-studnet-progress' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<DownloadingOfStudnetProgress />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/enroll-transfer-class/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EnrollmentTranfer />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/enroll-transfer-class' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EnrollmentTranfer />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/add-comment-document-in-roster/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<AddCommentsDocumentsForRoster />
							<FooterAdmin />
						</div>

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 3) ? (						
						<div className="teacher-page">
							<HeaderTeacher />
							<AddCommentsDocumentsForRosterTea />
							<FooterTeacher />
						</div>
					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/add-comment-document-in-roster' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<AddCommentsDocumentsForRoster />
							<FooterAdmin />
						</div>

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 3) ? (						
						<div className="teacher-page">
							<HeaderTeacher />
							<AddCommentsDocumentsForRosterTea />
							<FooterTeacher />
						</div>
					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/student-request-enrollment/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StudentRequestEnrollment />
							<FooterAdmin />
						</div>

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 3) ? (						
						<div className="teacher-page">
							<HeaderTeacher />
							<StudentRequestEnrollmentTea />
							<FooterTeacher />
						</div>
					) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 5) ? (						
						<div className="parent-page">
							<HeaderPR />
							<StudentRequestEnrollmentSt />
							<FooterPR/>
						</div>
					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/student-request-enrollment' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StudentRequestEnrollment />
							<FooterAdmin />
						</div>

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 3) ? (						
						<div className="teacher-page">
							<HeaderTeacher />
							<StudentRequestEnrollmentTea />
							<FooterTeacher />
						</div>
					) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 5) ? (						
						<div className="parent-page">
							<HeaderPR />
							<StudentRequestEnrollmentSt />
							<FooterPR/>
						</div>
					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/content-access-dialog/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<ContentAccessDialog />
							<FooterSuperAdmin />
						</div>

					) :((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<ContentAccessDialog />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/content-access-dialog' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<ContentAccessDialog />
							<FooterSuperAdmin />
						</div>

					) :((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<ContentAccessDialog />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/feature-access-architecture' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<FeatureAccessArchitecture />
							<FooterSuperAdmin />
						</div>

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<FeatureAccessArchitecture />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/school-based-tags' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<SchoolBasedTags />
							<FooterSuperAdmin />
						</div>

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<SchoolBasedTags />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/add-comments-for-multi' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<AddMultiCommentsDocumentsForRoster />
							<FooterAdmin />
						</div>

					) :((token && token!==undefined  && token!==null && token!=='') && last_role === 3) ? (						
						<div className="teacher-page">
							<HeaderTeacher />
							<AddMultiCommentsDocumentsForRosterForTea />
							<FooterTeacher />
						</div>
					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/upload-progress-file' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<UploadProgressFile />  
							<FooterAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role === 3) ? (						
						<div className="teacher-page">
							<HeaderTeacher />
							<UploadProgressFileTea />
							<FooterTeacher />
						</div>
					) :(
						<Redirect to="/login" />
					)
				)} />
				<Route path='/add-global-section' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<AddGlobalSection />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/change-status-enrollmet-in-student/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<ChangeStatusEnrollOfStudent />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/change-status-enrollmet-in-student' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<ChangeStatusEnrollOfStudent />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/apex-classroom-sync/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<ConnectionClassToSectionSyn />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/bulk-upload-student' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<UploadStudentBulk />  
							<FooterSuperAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<UploadStudentBulk />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/admin-detail/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<AdminDetail />  
							<FooterAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<AdminDetail />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/admin-detail' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<AdminDetail />  
							<FooterAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<AdminDetail />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/security-tags' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<SecurityTags />  
							<FooterSuperAdmin />
						</div>   

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<SecurityTags />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/ar-dashboard' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<ARDashboard />  
							<FooterSuperAdmin />
						</div>   

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<ARDashboard />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/ar-configuration' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<ARConfiguration />  
							<FooterSuperAdmin />
						</div>   

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<ARConfiguration />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/ar-processing' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<ARProcessing />  
							<FooterSuperAdmin />
						</div>   

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<ARProcessing />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/ar-payment-config' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<ARPaymentConfiguration />  
							<FooterSuperAdmin />
						</div>   

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<ARPaymentConfiguration />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/b2b-contract' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<B2BContract />  
							<FooterSuperAdmin />
						</div>   

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<B2BContract />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/contract-comment-document' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<CommentDocumentForContract />  
							<FooterSuperAdmin />
						</div>   

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<CommentDocumentForContract />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/add-contract-comment-document' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<AddCommentDocumentForContract />  
							<FooterSuperAdmin />
						</div>   

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<AddCommentDocumentForContract />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/ar-contracts' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<ARContracts />  
							<FooterSuperAdmin />
						</div>   

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<ARContracts />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/ar-master-contract' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<MasterContract />  
							<FooterSuperAdmin />
						</div>   

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<MasterContract />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/ar-contract-templates' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<ARContractTemplates />  
							<FooterSuperAdmin />
						</div>   

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<ARContractTemplates />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/ar-invoices' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<ARInvoices />  
							<FooterSuperAdmin />
						</div>   

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<ARInvoices />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/ar-products' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<ARProducts />  
							<FooterSuperAdmin />
						</div>   

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<ARProducts />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/ar-payment-history' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<ARPaymentHistory />  
							<FooterSuperAdmin />
						</div>   

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<ARPaymentHistory />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/aging-report' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<AgingReport />  
							<FooterSuperAdmin />
						</div>   

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<AgingReport />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/link-parents/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<LinkParents />  
							<FooterAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<LinkParents />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/link-parents' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<LinkParents />  
							<FooterAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<LinkParents />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/students-transcripts' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<RecipientBulkTranscript />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/enter-grades/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<GradebookOfStudent />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/enter-grades' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<GradebookOfStudent />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/upload-other-grade' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<Uploadothergrade />  
							<FooterSuperAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<Uploadothergrade />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/upload-enrollments' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<UploadEnrollments />  
							<FooterSuperAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<UploadEnrollments />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/send-mail' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<SendMailtoUserAd />  
							<FooterAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===3) ? (
						<div className="teacher-page">
							<HeaderTeacher/>
							<SendMailtoUserTea />  
							<FooterTeacher />
						</div>   

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role===4) ? (
						<div className="student-page">
							<Header/>
							<SendMailtoUser />  
							<Footer />
						</div>   

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role===5) ? (
						<div className="parent-page">
							<HeaderPR/>
							<SendMailtoUser />  
							<FooterPR/>
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/edit-multiple-grade/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<EditMultipleGrade />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/edit-multiple-grade' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<EditMultipleGrade />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/link-advisor/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<LinkAdvisor />  
							<FooterAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<LinkAdvisor />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/link-advisor' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<LinkAdvisor />  
							<FooterAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<LinkAdvisor />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/bulk-assignment-fields' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<StudentsBulkAssignment />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/upload-participation-grade-bonus-point' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<UploadParticipationGradeBonusPoin />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/student-request-enrollment-edit-comment/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StudentRequestEnrollmentAddCommentDocument />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/student-request-enrollment-edit-comment' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StudentRequestEnrollmentAddCommentDocument />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/tyler-synchronization-az-schools' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<TylerSynchronizationforAZSchools />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/school-tags' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<SchoolTags />  
							<FooterSuperAdmin />
						</div>   

					) :
					 ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<SchoolTags />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/schools' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<SchoolList />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/schoolgroup' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<Schoolgroup />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/configuration-schoolgroup' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<ConfigurationSchoolGroup />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/add-school' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<AddSchool />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/global-user-directory' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<GlobalUserDirectory />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/edit-school/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<EditSchool />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/edit-school' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<EditSchool />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/add-school-group' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<AddSchoolGroup />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/edit-school-group/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<EditSchoolGroup />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/edit-school-group' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<EditSchoolGroup />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/edit-user-school-tag/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<EditUserSchoolTag />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/edit-user-school-tag' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<EditUserSchoolTag />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/edit-user-role-tag/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<EditUserRoleTag />  
							<FooterSuperAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<EditUserRoleTag />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/edit-user-role-tag' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<EditUserRoleTag />  
							<FooterSuperAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<EditUserRoleTag />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/user-roles' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<UserRoles />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/content-integration' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<ContentIntegration />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/upload-course' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<UploadCourse />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/eventqueue' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<Eventqueue />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/notificationsqueue' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<Notificationsqueue />  
							<FooterSuperAdmin />
						</div>   

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<Notificationsqueue />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />	
				<Route path='/notificationsrules' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<NotificationRule />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/copy-notification-rules' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<CopyNotificationRules />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/add-notificationsrules' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<Addnotificationsrules />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/edit-notificationsrules/:id' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<Editnotificationsrules />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/edit-notificationsrules' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<Editnotificationsrules />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/add-eventqueue' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<Addeventqueue />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/edit-notificationqueue' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<Editnotificationqueue />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/view-notificationqueue/:id' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<Viewnotificationqueue />  
							<FooterSuperAdmin />
						</div>   

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<Viewnotificationqueue />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/view-notificationqueue' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<Viewnotificationqueue />  
							<FooterSuperAdmin />
						</div>   

					) : ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<Viewnotificationqueue />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/adsl-home' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<ADSL />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/edit-custom-role-tag/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<EditCustomRoleTag />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/edit-custom-role-tag' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<EditCustomRoleTag />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/view-consolidated-tag/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<ViewConsolidatedTag />  
							<FooterSuperAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<ViewConsolidatedTag />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/view-consolidated-tag' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<ViewConsolidatedTag />  
							<FooterSuperAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<ViewConsolidatedTag />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/student-detail/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<StudentDetail />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/student-detail' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<StudentDetail />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/security-tag-dictionary' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<SecurityTagDictionary />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/edit-security-tag-dictionary/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<EditSecurityTagsDictionary />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/edit-security-tag-dictionary' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<EditSecurityTagsDictionary />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/add-security-tag-dictionary' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<AddSecurityTagsDictionary />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/reassignments/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<EventReassignments/>  
							<FooterSuperAdmin />
						</div>   

					) :
					 ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EventReassignments />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/reassignments' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<EventReassignments/>  
							<FooterSuperAdmin />
						</div>   

					) :
					 ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<EventReassignments />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/staff-reassignment' render={() => (					
					 ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StaffReassignments />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/staff-transfers-resources' render={() => (					
					 ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StaffTransfersResources />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/staff-transfers-sections' render={() => (					
					 ((token && token!==undefined  && token!==null && token!=='') && last_role === 2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<StaffTransfersSections />
							<FooterAdmin />
						</div>

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/multi-lingual' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<MultiLingual />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/translations' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<Translations />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/update-lingual/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<AddLingual />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/update-lingual' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<AddLingual />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/update-tranlation/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<UpdateTranlation />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/update-tranlation' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<UpdateTranlation />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/update-all-tranlation' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<EditAllTranlation />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/upload-tranlations' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<UploadTranlation />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/master-course-catalog' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<MasterCourseCatalog />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/add-master-course-catalog' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<AddMasterCourseCatalog />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/edit-master-course-catalog/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<EditMasterCourseCatalog />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/edit-master-course-catalog' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<EditMasterCourseCatalog />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/global-department' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<GlobalDeparment />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/update-global-departments/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<UpdateGlobalDepartments />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/update-global-departments' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<UpdateGlobalDepartments />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/languages' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<LanguageList />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/update-language/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<UpdateLanguese />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/teacher-section/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<TeacherSections />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/teacher-section' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<TeacherSections />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				
				<Route path='/teacher-advisees/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<TeacherAdvisees />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/teacher-advisees' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<TeacherAdvisees />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/admin-comment-document/:id/' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<AdminDetailCommentAndDocument />  
							<FooterAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<AdminDetailCommentAndDocument />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/admin-comment-document' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<AdminDetailCommentAndDocument />  
							<FooterAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<AdminDetailCommentAndDocument />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/admin-qualification/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<AdminQualification />  
							<FooterSuperAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<AdminQualification />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/admin-qualification' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<AdminQualification />  
							<FooterSuperAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<AdminQualification />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/admin-edit-log/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<AdminEditLog />  
							<FooterSuperAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<AdminEditLog />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/admin-edit-log' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<AdminEditLog />  
							<FooterSuperAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<AdminEditLog />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/admin-communications/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<AdminCommunications />  
							<FooterSuperAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<AdminCommunications />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/admin-communications' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<AdminCommunications />  
							<FooterSuperAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<AdminCommunications />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/batch-data-update' render={() => (
                   ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<UploadBatchDataUpdate />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/copy-courses' render={() => (
                   ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<CopyCourses />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/test-open-api' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<TestOpenAi />  
							<FooterAdmin />
						</div>   

					) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/course-open-api' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<CourseOpenAi />  
							<FooterAdmin />
						</div>   

					) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/update-api-course/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<UpdateAICourse />  
							<FooterAdmin />
						</div>   

					) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/unit-of-course/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<AIUnitlist />  
							<FooterAdmin />
						</div>   

					) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/lesson-of-unit/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<CourseOpenAiLesson />  
							<FooterAdmin />
						</div>   

					) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/view-lesson-content/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<ViewContentLesson />  
							<FooterAdmin />
						</div>   

					) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/all-parent-change-status/:id/' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<AllParentsChangeStatus />  
							<FooterAdmin />
						</div>   

					) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/copy-notification-rules-to-school-group' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<CopyNotificationRulesToSchoolGroup />  
							<FooterSuperAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/assigning-multiple-user-to-school' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role === 1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<AssigningMultipleUserToSchool />  
							<FooterSuperAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<AssigningMultipleUserToSchool />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				<Route path='/upload-section-status' render={() => (
					((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin/>
							<Uploadsectionstatus />  
							<FooterSuperAdmin />
						</div>   

					) :((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin/>
							<Uploadsectionstatus />  
							<FooterAdmin />
						</div>   

					) : (
						<Redirect to="/login" />
					)
				)} />
				
				<Route path='/request-type' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<RequestType />  
							<FooterAdmin />
						</div>   
					
                      ) :((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<RequestType />
							<FooterSuperAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/request-template' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<RequestTemplate />  
							<FooterAdmin />
						</div>   
					
                      ) :((token && token!==undefined  && token!==null && token!=='') && last_role===3)?
							<div className="teacher-page">
								<HeaderTeacher />
								<RequestTemplate />  
								<FooterTeacher />
							</div>:
						((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<RequestTemplate />
							<FooterSuperAdmin />
						</div>   
					
                      ) :((token && token!==undefined  && token!==null && token!=='') && last_role === 5) ? (
						<div className="parent-page">
							<HeaderPR />
							<RequestTemplate />
							<FooterPR />
						</div>

					) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/view-grade-detail' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<ViewGradeDetail />  
							<FooterAdmin />
						</div>   
					
                      ) :((token && token!==undefined  && token!==null && token!=='') && last_role===3)?
							<div className="teacher-page">
								<HeaderTeacher />
								<ViewGradeDetail />  
								<FooterTeacher />
							</div>:
						 (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/request-assign-user' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role === 5) ? (
						<div className="parent-page">
							<HeaderPR />
							<RequestUser />
							<FooterPR />
						</div>

					) :(last_role===4)?
							<div className="student-page">
								<ChatBox />
								<Header />
								<RequestUser />  
								<Footer />
							</div>: (
                        <Redirect to="/login" />
                      )
                )} />
				
				<Route path='/request-rule' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<RequestRule />  
							<FooterAdmin />
						</div>   
					
                      ) :((token && token!==undefined  && token!==null && token!=='') && last_role===3)?
							<div className="teacher-page">
								<HeaderTeacher />
								<RequestRule />  
								<FooterTeacher />
							</div>:
						((token && token!==undefined  && token!==null && token!=='') && last_role===1) ? (
						<div className="admin-page">
							<HeaderSuperAdmin />
							<RequestRule />
							<FooterSuperAdmin />
						</div>   
					
                      ) : ((token && token!==undefined  && token!==null && token!=='') && last_role === 5) ? (
						<div className="parent-page">
							<HeaderPR />
							<RequestRule />
							<FooterPR />
						</div>

					) : (
                        <Redirect to="/login" />
                      )
                )} />
				<Route path='/request-queue' render={() => (
                    ((token && token!==undefined  && token!==null && token!=='') && last_role===2) ? (
						<div className="admin-page">
							<HeaderAdmin />
							<RequestQueue />  
							<FooterAdmin />
						</div>   
					
                      ) : (
                        <Redirect to="/login" />
                      )
                )} />
				
				
				<Route path='/zoom/redirect' render={() => (
					((token && token!==undefined  && token!==null && token!=='')) ? (
						<ZoomRedirect />
					):(
						<Redirect to="/login" />
					)
				)} /> 
				

				<Route path='/change-password' render={() => (
					<ChangePassword />
				)} /> 
				<Route path='/forgot-password' render={() => (
					<ForgotPassword />
				)} /> 
				<Route path='/reset-password' render={() => (
					<ResetPassword />
				)} /> 

				<Route path='/login' render={() => (
					<Login />
				)} /> 
				<Route path='/callback' render={() => (
					<Callback />
				)} /> 
				
				{/*<Route path='/testS' render={() => (
					<TestS />
				)} /> */}
				
				 <Route path='*' render={() => (
					(token && token!==undefined  && token!==null && token!=='')? (
						<PageNotFound />  
					):
					<Redirect to="/login" />					
				)} />
				
		    </Switch>	
			</Suspense>	
			
		</div>
	);
}

const Root = () => {
  return (
        <Provider store={store}>
	      <BrowserRouter basename='/'> 
		    <Router />  
	      </BrowserRouter>
      </Provider>
  );
}


ReactDOM.render(<Root />, document.getElementById('root'));