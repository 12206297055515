export const APP_LOAD = 'APP_LOAD';
export const REDIRECT = 'REDIRECT';
export const HOME_PAGE_LOADED = 'HOME_PAGE_LOADED'
//export const BASE_API_IP = 'http://139.162.119.227/apicoopbet/public/api/'
//export const BASE_API = 'http://198.58.97.135/api/v2/'
// export const BASE_API = 'https://apiv5.adlsassist.com/api/v2/'
export const BASE_API = process.env.REACT_APP_API ? process.env.REACT_APP_API : 'https://apiv5.adlsassist.com/api/v2/';
// export const BASE_API_STAGING = 'https://apistaging.adlsassist.com/api/v2/';
export const BASE_API_STAGING = process.env.REACT_APP_API ? process.env.REACT_APP_API : 'https://apistaging.adlsassist.com/api/v2/';

export const BASE_API_PRERELEASE = process.env.REACT_APP_API_PRERELEASE ? process.env.REACT_APP_API_PRERELEASE : 'https://apiprerelease.adlsassist.com/api/v2/';

export const LOGOUT = 'LOGOUT'
//export const TOKEN_NAME = 'ADVANTAGES_TOKEN'
export const TOKEN_NAME = 'ADVANTAGES_SCHOOL_TOKEN'
export const LOGIN = 'LOGIN'
export const PAGE_NOT_FOUND_LOADED = 'PAGE_NOT_FOUND_LOADED'
export const CHANGE_TAB = 'CHANGE_TAB'
export const LEARNING_PAGE_LOADER = 'LEARNING_PAGE_LOADER'
export const CURENT_DATE = 'CURENT_DATE'
export const EVP_DATA = 'EVP_DATA'
export const EV_DATA = 'EV_DATA'
export const JOIN_CLASS_DATA = 'JOIN_CLASS_DATA'
export const LANGUAGE_KEY = 'LANGUAGE_KEY'



//export const BASE_IMAGE = importAll(require.context('../images', false, /\.(png|jpe?g|svg)$/));

function importAll(r) {
 let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}