import firebase from 'firebase';

var isIOS = ((/iPad|iPhone|iPod/.test(navigator.platform) ||
				(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
				!window.MSStream);
//alert(isIOS+"1234");
if(!isIOS){
	
	
	  const firebaseConfig = {
		  apiKey: "AIzaSyAExa2xMOPRBLHVyQyB21x6gu43LDMjOfo",
		  authDomain: "adls-assist.firebaseapp.com",
		  projectId: "adls-assist",
		  storageBucket: "adls-assist.appspot.com",
		  messagingSenderId: "1043210604818",
		  appId: "1:1043210604818:web:dc6193c240131f231363cb",
		  measurementId: "G-HJNZLDPCVS"
	}


	firebase.initializeApp(firebaseConfig);

	
}
export default firebase;