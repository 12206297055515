import {
  TOKEN_NAME,APP_LOAD,REDIRECT,HOME_PAGE_LOADED,PAGE_NOT_FOUND_LOADED,LOGIN,LEARNING_PAGE_LOADER,CURENT_DATE,EV_DATA,
  EVP_DATA,JOIN_CLASS_DATA,LANGUAGE_KEY
} from '../constants/actionTypes';

const defaultState = {
	appName: 'test.advantages-dls.info',
	tab: 'home',
	dashboardData: [],
	updateProps: true, 
	token: null,
	active: 'home'
};

export default (state = defaultState, action) => {
	switch (action.type) {
		case APP_LOAD:
			return  Object.assign({}, state, {
				appLoaded: true,
				token: action.access_token || null				
			});  
		case HOME_PAGE_LOADED:
			return  Object.assign({}, state, {
				dashboardData: action.data,
				tab: 'home',
				updateProps: true,
				active:'home'
			});	
			
		case LOGIN:
			return Object.assign({}, state, {
				redirectTo: action.redirectTo, 
				token: action.token,
				tab: 'home'
			})
		case REDIRECT:                   
			return  Object.assign({}, state, {redirectTo: null }); 	
		
		case TOKEN_NAME:
			return Object.assign({}, state, { token: action.data,updateProps: true})		
		case PAGE_NOT_FOUND_LOADED:
			return Object.assign({}, state, {tab: action.tab})
		case LEARNING_PAGE_LOADER:
			return Object.assign({}, state, {tab: action.tab})
		case CURENT_DATE:
			return  Object.assign({}, state, {
				current_date: action.data,
				updateProps: true
			})
		case EV_DATA:
			return  Object.assign({}, state, {
				ev_data: action.data,
				updateProps: true
			})
		case EVP_DATA:
			return  Object.assign({}, state, {
				event_data: action.data,
				updateProps: true
			})
		case JOIN_CLASS_DATA:
			return  Object.assign({}, state, {
				join_class_data: action.data,
				updateProps: true
			})
		case LANGUAGE_KEY:
			return  Object.assign({}, state, {
				language_key: action.data,
				updateProps: true
			})
			
		default:
			return Object.assign({}, state)
  }
};
