const languages = {
  en:{
	  sections:"Sections", 
	   showing_of:"Showing 10 of 20", 
	   filter:"Filter", 
	   students:"Students", 
	   school:"school", 
	   all_school:"All Schools", 
	   student:"Student", 	   
	   view:"View", 
	   no_data:"No data.", 
	   gradebook:"Gradebook", 
	   home:"Home", 
	   roster:"Roster", 
	   attendance:"Attendance", 
	   actions:"Actions", 
	   add_activity:"Add activity", 
	   finalize:"Finalize", 
	   reactivate:"Reactivate", 
	   send_message_to_all_section_students:"Send Message to All Section Students", 
	   launch_course:"Launch Course", 
	   start_class:"Start Class", 
	   clear_unit:"Clear Unit", 
	   period:"Period", 
	   search_for_a_student_or_id:"Search for a student or ID …", 
	   overall:"Overall", 
	   hide_complete_students:"Hide completed students", 
	   view_submission_grade:"View Submission & Grade", 
	   download_all_submission:"Download All Submission", 
	   un_exempt:"Un-exempt", 
	   exempt:"Exempt", 
	   edit:"Edit", 
	   enter_grades:"Enter Grades", 
	   assign_default_grade:"Assign Default Grade", 
	   unlock_default_grade:"Unlock Assignment", 
	   lock_assignment:"Lock Assignment", 
	   remove:"Remove", 
	   delete:"Delete", 
	   download:"Download", 
	   back_to_all_units:"Back To All Units", 
	   add_unit:"Add Unit", 
	   close:"Close", 
	   select_section:"Select Section", 
	   unit_name:"Unit Name", 
	   index:"Index", 
	   select_period:"Select Period", 
	   add:"Add", 
	   activity_name:"Activity Name", 
	   description:"Description", 
	   drap_n_drop_some_files_here:"Drag 'n' drop some files here, or click to select files", 
	   only_files_of_size_2mb:"(Only files of size 2MB or smaller will be accepted)", 
	   link:"Link", 
	   label:"Label", 
	   add_more_link:"Add More Link", 
	   activity_types :"Activity Types", 
	   unit :"Unit", 
	   shared_with_students :"Shared with students", 
	   preparation :"Preparation", 
	   max_scode :"Max Score", 
	   expiration_date :"Expiration Date", 
	   due_date :"Due Date", 
	   archived :"Archived", 
	   graded :"Graded", 
	   save :"Save", 
	   select :"Select", 
	   exempted:"Exempted", 
	   edit_unit:"Edit Unit", 
	   edit_activity:"Edit Activity", 
	   confirm:"Confirm", 
	   are_you_sure_to_delete:"Are you sure to delete this Unit?", 
	   cancel:"Cancel", 
	   are_you_sure_to_exempt:"Are you sure to Exempt this section activity?", 
	   override:"Override", 
	   extra_points:"Extra Points", 
	   file:"File", 
	   download_all:"Download All", 
	   score:"Score", 
	   comment:"Comment", 
	   submit_grade:"Submit Grade", 
	   grade:"Grade", 
	   are_you_sure_to_lock:"Are you sure to lock this section activity?", 
	   are_you_sure_to_permanently_delete:"Are you sure to permanently delete section activity?", 
	   views:"Views", 
	   mandatory:"Mandatory", 
	   showing:"Showing", 
	   of:"of", 
	   are_you_sure_to_unlock_this_section_activity:"Are you sure to unlock this section activity?", 
	   are_you_sure_to_clear_this_unit:"Are you sure to clear this Unit?", 
	    
	   
	   reset_filters:"Reset filters"
 },
 ar: {
	   sections:"المقاطع",
	   showing_of:"يظهر 10 من 20",
	   filter:"منقي",
	   students:"الطلاب",
	   school:"المدرسة",
	   all_school:"جميع المدارس",
	   student:"طالب علم",	  
	   view:"رأي",
	   no_data:"لايوجد بيانات.",
	   gradebook:"دفتر التقديرات",
	   home:"مسكن",
	   roster:"قائمة",
	   attendance:"حضور",
	   actions:"أجراءات",
	   add_activity:"أضف نشاطًا",
	   finalize:"وضع اللمسات الأخيرة",
	   reactivate:"اعادة تفعيل",
	   send_message_to_all_section_students:"إرسال رسالة إلى جميع طلاب القسم",
	   launch_course:"إطلاق الدورة",
	   start_class:"أبدا الدرس",
	   clear_unit:"وحدة واضحة",
	   period:"فترة",
	   search_for_a_student_or_id:"البحث عن طالب أو هوية ...",
	   overall:"شاملة",
	   hide_complete_students:"إخفاء الطلاب المكتملين",
	   view_submission_grade:"عرض التقديم والتقدير",
	   download_all_submission:"تحميل كل التقديمات",
	   un_exempt:"غير معفى",
	   exempt:"معفى",
	   edit:"يحرر",
	   enter_grades:"أدخل الدرجات",
	   assign_default_grade:"تعيين التقدير الافتراضي",
	   unlock_default_grade:"فتح التخصيص",
	   lock_assignment:"تأمين التخصيص",
	   remove:"إزالة",
	   delete:"حذف",
	   download:"تحميل",
	   back_to_all_units:"العودة إلى جميع الوحدات",
	   add_unit:"أضف وحدة",
	   close:"قريب",
	   select_section:"حدد القسم",
	   unit_name:"إسم الوحدة",
	   index:"فِهرِس",
	   select_period:"حدد الفترة",
	   add:"يضيف",
	   activity_name:"اسم النشاط",
	   description:"وصف",
	   drap_n_drop_some_files_here:"اسحب وأفلت بعض الملفات هنا ، أو انقر لتحديد الملفات",
	   only_files_of_size_2mb:"(لن يتم قبول سوى الملفات ذات الحجم 2 ميغا بايت أو أصغر)",
	   link:"نهاية لهذه الغاية",
	   label:"مُلصَق",
	   add_more_link:"إضافة المزيد من الارتباط",
	   activity_types:"أنواع النشاط",
	   unit:"وحدة",
	   shared_with_students:"مشترك مع الطلاب",
	   preparation:"تحضير",
	   max_scode:"أقصى درجة",
	   expiration_date:"تاريخ إنتهاء الصلاحية",
      due_date:"تاريخ الاستحقاق",
	   archived:"مؤرشف",
	   graded:"متدرج",
	   save:"يحفظ",
	   select:"يختار",
	   exempted:"معفى",
	   edit_unit:"تحرير الوحدة",
	   edit_activity:"تحرير النشاط",
	   confirm:"يتأكد",
	   are_you_sure_to_delete:"هل أنت متأكد من حذف هذه الوحدة؟",
	   cancel:"يلغي",
	   are_you_sure_to_exempt:"هل أنت متأكد من استثناء نشاط هذا القسم؟",
	   override:"تجاوز",
	   extra_points:"نقط اضافية",
	   file:"ملف",
	   download_all:"تحميل الكل",
	   score:"نتيجة",
	   comment:"تعليق",
	   submit_grade:"إرسال الدرجة",
	   grade:"صف دراسي",
	   are_you_sure_to_lock:"هل أنت متأكد من قفل نشاط هذا القسم؟",
	   are_you_sure_to_permanently_delete:"هل أنت متأكد من حذف نشاط القسم نهائيًا؟",
	   views:"الآراء",
	   mandatory:"إلزامي",
	   showing:"عرض", 
	   of:"من", 
	   are_you_sure_to_unlock_this_section_activity:"هل أنت متأكد من فتح نشاط هذا القسم؟",
	   are_you_sure_to_clear_this_unit:"هل أنت متأكد من مسح هذه الوحدة؟",
	   reset_filters:"إعادة تعيين المرشحات"
 },
 zh: {
		sections:"部分",
	   showing_of:"显示20个中的10个",
	   filter:"筛选",
	   students:"学生",
	   school:"学校",
	   all_school:"所有学校",
	   student:"学生",	   
	   view:"看法",
	   no_data:"没有数据。",
	   gradebook:"成绩册",
	   home:"家",
	   roster:"名册",
	   attendance:"出席",
	   actions:"行动",
	   add_activity:"添加活动",
	   finalize:"敲定",
	   reactivate:"重新激活",
	   send_message_to_all_section_students:"向所有部门的学生发送消息",
	   launch_course:"启动课程",
	   start_class:"开始上课",
	   clear_unit:"清除单位",
	   period:"时期",
	   search_for_a_student_or_id:"搜索学生或 ID ...",
	   overall:"全面的",
	   hide_complete_students:"隐藏已完成的学生",
	   view_submission_grade:"查看提交和评分",
	   download_all_submission:"下载所有提交",
	   un_exempt:"非豁免",
	   exempt:"豁免",
	   edit:"编辑",
	   enter_grades:"输入成绩",
	   assign_default_grade:"分配默认成绩",
	   unlock_default_grade:"解锁任务",
	   lock_assignment:"锁定分配",
	   remove:"消除",
	   delete:"删除",
	   download:"下载",
	   back_to_all_units:"返回所有单位",
	   add_unit:"添加单位",
	   close:"关",
	   select_section:"选择部分",
	   unit_name:"单位名称",
	   index:"指数",
	   select_period:"选择期间",
	   add:"添加",
	   activity_name:"活动名称",
	   description:"描述",
	   drap_n_drop_some_files_here:"将一些文件拖放到此处，或单击以选择文件",
	   only_files_of_size_2mb:"（只接受大小为 2MB 或更小的文件）",
	   link:"关联",
	   label:"标签",
	   add_more_link:"添加更多链接",
	   activity_types:"活动类型",
	   unit:"单元",
	   shared_with_students:"与学生共享",
	   preparation:"准备",
	   max_scode:"最高分",
	   expiration_date:"截止日期",
      due_date:"截止日期",
	   archived:"存档",
	   graded:"分级",
	   save:"节省",
	   select:"选择",
	   exempted:"豁免",
	   edit_unit:"编辑单位",
	   edit_activity:"编辑活动",
	   confirm:"确认",
	   are_you_sure_to_delete:"您确定要删除此单元吗？",
	   cancel:"取消",
	   are_you_sure_to_exempt:"您确定要豁免此部分活动吗？",
	   override:"覆盖",
	   extra_points:"加分",
	   file:"文件",
	   download_all:"全部下载",
	   score:"分数",
	   comment:"评论",
	   submit_grade:"提交成绩",
	   grade:"年级",
	   are_you_sure_to_lock:"您确定锁定此部分活动吗？",
	   are_you_sure_to_permanently_delete:"您确定要永久删除版块活动吗？",
	   views:"意见",
	   mandatory:"强制的",
	   showing:"显示", 
	   of:"的", 
	   are_you_sure_to_unlock_this_section_activity:"您确定解锁此版块活动吗？",
	   are_you_sure_to_clear_this_unit:"您确定清除此单元吗？",
	  
	   reset_filters:"重置过滤器"
 },
 fr: { 
		sections:"Sections",	
	   showing_of:"Montrant 10 sur 20",	   
	   filter:"Filtre",	   
	   students:"Étudiants",
	   school:"école",
	   all_school:"Toutes les écoles",
	   student:"Étudiant",
	   view:"Voir",
	   no_data:"Pas de données.",
	   gradebook:"Bulletin de notes",
	   home:"Maison",
	   roster:"Liste",
	   attendance:"Présence",
	   actions:"Actions",
	   add_activity:"Ajouter une activité",
	   finalize:"Finaliser",
	   reactivate:"Réactiver",
	   send_message_to_all_section_students:"Envoyer un message à tous les étudiants de la section",
	   launch_course:"Cours de lancement",
	   start_class:"Commencer les cours",
	   clear_unit:"Effacer l'unité",
	   period:"Période",
	   search_for_a_student_or_id:"Rechercher un étudiant ou une pièce d'identité…",
	   overall:"Globalement",
	   hide_complete_students:"Masquer les étudiants terminés",
	   view_submission_grade:"Afficher la soumission et la note",
	   download_all_submission:"Télécharger toutes les soumissions",
	   un_exempt:"Non exempté",
	   exempt:"Exempter",
	   edit:"Éditer",
	   enter_grades:"Saisissez les notes",
	   assign_default_grade:"Attribuer une note par défaut",
	   unlock_default_grade:"Déverrouiller l'affectation",
	   lock_assignment:"Verrouiller l'affectation",
	   remove:"Retirer",
	   delete:"Effacer",
	   download:"Télécharger",
	   back_to_all_units:"Retour à toutes les unités",
	   add_unit:"Ajouter une unité",
	   close:"Proche",
	   select_section:"Sélectionnez Rubrique",
	   unit_name:"Nom de l'unité",
	   index:"Indice",
	   select_period:"Sélectionnez Période",
	   add:"Ajouter",
	   activity_name:"Nom de l'activité",
	   description:"La description",
	   drap_n_drop_some_files_here:"Faites glisser et déposez des fichiers ici, ou cliquez pour sélectionner des fichiers",
	   only_files_of_size_2mb:"(Seuls les fichiers de taille inférieure ou égale à 2 Mo seront acceptés)",
	   link:"Lien",
	   label:"Étiquette",
	   add_more_link:"Ajouter plus de lien",
	   activity_types:"ypes d'activités",
	   unit:"Unité",
	   shared_with_students:"Partagé avec les étudiants",
	   preparation:"Préparation",
	   max_scode:"Note maximale",
	   expiration_date:"Date d'expiration",
      due_date:"Date d'échéance",
	   archived:"Archivé",
	   graded:"Gradué",
	   save:"Sauvegarder",
	   select:"Sélectionner",
	    exempted:"Exempté",
		edit_unit:"Modifier l'unité",
		edit_activity:"Modifier l'activité",
		confirm:"Confirmer",
	   are_you_sure_to_delete:"Voulez-vous vraiment supprimer cette unité ?",
	   cancel:"Annuler",
	   are_you_sure_to_exempt:"Êtes-vous sûr d'exempter cette activité de section ?",
	   override:"Passer outre",
	   extra_points:"Points bonus",
	   file:"Dossier",
	   download_all:"Tout télécharger",
	   score:"Score",
	   comment:"Commentaire",
	   submit_grade:"Soumettre la note",
	   grade:"Noter",
	   are_you_sure_to_lock:"Êtes-vous sûr de verrouiller l'activité de cette section ?",
	   are_you_sure_to_permanently_delete:"Voulez-vous vraiment supprimer définitivement l'activité de la section ?",
	   views:"Vues",
	   mandatory:"Obligatoire",
	   showing:"Montrant", 
	   of:"de", 
	   are_you_sure_to_unlock_this_section_activity:"Êtes-vous sûr de déverrouiller cette activité de section ?",
	   are_you_sure_to_clear_this_unit:"Êtes-vous sûr d'avoir effacé cette unité ?",
	   
	   reset_filters:"Réinitialiser les filtres"
 },
 it: {
		sections:"Sezioni",
	   showing_of:"Mostrando 10 su 20",
	   filter:"Filtro",
	   students:"Studenti",
	   school:"scuola",
	   all_school:"Tutte le scuole",
	   student:"Alunno",
	   view:"Visualizzazione",
	   no_data:"Nessun dato.",
	   gradebook:"Registro dei voti",
	   home:"Casa",
	   roster:"Elenco",
	   attendance:"Presenze",
	   actions:"Azioni",
	   add_activity:"Aggiungi attività",
	   finalize:"Finalizzare",
	   reactivate:"Riattiva",
	   send_message_to_all_section_students:"Invia messaggio a tutti gli studenti della sezione",
	   launch_course:"Lancia il corso",
	   start_class:"Classe iniziale",
	   clear_unit:"Cancella unità",
	   period:"Periodo",
	   search_for_a_student_or_id:"Cerca uno studente o un documento d'identità...",
	   overall:"Complessivamente",
	   hide_complete_students:"Nascondi studenti completati",
	   view_submission_grade:"Visualizza invio e voto",
	   download_all_submission:"Scarica tutti gli invii",
	   un_exempt:"Non esentato",
	   exempt:"Esonerare",
	   edit:"Modificare",
	   enter_grades:"Inserisci i voti",
	   assign_default_grade:"Assegna voto predefinito",
	   unlock_default_grade:"Sblocca assegnazione",
	   lock_assignment:"Assegnazione della serratura",
	   remove:"Rimuovere",
	   delete:"Elimina",
	   download:"Scarica",
	   back_to_all_units:"Torna a tutte le unità",
	   add_unit:"Aggiungi unità",
	   close:"Chiudere",
	   select_section:"Seleziona Sezione",
	   unit_name:"Nome unità",
	   index:"Indice",
	   select_period:"Seleziona Periodo",
	   add:"Aggiungere",
	   activity_name:"Nome attività",
	   description:"Descrizione",
	   drap_n_drop_some_files_here:"Trascina e rilascia alcuni file qui o fai clic per selezionare i file",
	   only_files_of_size_2mb:"(Saranno accettati solo file di dimensioni pari o inferiori a 2 MB)",
	   link:"Collegamento",
	   label:"Etichetta",
	   add_more_link:"Aggiungi altro collegamento",
	   activity_types:"Tipi di attività",
	   unit:"Unità",
	   shared_with_students:"Condiviso con gli studenti",
	   preparation:"Preparazione",
	   max_scode:"Punteggio massimo",
	   expiration_date:"Data di scadenza",
      due_date:"Data di scadenza",
	   archived:"Archiviato",
	   graded:"Classificato",
	   save:"Salva",
	   select:"Selezionare",
	    exempted:"Esentata",
		edit_unit:"Modifica unità",
		edit_activity:"Modifica attività",
		confirm:"Confermare",
	   are_you_sure_to_delete:"Sei sicuro di eliminare questa unità?",
	   cancel:"Annulla",
	   are_you_sure_to_exempt:"Sei sicuro di esentare questa attività di sezione?",
	   override:"Oltrepassare",
	   extra_points:"Punti extra",
	   file:"File",
	   download_all:"Scarica tutto",
	   score:"Punto",
	   comment:"Commento",
	   submit_grade:"Invia voto",
	   grade:"Grado",
	   are_you_sure_to_lock:"Sei sicuro di bloccare l'attività di questa sezione?",
	   are_you_sure_to_permanently_delete:"Sei sicuro di eliminare definitivamente l'attività della sezione?",
	   views:"Visualizzazioni",
	   mandatory:"Obbligatoria",
	   showing:"Mostrando", 
	   of:"di",
	   are_you_sure_to_unlock_this_section_activity:"Sei sicuro di sbloccare l'attività di questa sezione?",
	   are_you_sure_to_clear_this_unit:"Sei sicuro di cancellare questa unità?",
	  
	   reset_filters:"Ripristina i filtri"
 },
 kk: {
		sections:"Бөлімдер",
	   showing_of:"10-дан 20-ны көрсету",
	   filter:"Сүзу",
	   students:"Оқушылар",
	   school:"мектеп",
	   all_school:"Барлық мектептер",
	   student:"Студент",
	   view:"Көру",
	   no_data:"Деректер жоқ.",
	   gradebook:"Баға кітапшасы",
	   home:"Үй",
	   roster:"Тізім",
	   attendance:"Қатысу",
	   actions:"Әрекеттер",
	   add_activity:"Әрекетті қосыңыз",
	   finalize:"Аяқтау",
	   reactivate:"Қайта белсендіру",
	   send_message_to_all_section_students:"Барлық бөлім студенттеріне хабарлама жіберу",
	   launch_course:"Курсты бастау",
	   start_class:"Сабақты бастау",
	   clear_unit:"Бірлікті тазалау",
	   period:"Кезең",
	   search_for_a_student_or_id:"Студент немесе жеке куәлікті іздеу…",
	   overall:"Жалпы",
	   hide_complete_students:"Аяқталған оқушыларды жасыру",
	   view_submission_grade:"Жіберу мен бағаны көру",
	   download_all_submission:"Барлық жіберуді жүктеп алыңыз",
	   un_exempt:"Босатылған",
	   exempt:"Босатылған",
	   edit:"Өңдеу",
	   enter_grades:"Бағаларды енгізіңіз",
	   assign_default_grade:"Әдепкі баға тағайындаңыз",
	   unlock_default_grade:"Тапсырманың құлпын ашу",
	   lock_assignment:"Тапсырманы құлыптау",
	   remove:"Жою",
	   delete:"Жою",
	   download:"Жүктеп алу",
	   back_to_all_units:"Барлық бірліктерге оралу",
	   add_unit:"Бірлік қосу",
	   close:"Жабық",
	   select_section:"Бөлімді таңдаңыз",
	   unit_name:"Бірлік атауы",
	   index:"Индекс",
	   select_period:"Кезеңді таңдаңыз",
	   add:"қосу",
	   activity_name:"Әрекет атауы",
	   description:"Сипаттама",
	   drap_n_drop_some_files_here:"Кейбір файлдарды осы жерге сүйреп апарыңыз немесе файлдарды таңдау үшін басыңыз",
	   only_files_of_size_2mb:"(Өлшемі 2 МБ немесе одан кіші файлдар ғана қабылданады)",
	   link:"Сілтеме",
	   label:"Заттаңба",
	   add_more_link:"Қосымша сілтеме қосу",
	   activity_types:"Әрекет түрлері",
	   unit:"Бірлік",
	   shared_with_students:"Оқушылармен бөлісті",
	   preparation:"Дайындық",
	   max_scode:"Максималды ұпай",
	   expiration_date:"Мерзімнің өту күні",
      due_date:"Мерзімі",
	   archived:"Мұрағатталған",
	   graded:"Бағаланған",
	   save:"Сақтау",
	   select:"таңдаңыз",
	    exempted:"Босатылған",
		edit_unit:"Өңдеу бірлігі",
		edit_activity:"Әрекетті өңдеу",
		confirm:"Растау",
	   are_you_sure_to_delete:"Бұл құрылғыны шынымен жоясыз ба?",
	   cancel:"Болдырмау",
	   are_you_sure_to_exempt:"Бұл бөлім әрекетін босатуға сенімдісіз бе?",
	   override:"Қайта анықтау",
	   extra_points:"Қосымша ұпайлар",
	   file:"Файл",
	   download_all:"Барлығын жүктеп алыңыз",
	   score:"Гол",
	   comment:"Түсініктеме",
	   submit_grade:"Баға жіберу",
	   grade:"Баға",
	   are_you_sure_to_lock:"Осы бөлімдегі әрекетті құлыптауға сенімдісіз бе?",
	   are_you_sure_to_permanently_delete:"Бөлім әрекетін біржола жоясыз ба?",
	   views:"Көрулер",
	   mandatory:"Міндетті",
	   showing:"Көрсетілуде", 
	   of:"бойынша",
	   are_you_sure_to_unlock_this_section_activity:"Бұл бөлім әрекетінің құлпын ашуға сенімдісіз бе?",
	   are_you_sure_to_clear_this_unit:"Бұл құрылғыны тазалауға сенімдісіз бе?",
	  
	   reset_filters:"Сүзгілерді қалпына келтіріңіз"
 },
 ko: {
		sections:"섹션",
	   showing_of:"20/20을 보여줍니다",
	   filter:"필터",
	   students:"학생",
	   school:"학교",
	   all_school:"모든 학교",
	   student:"학생",
	   view:"보다",
	   no_data:"데이터가 없습니다.",
	   gradebook:"성적표",
	   home:"집",
	   roster:"명단에",
	   attendance:"출석",
	   actions:"행위",
	   add_activity:"활동을 추가합니다",
	   finalize:"마무리합니다",
	   reactivate:"재활성화합니다",
	   send_message_to_all_section_students:"모든 섹션 학생에게 메시지를 보냅니다",
	   launch_course:"코스를 시작합니다",
	   start_class:"수업을 시작합니다",
	   clear_unit:"단위를 지웁니다",
	   period:"기간",
	   search_for_a_student_or_id:"학생 또는 ID 검색 …",
	   overall:"전반적인",
	   hide_complete_students:"완료한 학생을 숨깁니다",
	   view_submission_grade:"제출 및 성적 보기",
	   download_all_submission:"모든 제출물을 다운로드합니다",
	   un_exempt:"면제되지 않습니다",
	   exempt:"면제",
	   edit:"편집하다",
	   enter_grades:"성적을 입력합니다",
	   assign_default_grade:"기본 등급을 지정합니다",
	   unlock_default_grade:"과제를 잠금 해제합니다",
	   lock_assignment:"잠금 할당",
	   remove:"제거하다",
	   delete:"삭제",
	   download:"다운로드",
	   back_to_all_units:"모든 단위로 돌아가기",
	   add_unit:"단위를 추가합니다",
	   close:"닫다",
	   select_section:"섹션을 선택합니다",
	   unit_name:"장치 이름",
	   index:"색인",
	   select_period:"기간을 선택합니다",
	   add:"추가하다",
	   activity_name:"활동 이름",
	   description:"설명",
	   drap_n_drop_some_files_here:"여기에 파일을 드래그 앤 드롭하거나 클릭하여 파일을 선택하십시오",
	   only_files_of_size_2mb:"(크기가 2MB 이하인 파일만 허용됩니다.)",
	   link:"링크",
	   label:"상표",
	   add_more_link:"더 많은 링크를 추가합니다",
	   activity_types:"활동 유형",
	   unit:"단위",
	   shared_with_students:"학생들과 공유합니다",
	   preparation:"준비",
	   max_scode:"최대 점수",
	   expiration_date:"만료일",
      due_date:"기한",
	   archived:"보관됨",
	   graded:"등급",
	   save:"구하다",
	   select:"고르다",
	    exempted:"면제",
		edit_unit:"단위 편집",
		edit_activity:"활동 편집",
		confirm:"확인하다",
	   are_you_sure_to_delete:"이 장치를 삭제하시겠습니까?",
	   cancel:"취소",
	   are_you_sure_to_exempt:"이 섹션 활동을 면제하시겠습니까?",
	   override:"우세하다",
	   extra_points:"추가 포인트",
	   file:"파일",
	   download_all:"모두 다운로드",
	   score:"점수",
	   comment:"논평",
	   submit_grade:"성적을 제출합니다",
	   grade:"등급",
	   are_you_sure_to_lock:"이 섹션 활동을 잠그시겠습니까?",
	   are_you_sure_to_permanently_delete:"섹션 활동을 영구적으로 삭제하시겠습니까?",
	   views:"견해",
	   mandatory:"필수적인",
	   showing:"전시", 
	   of:"의",
	   are_you_sure_to_unlock_this_section_activity:"이 섹션 활동을 잠금 해제하시겠습니까?",
	   are_you_sure_to_clear_this_unit:"이 유닛을 클리어하시겠습니까?",
	   
	   reset_filters:"필터를 재설정합니다"
 },
 pt: {
		sections:"Seções",
	   showing_of:"Mostrando 10 de 20",
	   filter:"Filtro",
	   students:"Estudiantes",
	   school:"escuela",
	   all_school:"Todas las Escuelas",
	   student:"Estudante",
	   view:"Visualizar",
	   no_data:"Sem dados.",
	   gradebook:"Livro de notas",
	   home:"Home",
	   roster:"Lista",
	   attendance:"Comparecimento",
	   actions:"Ações",
	   add_activity:"Adicionar atividade",
	   finalize:"Finalizar",
	   reactivate:"Reativar",
	   send_message_to_all_section_students:"Enviar Mensagem a Todos os Alunos da Seção",
	   launch_course:"Abrir o curso",
	   start_class:"Começar a aula",
	   clear_unit:"Unidade Limpa",
	   period:"Período",
	   search_for_a_student_or_id:"Procure um aluno ou ID…",
	   overall:"No geral",
	   hide_complete_students:"Ocultar alunos concluídos",
	   view_submission_grade:"Ver submissão e nota",
	   download_all_submission:"Baixe todos os envios",
	   un_exempt:"Não isento",
	   exempt:"Isentar",
	   edit:"Editar",
	   enter_grades:"Digite Graus",
	   assign_default_grade:"Atribuir nota padrão",
	   unlock_default_grade:"Desbloquear Atribuição",
	   lock_assignment:"Atribuição de bloqueio",
	   remove:"Remover",
	   delete:"Excluir",
	   download:"Download",
	   back_to_all_units:"Voltar para todas as unidades",
	   add_unit:"Adicionar unidade",
	   close:"Perto",
	   select_section:"Selecione Seção",
	   unit_name:"Nome da Unidade",
	   index:"Índice",
	   select_period:"Selecione Período",
	   add:"Adicionar",
	   activity_name:"Nome da atividade",
	   description:"Descrição",
	   drap_n_drop_some_files_here:"Arraste e solte alguns arquivos aqui, ou clique para selecionar arquivos",
	   only_files_of_size_2mb:"(Apenas arquivos de tamanho 2MB ou menor serão aceitos)",
	   link:"Link",
	   label:"Etiqueta",
	   add_more_link:"Adicionar mais links",
	   activity_types:"Tipos de atividade",
	   unit:"Unidade",
	   shared_with_students:"Compartilhado com os alunos",
	   preparation:"Preparação",
	   max_scode:"Pontuação máxima",
	   expiration_date:"Data de validade",
      due_date:"Data de vencimento",
	   archived:"Arquivado",
	   graded:"Graduado",
	   save:"Salvar",
	   select:"Selecione",
	    exempted:"Isenta",
		edit_unit:"Editar unidade",
		edit_activity:"Editar atividade",
		confirm:"Confirme",
	   are_you_sure_to_delete:"Tem certeza de que deseja excluir esta unidade?",
	   cancel:"Cancelar",
	   are_you_sure_to_exempt:"Tem certeza de isentar esta atividade de seção?",
	   override:"Sobrepor",
	   extra_points:"Pontos extras",
	   file:"Arquivo",
	   download_all:"Baixar tudo",
	   score:"Pontuação",
	   comment:"Comente",
	   submit_grade:"Enviar nota",
	   grade:"Avaliar",
	   are_you_sure_to_lock:"Tem certeza de que deseja bloquear esta atividade de seção?",
	   are_you_sure_to_permanently_delete:"Tem certeza de excluir permanentemente a atividade da seção?",
	   views:"Visualizações",
	   mandatory:"Obrigatoriedade",
	   showing:"Mostrando", 
	   of:"de",
	   are_you_sure_to_unlock_this_section_activity:"Tem certeza de que deseja desbloquear a atividade desta seção?",
	   are_you_sure_to_clear_this_unit:"Tem certeza que deseja limpar esta unidade?",
	  
	   reset_filters:"Redefinir filtros"
 },
 ru: {
		sections:"Разделы",
	   showing_of:"Показывая 10 из 20",
	   filter:"Фильтр",
	   students:"Ученики",
	   school:"школа",
	   all_school:"Все школы",
	   student:"Ученик",
	   view:"Вид",
	   no_data:"Нет данных.",
	   gradebook:"Зачетная книжка",
	   home:"Дом",
	   roster:"Список",
	   attendance:"Посещаемость",
	   actions:"Действия",
	   add_activity:"Добавьте активность",
	   finalize:"Завершить",
	   reactivate:"Повторно активировать",
	   send_message_to_all_section_students:"Отправить сообщение всем учащимся секции",
	   launch_course:"Запустить курс",
	   start_class:"Стартовый класс",
	   clear_unit:"Очистить блок",
	   period:"Период",
	   search_for_a_student_or_id:"Поиск учащегося или удостоверения личности…",
	   overall:"Общий",
	   hide_complete_students:"Скрыть завершенных студентов",
	   view_submission_grade:"Просмотр отправки и оценки",
	   download_all_submission:"Скачать все материалы",
	   un_exempt:"Неосвобожденный",
	   exempt:"Освобождать",
	   edit:"Редактировать",
	   enter_grades:"Введите оценки",
	   assign_default_grade:"Назначить оценку по умолчанию",
	   unlock_default_grade:"Разблокировать задание",
	   lock_assignment:"Назначение блокировки",
	   remove:"Удалять",
	   delete:"Удалить",
	   download:"Скачать",
	   back_to_all_units:"Назад ко всем единицам",
	   add_unit:"Добавить единицу",
	   close:"Закрывать",
	   select_section:"Выберите Раздел",
	   unit_name:"Имя единицы",
	   index:"Индекс",
	   select_period:"Выберите Период",
	   add:"Добавлять",
	   activity_name:"Название деятельности",
	   description:"Описание",
	   drap_n_drop_some_files_here:"Перетащите сюда несколько файлов или нажмите, чтобы выбрать файлы",
	   only_files_of_size_2mb:"(Принимаются только файлы размером 2 МБ или меньше)",
	   link:"Ссылка на сайт",
	   label:"Этикетка",
	   add_more_link:"Добавить еще ссылку",
	   activity_types:"ипы деятельности",
	   unit:"Ед. изм",
	   shared_with_students:"Совместно со студентами",
	   preparation:"Подготовка",
	   max_scode:"Максимальный балл",
	   expiration_date:"Срок годности",
      due_date:"Дата возвращения",
	   archived:"В архиве",
	   graded:"Оценка",
	   save:"Сохранять",
	   select:"Выбирать",
	    exempted:"Освобожденный",
		edit_unit:"Изменить единицу измерения",
		edit_activity:"Редактировать деятельность",
		confirm:"Подтверждать",
	   are_you_sure_to_delete:"Вы уверены, что хотите удалить этот блок?",
	   cancel:"Отмена",
	   are_you_sure_to_exempt:"Вы уверены, что хотите исключить действия из этого раздела?",
	   override:"Переопределить",
	   extra_points:"Дополнительные очки",
	   file:"Файл",
	   download_all:"Скачать все",
	   score:"Счет",
	   comment:"Комментарий",
	   submit_grade:"Отправить оценку",
	   grade:"Оценка",
	   are_you_sure_to_lock:"Вы уверены, что блокируете активность в этом разделе?",
	   are_you_sure_to_permanently_delete:"Вы уверены, что навсегда удалите активность в разделе?",
	   views:"Просмотры",
	   mandatory:"Обязательный",
	   showing:"Показаны", 
	   of:"из",
	   are_you_sure_to_unlock_this_section_activity:"Вы уверены, что разблокируете активность в этом разделе?",
	   are_you_sure_to_clear_this_unit:"Вы уверены, что хотите очистить этот модуль?",
	   
	   reset_filters:"Сбросить фильтры"
 },
 es: {
		sections:"Secciones",
	   showing_of:"Mostrando 10 de 20",
	   filter:"Filtrar",
	   students:"Estudiantes",
	   school:"escuela",
	   all_school:"Todas las Escuelas",
	   student:"Alumno",
	   view:"Vista",
	   no_data:"Sem dados.",
	   gradebook:"Libro de calificaciones",
	   home:"Hogar",
	   roster:"Lista",
	   attendance:"Asistencia",
	   actions:"Comportamiento",
	   add_activity:"Añadir actividad",
	   finalize:"Finalizar",
	   reactivate:"Reactivar",
	   send_message_to_all_section_students:"Enviar mensaje a todos los estudiantes de la sección",
	   launch_course:"Curso de Lanzamiento",
	   start_class:"Comenzar la clase",
	   clear_unit:"Unidad clara",
	   period:"Período",
	   search_for_a_student_or_id:"Buscar un estudiante o ID…",
	   overall:"General",
	   hide_complete_students:"Ocultar alumnos completados",
	   view_submission_grade:"Ver envío y calificación",
	   download_all_submission:"Descargar todos los envíos",
	   un_exempt:"No exento",
	   exempt:"Eximir",
	   edit:"Editar",
	   enter_grades:"Ingrese calificaciones",
	   assign_default_grade:"Asignar calificación predeterminada",
	   unlock_default_grade:"Desbloquear asignación",
	   lock_assignment:"Asignación de bloqueo",
	   remove:"Remover",
	   delete:"Borrar",
	   download:"Descargar",
	   back_to_all_units:"Volver a todas las unidades",
	   add_unit:"Añadir Unidad",
	   close:"Cerca",
	   select_section:"Seleccione Sección",
	   unit_name:"Nombre de la unidad",
	   index:"Índice",
	   select_period:"Seleccione Período",
	   add:"Agregar",
	   activity_name:"Nombre de la actividad",
	   description:"Descripción",
	   drap_n_drop_some_files_here:"Arrastre y suelte algunos archivos aquí, o haga clic para seleccionar archivos",
	   only_files_of_size_2mb:"(Solo se aceptarán archivos de 2 MB o menos)",
	   link:"Enlace",
	   label:"Etiqueta",
	   add_more_link:"Agregar más enlace",
	   activity_types:"Tipos de actividades",
	   unit:"Unidad",
	   shared_with_students:"Compartido con los estudiantes",
	   preparation:"Preparación",
	   max_scode:"Maximo puntaje",
	   expiration_date:"Fecha de caducidad",
      due_date:"Fecha de vencimiento",
	   archived:"Archivado",
	   graded:"Calificado",
	   save:"Ahorrar",
	   select:"Seleccione",
	    exempted:"Exentada",
		edit_unit:"Editar unidad",
		edit_activity:"Editar actividad",
		confirm:"Confirmar",
	   are_you_sure_to_delete:"¿Estás seguro de eliminar esta unidad?",
	   cancel:"Cancelar",
	   are_you_sure_to_exempt:"¿Está seguro de Eximir la actividad de esta sección?",
	   override:"Anular",
	   extra_points:"Puntos extra",
	   file:"Expediente",
	   download_all:"Descargar todo",
	   score:"Puntaje",
	   comment:"Comentario",
	   submit_grade:"Enviar calificación",
	   grade:"Calificación",
	   are_you_sure_to_lock:"¿Estás seguro de bloquear la actividad de esta sección?",
	   are_you_sure_to_permanently_delete:"¿Estás seguro de eliminar permanentemente la actividad de la sección?",
	   views:"Puntos de vista",
	   mandatory:"Obligatoria",
	   showing:"Demostración", 
	   of:"de",
	   are_you_sure_to_unlock_this_section_activity:"¿Estás segura de desbloquear la actividad de esta sección?",
	   are_you_sure_to_clear_this_unit:"¿Estás segura de borrar esta Unidad?",
	   
	   reset_filters:"Restablecer filtros"
 },
 vi: {
		sections:"Phần",	 
	   sections:"Phần",	   
	   showing_of:"Hiển thị 10 trên 20",	   
	   filter:"Lọc",	
	   students:"Học Sinh",
	   school:"trường học",
	   all_school:"Tất cả các trường học",
	   student:"Học Sinh",
	   school:"trường học",
	   email:"Thư điện tử",
	   grade_level:"Khối",
	   status:"Trạng thái",
	   seach_for_a_student_or_id:"Tìm kiếm sinh viên hoặc ID…",
	   search:"Tìm kiếm …",
	   message_student:"Nhắn học sinh",
	   request_enrollment:"Yêu cầu ghi danh",
	   print_schedule:"Lịch in",
	   view:"Lượt xem",
	   no_data:"Không có dữ liệu.",
	   gradebook:"Sổ điểm",
	   home:"Nhà",
	   roster:"Đội hình",
	   attendance:"Sự tham dự",
	   actions:"Các hành động",
	   add_activity:"Thêm hoạt động",
	   finalize:"Kết thúc",
	   reactivate:"Kích hoạt lại",
	   send_message_to_all_section_students:"Gửi tin nhắn cho tất cả học sinh phần",
	   launch_course:"Khởi động khóa học",
	   start_class:"Bắt đầu lớp học",
	   clear_unit:"Xóa đơn vị",
	   period:"Giai đoạn",
	   search_for_a_student_or_id:"Tìm kiếm sinh viên hoặc ID…",
	   overall:"Tổng thể",
	   hide_complete_students:"Ẩn học sinh đã hoàn thành",
	   view_submission_grade:"Xem bài nộp và điểm",
	   download_all_submission:"Tải xuống Tất cả Nội dung đệ trình",
	   un_exempt:"Không được miễn trừ",
	   exempt:"Miễn",
	   edit:"Chỉnh sửa",
	   enter_grades:"Nhập điểm",
	   assign_default_grade:"Chỉ định Lớp Mặc định",
	   unlock_default_grade:"Mở khóa nhiệm vụ",
	   lock_assignment:"Khóa chuyển nhượng",
	   remove:"Loại bỏ",
	   delete:"Xóa bỏ",
	   download:"Tải xuống",
	   back_to_all_units:"Quay lại tất cả các đơn vị",
	   add_unit:"Thêm đơn vị",
	   close:"Đóng",
	   select_section:"Chọn Phần",
	   unit_name:"Tên bài",
	   index:"Mục lục",
	   select_period:"Chọn Khoảng thời gian",
	   add:"Thêm",
	   activity_name:"Tên hoạt động",
	   description:"Sự mô tả",
	   drap_n_drop_some_files_here:"Kéo 'n' thả một số tệp vào đây hoặc nhấp để chọn tệp",
	   only_files_of_size_2mb:"(Chỉ những tệp có kích thước từ 2MB trở xuống mới được chấp nhận)",
	   link:"Liên kết",
	   label:"Nhãn mác",
	   add_more_link:"Thêm liên kết khác",
	   activity_types:"Các loại hoạt động",
	   unit:"Đơn vị",
	   shared_with_students:"Chia sẻ với học sinh",
	   preparation:"Sự chuẩn bị.",
	   max_scode:"Điểm tối đa",
	   expiration_date:"Ngày hết hạn",
      due_date:"Ngày đáo hạn",
	   archived:"Đã lưu trữ",
	   graded:"Đã phân loại",
	   save:"Lưu",
	   select:"Lựa chọn",
	    exempted:"Được miễn",
		edit_unit:"Chỉnh sửa đơn vị",
		edit_activity:"Chỉnh sửa hoạt động",
		confirm:"Xác nhận",
	   are_you_sure_to_delete:"Bạn có chắc chắn muốn xóa Đơn vị này không?",
	   cancel:"Hủy bỏ",
	   are_you_sure_to_exempt:"Bạn có chắc chắn Miễn hoạt động phần này không?",
	   override:"Ghi đè",
	   extra_points:"Những điểm cộng",
	   file:"Tập tin",
	   download_all:"Tải xuống tất cả",
	   score:"Điểm",
	   comment:"Bình luận",
	   submit_grade:"Gửi điểm",
	   grade:"Lớp",
	   are_you_sure_to_lock:"Bạn có chắc chắn khóa hoạt động phần này không?",
	   are_you_sure_to_permanently_delete:"Bạn có chắc chắn xóa vĩnh viễn hoạt động của phần không?",
	   views:"Lượt xem",
	   mandatory:"Bắt buộc",
	   showing:"Đang hiển thị", 
	   of:"của",
	   are_you_sure_to_unlock_this_section_activity:"Bạn có chắc chắn để mở khóa hoạt động phần này?",
	   are_you_sure_to_clear_this_unit:"Bạn có chắc chắn để xóa Đơn vị này?",
	   
	   reset_filters:"Đặt lại bộ lọc"
 },
 hi:{
	  sections:"धारा", 
	   showing_of:"20 में से 10 दिखा रहा है", 
	   filter:"फ़िल्टर", 
	   students:"छात्र", 
	   school:"स्कूल", 
	   all_school:"सभी स्कूल", 
	   student:"विद्यार्थी", 	   
	   view:"देखना", 
	   no_data:"कोई आकड़ा उपलब्ध नहीं है।", 
	   gradebook:"ग्रेडबुक", 
	   home:"घर", 
	   roster:"रोस्टर", 
	   attendance:"उपस्थिति", 
	   actions:"कार्रवाई", 
	   add_activity:"गतिविधि जोड़ें", 
	   finalize:"अंतिम रूप", 
	   reactivate:"पुन: सक्रिय", 
	   send_message_to_all_section_students:"सभी अनुभाग छात्रों को संदेश भेजें", 
	   launch_course:"लॉन्च कोर्स", 
	   start_class:"कक्षा शुरू", 
	   clear_unit:"क्लियर यूनिट", 
	   period:"अवधि", 
	   search_for_a_student_or_id:"एक छात्र या आईडी खोजें ...", 
	   overall:"कुल मिलाकर", 
	   hide_complete_students:"पूर्ण छात्रों को छुपाएं", 
	   view_submission_grade:"सबमिशन और ग्रेड देखें", 
	   download_all_submission:"सभी सबमिशन डाउनलोड करें", 
	   un_exempt:"गैर-छूट", 
	   exempt:"मुक्त करें", 
	   edit:"संपादन करना", 
	   enter_grades:"ग्रेड दर्ज करें", 
	   assign_default_grade:"डिफ़ॉल्ट ग्रेड असाइन करें", 
	   unlock_default_grade:"असाइनमेंट अनलॉक करें", 
	   lock_assignment:"लॉक असाइनमेंट", 
	   remove:"निकालना", 
	   delete:"मिटाना", 
	   download:"डाउनलोड", 
	   back_to_all_units:"सभी इकाइयों पर वापस", 
	   add_unit:"यूनिट जोड़ें", 
	   close:"बंद करना", 
	   select_section:"अनुभाग का चयन करें", 
	   unit_name:"इकाई का नाम", 
	   index:"अनुक्रमणिका", 
	   select_period:"अवधि का चयन करें", 
	   add:"जोड़ें", 
	   activity_name:"गतिविधि का नाम", 
	   description:"विवरण", 
	   drap_n_drop_some_files_here:"'एन' खींचें और कुछ फ़ाइलें यहां छोड़ें, या फ़ाइलों का चयन करने के लिए क्लिक करें", 
	   only_files_of_size_2mb:"(केवल 2MB या उससे छोटे आकार की फ़ाइलें ही स्वीकार की जाएंगी)", 
	   link:"संपर्क", 
	   label:"लेबल", 
	   add_more_link:"अधिक लिंक जोड़ें", 
	   activity_types :"गतिविधि के प्रकार", 
	   unit :"यूनिट", 
	   shared_with_students :"छात्रों के साथ साझा किया", 
	   preparation :"तैयारी", 
	   max_scode:"मैक्स स्कोर", 
	   expiration_date :"समाप्ति तिथि", 
	   due_date :"नियत तारीख", 
	   archived :"संग्रहीत", 
	   graded :"ग्रेड दिया गया", 
	   save :"बचाना", 
	   select :"चुनना", 
	   exempted:"छूट प्राप्त", 
	   edit_unit:"इकाई संपादित करें", 
	   edit_activity:"गतिविधि संपादित करें", 
	   confirm:"पुष्टि करें", 
	   are_you_sure_to_delete:"क्या आप निश्चित रूप से इस इकाई को हटाना चाहते हैं?", 
	   cancel:"रद्द करना", 
	   are_you_sure_to_exempt:"क्या आप निश्चित रूप से इस अनुभाग गतिविधि से छूट प्राप्त करना चाहते हैं?", 
	   override:"अवहेलना", 
	   extra_points:"अतिरिक्त अंक", 
	   file:"फ़ाइल", 
	   download_all:"सभी डाउनलोड", 
	   score:"अंक", 
	   comment:"टिप्पणी", 
	   submit_grade:"ग्रेड जमा करें", 
	   grade:"श्रेणी", 
	   are_you_sure_to_lock:"क्या आप वाकई इस सेक्शन की गतिविधि को लॉक करना चाहते हैं?", 
	   are_you_sure_to_permanently_delete:"क्या आप निश्चित रूप से अनुभाग गतिविधि को स्थायी रूप से हटाना चाहते हैं?", 
	   views:"विचारों", 
	   mandatory:"अनिवार्य", 
	   showing:"दिखा", 
	   of:"का", 
	   are_you_sure_to_unlock_this_section_activity:"क्या आप वाकई इस सेक्शन गतिविधि को अनलॉक करना चाहते हैं?", 
	   are_you_sure_to_clear_this_unit:"क्या आप निश्चित रूप से इस यूनिट को क्लियर कर लेंगे?", 
	    
	   
	   reset_filters:"फ़िल्टर रीसेट करें"
 },
  tr:{
	  sections:"Sections", 
	   showing_of:"Showing 10 of 20", 
	   filter:"Filter", 
	   students:"Students", 
	   school:"school", 
	   all_school:"All Schools", 
	   student:"Student", 	   
	   view:"View", 
	   no_data:"No data.", 
	   gradebook:"Gradebook", 
	   home:"Home", 
	   roster:"Roster", 
	   attendance:"Attendance", 
	   actions:"Actions", 
	   add_activity:"Add activity", 
	   finalize:"Finalize", 
	   reactivate:"Reactivate", 
	   send_message_to_all_section_students:"Send Message to All Section Students", 
	   launch_course:"Launch Course", 
	   start_class:"Start Class", 
	   clear_unit:"Clear Unit", 
	   period:"Period", 
	   search_for_a_student_or_id:"Search for a student or ID …", 
	   overall:"Overall", 
	   hide_complete_students:"Hide completed students", 
	   view_submission_grade:"View Submission & Grade", 
	   download_all_submission:"Download All Submission", 
	   un_exempt:"Un-exempt", 
	   exempt:"Exempt", 
	   edit:"Edit", 
	   enter_grades:"Enter Grades", 
	   assign_default_grade:"Assign Default Grade", 
	   unlock_default_grade:"Unlock Assignment", 
	   lock_assignment:"Lock Assignment", 
	   remove:"Remove", 
	   delete:"Delete", 
	   download:"Download", 
	   back_to_all_units:"Back To All Units", 
	   add_unit:"Add Unit", 
	   close:"Close", 
	   select_section:"Select Section", 
	   unit_name:"Unit Name", 
	   index:"Index", 
	   select_period:"Select Period", 
	   add:"Add", 
	   activity_name:"Activity Name", 
	   description:"Description", 
	   drap_n_drop_some_files_here:"Drag 'n' drop some files here, or click to select files", 
	   only_files_of_size_2mb:"(Only files of size 2MB or smaller will be accepted)", 
	   link:"Link", 
	   label:"Label", 
	   add_more_link:"Add More Link", 
	   activity_types :"Activity Types", 
	   unit :"Unit", 
	   shared_with_students :"Shared with students", 
	   preparation :"Preparation", 
	   max_scode :"Max Score", 
	   expiration_date :"Expiration Date", 
	   due_date :"Due Date", 
	   archived :"Archived", 
	   graded :"Graded", 
	   save :"Save", 
	   select :"Select", 
	   exempted:"Exempted", 
	   edit_unit:"Edit Unit", 
	   edit_activity:"Edit Activity", 
	   confirm:"Confirm", 
	   are_you_sure_to_delete:"Are you sure to delete this Unit?", 
	   cancel:"Cancel", 
	   are_you_sure_to_exempt:"Are you sure to Exempt this section activity?", 
	   override:"Override", 
	   extra_points:"Extra Points", 
	   file:"File", 
	   download_all:"Download All", 
	   score:"Score", 
	   comment:"Comment", 
	   submit_grade:"Submit Grade", 
	   grade:"Grade", 
	   are_you_sure_to_lock:"Are you sure to lock this section activity?", 
	   are_you_sure_to_permanently_delete:"Are you sure to permanently delete section activity?", 
	   views:"Views", 
	   mandatory:"Mandatory", 
	   showing:"Showing", 
	   of:"of", 
	   are_you_sure_to_unlock_this_section_activity:"Are you sure to unlock this section activity?", 
	   are_you_sure_to_clear_this_unit:"Are you sure to clear this Unit?", 
	    
	   
	   reset_filters:"Reset filters"
 },
  mr:{
	  sections:"विभाग", 
	   showing_of:"20 पैकी 10 दाखवत आहे", 
	   filter:"फिल्टर करा", 
	   students:"विद्यार्थीच्या", 
	   school:"शाळा", 
	   all_school:"सर्व शाळा", 
	   student:"विद्यार्थी", 	   
	   view:"पहा", 
	   no_data:"माहिती उपलब्ध नाही.", 
	   gradebook:"ग्रेडबुक", 
	   home:"मुख्यपृष्ठ", 
	   roster:"रोस्टर", 
	   attendance:"उपस्थिती", 
	   actions:"क्रिया", 
	   add_activity:"क्रियाकलाप जोडा", 
	   finalize:"अंतिम करा", 
	   reactivate:"पुन्हा सक्रिय करा", 
	   send_message_to_all_section_students:"सर्व विभागातील विद्यार्थ्यांना संदेश पाठवा", 
	   launch_course:"कोर्स लाँच करा", 
	   start_class:"वर्ग सुरू करा", 
	   clear_unit:"एकक साफ करा", 
	   period:"कालावधी", 
	   search_for_a_student_or_id:"विद्यार्थी किंवा आयडी शोधा…", 
	   overall:"एकूणच", 
	   hide_complete_students:"पूर्ण झालेले विद्यार्थी लपवा", 
	   view_submission_grade:"सबमिशन आणि ग्रेड पहा", 
	   download_all_submission:"सर्व सबमिशन डाउनलोड करा", 
	   un_exempt:"अन-मुक्त", 
	   exempt:"सूट", 
	   edit:"सुधारणे", 
	   enter_grades:"ग्रेड प्रविष्ट करा", 
	   assign_default_grade:"डीफॉल्ट ग्रेड नियुक्त करा", 
	   unlock_default_grade:"असाइनमेंट अनलॉक करा", 
	   lock_assignment:"असाइनमेंट लॉक करा", 
	   remove:"काढा", 
	   delete:"हटवा", 
	   download:"डाउनलोड करा", 
	   back_to_all_units:"सर्व युनिट्सकडे परत", 
	   add_unit:"युनिट जोडा", 
	   close:"बंद", 
	   select_section:"विभाग निवडा", 
	   unit_name:"युनिटचे नाव", 
	   index:"निर्देशांक", 
	   select_period:"कालावधी निवडा", 
	   add:"ॲड", 
	   activity_name:"क्रियाकलाप नाव", 
	   description:"वर्णन", 
	   drap_n_drop_some_files_here:"ड्रॅग 'एन' येथे काही फाइल्स ड्रॉप करा किंवा फाइल्स निवडण्यासाठी क्लिक करा", 
	   only_files_of_size_2mb:"(फक्त 2MB किंवा त्यापेक्षा लहान आकाराच्या फायली स्वीकारल्या जातील)", 
	   link:"दुवा", 
	   label:"लेबल", 
	   add_more_link:"आणखी लिंक जोडा", 
	   activity_types :"क्रियाकलाप प्रकार", 
	   unit :"युनिट", 
	   shared_with_students :"विद्यार्थ्यांसोबत शेअर केले", 
	   preparation :"तयारी", 
	   max_scode :"कमाल स्कोअर", 
	   expiration_date :"कालबाह्यता तारीख", 
	   due_date :"देय तारीख", 
	   archived :"संग्रहित", 
	   graded :"श्रेणीबद्ध", 
	   save :"जतन करा", 
	   select :"निवडा", 
	   exempted:"सूट दिली", 
	   edit_unit:"युनिट संपादित करा", 
	   edit_activity:"क्रियाकलाप संपादित करा", 
	   confirm:"पुष्टी", 
	   are_you_sure_to_delete:"तुम्हाला हे युनिट हटवायचे आहे का?", 
	   cancel:"रद्द करा", 
	   are_you_sure_to_exempt:"तुम्हाला खात्री आहे की या विभागातील क्रियाकलापातून सूट मिळेल?", 
	   override:"ओव्हरराइड करा", 
	   extra_points:"अतिरिक्त गुण", 
	   file:"फाईल", 
	   download_all:"सर्व डाउनलोड करा", 
	   score:"धावसंख्या", 
	   comment:"टिप्पणी", 
	   submit_grade:"ग्रेड सबमिट करा", 
	   grade:"ग्रेड", 
	   are_you_sure_to_lock:"तुम्हाला हा विभाग क्रियाकलाप लॉक करण्याची खात्री आहे?", 
	   are_you_sure_to_permanently_delete:"तुमची खात्री आहे की विभाग क्रियाकलाप कायमचा हटवायचा आहे?", 
	   views:"दृश्ये", 
	   mandatory:"अनिवार्य", 
	   showing:"दाखवत आहे", 
	   of:"च्या", 
	   are_you_sure_to_unlock_this_section_activity:"तुम्हाला हा विभाग क्रियाकलाप अनलॉक करण्याची खात्री आहे?", 
	   are_you_sure_to_clear_this_unit:"तुम्हाला हे युनिट साफ करण्याची खात्री आहे का?", 
	    
	   
	   reset_filters:"फिल्टर रीसेट करा"
 },
  kn:{
	  sections:"ವಿಭಾಗಗಳು", 
	   showing_of:"20 ರಲ್ಲಿ 10 ತೋರಿಸಲಾಗುತ್ತಿದೆ", 
	   filter:"ಫಿಲ್ಟರ್", 
	   students:"ವಿದ್ಯಾರ್ಥಿಗಳು", 
	   school:"ಶಾಲೆ", 
	   all_school:"ಎಲ್ಲಾ ಶಾಲೆಗಳು", 
	   student:"ವಿದ್ಯಾರ್ಥಿ", 	   
	   view:"ನೋಟ", 
	   no_data:"ಮಾಹಿತಿ ಇಲ್ಲ.", 
	   gradebook:"ಗ್ರೇಡ್ಬುಕ್", 
	   home:"ಮನೆ", 
	   roster:"ರೋಸ್ಟರ್", 
	   attendance:"ಹಾಜರಾತಿ", 
	   actions:"ಕ್ರಿಯೆಗಳು", 
	   add_activity:"ಚಟುವಟಿಕೆಯನ್ನು ಸೇರಿಸಿ", 
	   finalize:"ಅಂತಿಮಗೊಳಿಸು", 
	   reactivate:"ಪುನಃ ಸಕ್ರಿಯಗೊಳಿಸು", 
	   send_message_to_all_section_students:"ಎಲ್ಲಾ ವಿಭಾಗದ ವಿದ್ಯಾರ್ಥಿಗಳಿಗೆ ಸಂದೇಶವನ್ನು ಕಳುಹಿಸಿ", 
	   launch_course:"ಕೋರ್ಸ್ ಅನ್ನು ಪ್ರಾರಂಭಿಸಿ", 
	   start_class:"ತರಗತಿಯನ್ನು ಪ್ರಾರಂಭಿಸಿ", 
	   clear_unit:"ತೆರವುಗೊಳಿಸಿ ಘಟಕ", 
	   period:"ಅವಧಿ", 
	   search_for_a_student_or_id:"ವಿದ್ಯಾರ್ಥಿ ಅಥವಾ ID ಗಾಗಿ ಹುಡುಕಿ…", 
	   overall:"ಒಟ್ಟಾರೆ", 
	   hide_complete_students:"ಪೂರ್ಣಗೊಂಡ ವಿದ್ಯಾರ್ಥಿಗಳನ್ನು ಮರೆಮಾಡಿ", 
	   view_submission_grade:"ಸಲ್ಲಿಕೆ ಮತ್ತು ಗ್ರೇಡ್ ವೀಕ್ಷಿಸಿ", 
	   download_all_submission:"ಎಲ್ಲಾ ಸಲ್ಲಿಕೆಯನ್ನು ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ", 
	   un_exempt:"ವಿನಾಯಿತಿ ಇಲ್ಲ", 
	   exempt:"ವಿನಾಯಿತಿ", 
	   edit:"ತಿದ್ದು", 
	   enter_grades:"ಶ್ರೇಣಿಗಳನ್ನು ನಮೂದಿಸಿ", 
	   assign_default_grade:"ಡೀಫಾಲ್ಟ್ ಗ್ರೇಡ್ ಅನ್ನು ನಿಯೋಜಿಸಿ", 
	   unlock_default_grade:"ನಿಯೋಜನೆಯನ್ನು ಅನ್‌ಲಾಕ್ ಮಾಡಿ", 
	   lock_assignment:"ಲಾಕ್ ನಿಯೋಜನೆ", 
	   remove:"ತೆಗೆದುಹಾಕಿ", 
	   delete:"ಅಳಿಸಿ", 
	   download:"ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ", 
	   back_to_all_units:"ಎಲ್ಲಾ ಘಟಕಗಳಿಗೆ ಹಿಂತಿರುಗಿ", 
	   add_unit:"ಘಟಕವನ್ನು ಸೇರಿಸಿ", 
	   close:"ಮುಚ್ಚಿ", 
	   select_section:"ವಿಭಾಗವನ್ನು ಆಯ್ಕೆಮಾಡಿ", 
	   unit_name:"ಘಟಕದ ಹೆಸರು", 
	   index:"ಸೂಚ್ಯಂಕ", 
	   select_period:"ಅವಧಿಯನ್ನು ಆಯ್ಕೆಮಾಡಿ", 
	   add:"ಸೇರಿಸಿ", 
	   activity_name:"ಚಟುವಟಿಕೆಯ ಹೆಸರು", 
	   description:"ವಿವರಣೆ", 
	   drap_n_drop_some_files_here:"ಇಲ್ಲಿ ಕೆಲವು ಫೈಲ್‌ಗಳನ್ನು ಡ್ರಾಪ್ 'n' ಎಳೆಯಿರಿ ಅಥವಾ ಫೈಲ್‌ಗಳನ್ನು ಆಯ್ಕೆ ಮಾಡಲು ಕ್ಲಿಕ್ ಮಾಡಿ", 
	   only_files_of_size_2mb:"(2MB ಅಥವಾ ಅದಕ್ಕಿಂತ ಕಡಿಮೆ ಗಾತ್ರದ ಫೈಲ್‌ಗಳನ್ನು ಮಾತ್ರ ಸ್ವೀಕರಿಸಲಾಗುತ್ತದೆ)", 
	   link:"ಲಿಂಕ್", 
	   label:"ಲೇಬಲ್", 
	   add_more_link:"ಇನ್ನಷ್ಟು ಲಿಂಕ್ ಸೇರಿಸಿ", 
	   activity_types :"ಚಟುವಟಿಕೆಯ ವಿಧಗಳು", 
	   unit :"ಘಟಕ", 
	   shared_with_students :"ವಿದ್ಯಾರ್ಥಿಗಳೊಂದಿಗೆ ಹಂಚಿಕೊಂಡರು", 
	   preparation :"ತಯಾರಿ", 
	   max_scode :"ಗರಿಷ್ಠ ಸ್ಕೋರ್", 
	   expiration_date :"ಮುಕ್ತಾಯ ದಿನಾಂಕ", 
	   due_date :"ಅಂತಿಮ ದಿನಾಂಕ", 
	   archived :"ಆರ್ಕೈವ್ ಮಾಡಲಾಗಿದೆ", 
	   graded :"ಶ್ರೇಣೀಕರಿಸಲಾಗಿದೆ", 
	   save :"ಉಳಿಸಿ", 
	   select :"ಆಯ್ಕೆ ಮಾಡಿ", 
	   exempted:"ವಿನಾಯಿತಿ ನೀಡಲಾಗಿದೆ", 
	   edit_unit:"ಘಟಕವನ್ನು ಸಂಪಾದಿಸಿ", 
	   edit_activity:"ಚಟುವಟಿಕೆಯನ್ನು ಸಂಪಾದಿಸಿ", 
	   confirm:"ದೃಢೀಕರಿಸಿ", 
	   are_you_sure_to_delete:"ಈ ಘಟಕವನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?", 
	   cancel:"ರದ್ದುಮಾಡಿ", 
	   are_you_sure_to_exempt:"ಈ ವಿಭಾಗದ ಚಟುವಟಿಕೆಗೆ ವಿನಾಯಿತಿ ನೀಡಲು ನೀವು ಖಚಿತವಾಗಿರುವಿರಾ?", 
	   override:"ಅತಿಕ್ರಮಿಸಿ", 
	   extra_points:"ಹೆಚ್ಚುವರಿ ಅಂಕಗಳು", 
	   file:"ಫೈಲ್", 
	   download_all:"ಎಲ್ಲವನ್ನೂ ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ", 
	   score:"ಸ್ಕೋರ್", 
	   comment:"ಕಾಮೆಂಟ್ ಮಾಡಿ", 
	   submit_grade:"ಗ್ರೇಡ್ ಸಲ್ಲಿಸಿ", 
	   grade:"ಗ್ರೇಡ್", 
	   are_you_sure_to_lock:"ಈ ವಿಭಾಗದ ಚಟುವಟಿಕೆಯನ್ನು ಲಾಕ್ ಮಾಡುವುದು ಖಚಿತವೇ?", 
	   are_you_sure_to_permanently_delete:"ವಿಭಾಗದ ಚಟುವಟಿಕೆಯನ್ನು ಶಾಶ್ವತವಾಗಿ ಅಳಿಸುವುದು ಖಚಿತವೇ?", 
	   views:"ವೀಕ್ಷಣೆಗಳು", 
	   mandatory:"ಕಡ್ಡಾಯ", 
	   showing:"ತೋರಿಸಲಾಗುತ್ತಿದೆ", 
	   of:"ನ", 
	   are_you_sure_to_unlock_this_section_activity:"ಈ ವಿಭಾಗದ ಚಟುವಟಿಕೆಯನ್ನು ಅನ್‌ಲಾಕ್ ಮಾಡುವುದು ಖಚಿತವೇ?", 
	   are_you_sure_to_clear_this_unit:"ಈ ಘಟಕವನ್ನು ತೆರವುಗೊಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?", 
	    
	   
	   reset_filters:"ಫಿಲ್ಟರ್‌ಗಳನ್ನು ಮರುಹೊಂದಿಸಿ"
 },
}

//export const languagesArray = Object.keys(languages)

export default languages
