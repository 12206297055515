import React from 'react';
import { Link } from 'react-router-dom'
//import { Link,withRouter, history} from 'react-router-dom'
//import { DropdownMenu, MenuItem } from 'react-bootstrap-dropdown-menu';
import { connect } from 'react-redux'
import agent from '../agent'
import { Cookies } from 'react-cookie'
import {TOKEN_NAME,CHANGE_TAB, LOGOUT, APP_LOAD,LOGIN,LANGUAGE_KEY} from '../constants/actionTypes'
import '../styles/App.css'
import '../styles/responsiveNew.css'
import {Dropdown} from 'react-bootstrap'
import $ from 'jquery';
import Avatar from 'react-avatar';
//import { datadogRum } from '@datadog/browser-rum';
import ModalErorr from './common/ModalErorr'
import LocalizedStrings from 'react-localization';
import Menu from './language/menu.js';
import ReactLoading from 'react-loading';
/*datadogRum.init({
    applicationId: 'eeb5d8b0-7f98-4f9b-8590-f008d29b4db6',
    clientToken: 'pubd25436f14cdf16ad87f370051b533be5',
    site: 'datadoghq.com',
    service:'test.advantages-dls.info',
    // Specify a version number to identify the deployed version of your application in Datadog 
    // version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true
});*/
const mapStateToProps = state => (
    Object.assign({}, state, {
		paramsSave: state.home,
		token: state.common.token,
		tab: state.common.tab,
	})      
);

const mapDispatchToProps = dispatch => ({  
	onLogin: (redirectTo, token) => {
		dispatch({ type: LOGIN, redirectTo, token})  
	},
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	setToken: (data)=>{ 
		dispatch({ type: TOKEN_NAME, data })
	},
	onLoad: (access_token) =>
		dispatch({ type: APP_LOAD, access_token }),
	onLogout: () =>
		dispatch({type: LOGOUT}),
	onResetLanguesKey: (data) => {
		dispatch({ type: LANGUAGE_KEY, data})  
	},
});
let sMenu=new LocalizedStrings(Menu);
let host = window.location.hostname;
let href = window.location.href.includes('/login') ? '' : window.location.href;

class Header extends React.Component {
	constructor(){
		super()
		this.state = {
			search:'',username:'',schools:[],school_logo:'',school_name:'',photo:'',digital_seal:'',
			gradebook: 0, attendance: 0, learning_path: 0, email: 0, resources: 0,dataRole:[],user_id:'',
			isOpenModal:false,msg:'',last_role:'',last_school_id:'',impersonate_data:'',
			impersonate_info:'',impersonate_role:'',listAccess:[],
			help_link:'https://advantages-dls.zendesk.com/hc/en-us',dataLangues:[],language:'en',
			viewcontract: false, updatecontract: false,viewinvoice: false, updateinvoice: false, payinvoice: false,
			supportmultilingual:false,gotoLogin:false,loaderspiner:false,
			viewmessaging: false,readlearningpathtemplates: false,viewattendance: false,viewresources: false,viewgradebook: false,
		}
		//---
		this.zendesksso=()=>{
			agent.admin.zendesksso({}).then(datas => {				
				if(datas){					
					window.open(datas, '_blank');
				}
					
			});
		}
		this.get_configuration=()=>{
			agent.admin.get_configuration({}).then(datas => {
				//console.log(datas)
				if(datas.status===true){
					let data=datas.data;
					if(!data) return;
					let user_settings=data.user_settings;
					if(user_settings!=undefined && user_settings!=null){
						window.localStorage.setItem('user_settings',JSON.stringify(user_settings));
					}
					let viewcontract = false;
					let updatecontract = false;
					let viewinvoice = false;
					let updateinvoice = false;
					let payinvoice = false;
					let supportmultilingual = false;
					let viewmessaging = false;
					let readlearningpathtemplates = false;
					let viewattendance = false;
					let viewresources = false;
					let viewgradebook = false;
					if (user_settings != '' && user_settings != undefined && user_settings != null){
						viewcontract = user_settings.hasOwnProperty('viewcontract') ? user_settings.viewcontract : false;
						updatecontract = user_settings.hasOwnProperty('updatecontract') ? user_settings.updatecontract : false;
						viewinvoice = user_settings.hasOwnProperty('viewinvoice') ? user_settings.viewinvoice : false;
						updateinvoice = user_settings.hasOwnProperty('updateinvoice') ? user_settings.updateinvoice : false;
						payinvoice = user_settings.hasOwnProperty('payinvoice') ? user_settings.payinvoice : false;
						supportmultilingual = user_settings.hasOwnProperty('supportmultilingual') ? user_settings.supportmultilingual : false;
						viewmessaging = user_settings.hasOwnProperty('viewmessaging') ? user_settings.viewmessaging : false;
						readlearningpathtemplates = user_settings.hasOwnProperty('readlearningpathtemplates') ? user_settings.readlearningpathtemplates : false;
						viewattendance = user_settings.hasOwnProperty('viewattendance') ? user_settings.viewattendance : false;
						viewresources = user_settings.hasOwnProperty('viewresources') ? user_settings.viewresources : false;
						viewgradebook = user_settings.hasOwnProperty('viewgradebook') ? user_settings.viewgradebook : false;
					}
					this.setState({
						viewcontract: viewcontract,
						updatecontract: updatecontract,
						viewinvoice: viewinvoice,
						updateinvoice: updateinvoice,
						payinvoice: payinvoice,
						supportmultilingual: supportmultilingual,
						viewmessaging: viewmessaging,
						readlearningpathtemplates: readlearningpathtemplates,
						viewattendance: viewattendance,
						viewresources: viewresources,
						viewgradebook: viewgradebook,
					}, () => { })
					
				}else{
					if (host != 'localhost') {
						window.sessionStorage.setItem('previous_href', href);
						window.location.href = '/login'
					}
				}
			})
		}
		this.getUserSchools=(id)=>{
			agent.auth.getUserSchools({}).then(datas => {
				if(datas.status===true){
					//let results=datas.results;
					let results=datas.data;
					let school_name='';let school_logo='';let digital_seal='';let help_link='';
					let external_transcript_handler_url='';
					let external_transcript_handler_help='';
					let schools=results.map(dt=>{
						if(dt.school_id===id){
							school_name=dt.school_name;
							school_logo=dt.school_logo;
							digital_seal=dt.digital_seal;
							help_link=dt.help_link;
							external_transcript_handler_url=dt.external_transcript_handler_url;
							external_transcript_handler_help=dt.external_transcript_handler_help;
							window.localStorage.setItem('external_transcript_handler_url',external_transcript_handler_url)
							window.localStorage.setItem('external_transcript_handler_help',external_transcript_handler_help)
						}
						return dt;
					})
					this.setState({schools:schools,school_name:school_name,school_logo:school_logo,digital_seal:digital_seal,help_link:help_link},()=>{
						//console.log(external_transcript_handler_url)
					})
				}
			})
		}
		this.unimpersonate=(e)=>{
			this.setState({loaderspiner:true})
			agent.admin.unimpersonate({impersonate_data:this.state.impersonate_data,}).then(datas => {
				if(datas.status===true){
					
					let UserInfo=datas.data;
					let last_role=parseInt(UserInfo.last_role);
					window.localStorage.setItem('last_role',last_role);
					const cookies = new Cookies()
					const expires_in = datas.expires_in / 60 / 60 / 24
					let dat = new Date()
					//dat.setDate(dat.getDate() + expires_in)	
					dat.setTime(dat.getTime() + (1*24*60*60*1000));	
					//cookies.set(TOKEN_NAME, UserInfo.token, {expires: dat})
					this.setCookie(TOKEN_NAME, UserInfo.token, 1)
					window.localStorage.setItem('TOKEN_NAME',UserInfo.token)
					cookies.set('USERLOG', UserInfo, {expires: dat})
					this.setCookie('USERLOG', UserInfo, 1)
					//console.log(UserInfo)
					//window.localStorage.setItem('USERLOG',JSON.stringify(UserInfo))
					let name_log=(last_role===1)?'USERLOGSUP':'USERLOG';
					window.localStorage.setItem(name_log,JSON.stringify(UserInfo));
					let name=UserInfo.fname;
					name=name.split(" ");
					window.localStorage.setItem('NAMEINFO',name[0])
					window.localStorage.setItem('EMAIL',UserInfo.email)
					//agent.setToken(UserInfo.token)
					//this.props.onLogin('home',UserInfo.token);	
					//this.setState({school_name:UserInfo.school_name})	
					//this.getUserSchools(school_id);
					//this.get_user_role();
					//window.location.href = '/home';
					this.setState({loaderspiner:false})
					//setTimeout(() => {
						  var url=window.localStorage.getItem('url_before_imper');
						  url=(url!=undefined && url!='')?url:'/user-directory';
						  window.location.href = url;
						//},1000)
						
					
					
				}else{
					this.setState({msg: datas.message,isOpenModal:true,gotoLogin:true,loaderspiner:false})
				}
					
			});
		}
		this.close=()=>{
			let gotoLogin=this.state.gotoLogin;
			this.setState({isOpenModal:false},()=>{
				if(gotoLogin==true){
					window.location.href = '/login'
				}
			})
		}
		this.changeSchool=(school_id,e)=>{
			let host=window.location.hostname
			host= host.split(".")
			let hosti=(host!==undefined && host!==null && host[0]!==undefined)?host[0]:'';
			let role = (hosti === "teacher") ? 3 : (hosti === "sadmin" || hosti === "portal") ? 2 : (hosti === "sa") ? 1 : 2;
			agent.auth.changeSchool({school_id:school_id,role:role}).then(datas => {
				//console.log(datas)
				if(datas.status===true){
					
					let UserInfo=datas.data;
					let last_role=parseInt(UserInfo.last_role);
					window.localStorage.setItem('last_role',last_role);
					/*if(last_role==2 && hosti!="sadmin" && hosti!="localhost" && hosti!='staging'){
						let sso_portal=UserInfo.sso_portal;
						let a= document.createElement('a');
						a.href= sso_portal;
						a.click();
					}else{*/
						const cookies = new Cookies()
						const expires_in = datas.expires_in / 60 / 60 / 24
						let dat = new Date()
						//dat.setDate(dat.getDate() + expires_in)	
						dat.setTime(dat.getTime() + (1*24*60*60*1000));	
						//cookies.set(TOKEN_NAME, UserInfo.token, {expires: dat})
						this.setCookie(TOKEN_NAME, UserInfo.token, 1)
						window.localStorage.setItem('TOKEN_NAME',UserInfo.token)
						cookies.set('USERLOG', UserInfo, {expires: dat})
						//this.setCookie('USERLOG', UserInfo, 1)
						//console.log(UserInfo)
						//window.localStorage.setItem('USERLOG',JSON.stringify(UserInfo))
						let name_log=(last_role===1)?'USERLOGSUP':'USERLOG';
						window.localStorage.setItem(name_log,JSON.stringify(UserInfo));
						let name=UserInfo.fname;
						name=name.split(" ");
						window.localStorage.setItem('NAMEINFO',name[0])
						window.localStorage.setItem('EMAIL',UserInfo.email)
						agent.setToken(UserInfo.token)
						this.props.onLogin('home',UserInfo.token);	
						this.setState({school_name:UserInfo.school_name})	
						this.getUserSchools(school_id);
						this.get_user_role();
						window.location.href = '/home';
					//}
					
				}else{
					this.setState({msg: datas.message,isOpenModal:true})
				}
					
			});
		}
		this.setCookie=(name,value,days)=> {
			const cookies = new Cookies()
			var expires = "";
			if (days) {
				var date = new Date();
				date.setTime(date.getTime() + (days*24*60*60*1000));
				//date.setTime(date.getTime() + (days*1*2*60*1000));
				expires = "; expires=" + date.toUTCString();
				console.log(date)
				cookies.set(name, value, {expires: date})
			}
			//document.cookie = name + "=" + (value || "")  + expires + "; path=/";
			
		}
		/*this.user_change_role_bk=(role,e)=>{
			agent.auth.user_change_role({role:role}).then(datas => {
				//console.log(datas)
				if(datas.status===true){
					let UserInfo=datas.data;
					if(parseFloat(role)===4 || parseFloat(role)===5 ){
						const cookies = new Cookies()
						//const expires_in = data.expires_in / 60 / 60 / 24
						let dat = new Date()
						//dat.setDate(dat.getDate() + expires_in)	
						dat.setTime(dat.getTime() + (1*24*60*60*1000));	
						//cookies.set(TOKEN_NAME, UserInfo.token, {expires: dat})
						this.setCookie(TOKEN_NAME, UserInfo.token, 1)
						window.localStorage.setItem('TOKEN_NAME',UserInfo.token)
						cookies.set('USERLOG', UserInfo, {expires: dat})
						//this.setCookie('USERLOG', UserInfo, 1)
						//console.log(UserInfo)
						window.localStorage.setItem('USERLOG',JSON.stringify(UserInfo))
						let name=UserInfo.fname;
						name=name.split(" ");
						window.localStorage.setItem('NAMEINFO',name[0])
						window.localStorage.setItem('EMAIL',UserInfo.email)
					
						window.location.reload();
					}else if( parseFloat(role)===3){
						//this.auto_login(UserInfo.token);
						let url='https://teacher.adlsassist.com/login?token='+UserInfo.token;
						let a= document.createElement('a');
						a.href= url;
						a.click();
					}else{
						let sso_portal=UserInfo.sso_portal;
						let a= document.createElement('a');
						a.href= sso_portal;
						a.click();
					}
					
				}
					
			});
		}
		*/
		this.user_change_role=(role,e)=>{
			let host=window.location.hostname
			host= host.split(".")
			let hosti=(host!==undefined && host!==null && host[0]!==undefined)?host[0]:''
			//console.log(role,host)
			
			agent.auth.user_change_role({role:role}).then(datas => {
				//console.log(datas)
				if(datas.status===true){
					let UserInfo=datas.data;
					let last_role=parseInt(UserInfo.last_role);
					window.localStorage.setItem('last_role',last_role);
					/*if(parseFloat(role)==2 && hosti!="sadmin" && hosti!="localhost" && hosti!='staging'){
						let sso_portal=UserInfo.sso_portal;
						let a= document.createElement('a');
						a.href= sso_portal;
						a.click();
					}else{*/
						const cookies = new Cookies()
						//const expires_in = data.expires_in / 60 / 60 / 24
						let dat = new Date()
						//dat.setDate(dat.getDate() + expires_in)	
						dat.setTime(dat.getTime() + (1*24*60*60*1000));	
						//cookies.set(TOKEN_NAME, UserInfo.token, {expires: dat})
						this.setCookie(TOKEN_NAME, UserInfo.token, 1)
						window.localStorage.setItem('TOKEN_NAME',UserInfo.token)
						cookies.set('USERLOG', UserInfo, {expires: dat})
						//this.setCookie('USERLOG', UserInfo, 1)
						//console.log(UserInfo)
						//window.localStorage.setItem('USERLOG',JSON.stringify(UserInfo))
						let name_log=(last_role===1)?'USERLOGSUP':'USERLOG';
						window.localStorage.setItem(name_log,JSON.stringify(UserInfo));
						let name=UserInfo.fname;
						name=name.split(" ");
						window.localStorage.setItem('NAMEINFO',name[0])
						window.localStorage.setItem('EMAIL',UserInfo.email)
					
						window.location.href = '/home';
					//}
					
				}
					
			});
		}
		
		
		this.auto_login=(token)=>{
			agent.auth.auto_login(token).then(data => {
				//console.log(data)
				if(data.status === true){
					let UserInfo=data.data;
					let last_role=parseInt(UserInfo.last_role);
					window.localStorage.setItem('last_role',last_role);
					const cookies = new Cookies()
					const expires_in = data.expires_in / 60 / 60 / 24
					let dat = new Date()
					//dat.setDate(dat.getDate() + expires_in)	
					dat.setTime(dat.getTime() + (1*24*60*60*1000));	
					//cookies.set(TOKEN_NAME, UserInfo.token, {expires: dat})
					this.setCookie(TOKEN_NAME, UserInfo.token, 1)
					window.localStorage.setItem('TOKEN_NAME',UserInfo.token)
					cookies.set('USERLOG', UserInfo, {expires: dat})
					//this.setCookie('USERLOG', UserInfo, 1)
					//console.log(UserInfo)
					//window.localStorage.setItem('USERLOG',JSON.stringify(UserInfo))
					let name_log=(last_role===1)?'USERLOGSUP':'USERLOG';
					window.localStorage.setItem(name_log,JSON.stringify(UserInfo));
					let name=UserInfo.fname;
					name=name.split(" ");
					window.localStorage.setItem('NAMEINFO',name[0])
					window.localStorage.setItem('EMAIL',UserInfo.email)
					agent.setToken(UserInfo.token)
					this.props.onLogin('home',UserInfo.token);
						
					window.location.href = '/home'
				}
			})
		}
		this.get_user_role=(token,e)=>{
			//console.log(school_id)
			agent.auth.get_user_role({}).then(datas => {
				//console.log(datas)
				if(datas.status===true){
					//console.log(datas)
					let roles = datas.data;
					let pathname = window.location.pathname;
					if (pathname.includes('home')) {
						roles.forEach(r => {
							if (r.role == 5 && r.status == "Contract & Payment Pending") {
								window.location.href = "/student-contract";
							}
						});
					}
					this.setState({dataRole: roles})
				}
					
			});
		}
		this.get_content_roles_sso=()=>{
			agent.admin.get_content_roles_sso({}).then(datas => {
				//console.log(datas)
				if(datas.status===true){
					let data=datas.data;
					this.setState({listAccess:data})
				}
					
			});
		}
		this.log_sso=(url,e)=>{
			let a= document.createElement('a');
			a.target= '_blank';
			a.href= url;
			a.click();
		}
		this.toggle=(e)=>{
			$(".b").toggle();
		}
		this.get_languages=()=>{
			let language=this.state.language;
			agent.home.get_languages({}).then(datas => {
				if(datas.status===true){
					//console.log(datas)
					this.setState({dataLangues:datas.data},()=>{
						let thiss=this;
						//==== Start Language Drowdown Js
						var langArray = [];
						var langArrayChoi = [];
						$('.vodiapicker option').each(function(){
							var img = $(this).attr("datathumbnail");
							var text = this.innerText;
							var value = $(this).val();
							var item = '<li><img src="'+ img +'" alt="" value="'+value+'"/><span>'+ text +'</span></li>';
							langArray.push(item);
							if(language==value){
								langArrayChoi.push(item);
							}
						})
						$('#a').html(langArray);
						$('.btn-select').html(langArrayChoi[0]);
						$('.btn-select').attr('value', language);
						$('#a li').click(function(){
							var img = $(this).find('img').attr("src");
							var value = $(this).find('img').attr('value');
							var text = this.innerText;
							var item = '<li><img src="'+ img +'" alt="" /><span>'+ text +'</span></li>';
							$('.btn-select').html(item);
							$('.btn-select').attr('value', value);
							thiss.handleLanguageChange(value);
							$(".b").toggle();
							//alert(value)
						});

						$(".btn-select").click(function(){
							$(".b").toggle();
						});

						var sessionLang = localStorage.getItem('lang');
						if (sessionLang){
						  var langIndex = langArray.indexOf(sessionLang);
						  $('.btn-select').html(langArray[langIndex]);
						  $('.btn-select').attr('value', sessionLang);
						} else {
						  var langIndex = langArray.indexOf('ch');
						  //console.log(langIndex);
						  $('.btn-select').html(langArray[langIndex]);
						}
						//==== End Language Drowdown Js
					})
				}
					
			});
		}
		this.handleLanguageChange=(lang)=>{
			//console.log(lang)
			this.setState(prevState => ({
			  language: lang
			}))
			window.localStorage.setItem('language',lang);
			this.save_language(lang);
		}
		this.save_language=()=>{
			agent.home.save_language({language:this.state.language}).then(datas => {
				if(datas.status===true){
					this.props.onResetLanguesKey(this.state.language);
				}
			})
		}
		this.goToSetudentInfo=()=>{
			let student=window.localStorage.getItem('students');let students=[];
			if(student!==undefined && student!==null && student!=='[object Object]' && student!==''){
				students=JSON.parse(student);
				if((students!=undefined && students!=null && students.length>0)){
					window.location.href="/student-info/0";
				}else{
					this.setState({msg:"This Parent don't have student.",isOpenModal:true})
				}
			}
		}
	}
	//---------------------------
	 
	componentWillReceiveProps(nextProps) {
		if (nextProps.common.redirectTo) {
		  const token = window.localStorage.getItem(TOKEN_NAME)
		  const nextRedirect = nextProps.common.redirectTo
		  if(token && nextRedirect === '/login'){ // login page
			nextProps.history.push('/')	
		  } else if(token){       		
			nextProps.history.push(nextProps.common.redirectTo)                  
		  } else {
			//nextProps.history.push('/login') 
		  }
		  
		  //this.props.onRedirect();
		}
	}	

	componentWillMount() {
		//-----------------------
		
		this.get_configuration();
		const cookies = new Cookies()
		//const token = cookies.get(TOKEN_NAME)
		const token=window.localStorage.getItem('TOKEN_NAME');
		if(token && token!==undefined  && token!==null && token!==''){			
			agent.setToken(token)
			//const data = JSON.parse(token)
			this.props.onLoad( token) 
			//this.props.onLoad( data.UserInfo , data.access_token)       
		}else{
			window.location.href='/login'
		}
		//let userInfo=cookies.get('USERLOG');console.log(userInfo)
		let userInfo=window.localStorage.getItem('USERLOG');
		userInfo=JSON.parse(userInfo);console.log(userInfo)
		let languages=window.localStorage.getItem('language');
		let host=window.location.hostname
		host= host.split(".")
		let hosti=(host!==undefined && host!==null && host[0]!==undefined)?host[0]:''
		if(userInfo!=='' && userInfo!==undefined){
			let fname=userInfo.fname
			let lname=userInfo.lname
			let mname=userInfo.mname
			let photo=userInfo.photo
			let user_id=userInfo.user_id
			let school_name=userInfo.school_name;
			let last_role=userInfo.last_role;
			let last_school_id=userInfo.last_school_id;
			let impersonate_data=userInfo.impersonate_data
			let impersonate_info=userInfo.impersonate_info
			let impersonate_role=userInfo.impersonate_role
			let language=userInfo.language
			/*let school_name='';let school_logo='';
			let schools=userInfo.schools.map(dt=>{
				if(dt.school_id===userInfo.last_school_id){
					school_name=dt.school_name;
					school_logo=dt.school_logo;
				}
				return dt;
			})*/
			//console.log(userInfo)
			if(languages!=undefined && languages!=null && languages!='' && (hosti=='staging' || hosti=="localhost")){
				this.setState({language:languages},()=>{
					this.get_languages();
					window.localStorage.setItem('language',languages);
				});
			}else if(language!=undefined && language!=null && language!='' && (hosti=='staging' || hosti=="localhost")){
				this.setState({language:language},()=>{
					this.get_languages();
					window.localStorage.setItem('language',language);
				});
			}else{
				this.get_languages();
			}
			this.setState({
				username: lname + ", " + fname + " " + mname, photo: photo, user_id: user_id,
				school_name: school_name, last_role: last_role,
				last_school_id: parseFloat(last_school_id),
				impersonate_data: impersonate_data,
				impersonate_info: impersonate_info,
				impersonate_role: impersonate_role,
			}, () => {
				this.getUserSchools(parseInt(userInfo.last_school_id));
				this.get_user_role();
				this.get_content_roles_sso();
			})
			let hosts=window.location.hostname
			hosts= hosts.split(".")
			let hostis=(hosts!==undefined && hosts!==null && hosts[0]!==undefined)?hosts[0]:''
			if(hostis!="portal" && hostis!="teacher"){
				//this.get_content_roles_sso();
			}	
				
		}else{
			window.location.href='/login'
		}
		
		
		
		
	}	
	componentDidMount() {
		
		//==== Start Mobile menu js 
		$('.mobile-menu-icon').click(function(){
		  $('.mobile_main_menu_content .mu_accordion').slideToggle();
		});
		//==== End Mobile menu js 

		//==== Start Search Button Dropdown
		$('.mu_search_bar form button').click(function(){
		  $('.mu_dropdown').toggle();
		  return false;
		});
		$('body').click(function() {
		 $('.mu_dropdown').hide();
		});
		//==== End Search Button Dropdown

		//==== Start Alert Box Js
		$('.mu_alert_box_right a').click(function(){
		  $('.mu_alert_box').fadeOut();
		  return false;
		});
		$('.custom_alert_right a').click(function(){
		  $('.custom_alert_design').fadeOut();
		  return false;
		});
		//==== End Alert Box Js

		//==== Start Mobile Main Menu Js 
		$('.mobile-menu-icon').click(function(){
		  $('.header-bottom-content ul').slideToggle();
		});
		$('.mu_accordion').click(function(){
		  $('.mu_accordion').slideToggle();
		})
		//==== End Mobile Main Menu Js 

		//==== Start Progress Tracking Table Accordion Icon Js
		$(".rjdesign").click(function(e){
		  $(this).toggleClass("active");
		  e.preventDefault();
		});
		//==== End Progress Tracking Table Accordion Icon Js

		//==== Start Hide/Show Password Js
		$(".toggle-password").click(function() {
		  $(this).toggleClass("fa-eye fa-eye");
		  var input = $($(this).attr("toggle"));
		  if (input.attr("type") == "password") {
			input.attr("type", "text");
		  } else {
			input.attr("type", "password");
		  }
		});
		//==== Start Hide/Show Password Js

		//==== Start Recommendation Option Content Js
		$(".single-request-recommendation-option-content").click(function(){
		  $(".single-request-recommendation-option-content").removeClass("show");
		  $(this).addClass('show');
		});
		$(".request-recommendation-option-content:nth-child(2) .single-request-recommendation-option-area").click(function(){
		  $(".cross-button-image").toggle();
		});
		//==== End Recommendation Option Content Js

		//==== Start Popup Custom Js
		$(".st_single_action a").click(function(){
		  // return false;
		});
		$(".action_st_btn_img a.enrollment-show").click(function(){
		  $(".request-enrollment-area").fadeIn(10);
		});
		$(".same-close-button-left button,.single-same-button-design button.cancel").click(function(){
		  $(".request-enrollment-area").fadeOut(10);
		});

		$(".action_st_btn_img a.resources").click(function(){
		  $(".resources-area").fadeIn(10);
		});
		$(".same-close-button-left button").click(function(){
		  $(".resources-area").fadeOut(10);
		});

		$(".action_st_btn_img a.transcript").click(function(){
		  $(".request-transcript-area").fadeIn(10);
		});
		$(".same-close-button-left button").click(function(){
		  $(".request-transcript-area").fadeOut(10);
		});

		$(".action_st_btn_img a.recommendation").click(function(){
		  $(".request-recommendation-area").fadeIn(10);
		});
		$(".same-close-button-left button,.single-same-button-design button.cancel").click(function(){
		  $(".request-recommendation-area").fadeOut(10);
		});
		let thiss=this;
		//==== Start Language Drowdown Js
		var langArray = [];
		$('.vodiapicker option').each(function(){
			var img = $(this).attr("data-thumbnail");
			var text = this.innerText;
			var value = $(this).val();
			var item = '<li><img src="'+ img +'" alt="" value="'+value+'"/><span>'+ text +'</span></li>';
			langArray.push(item);
		})
		$('#a').html(langArray);
		$('.btn-select').html(langArray[0]);
		$('.btn-select').attr('value', 'en');
		$('#a li').click(function(){
			var img = $(this).find('img').attr("src");
			var value = $(this).find('img').attr('value');
			var text = this.innerText;
			var item = '<li><img src="'+ img +'" alt="" /><span>'+ text +'</span></li>';
			$('.btn-select').html(item);
			$('.btn-select').attr('value', value);
			thiss.handleLanguageChange(value);
			$(".b").toggle();
		});

		$(".btn-select").click(function(){
			$(".b").toggle();
		});

		var sessionLang = localStorage.getItem('lang');
		if (sessionLang){
		  var langIndex = langArray.indexOf(sessionLang);
		  $('.btn-select').html(langArray[langIndex]);
		  $('.btn-select').attr('value', sessionLang);
		} else {
		  var langIndex = langArray.indexOf('ch');
		  //console.log(langIndex);
		  $('.btn-select').html(langArray[langIndex]);
		}
		//==== End Language Drowdown Js
	}
	componentWillUnmount() {
	  //window.removeEventListener('resize', this.handleWindowSizeChange);
	}
	handleChange(field, e){
		this.setState({[field]: e.target.value})
	}
	handleDoLogout(){
		/*var cookies = document.cookie.split(";");

		for (var i = 0; i < cookies.length; i++) {
			var cookie = cookies[i];
			var eqPos = cookie.indexOf("=");
			var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
		}*/
		let pathname=window.localStorage.getItem('pathname');
		const cooki = new Cookies()
		cooki.remove(TOKEN_NAME)
		this.props.onLogout()	
		//console.log(pathname)
		//window.localStorage.setItem('students','')
		window.localStorage.setItem('USERLOG','');
		window.localStorage.setItem('TOKEN_NAME','')
		if(pathname!==undefined && pathname!==''){
			window.location.href = '/login'+pathname
		}else{
			window.location.href = '/login'
		}
		window.localStorage.setItem('language','');
		
	}
	generateColor () {
		return '#' +  Math.random().toString(16).substr(-6);
	}

	render() {
		const token = this.props.token
		const tab = this.props.tab
		let schools=this.state.schools
		let school_logo=this.state.school_logo
		let school_name=this.state.school_name
		let photo=this.state.photo
		let username=this.state.username
		let dataRole=this.state.dataRole
		let impersonate_data=this.state.impersonate_data
		let listAccess=this.state.listAccess
		//console.log(school_logo)
		let help_link=this.state.help_link;
		let dataLangues=this.state.dataLangues;
		let supportmultilingual=this.state.supportmultilingual;
		//console.log(this.state.language)
		sMenu.setLanguage(this.state.language);
		let host=window.location.hostname
		host= host.split(".")
		let hosti=(host!==undefined && host!==null && host[0]!==undefined)?host[0]:''
		if(hosti!="localhost" && hosti!="staging" && hosti!="sa" && dataRole!==undefined && dataRole!==null && dataRole.length>1){
			dataRole=dataRole.filter(ti=>ti.role!=1);
		}else{
			dataRole=dataRole;
		}
		//---------
			return (
				<header className="header-area">           
					<div className="header-top-area">
						<div className="container">
							<div className={(this.state.language=='ar')?'header-top-content arabic':'header-top-content'}>
								<div className={(this.state.language=='ar')?'header-top-left-content arabic':'header-top-left-content'}>
								
									<div className="header-logo-dropdown">
										
										<Dropdown className={(this.state.language=='ar')?"arabic":""}>
										  <Dropdown.Toggle variant="success" id="dropdownMenuButton" className="dropdown-toggle shfocus logo-sch">
											{
											(school_logo!=='')?<img className="h-80" src={(school_logo!=='')?school_logo:"/media/home/logo.png"} alt="Images" />:
											 <Avatar size="48" round={true} className="avatar-title" 
												color={'#00a2a7'} name={this.state.school_name} />
											}
											<h6 className="ml-3 mb-0 max-w-150px text-truncate">{school_name} </h6>
											<div className="down-icon"><img src="/media/home/down-arrow.png" alt="Images" /></div>
										  </Dropdown.Toggle>

										  <Dropdown.Menu className="dropdown-menu">
										  {
											  (schools!==null && schools!==undefined)?schools.map((dt,i)=>{
												  let ustatus=dt.user_status;
												  let message_status=(ustatus!=undefined && ustatus!=null && ustatus!='' && ustatus.message_status!=undefined)?ustatus.message_status:'';
												  let status=(ustatus!=undefined && ustatus!=null && ustatus!='')?ustatus.status:'';
												 
												  //Inactive
												  if(status!='Inactive'){
													  return(
														<Dropdown.Item href="" className={(this.state.last_school_id==parseFloat(dt.school_id))?"dropdown-item widths active":"dropdown-item widths"} key={i} onClick={this.changeSchool.bind(this,dt.school_id)} >
															<div>
																<span className="symbol symbol-20 mr-6">
																	<img src={dt.school_logo} alt=""/>
																</span>
																<span className="navi-text-school">{dt.school_name}</span>
																<span className="navi-status-scholl" style={{'color':(ustatus!=undefined && ustatus!=null && ustatus!='' && ustatus.access==true)?'green':'red'}}>({(ustatus!=undefined && ustatus!=null && ustatus!='')?ustatus.status:''})</span>
																{(message_status!=undefined && message_status!=null && message_status!='')?<span className="massage-status"></span>:''}
															</div>
															{/*<span className="shoo-status-mes">{(ustatus!=undefined && ustatus!=null && ustatus!='' && ustatus.message_status!=undefined)?ustatus.message_status:''}</span>*/}
														</Dropdown.Item>
													  )
												  }
											  }):''
										  }
											
										  </Dropdown.Menu>
										</Dropdown>
										
									</div>
									
									
									
								</div>

								<div className={(this.state.language=='ar')?'header-top-right-content arabic':'header-top-right-content'}>
									{/*hosti!='staging' && hosti!="localhost"*/}
									<div className="header-flag-dropdown" style={{'display':(supportmultilingual!=true)?'none':''}}>
										<select className="vodiapicker">
										{
											(dataLangues!=undefined && dataLangues!=null)?dataLangues.map((dt,i)=>{
												return(
													<option value={dt.value} key={i} className="test" datathumbnail={dt.flag} >{dt.label}</option>
												)
											}):
											<option value="en" className="test" datathumbnail="/media/home/flag.png">English</option>
										}
											
										</select>
										<div className="lang-select">
											<button className="btn-select" value="" onClick={this.toggle.bind(this)}></button>
											<div className="b">
												<ul id="a"></ul>
											</div>
										</div>
									</div>
									 <div className="inbox-area-content" style={{'display':'none'}}>
										<div className="inbox-left-content-image">
											<Link to="/#"><img src={'/media/home/mail.png'} alt="Images" /></Link>
										</div>
										<div className="inbox-right-content-text">
											<p>Inbox</p>
										</div>
									</div>
									{
											(this.state.loaderspiner==true)?
											<ReactLoading type="spinningBubbles" color="#00BFFF" height={100} width={100} />:''
										}
									<div className="header-profile-dropdown header-logo-dropdown">
										
										<Dropdown>
										  <Dropdown.Toggle variant="success" id="dropdownMenuButton" className="dropdown-toggle shfocus drop-logo logo-sch">
												<span className="profile-content">
													<span className="profile-image">
														<img src="/media/home/porfile.png" alt="Images" style={{'display':'none'}}/>
														
														{
															(photo!=='' && photo!==null)?<img src={photo}/>:
															<Avatar size="35" round={true} className="avatar-title" 
																						color={'#00a2a7'} name={this.state.username} />
														}
													</span>
													<span className="profile-text">
														<h5>{(username.length>20)?username.substr(0, 20)+"...":this.state.username}</h5>
														<p>{sMenu.parent_mentor} (ID# {this.state.user_id})</p>
													</span>
												</span>
												<div className="down-icon-two"><img src="/media/home/down-arrow.png" alt="Images" /></div>
										  </Dropdown.Toggle>

										  <Dropdown.Menu className="dropdown-menu adjust">
											<Dropdown.Item className={(this.state.language=='ar')?'dropdown-item width-two arabic':'dropdown-item width-two'} href="/parent">{sMenu.parent_mentor_page} <span style={{display:'none'}}>3</span></Dropdown.Item>
											<Dropdown.Item className={(this.state.language=='ar')?'dropdown-item width-two arabic':'dropdown-item width-two'} href="/setting">{sMenu.settings}</Dropdown.Item>
											{(impersonate_data!=undefined && impersonate_data!=null && impersonate_data!='') && (
											<Dropdown.Item className={(this.state.language=='ar')?'dropdown-item width-two arabic':'dropdown-item width-two'} onClick={this.unimpersonate.bind(this)}>
												<div className="">
													<p style={{'color':'#5b9bd1'}}>{sMenu.end_impersonation_mode}</p>
													<p>Impersonator: {this.state.impersonate_info}( {this.state.impersonate_role} )</p>
												</div>
											</Dropdown.Item>)
											}
											{
												(dataRole!==undefined && dataRole!==null && dataRole.length>1)?dataRole.map((ti,l)=>{
													return(
													<Dropdown.Item style={{'display':(hosti!="localhost" && hosti!="staging" && hosti!="sa" && parseFloat(ti.role)==1)?'none':''}} className={(this.state.language=='ar')?'dropdown-item width-two arabic log-i-role':'dropdown-item width-two log-i-role'} key={l} onClick={this.user_change_role.bind(this,ti.role)}>{sMenu.log_in_as} {(ti.role!==5)?ti.role_name:'Parent / Mentor'}</Dropdown.Item>
													)
												}):''
											}
											{
												(listAccess!=undefined && listAccess!=null && listAccess!='')?listAccess.map((ti,l)=>{
													return(
													<Dropdown.Item className={(this.state.language=='ar')?'dropdown-item width-two arabic log-i-role':'dropdown-item width-two log-i-role'} key={l} onClick={this.log_sso.bind(this,ti.sso_url)}>{sMenu.access} {ti.source_name} {sMenu.as} {ti.role_name}</Dropdown.Item>
													)
												}):''
											}
											<Dropdown.Item className={(this.state.language=='ar')?'dropdown-item width-two arabic':'dropdown-item width-two'} onClick={this.handleDoLogout.bind(this)}>{sMenu.log_out}</Dropdown.Item>
										  </Dropdown.Menu>
										</Dropdown>
										
									</div>
									
									<div className="help-contetn">
										<a onClick={this.zendesksso.bind(this)} target="_blank">
											<img src="/media/home/help.png" alt="Images" />
											
										</a>
									</div>
								</div>
								
							</div>
						</div>
					</div>
					
					<div className="header-bottom-area">
						<div className="container">						
							<div className="mobile-menu-icon">
								<div className="all-p-humber">
									<span></span>
									<span></span>
									<span></span>
									<span></span>
								</div>
							</div>							
							
							<div className="mobile-search-icon">
								<a href="#search"><img src="/media/home/search.png" alt="Images" /></a>
								<div id="search">
									<button type="button" className="close"><i className="fa fa-times" aria-hidden="true"></i></button>
									<form>
										<input type="search" value={this.state.search} placeholder="Search Keyword" onChange={this.handleChange.bind(this)}/>
										<button type="submit" className="btn btn-primary">Search</button>
									</form>
								</div>
							</div>
							
							<div className="header-bottom-content">
								<ul className={(this.state.language=='ar')?'arabic':''}>
									<li>
										<a className={(tab==='home')?"tablinks actives":"tablinks"} href="/home">
											<img src="/media/home/home.png" alt="Images" />
											{sMenu.home}
										</a>
									</li>

									<li>
										<a className={(tab==='schedule')?"tablinks actives":"tablinks"}  href="/calendar">
											<img src="/media/home/icon.png" alt="Images" />
											{sMenu.calendar}
										</a>
									</li>
									<li>
										<a className={(tab==='progress-tracking')?"tablinks actives":"tablinks"} href={(this.state.readlearningpathtemplates==true)?"/progress-tracking":"/progress-tracking-current"}>
											<img src="/media/home/equalizer.png" alt="Images" />
											{sMenu.progress_tracking}
										</a>
									</li>
									{this.state.viewresources == true && (
									<li>
										<a className={(tab==='resources')?"tablinks actives":"tablinks"} href="/resources">
											<img src="/media/home/inbox.png" alt="Images" />
											{sMenu.resources}
											<span style={{'display':'none'}}>14</span>
										</a>
									</li>
									)}
									<li>
										<div className="link">
											<a className={(tab === 'student-info') ? "tablinks actives" : "tablinks"} onClick={this.goToSetudentInfo.bind(this)}>
												<img src="/media/home/s_student.png" alt="Images" />
												Academic Records
											</a>
										</div>
									</li>
									{this.state.viewmessaging == true && (
									<li>
										<a className={(tab==='messages')?"tablinks actives":"tablinks"} href="/messages-inbox">
											<i className="fa fa-envelope msg-ic"></i>
											{sMenu.inbox}
										</a>
									</li>
									)}
									{(this.state.viewcontract == true || this.state.updatecontract == true || this.state.viewinvoice == true || this.state.updateinvoice == true || this.state.payinvoice == true) && (
										<li>
											<Dropdown>
												<Dropdown.Toggle variant="success" id="dropdownMenuButton" className="dropdown-toggle shfocus drop-menu-it">
													<a className={(tab === 'student-contract' || tab == 'student-invoice') ? "tablinks actives" : "tablinks"} style={{ 'padding': '18px 10px', 'marginRight': '15px' }}>
														<img src="/media/teacher/icon-request.png" alt="Images" className="ic-menu" style={{ 'height': '16px' }} />
															{sMenu.contracts_invoices}
														<div className="down-icon-two"><img src="/media/home/down-arrow.png" alt="Images" style={{ 'width': '12px', 'height': '8px', 'right': '-18px' }} /></div>
													</a>

												</Dropdown.Toggle>
												<Dropdown.Menu className="dropdown-menu adjust">
													{(this.state.viewcontract == true || this.state.updatecontract == true) && (
														<Dropdown.Item className={(tab === 'student-contract')?"dropdown-item width-two active":"dropdown-item width-two"} href="/student-contract">{sMenu.contracts}</Dropdown.Item>
													)}
													{(this.state.viewinvoice == true || this.state.updateinvoice == true || this.state.payinvoice == true) && (
														<Dropdown.Item className={(tab === 'student-invoice')?"dropdown-item width-two active":"dropdown-item width-two"} href="/student-invoice">{sMenu.invoices}</Dropdown.Item>
													)}
												</Dropdown.Menu>
											</Dropdown>
										</li>
									)}
									{(hosti=="localhost" || hosti=="staging")?
									<li>
										<div className="dc_dots_area" >
										  <Dropdown>
											<Dropdown.Toggle variant="success" id="dropdownMenuButton11" className="dropdown-toggle shfocus">
											  <div className="down-icon"><img src="/media/list-resource/ar-dot.png" alt="Images"  /></div>
											</Dropdown.Toggle>
						
											<Dropdown.Menu className="dropdown-menu">
												
												<Dropdown.Item className="dropdown-item width-two" href="/request-assign-user"> Request</Dropdown.Item>
											
											</Dropdown.Menu>
										  </Dropdown>
						
										</div>
									</li>:''}
								</ul>
							</div>
							

						   
							<div className="mobile_main_menu_content">
								<ul id="mu_accordion" className="mu_accordion">
									<li>
										<div className="link">
											<a className="tablinks actives"  href="/home">
												<img src="/media/home/home.png" alt="Images" />
												{sMenu.home}
											</a>
										</div>
									</li>

									
									<li>
										<div className="link">
											<a className={(tab==='schedule')?"tablinks actives":"tablinks"}  href="/calendar">
												<img src="/media/home/icon.png" alt="Images" />
												{sMenu.calendar}
											</a>
										</div>
									</li>
									<li>
										<div className="link">
											<a className={(tab==='progress-tracking')?"tablinks actives":"tablinks"} href={(this.state.readlearningpathtemplates==true)?"/progress-tracking":"/progress-tracking-current"}>
												<img src="/media/home/equalizer.png" alt="Images" />
												{sMenu.progress_tracking}
											</a>
										</div>
									</li>
									{this.state.viewresources == true && (
										<li>
											<div className="link">
												<a className={(tab === 'resources') ? "tablinks actives" : "tablinks"} href="/resources">
													<img src="/media/home/inbox.png" alt="Images" />
													{sMenu.resources}
													<span style={{ 'display': 'none' }}>14</span>
												</a>
											</div>
										</li>
									)}
									
									{this.state.viewmessaging == true && (
										<li>
											<div className="link">
												<a className={(tab === 'messages') ? "tablinks actives" : "tablinks"} href="/messages-inbox">
													<i className="fa fa-envelope msg-ic"></i>
													{sMenu.inbox}
												</a>
											</div>
										</li>
									)}
									<li>
										<div className="link">
											<a href="/setting">Settings</a>
										</div>
									</li>
									<li>
										<div className="link">
											<a href="/parent">Parent page</a>
										</div>
									</li>
									<li>
										<div className="link">
											<a href="#" onClick={this.handleDoLogout.bind(this)}>Log out</a>
										</div>
									</li>
								</ul>
							</div>
						   <ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msg} id="mssage-status"/>
						</div>
					</div>
					
				</header>
				
			)			
		
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
//export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));