import React from 'react'
import ReactDOM from 'react-dom';
import {FormControl, Row, Col, Button} from 'react-bootstrap';
//import InputMask from 'react-input-mask'; 
import $ from 'jquery';

import agent from '../../agent'


import '../../styles/modal.css'; 

import Modal from 'react-bootstrap-modal';
import { connect } from 'react-redux';
import { 
  //BUILDING_LOADED,
  
}from '../../constants/actionTypes'; 
//import menu from '../common/menu.json'
//import { Cookies } from 'react-cookie'
//import { setLocale, getLocale, localizeKey } from 'react-redux-localization';
import ModalL from '../language/modal.js';
import LocalizedStrings from 'react-localization';

const mapStateToProps = state => (
    Object.assign({}, state, {
		languageKey: state.common.languageKey
	
    })      
);
const mapDispatchToProps = dispatch => ({
  /*loadCustomers: (data) => {
      dispatch({ type: CUSTOMER_BUILDING_LOADED, payload: data })  
  },
  */
});
let sModal=new LocalizedStrings(ModalL);
class ModalErorr extends React.Component {
	
    constructor() {
        super();
		
        this.state = {			
			isOpen: false,
			text:'',
			language:'en',
			
        }
		
		this.hideModal = ev => {
		  this.setState({
			isOpen: false,
		  });
		 this.props.close();
			
		};
		
		
		//---
				
    } 
	
	
	
    componentWillReceiveProps(nextProps) {
		//console.log(nextProps)
		if(nextProps.languageKey){
			//this.setState({lguages:nextProps.languageKey});
		}
		if(nextProps){
			this.setState({isOpen:nextProps.isOpen,
				text:nextProps.text,language:nextProps.language,
			});
			
		}
		
    }	
	componentWillUpdate(nextProps, nextState){
		
		
		
	}
	componentDidMount() {
		
		
		
		
	}
	componentWillMount() {
		
		//const cookies = new Cookies()
		//let language=(cookies.get('language')!='' && cookies.get('language') !=null && cookies.get('language')!== 'undefined')?cookies.get('language'):'1005';
		//this.setState({'lguages':language})
		
	}
 
  render() {
	  // const oderInfo=this.props.orderParams;//console.log(oderInfo)
		let language=(this.state.language!=undefined && this.state.language!=null && this.state.language!='')?this.state.language:'en';
		sModal.setLanguage(language);
        return (
			<Modal
			  show={this.state.isOpen}
			  onHide={this.hideModal}
			  aria-labelledby="ModalHeader"
			  id="small"
			  className={this.props.id}
			>
			  <Modal.Header closeButton>
				<Modal.Title id='ModalHeader'>
					<div className="modal-worker-title">
						<span className="modal-detail-order-title"><span style={{'color':'#b81900'}}>{sModal.error}</span></span>
					</div>
				</Modal.Title>
			  </Modal.Header>
			  <Modal.Body>
					<div className="row">		
						<div className="col-md-12 mg5">{this.state.text}</div>
						
						<div className="col-lg-12 lblMessage" id="lblMessage" style={{'color':'red'}}></div>
					</div>
			  </Modal.Body>
			  <Modal.Footer>
					<div className="col-lg-12" style={{'textAlign':'left','padding':'0'}}> 						
						<button type="button" className="btn btn-primary" onClick={this.props.close}>{sModal.ok}</button>
					</div>
			  </Modal.Footer>
			</Modal>
        ); 
	 //}
	//return null;
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalErorr); 
//export default ModalComfirmOrder