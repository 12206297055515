const languages = {
  en:{
   student:"Student",
   academic_information:"Academic Information",
   settings:"Settings",
   end_impersonation_mode:"End Impersonation Mode",
   access:"Access",
   as:"as",
   log_in_as:"Log in as",
   parent_mentor:"Parent / Mentor",
   parent_mentor_page:"Parent / Mentor Page",
   log_out:"Log Out",
   home:"Home",
   learning:"Learning",
   schedule:"Schedule",
   progress_tracking:"Progress Tracking",
   resources:"Resources",
   messages:"Messages",
   calendar:"Calendar",
   sections:"Sections",
   students:"Students",
   gradebook:"Gradebook",
   attendance:"Attendance",
   chat:"Chat",   
   view_all:"View all",   
   need_help:"Need help?",   
   contact_support:"Contact Support",   
   all_right_reserved:"All Rights Reserved",   
   privacy:"Privacy",   
   terms_of_use:"Terms of Use",   
   reporting_center:"Reporting Center",
   month:"Month",   
   week:"week",
   impersonator:"Impersonator",
   teacher_page:"Teacher Page",
   rosters:"Rosters",
   inbox:"Inbox",
   year:"Year", 
   teacher_id_:"Teacher (ID# 29089)",
   admin:"Admin",
   profile_page:"Profile Page",
   acount_settings:"Account Settings",
   school_setup:"School Setup",
   course:"Courses",
   users:"Users",
   users_directory:"User Directory",
   teachers:"Teachers",
   parents_mentor:"Parents / Mentor",
   applications:"Applications",
   requests_Review:"Requests & Review",
   edit_Request:"Edit Request",
   enrollment_Request:"Enrollment Request",
   withdrawal_Request:"Withdrawal Request",
   extension_Request:"Extension Request",
   letter_of_Recommendation_Request:"Letter of Recommendation Request",
   transcript_Request:"Transcript Request",
   comment_and_Document_Review:"Comment and Document Review",
   //auto_Promote:"Auto Promote",
   auto_Promote:"Student Grade Level Promotion",
   compliance_Logs:"Compliance Logs",
   communication_Logs:"Communication Logs",
   contracts:"Contracts",
   invoices:"Invoices",
   account_receivable:"Account Receivable",
   staff_reassignment:"Staff Reassignment",
   batch_data_update:"Student Data Update",
   contracts_invoices:"Contracts/Invoices",
 },
 ar: {
   student:"طالب علم",
   academic_information:"المعلومات الأكاديمية",
   settings:"إعدادات",
   end_impersonation_mode:"وضع انتحال شخصية نهاية",
   access:"وصول",
   as:"كما",
   log_in_as:"سجل دخول",
   parent_mentor:"الوالد / المعلم",
   parent_mentor_page:"صفحة الوالدين / المعلم",
   log_out:"تسجيل خروج",
   home:"مسكن",
   learning:"التعلم",
   schedule:"برنامج",
   progress_tracking:"تتبع التقدم",
   resources:"موارد",
   messages:"رسائل",
   calendar:"تقويم",
   sections:"المقاطع",
   students:"الطلاب",
   gradebook:"دفتر الصف",
   attendance:"حضور",
   month:"شهر",   
   week:"أسبوع",
   chat:"دردشة",
   view_all:"عرض الكل",
   need_help:"تحتاج مساعدة؟",   
   contact_support:"اتصل بالدعم",   
   all_right_reserved:"كل الحقوق محفوظة",   
   privacy:"خصوصية",   
   terms_of_use:"شروط الاستخدام",  
   reporting_center:"مركز التقارير",
   impersonator:"منتحل",
   teacher_page:"صفحة المعلم",
   rosters:"قوائم",
   inbox:"صندوق الوارد",
   year:"سنة", 
   teacher_id_:"المعلم (معرف# 29089)",
   admin:"مسؤل",
   profile_page:"الصفحة الشخصية",
   acount_settings:"الصفحة الشخصية",
   school_setup:"إعداد المدرسة",
   course:"مسار",
   users:"المستخدمون",
   users_directory:"دليل المستخدم",
   teachers:"معلمون",
   parents_mentor:"الآباء / المرشد",
   applications:"التطبيقات",
   requests_Review:"الطلبات والمراجع",
   edit_Request:"تحرير الطلب",
   enrollment_Request:"طلب التسجيل",
   withdrawal_Request:"طلب سحب",
   extension_Request:"طلب تمديد",
   letter_of_Recommendation_Request:"طلب خطاب توصية",
   transcript_Request:"اطلب نسخة",
   comment_and_Document_Review:"التعليق ومراجعة الوثيقة",
  // auto_Promote:"الترويج التلقائي",
   auto_Promote:"ترويج مستوى الصف الطالب",
   compliance_Logs:"سجلات الامتثال",
   communication_Logs:"سجلات الاتصال",
   contracts:"انكماش",
   invoices:"فواتير",
   account_receivable:"حساب العميل",
   staff_reassignment:"إعادة تعيين الموظفين",
   batch_data_update:"تحديث بيانات الطالب",
   contracts_invoices:"العقود/الفواتير",
 },
 zh: {
   student:"学生",
   academic_information:"学术信息",
   settings:"设置",
   end_impersonation_mode:"结束模仿模式",
   access:"使用权",
   as:"作为",
   log_in_as:"登录为",
   parent_mentor:"家长 /导师",
   parent_mentor_page:"家长 /导师页面",
   log_out:"登出",
   home:"家",
   learning:"学习",
   schedule:"日程",
   progress_tracking:"进度跟踪",
   resources:"资源",
   messages:"消息",
   calendar:"日历",
   sections:"部分",
   students:"学生",
   gradebook:"年级",
   attendance:"出席",
   month:"月",
   week:"星期",
   chat:"聊天",
   view_all:"查看全部",
   need_help:"需要帮忙？",   
   contact_support:"联系支持",   
   all_right_reserved:"版权所有",   
   privacy:"隐私",   
   terms_of_use:"使用条款", 
   reporting_center:"报告中心",
   impersonator:"模仿者",
   teacher_page:"老师页面",
   rosters:"名册",
   inbox:"收件箱",
   year:"年", 
   teacher_id_:"老师（ID＃29089）",
   admin:"行政",
   profile_page:"个人资料页",
   acount_settings:"个人资料页",
   school_setup:"学校设置",
   course:"课程",
   users:"用户",
   users_directory:"用户目录",
   teachers:"教师",
   parents_mentor:"父母/导师",
   applications:"应用程序",
   requests_Review:"请求和审查",
   edit_Request:"编辑请求",
   enrollment_Request:"注册请求",
   withdrawal_Request:"提款请求",
   extension_Request:"延期请求",
   letter_of_Recommendation_Request:"推荐信要求",
   transcript_Request:"成绩单请求",
   comment_and_Document_Review:"评论和文件审查",
   //auto_Promote:"自动推广",
   auto_Promote:"学生等级晋升",
   compliance_Logs:"合规日志",
   communication_Logs:"通信日志",
   contracts:"合同",
   invoices:"发票",
   account_receivable:"应收帐户",
   staff_reassignment:"员工重新分配",
   batch_data_update:"学生数据更新",
   contracts_invoices:"合同/发票",
 },
 fr: {
   student:"Étudiant",
   academic_information:"Information Académique",
   settings:"Réglages",
   end_impersonation_mode:"Mode d'identification de fin",
   access:"Accéder",
   as:"comme",
   log_in_as:"Se connecter en tant que",
   parent_mentor:"Parent / mentor",
   parent_mentor_page:"Page parent / mentor",
   log_out:"Se déconnecter",
   home:"Maison",
   learning:"Apprentissage",
   schedule:"Programme",
   progress_tracking:"Suivi des progrès",
   resources:"Ressources",
   messages:"messages",
   calendar:"Calendrier",
   sections:"Sections",
   students:"Étudiants",
   gradebook:"bulletin de notes",
   attendance:"Présence",
   month:"Mois",
   week:"la semaine",
   chat:"Discuter",
   view_all:"Voir tout",
   need_help:"Besoin d'aide?",   
   contact_support:"Contactez le support",   
   all_right_reserved:"Tous les droits sont réservés",   
   privacy:"Intimité",   
   terms_of_use:"Conditions d'utilisation", 
   reporting_center:"Centre de reportage",
   impersonator:"Imitateur",
   teacher_page:"Page d'enseignant",
   rosters:"Listes",
   inbox:"Boîte de réception",
   year:"An", 
   teacher_id_:"Enseignant (ID # 29089)",
   admin:"Administratrice",
   profile_page:"Page de profil",
   acount_settings:"Page de profil",
   school_setup:"Configuration de l'école",
   course:"Cours",
   users:"Utilisateurs",
   users_directory:"Répertoire des utilisateurs",
   teachers:"Enseignants",
   parents_mentor:"Parents / Parrains",
   applications:"Applications",
   requests_Review:"Demandes et révision",
   edit_Request:"Modifier la demande",
   enrollment_Request:"Demande d'inscription",
   withdrawal_Request:"Demande de retrait",
   extension_Request:"Demande de prolongation",
   letter_of_Recommendation_Request:"Demande de lettre de recommandation",
   transcript_Request:"Demande de transcription",
   comment_and_Document_Review:"Commentaire et examen des documents",
  // auto_Promote:"Promotion automatique",
   auto_Promote:"Promotion de niveau scolaire",
   compliance_Logs:"Journaux de conformité",
   communication_Logs:"Journaux de communication",
   contracts:"Contractes",
   invoices:"Factures",
   account_receivable:"Compte recevable",
   staff_reassignment:"Réaffectation du personnel",
   batch_data_update:"Mise à jour des données des étudiants",
   contracts_invoices:"Contrats / factures",
 },
 it: {
   student:"Alunno",
   academic_information:"informazione accademica",
   settings:"Impostazioni",
   end_impersonation_mode:"Modalità di imitazione finale",
   access:"Accesso",
   as:"come",
   log_in_as:"Accedi come",
   parent_mentor:"Genitore / mentore",
   parent_mentor_page:"Pagina genitore / mentore",
   log_out:"Disconnettersi",
   home:"Home",
   learning:"Apprendimento",
   schedule:"Programma",
   progress_tracking:"Tracciamento dei progressi",
   resources:"Risorse",
   messages:"Messaggi",
   calendar:"Calendario",
   sections:"Sezioni",
   students:"Studenti",
   gradebook:"Gradebook",
   attendance:"Presenza",
    month:"Mese",
   week:"settimana",
   chat:"Chiacchierata",
   view_all:"Mostra tutto",
   need_help:"Ho bisogno di aiuto?",   
   contact_support:"Contatta il supporto",   
   all_right_reserved:"Tutti i diritti riservati",   
   privacy:"Privacy",   
   terms_of_use:"Termini di utilizzo", 
   reporting_center:"Centro di reporting",
   impersonator:"Impersonatore",
   teacher_page:"Pagina dell'insegnante",
   rosters:"Elenchi",
   inbox:"Inbox",
   year:"Anno", 
   teacher_id_:"Insegnante (ID n. 29089)",
   admin:"Amministratrice",
   profile_page:"Pagina profilo",
   acount_settings:"Pagina profilo",
   school_setup:"Installazione della scuola",
   course:"Corso",
   users:"Utenti",
   users_directory:"Elenco utenti",
   teachers:"Insegnanti",
   parents_mentor:"Genitori / Mentore",
   applications:"Applicazioni",
   requests_Review:"Richieste e revisione",
   edit_Request:"Modifica richiesta",
   enrollment_Request:"Richiesta di iscrizione",
   withdrawal_Request:"Richiesta di prelievo",
   extension_Request:"Richiesta di estensione",
   letter_of_Recommendation_Request:"Lettera di richiesta di raccomandazione",
   transcript_Request:"Richiesta di trascrizione",
   comment_and_Document_Review:"Commento e revisione del documento",
   //auto_Promote:"Promozione automatica",
   auto_Promote:"Promozione di livello per studenti",
   compliance_Logs:"Registri di conformità",
   communication_Logs:"Registri di comunicazione",
   contracts:"Contratti",
   invoices:"Fatture",
   account_receivable:"Creazione di conto",
   staff_reassignment:"Riassegnazione del personale",
   batch_data_update:"Aggiornamento dei dati degli studenti",
   contracts_invoices:"Contratti/fatture",
 },
 kk: {
   student:"Студент",
   academic_information:"Академиялық ақпарат",
   settings:"Параметрлер",
   end_impersonation_mode:"Аяқталу режимі",
   access:"Өтетін жер",
   as:"қалай",
   log_in_as:"Кіріңіз",
   parent_mentor:"Ата-ана / тәлімгер",
   parent_mentor_page:"Ата-аналық / тәлімгер беті",
   log_out:"Шығу",
   home:"Үй",
   learning:"Үйрету",
   schedule:"Кесте",
   progress_tracking:"Прогрессті бақылау",
   resources:"Ресурс",
   messages:"Хабарлар",
   calendar:"Күнтібзе",
   sections:"Бөлімдер",
   students:"Студенттер",
   gradebook:"Сыныптама",
   attendance:"Бару",
   month:"Ай",
   week:"апта",
   chat:"Сұхбат",
   view_all:"Барлығын көру",
   need_help:"Көмек қажет?",   
   contact_support:"Байланыс қолдауы",   
   all_right_reserved:"Барлық құқықтар сақталған",   
   privacy:"Жалғыздық",   
   terms_of_use:"Қолдану ережелері", 
   reporting_center:"Есеп беру орталығы",
   impersonator:"Еліктіруші",
   teacher_page:"Мұғалім беті",
   rosters:"Тоқтар",
   inbox:"Кіріс жәшігі",
   year:"Жыл", 
   teacher_id_:"Мұғалім (ID # 29089)",
   admin:"Админ",
   profile_page:"Профиль беті",
   acount_settings:"Профиль беті",
   school_setup:"Мектепті орнату",
   course:"Курс",
   users:"Пайдаланушылар",
   users_directory:"Пайдаланушы каталогы",
   teachers:"Мұғалімдер",
   parents_mentor:"Ата-аналар / тәлімгер",
   applications:"Қолданбалар",
   requests_Review:"Сұраулар және шолу",
   edit_Request:"Өңдеу сұрауы",
   enrollment_Request:"Тіркелу сұрауы",
   withdrawal_Request:"Шығару туралы өтініш",
   extension_Request:"Кеңейтім сұрауы",
   letter_of_Recommendation_Request:"Ұсыныс хаты",
   transcript_Request:"Транскрипт сұрауы",
   comment_and_Document_Review:"Түсініктеме және құжатты шолу",
   //auto_Promote:"Автоматты жылжыту",
   auto_Promote:"Студенттік сынып деңгейін жоғарылату",
   compliance_Logs:"Сәйкестік журналдары",
   communication_Logs:"Байланыс журналдары",
   contracts:"Келісімшарттар",
   invoices:"Шот-фактуралар",
   account_receivable:"Дебиторлық берешек",
   staff_reassignment:"Қызметкерлерді қайта тағайындау",
   batch_data_update:"Студенттік деректерді жаңарту",
   contracts_invoices:"Шарттар / шот-фактуралар",
 },
 ko: {
   student:"학생",
   academic_information:"학업 정보",
   settings:"설정",
   end_impersonation_mode:"최종 사망자 모드",
   access:"입장",
   as:"~처럼",
   log_in_as:"로그인하십시오",
   parent_mentor:"부모 / 멘토",
   parent_mentor_page:"부모 / 멘토 페이지",
   log_out:"로그 아웃",
   home:"집",
   learning:"학습",
   schedule:"일정",
   progress_tracking:"진행 추적",
   resources:"자원",
   messages:"메시지",
   calendar:"달력",
   sections:"섹션",
   students:"재학생",
   gradebook:"등급",
   attendance:"출석",
   month:"월",
   week:"주",
   chat:"채팅",
   view_all:"모두보기",
   need_help:"도움이 필요하다?",   
   contact_support:"연락처 지원",   
   all_right_reserved:"판권 소유",   
   privacy:"은둔",   
   terms_of_use:"이용 약관", 
   reporting_center:"보고 센터",
   impersonator:"가장 한 사람",
   teacher_page:"교사 페이지",
   rosters:"명단",
   inbox:"받은 편지함",
   year:"년도", 
   teacher_id_:"교사 (ID# 29089)",
   admin:"관리자",
   profile_page:"프로필 페이지",
   acount_settings:"프로필 페이지",
   school_setup:"학교 설정",
   course:"강의",
   users:"사용자",
   users_directory:"사용자 디렉토리",
   teachers:"교사",
   parents_mentor:"부모님/멘토",
   applications:"응용 프로그램",
   requests_Review:"요청 및 검토",
   edit_Request:"요청 수정",
   enrollment_Request:"등록 요청",
   withdrawal_Request:"철회 요청",
   extension_Request:"확장 요청",
   letter_of_Recommendation_Request:"추천서 요청서",
   transcript_Request:"성적표 요청",
   comment_and_Document_Review:"의견 및 문서 검토",
  // auto_Promote:"자동 승격",
   auto_Promote:"학생 학년 수준 프로모션",
   compliance_Logs:"규정 준수 로그",
   communication_Logs:"통신 로그",
   contracts:"계약",
   invoices:"송장",
   account_receivable:"계정 미수금",
   staff_reassignment:"직원 재 할당",
   batch_data_update:"학생 데이터 업데이트",
   contracts_invoices:"계약/송장",
 },
 pt: {
   student:"Estudante",
   academic_information:"Informação acadêmica",
   settings:"Configurações",
   end_impersonation_mode:"Encerrar modo de personificação",
   access:"Acesso",
   as:"Como",
   log_in_as:"Faça login como",
   parent_mentor:"Pai / mentor",
   parent_mentor_page:"Página de pai / mentor",
   log_out:"Sair",
   home:"Casa",
   learning:"Aprendizado",
   schedule:"Agenda",
   progress_tracking:"Rastreamento de progresso",
   resources:"Recursos",
   messages:"Mensagens",
   calendar:"Calendário",
   sections:"Seções",
   students:"Alunos",
   gradebook:"Livro de notas",
   attendance:"Comparecimento",
   month:"Mês",
   week:"semana",
   chat:"Bater papo",
   view_all:"Ver tudo",
   need_help:"Precisa de Ajuda?",   
   contact_support:"Contato de Suporte",   
   all_right_reserved:"Todos os direitos reservados",   
   privacy:"Privacidade",   
   terms_of_use:"Termos de uso", 
   reporting_center:"Centro de relatórios",
   impersonator:"Personificador",
   teacher_page:"Página do professor",
   rosters:"Listas",
   inbox:"Caixa de entrada",
   year:"Ano", 
   teacher_id_:"Professor (ID# 29089)",
   admin:"Administrador",
   profile_page:"Página de perfil",
   acount_settings:"Página de perfil",
   school_setup:"Configuração da escola",
   course:"Curso",
   users:"Usuários",
   users_directory:"Diretório de usuários",
   teachers:"Professores",
   parents_mentor:"Pais / Orientador",
   applications:"Formulários",
   requests_Review:"Pedidos e Revisão",
   edit_Request:"Solicitação de edição",
   enrollment_Request:"solicitação de matrícula",
   withdrawal_Request:"Pedido de retirada",
   extension_Request:"Solicitação de Extensão",
   letter_of_Recommendation_Request:"Solicitação de Carta de Recomendação",
   transcript_Request:"Solicitação de transcrição",
   comment_and_Document_Review:"Comentário e revisão do documento",
  // auto_Promote:"Autopromoção",
   auto_Promote:"Promoção de nível de alunos do aluno",
   compliance_Logs:"Registros de Conformidade",
   communication_Logs:"Registros de Comunicação",
   contracts:"Contratos",
   invoices:"Faturas",
   account_receivable:"Conta a receber",
   staff_reassignment:"Reatribuição de pessoal",
   batch_data_update:"Atualização de dados do aluno",
   contracts_invoices:"Contratos/faturas",
 },
 ru: {
   student:"Ученик",
   academic_information:"Академическая информация",
   settings:"Настройки",
   end_impersonation_mode:"Конец режима олицетворения",
   access:"Доступ",
   as:"в качестве",
   log_in_as:"Войдите как",
   parent_mentor:"Родитель / наставник",
   parent_mentor_page:"Страница родителей / наставника",
   log_out:"Выйти",
   home:"Дом",
   learning:"Обучение",
   schedule:"Расписание",
   progress_tracking:"Отслеживание прогресса",
   resources:"Ресурсы",
   messages:"Сообщения",
   calendar:"Календарь",
   sections:"Разделы",
   students:"Ученики",
   gradebook:"Класс",
   attendance:"Посещаемость",
   month:"Месяц",
   week:"неделя",
   chat:"Чат",
   view_all:"Посмотреть все",
   need_help:"Нужна помощь?",   
   contact_support:"Контактная поддержка",   
   all_right_reserved:"Все права защищены",   
   privacy:"Конфиденциальность",   
   terms_of_use:"Условия эксплуатации", 
   reporting_center:"Отчетный центр",
   impersonator:"Подражатель",
   teacher_page:"Страница учителя",
   rosters:"Списки",
   inbox:"Входящие",
   year:"Год", 
   teacher_id_:"Учитель (ID# 29089)",
   admin:"Админ",
   profile_page:"Страница профиля",
   acount_settings:"Страница профиля",
   school_setup:"Настройка школы",
   course:"Курс",
   users:"Пользователи",
   users_directory:"Каталог пользователей",
   teachers:"Учителя",
   parents_mentor:"Родители/Наставник",
   applications:"Приложения",
   requests_Review:"Запросы и обзор",
   edit_Request:"Изменить запрос",
   enrollment_Request:"Запрос на регистрацию",
   withdrawal_Request:"Запрос на вывод средств",
   extension_Request:"Запрос на продление",
   letter_of_Recommendation_Request:"Рекомендательное письмо-запрос",
   transcript_Request:"Запрос транскрипта",
   comment_and_Document_Review:"Комментарий и обзор документа",
   //auto_Promote:"Автоматическое продвижение",
   auto_Promote:"Продвижение уровня ученика",
   compliance_Logs:"Журналы соответствия",
   communication_Logs:"Журналы связи",
   contracts:"Контракты",
   invoices:"Счета",
   account_receivable:"Дебиторская задолженность",
   staff_reassignment:"Персонал Персонал",
   batch_data_update:"Обновление данных студента",
   contracts_invoices:"Контракты/счета",
 },
 es: {
   student:"Alumno",
   academic_information:"Información Académica",
   settings:"Ajustes",
   end_impersonation_mode:"Modo de suplantación final",
   access:"Acceso",
   as:"como",
   log_in_as:"Iniciar sesión como",
   parent_mentor:"Padre / mentor",
   parent_mentor_page:"Página de padres / mentores",
   log_out:"Cerrar sesión",
   home:"Hogar",
   learning:"Aprendizaje",
   schedule:"Calendario",
   progress_tracking:"Seguimiento de progreso",
   resources:"Recursos",
   messages:"Mensajes",
   calendar:"Calendario",
   sections:"Secciones",
   students:"Estudiantes",
   gradebook:"Libro de calificaciones",
   attendance:"Asistencia",
   month:"Mes",
   week:"semana",
   chat:"Charlar",
   view_all:"Ver todo",
   need_help:"¿Necesitas ayuda?",   
   contact_support:"Soporte de contacto",   
   all_right_reserved:"Reservados todos los derechos",   
   privacy:"Privacidad",   
   terms_of_use:"Términos de Uso",
   reporting_center:"Centro de informes",
   impersonator:"Imitador",
   teacher_page:"Página del profesor",
   rosters:"Listas",
   inbox:"Bandeja de entrada",
   year:"Año", 
   teacher_id_:"Maestro (ID# 29089)",
   admin:"Administradora",
   profile_page:"Página de perfil",
   acount_settings:"Página de perfil",
   school_setup:"Configuración de la escuela",
   course:"Curso",
   users:"Usuarios",
   users_directory:"Directorio de usuarios",
   teachers:"maestros",
   parents_mentor:"Padres / Mentor",
   applications:"Aplicaciones",
   requests_Review:"Solicitudes y revisión",
   edit_Request:"Editar solicitud",
   enrollment_Request:"Solicitud de Inscripción",
   withdrawal_Request:"Solicitud de Retiro",
   extension_Request:"Solicitud de prórroga",
   letter_of_Recommendation_Request:"Solicitud de carta de recomendación",
   transcript_Request:"Solicitud de Transcripción",
   comment_and_Document_Review:"Comentario y revisión de documentos",
   //auto_Promote:"Promoción automática",
   auto_Promote:"Promoción de nivel de grado estudiantil",
   compliance_Logs:"Registros de cumplimiento",
   communication_Logs:"Registros de comunicación",
   contracts:"Contratos",
   invoices:"Facturas",
   account_receivable:"Cuenta por cobrar",
   staff_reassignment:"Reasignación del personal",
   batch_data_update:"Actualización de datos del estudiante",
   contracts_invoices:"Contratos/facturas",
 },
 vi: {
   student:"Học sinh",
   academic_information:"Thông tin học thuật",
   settings:"Cài đặt",
   end_impersonation_mode:"Kết thúc chế độ mạo danh",
   access:"Truy cập",
   as:"như",
   log_in_as:"Vào",
   parent_mentor:"Cha mẹ / người cố vấn",
   parent_mentor_page:"Trang phụ huynh / cố vấn",
   log_out:"Đăng xuất",
   home:"Nhà",
   learning:"Học tập",
   schedule:"Lịch trình",
   progress_tracking:"Theo dõi tiến trình",
   resources:"Tài nguyên",
   messages:"Tin nhắn",
   calendar:"Lịch",
   sections:"Phần",
   students:"học sinh",
   gradebook:"Lớp",
   attendance:"Sự tham dự",
   month:"Tháng",
   week:"Tuần",
   chat:"Trò chuyện",
   view_all:"Xem tất cả",
   need_help:"Cần giúp đỡ?",   
   contact_support:"Liên hệ hỗ trợ",   
   all_right_reserved:"Đã đăng ký Bản quyền",   
   privacy:"Sự riêng tư",   
   terms_of_use:"Điều khoản sử dụng",
   reporting_center:"Trung tâm báo cáo",
   impersonator:"Người đóng góp",
   teacher_page:"Trang giáo viên",
   rosters:"Danh sách",
   inbox:"Hộp thư đến",
   year:"Năm", 
   teacher_id_:"Giáo viên (ID# 29089)",
   admin:"Quản trị viên",
   profile_page:"Trang hồ sơ",
   acount_settings:"Trang hồ sơ",
   school_setup:"Thiết lập trường học",
   course:"Khóa học",
   users:"Người dùng",
   users_directory:"Danh mục người dùng",
   teachers:"Giáo viên",
   parents_mentor:"Cha mẹ / Người cố vấn",
   applications:"Các ứng dụng",
   requests_Review:"Yêu cầu & Đánh giá",
   edit_Request:"Chỉnh sửa Yêu cầu",
   enrollment_Request:"Yêu cầu ghi danh",
   withdrawal_Request:"Yêu cầu rút tiền",
   extension_Request:"Yêu cầu gia hạn",
   letter_of_Recommendation_Request:"Thư yêu cầu giới thiệu",
   transcript_Request:"Yêu cầu bảng điểm",
   comment_and_Document_Review:"Nhận xét và Đánh giá tài liệu",
  // auto_Promote:"Tự động thăng hạng",
   auto_Promote:"Chương trình khuyến mãi cấp lớp học sinh",
   compliance_Logs:"Nhật ký tuân thủ",
   communication_Logs:"Nhật ký giao tiếp",
   contracts:"Hợp đồng",
   invoices:"Hóa đơn",
   account_receivable:"Tài khoản phải thu",
   staff_reassignment:"Nhân viên chỉ định lại",
   batch_data_update:"Cập nhật dữ liệu của sinh viên",
   contracts_invoices:"Hợp đồng/hóa đơn",
 },
  hi:{
   student:"विद्यार्थी",
   academic_information:"शैक्षणिक सूचना",
   settings:"समायोजन",
   end_impersonation_mode:"प्रतिरूपण मोड समाप्त करें",
   access:"अभिगम",
   as:"जैसा",
   log_in_as:"इससे लॉगिन करें",
   parent_mentor:"अभिभावक/सलाहकार",
   parent_mentor_page:"अभिभावक / सलाहकार पृष्ठ",
   log_out:"लॉग आउट",
   home:"घर",
   learning:"सीखना",
   schedule:"अनुसूची",
   progress_tracking:"प्रगति ट्रैकिंग",
   resources:"संसाधन",
   messages:"संदेशों",
   calendar:"पंचांग",
   sections:"धारा",
   students:"छात्र",
   gradebook:"ग्रेडबुक",
   attendance:"उपस्थिति",
   chat:"बात करना",   
   view_all:"सभी देखें",   
   need_help:"मदद की ज़रूरत है?",   
   contact_support:"सहयोग टीम से संपर्क करें",   
   all_right_reserved:"सर्वाधिकार सुरक्षित",   
   privacy:"निजता",   
   terms_of_use:"उपयोग की शर्तें",   
   reporting_center:"रिपोर्टिंग केंद्र",
   month:"महीना",   
   week:"सप्ताह",
   impersonator:"अभिनय करनेवाला",
   teacher_page:"शिक्षक पृष्ठ",
   rosters:"रोस्टरों",
   inbox:"इनबॉक्स",
   year:"वर्ष", 
   teacher_id_:"शिक्षक (आईडी # 29089)",
   admin:"व्यवस्थापक",
   profile_page:"प्रोफ़ाइल पृष्ठ",
   acount_settings:"अकाउंट सेटिंग",
   school_setup:"स्कूल सेटअप",
   course:"पाठ्यक्रम",
   users:"उपयोगकर्ताओं",
   users_directory:"उपयोगकर्ता निर्देशिका",
   teachers:"शिक्षकों की",
   parents_mentor:"माता-पिता / गुरु",
   applications:"अनुप्रयोग",
   requests_Review:"अनुरोध और समीक्षा",
   edit_Request:"अनुरोध संपादित करें",
   enrollment_Request:"नामांकन अनुरोध",
   withdrawal_Request:"वापसी का निवेदन",
   extension_Request:"विस्तार अनुरोध",
   letter_of_Recommendation_Request:"सिफारिश अनुरोध पत्र",
   transcript_Request:"प्रतिलेख अनुरोध",
   comment_and_Document_Review:"टिप्पणी और दस्तावेज़ समीक्षा",
  // auto_Promote:"ऑटो प्रचार",
   auto_Promote:"छात्र ग्रेड स्तर पदोन्नति",
   compliance_Logs:"अनुपालन लॉग",
   communication_Logs:"संचार लॉग",
   contracts:"ठेके",
   invoices:"चालान",
   account_receivable:"प्राप्य खाता",
   staff_reassignment:"कर्मचारियों का पुनर्मूल्यांकन",
   batch_data_update:"छात्र डेटा अद्यतन",
   contracts_invoices:"अनुबंध/चालान",
 },
 tr:{
	student:"Öğrenci",
   academic_information:"Akademik bilgi",
   settings:"Ayarlar",
   end_impersonation_mode:"Bitiş Kimlik Kişisel Modu",
   access:"Erişim",
   as:"gibi",
   log_in_as:"Oturum açın",
   parent_mentor:"Ebeveyn / Mentor",
   parent_mentor_page:"Ebeveyn / Mentor Sayfası",
   log_out:"Çıkış Yap",
   home:"Ev",
   learning:"Öğrenme",
   schedule:"Takvim",
   progress_tracking:"İlerleme İzleme",
   resources:"Kaynaklar",
   messages:"Mesaj",
   calendar:"Takvim",
   sections:"Bölümler",
   students:"Öğrenciler",
   gradebook:"Not kitabı",
   attendance:"Katılma",
   chat:"Sohbet",   
   view_all:"Hepsini gör",   
   need_help:"Yardıma mı ihtiyacınız var?",   
   contact_support:"İletişim desteği",   
   all_right_reserved:"Her hakkı saklıdır",   
   privacy:"Mahremiyet",   
   terms_of_use:"Kullanım Şartları",   
   reporting_center:"Raporlama merkezi",
   month:"Ay",   
   week:"hafta",
   impersonator:"Taklitçi",
   teacher_page:"Öğretmen sayfası",
   rosters:"Kadrolar",
   inbox:"Gelen kutusu",
   year:"Yıl", 
   teacher_id_:"Öğretmen (ID# 29089)",
   admin:"Yönetici",
   profile_page:"Profil sayfası",
   acount_settings:"Hesap ayarları",
   school_setup:"Okul kurulumu",
   course:"Kurs",
   users:"Kullanıcılar",
   users_directory:"Kullanıcı dizin",
   teachers:"Öğretmen",
   parents_mentor:"Ebeveynler / Mentor",
   applications:"Başvuru",
   requests_Review:"İstekler ve İnceleme",
   edit_Request:"İsteği Düzenleं",
   enrollment_Request:"Kayıt talebi",
   withdrawal_Request:"Para çekme talebi",
   extension_Request:"Uzatma isteği",
   letter_of_Recommendation_Request:"Tavsiye Talebi Talebiर",
   transcript_Request:"Transkript isteği",
   comment_and_Document_Review:"Yorum ve belge incelemesi",
   //auto_Promote:"Otomatik Tanıtım",
   auto_Promote:"Öğrenci Sınıfı Seviye Tanıtımı",
   compliance_Logs:"Uyum Günlükleri",
   communication_Logs:"İletişim Günlükleri",
   contracts:"Sözleşmeler",
   invoices:"Faturalar",
	account_receivable:"Alacak hesapları",
    staff_reassignment:"Personel yeniden atama",
    batch_data_update:"Öğrenci Veri Güncellemesi", 
    contracts_invoices:"Sözleşmeler/faturalar", 
 },
  mr:{
   student:"विद्यार्थी",
   academic_information:"शैक्षणिक माहिती",
   settings:"सेटिंग्ज",
   end_impersonation_mode:"तोतयागिरी मोड समाप्त करा",
   access:"प्रवेश",
   as:"म्हणून",
   log_in_as:"म्हणून लॉग इन करा",
   parent_mentor:"पालक / मार्गदर्शक",
   parent_mentor_page:"पालक / मार्गदर्शक पृष्ठ",
   log_out:"बाहेर पडणे",
   home:"मुख्यपृष्ठ",
   learning:"शिकत आहे",
   schedule:"वेळापत्रक",
   progress_tracking:"प्रगती ट्रॅकिंग",
   resources:"संसाधने",
   messages:"संदेश",
   calendar:"कॅलेंडर",
   sections:"विभाग",
   students:"विद्यार्थीच्या",
   gradebook:"ग्रेडबुक",
   attendance:"उपस्थिती",
   chat:"गप्पा",   
   view_all:"सर्व पहा",   
   need_help:"मदत पाहिजे?",   
   contact_support:"सपोर्टशी संपर्क साधा",   
   all_right_reserved:"सर्व हक्क राखीव",   
   privacy:"गोपनीयता",   
   terms_of_use:"वापरण्याच्या अटी",   
   reporting_center:"अहवाल केंद्र",
   month:"महिना",   
   week:"आठवडा",
   impersonator:"तोतयागिरी करणारा",
   teacher_page:"शिक्षक पृष्ठ",
   rosters:"रोस्टर्स",
   inbox:"इनबॉक्स",
   year:"वर्ष", 
   teacher_id_:"शिक्षक (ID# 29089)",
   admin:"ॲडमिन",
   profile_page:"प्रोफाइल पृष्ठ",
   acount_settings:"खाते सेटिंग्ज",
   school_setup:"शाळा सेटअप",
   course:"अभ्यासक्रम",
   users:"वापरकर्ते",
   users_directory:"वापरकर्ता निर्देशिका",
   teachers:"शिक्षक",
   parents_mentor:"पालक / मार्गदर्शक",
   applications:"अर्ज",
   requests_Review:"विनंत्या आणि पुनरावलोकन",
   edit_Request:"विनंती संपादित करा",
   enrollment_Request:"नावनोंदणी विनंती",
   withdrawal_Request:"मागे घेण्याची विनंती",
   extension_Request:"विस्ताराची विनंतीt",
   letter_of_Recommendation_Request:"शिफारस विनंती पत्र",
   transcript_Request:"उतारा विनंती",
   comment_and_Document_Review:"टिप्पणी आणि दस्तऐवज पुनरावलोकन",
   //auto_Promote:"Auto Promote",
   auto_Promote:"विद्यार्थी ग्रेड स्तर पदोन्नती",
   compliance_Logs:"अनुपालन नोंदी",
   communication_Logs:"संप्रेषण नोंदी",
   contracts:"करार",
   invoices:"पावत्या",
   account_receivable:"खात्यात येणे बाकी",
   staff_reassignment:"कर्मचारी पुनर्नियुक्ती",
   batch_data_update:"विद्यार्थी डेटा अपडेट",
   contracts_invoices:"करार / पावत्या",
 },
  kn:{
   student:"ವಿದ್ಯಾರ್ಥಿ",
   academic_information:"ಶೈಕ್ಷಣಿಕ ಮಾಹಿತಿ",
   settings:"ಸಂಯೋಜನೆಗಳು",
   end_impersonation_mode:"ಸೋಗು ಹಾಕುವಿಕೆಯ ಮೋಡ್ ಅನ್ನು ಕೊನೆಗೊಳಿಸಿ",
   access:"ಪ್ರವೇಶ",
   as:"ಎಂದು",
   log_in_as:"ಲಾಗಿನ್ ಆಗಿ",
   parent_mentor:"ಪೋಷಕ / ಮಾರ್ಗದರ್ಶಕ",
   parent_mentor_page:"ಪೋಷಕ / ಮಾರ್ಗದರ್ಶಕ ಪುಟ",
   log_out:"ಲಾಗ್ ಔಟ್",
   home:"ಮನೆ",
   learning:"ಕಲಿಕೆ",
   schedule:"ವೇಳಾಪಟ್ಟಿ",
   progress_tracking:"ಪ್ರಗತಿ ಟ್ರ್ಯಾಕಿಂಗ್",
   resources:"ಸಂಪನ್ಮೂಲಗಳು",
   messages:"ಸಂದೇಶಗಳು",
   calendar:"ಕ್ಯಾಲೆಂಡರ್",
   sections:"ವಿಭಾಗಗಳು",
   students:"ವಿದ್ಯಾರ್ಥಿಗಳು",
   gradebook:"ಗ್ರೇಡ್ಬುಕ್",
   attendance:"ಹಾಜರಾತಿ",
   chat:"ಚಾಟ್ ಮಾಡಿ",   
   view_all:"ಎಲ್ಲಾ ವೀಕ್ಷಿಸಿ",   
   need_help:"ಸಹಾಯ ಬೇಕೇ?",   
   contact_support:"ಬೆಂಬಲವನ್ನು ಸಂಪರ್ಕಿಸಿ",   
   all_right_reserved:"ಎಲ್ಲ ಹಕ್ಕುಗಳನ್ನು ಕಾಯ್ದಿರಿಸಲಾಗಿದೆ",   
   privacy:"ಗೌಪ್ಯತೆ",   
   terms_of_use:"Tಬಳಕೆಯ ನಿಯಮಗಳು",   
   reporting_center:"ವರದಿ ಕೇಂದ್ರ",
   month:"ತಿಂಗಳು",   
   week:"ವಾರ",
   impersonator:"ವೇಷಧಾರಿ",
   teacher_page:"ಶಿಕ್ಷಕರ ಪುಟ",
   rosters:"ರೋಸ್ಟರ್ಸ್",
   inbox:"ಇನ್‌ಬಾಕ್ಸ್",
   year:"ವರ್ಷ", 
   teacher_id_:"ಶಿಕ್ಷಕ (ID# 29089)",
   admin:"ನಿರ್ವಾಹಕ",
   profile_page:"ಪ್ರೊಫೈಲ್ ಪುಟ",
   acount_settings:"ಖಾತೆ ಸೆಟ್ಟಿಂಗ್‌ಗಳು",
   school_setup:"ಶಾಲೆಯ ಸೆಟಪ್",
   course:"ಕೋರ್ಸ್‌ಗಳು",
   users:"ಬಳಕೆದಾರರು",
   users_directory:"ಬಳಕೆದಾರ ಡೈರೆಕ್ಟರಿ",
   teachers:"ಶಿಕ್ಷಕರು",
   parents_mentor:"ಪೋಷಕರು / ಮಾರ್ಗದರ್ಶಕರು",
   applications:"ಅರ್ಜಿಗಳನ್ನು",
   requests_Review:"ವಿನಂತಿಗಳು ಮತ್ತು ವಿಮರ್ಶೆ",
   edit_Request:"ವಿನಂತಿಯನ್ನು ಸಂಪಾದಿಸಿ",
   enrollment_Request:"ನೋಂದಣಿ ವಿನಂತಿ",
   withdrawal_Request:"ಹಿಂತೆಗೆದುಕೊಳ್ಳುವ ವಿನಂತಿ",
   extension_Request:"ವಿಸ್ತರಣೆ ವಿನಂತಿ",
   letter_of_Recommendation_Request:"ಶಿಫಾರಸು ಪತ್ರ",
   transcript_Request:"ಪ್ರತಿಲಿಪಿ ವಿನಂತಿ",
   comment_and_Document_Review:"ಕಾಮೆಂಟ್ ಮತ್ತು ಡಾಕ್ಯುಮೆಂಟ್ ವಿಮರ್ಶೆ",
   //auto_Promote:"Auto Promote",
   auto_Promote:"ವಿದ್ಯಾರ್ಥಿ ದರ್ಜೆಯ ಮಟ್ಟದ ಪ್ರಚಾರ",
   compliance_Logs:"ಅನುಸರಣೆ ದಾಖಲೆಗಳು",
   communication_Logs:"ಸಂವಹನ ದಾಖಲೆಗಳು",
   contracts:"ಒಪ್ಪಂದಗಳು",
   invoices:"ಇನ್ವಾಯ್ಸ್ಗಳು",
   account_receivable:"ಖಾತೆ ಸ್ವೀಕಾರಾರ್ಹ",
   staff_reassignment:"ಸಿಬ್ಬಂದಿ ಮರುನಿಯೋಜನೆ",
   batch_data_update:"ವಿದ್ಯಾರ್ಥಿ ಡೇಟಾ ನವೀಕರಣ",
   contracts_invoices:"ಒಪ್ಪಂದಗಳು/ಇನ್‌ವಾಯ್ಸ್‌ಗಳು",
 },
}

//export const languagesArray = Object.keys(languages)

export default languages
