import React from 'react';
import { Link } from 'react-router-dom'
//import { Link,withRouter, history} from 'react-router-dom'
//import { DropdownMenu, MenuItem } from 'react-bootstrap-dropdown-menu';
import { connect } from 'react-redux'
import agent from '../agent'
import { Cookies } from 'react-cookie'
import {LOGIN,TOKEN_NAME,CHANGE_TAB, LOGOUT, APP_LOAD, JOIN_CLASS_DATA} from '../constants/actionTypes'
import '../styles/main.css'
import '../styles/responsive.css'
import {Dropdown} from 'react-bootstrap'
import $ from 'jquery';
import Avatar from 'react-avatar';
//import { datadogRum } from '@datadog/browser-rum';
import firebase from '../firebase'
import ModalErorr from './common/ModalErorr'
import ModalSchoolGroupSchools from './common/ModalSchoolGroupSchools';
import Menu from './language/menu.js';
import LocalizedStrings from 'react-localization';

const mapStateToProps = state => (
    Object.assign({}, state, {
		paramsSave: state.home,
		token: state.common.token,
		tab: state.common.tab,
		join_class_data: state.common.join_class_data,
	})      
);

const mapDispatchToProps = dispatch => ({  
	onLogin: (redirectTo, token) => {
		dispatch({ type: LOGIN, redirectTo, token})  
	},
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	setToken: (data)=>{ 
		dispatch({ type: TOKEN_NAME, data })
	},
	setJoinClass: (data)=>{ 
		dispatch({ type: JOIN_CLASS_DATA, data })
	},
	onLoad: (access_token) =>
		dispatch({ type: APP_LOAD, access_token }),
	onLogout: () =>
		dispatch({type: LOGOUT}),
});
let host = window.location.hostname;
let href = window.location.href.includes('/login') ? '' : window.location.href;
let sMenu=new LocalizedStrings(Menu);

class Header extends React.Component {
	constructor(){
		super()
		this.state = {
			search:'',username:'',schools:[],school_logo:'',school_name:'',photo:'',digital_seal:'',
			isOpenModal:false,msg:'',last_role:'',last_school_id:'',allschools:[],
			listSchoolGroupSchools:'',			
			help_link:'https://advantages-dls.zendesk.com/hc/en-us',
			show_app_links:1,impersonate_data:'',impersonate_info:'',impersonate_role:'',
			customreportviewer:true,language:'en',dataRole:[],listAccess:[],saviewcontracts:false,
			saviewschoolgroups:false,saupdateschoolgroups:false,saviewconfigurationschoolgroups:false,
			saupdateconfigurationschoolgroups:false,saviewglobaldepartment:false,saupdateglobaldepartment:false,
			saviewmastercoursecatalog:false,saupdatemastercoursecatalog:false,saviewsecuritytagsdictionary:false,
			saupdatesecuritytagsdictionary:false,saviewdynamicmultilingual:false,saupdatedynamicmultilingual:false,
			sacustomreportviewer:false,saviewadlshome:false,saupdateschoolsetup:false,sareadschoolsetup:false,
			samanageglobalmessages:false,saeditschool:false,sareadschool:false,saupdatedataprocess:false,saupdatenotifications:false,
			viewmessaging: false,readlearningpathtemplates: false,viewattendance: false,viewresources: false,viewgradebook: false,
		}
		
		this.zendesksso=()=>{
			agent.admin.zendesksso({}).then(datas => {				
				if(datas){					
					window.open(datas, '_blank');
				}
					
			});
		}
		this.unimpersonate=(e)=>{
			agent.admin.unimpersonate({impersonate_data:this.state.impersonate_data,}).then(datas => {
				if(datas.status===true){
					
					let UserInfo=datas.data;
					let last_role=parseInt(UserInfo.last_role);
					window.localStorage.setItem('last_role',last_role);
					const cookies = new Cookies()
					const expires_in = datas.expires_in / 60 / 60 / 24
					let dat = new Date()
					//dat.setDate(dat.getDate() + expires_in)	
					dat.setTime(dat.getTime() + (1*24*60*60*1000));	
					//cookies.set(TOKEN_NAME, UserInfo.token, {expires: dat})
					this.setCookie(TOKEN_NAME, UserInfo.token, 1)
					window.localStorage.setItem('TOKEN_NAME',UserInfo.token)
					cookies.set('USERLOG', UserInfo, {expires: dat})
					this.setCookie('USERLOG', UserInfo, 1)
					//console.log(UserInfo)
					//window.localStorage.setItem('USERLOG',JSON.stringify(UserInfo))
					let name_log=(last_role===1)?'USERLOGSUP':'USERLOG';
					window.localStorage.setItem(name_log,JSON.stringify(UserInfo));
					let name=UserInfo.fname;
					name=name.split(" ");
					window.localStorage.setItem('NAMEINFO',name[0])
					window.localStorage.setItem('EMAIL',UserInfo.email)
					
					setTimeout(() => {
						  var url=window.localStorage.getItem('url_before_imper');
						  url=(url!=undefined && url!='')?url:'/user-directory';
						  window.location.href = url;
						},1000)
						
					
					
				}else{
					this.setState({msg: datas.message,isOpenModal:true})
				}
					
			});
		}
		
		this.OpenModalSchoolGroupSchools=(listSchoolGroupSchools,trowcount,e)=>{
			let rowcount = this.state.rowcount;			
			if(rowcount>10){
				this.setState({isOpenModalSchoolGroupSchools:true,listSchoolGroupSchools:listSchoolGroupSchools})
			}
		}
		this.close=()=>{
			this.setState({isOpenModal:false,isOpenModalSchoolGroupSchools:false,
							})
		}
		//---
		this.getUserSchools=(id)=>{
			agent.auth.getUserSchools({}).then(datas => {
				if(datas.status===true){
					//let results=datas.results;
					let results=datas.data;
					if(results!=undefined && results!=null && results!=''){
						window.localStorage.setItem('SCHOOLLIST',JSON.stringify(results))
						let school_name='';let school_logo='';let digital_seal=''; let help_link='';
						let show_app_links=1;
						let schools = Object.keys(results).length != 0 ? results.map(dt => {
							if(dt.school_id===id){
								school_name=dt.school_name;
								school_logo=dt.school_logo;
								digital_seal=dt.digital_seal;
								help_link=dt.help_link;
								show_app_links = dt.show_app_links;
								
							}
							return dt;
						})	: [];
						
						this.setState({schools:schools,school_name:school_name,school_logo:school_logo,digital_seal:digital_seal,help_link:help_link},()=>{
						
						})
					}
				}
			})
		}
		this.getUserSchoolsAll=(id)=>{
			agent.auth.getSchoolGroupSchools({}).then(datas => {
				if(datas.status===true){
					let results=datas.results;
					let rowcount = results.count;
					let rowdata = results.data;
					let rowdatacolumn = results.datacolumn;
					let rowrows = results.rows;
					//window.localStorage.setItem('SCHOOLLIST',JSON.stringify(rowdata))
					let school_name='';let school_logo='';let digital_seal='';
					let schoolsrowdata = (rowdatacolumn != undefined) ? rowdatacolumn.map(dt => {
						// if(dt.school_id===id){
						// 	school_name=dt.school_name;
						// 	school_logo=dt.school_logo_temp;
						// 	digital_seal=dt.digital_seal_temp;
						// }
						return dt;
					})	: [];
					let schoolsrowrows=(rowrows != undefined) ? rowrows.map(dt=>{
						// if(dt.school_id===id){
						// 	school_name=dt.school_name;
						// 	school_logo=dt.school_logo_temp;
						// 	digital_seal=dt.digital_seal_temp;
						// }
						return dt;
					}) : [];	
					// this.setState({allschools:schools,school_name:school_name,school_logo:school_logo,digital_seal:digital_seal},()=>{					
					// })
					
					if(rowcount>0){
						this.setState({allschools:schoolsrowdata, rowcount:rowcount},()=>{
						
						})
					}
					// else{
					// 	this.setState({allschools:schoolsrowrows, rowcount:rowcount},()=>{
					
					// 	})
					// }
				}
			})
		}
		
		this.changeSchool=(school_id,e)=>{
			// console.log(school_id)
			agent.auth.changeSchool({school_id:school_id,role:1}).then(datas => {
				//console.log(datas)
				if(datas.status===true){
					
					let UserInfo=datas.data;
					let last_role=parseInt(UserInfo.last_role);
					window.localStorage.setItem('last_role',last_role);
					const cookies = new Cookies()
					const expires_in = datas.expires_in / 60 / 60 / 24
					let dat = new Date()
					//dat.setDate(dat.getDate() + expires_in)	
					dat.setTime(dat.getTime() + (1*24*60*60*1000));	
					//cookies.set(TOKEN_NAME, UserInfo.token, {expires: dat})
					this.setCookie(TOKEN_NAME, UserInfo.token, 1)
					window.localStorage.setItem('TOKEN_NAME',UserInfo.token)
					cookies.set('USERLOG', UserInfo, {expires: dat})
					//this.setCookie('USERLOG', UserInfo, 1)
					//console.log(UserInfo)
					//window.localStorage.setItem('USERLOG',JSON.stringify(UserInfo))
					let name_log=(last_role===1)?'USERLOGSUP':'USERLOG';
					window.localStorage.setItem(name_log,JSON.stringify(UserInfo));
					let name=UserInfo.fname;
					name=name.split(" ");
					window.localStorage.setItem('NAMEINFO',name[0])
					window.localStorage.setItem('EMAIL',UserInfo.email)
					agent.setToken(UserInfo.token)
					this.props.onLogin('home',UserInfo.token);	
					this.setState({school_name:UserInfo.school_name})	
					this.getUserSchools(school_id);
					this.getUserSchoolsAll(school_id);					
					this.get_user_role();
					window.location.href = '/home';
				}else{
					this.setState({msg: datas.message,isOpenModal:true})
				}
					
			});
		}
		this.setCookie=(name,value,days)=> {
			const cookies = new Cookies()
			var expires = "";
			if (days) {
				var date = new Date();
				date.setTime(date.getTime() + (days*24*60*60*1000));
				//date.setTime(date.getTime() + (days*1*2*60*1000));
				expires = "; expires=" + date.toUTCString();
				// console.log(date)
				cookies.set(name, value, {expires: date})
			}
			//document.cookie = name + "=" + (value || "")  + expires + "; path=/";
			
		}
		this.auto_login=(token)=>{
			agent.auth.auto_login(token).then(data => {
				//console.log(data)
				if(data.status === true){
					let UserInfo=data.data;
					let last_role=parseInt(UserInfo.last_role);
					window.localStorage.setItem('last_role',last_role);
					const cookies = new Cookies()
					const expires_in = data.expires_in / 60 / 60 / 24
					let dat = new Date()
					//dat.setDate(dat.getDate() + expires_in)	
					dat.setTime(dat.getTime() + (1*24*60*60*1000));	
					//cookies.set(TOKEN_NAME, UserInfo.token, {expires: dat})
					this.setCookie(TOKEN_NAME, UserInfo.token, 1)
					window.localStorage.setItem('TOKEN_NAME',UserInfo.token)
					cookies.set('USERLOG', UserInfo, {expires: dat})
					//this.setCookie('USERLOG', UserInfo, 1)
					//console.log(UserInfo)
					//window.localStorage.setItem('USERLOG',JSON.stringify(UserInfo))
					let name_log=(last_role===1)?'USERLOGSUP':'USERLOG';
					window.localStorage.setItem(name_log,JSON.stringify(UserInfo));
					let name=UserInfo.fname;
					name=name.split(" ");
					window.localStorage.setItem('NAMEINFO',name[0])
					window.localStorage.setItem('EMAIL',UserInfo.email)
					agent.setToken(UserInfo.token)
					this.props.onLogin('home',UserInfo.token);
						
					window.location.href = '/home'
				}
			})
		}
		
		this.user_change_role=(role,e)=>{
			agent.auth.user_change_role({role:role}).then(datas => {
				//console.log(datas)
				if(datas.status===true){
					let UserInfo=datas.data;
					let last_role=parseInt(UserInfo.last_role);
					window.localStorage.setItem('last_role',last_role);
					const cookies = new Cookies()
						//const expires_in = data.expires_in / 60 / 60 / 24
						let dat = new Date()
						//dat.setDate(dat.getDate() + expires_in)	
						dat.setTime(dat.getTime() + (1*24*60*60*1000));	
						//cookies.set(TOKEN_NAME, UserInfo.token, {expires: dat})
						this.setCookie(TOKEN_NAME, UserInfo.token, 1)
						window.localStorage.setItem('TOKEN_NAME',UserInfo.token)
						cookies.set('USERLOG', UserInfo, {expires: dat})
						//this.setCookie('USERLOG', UserInfo, 1)
						//console.log(UserInfo)
						//window.localStorage.setItem('USERLOG',JSON.stringify(UserInfo))
						let name_log=(last_role===1)?'USERLOGSUP':'USERLOG';
						window.localStorage.setItem(name_log,JSON.stringify(UserInfo));
						let name=UserInfo.fname;
						name=name.split(" ");
						window.localStorage.setItem('NAMEINFO',name[0])
						window.localStorage.setItem('EMAIL',UserInfo.email)
					
						//window.location.reload();
						window.location.href = '/home';
				}
					
			});
		}
		this.get_user_role=(token,e)=>{
			//console.log(school_id)
			agent.auth.get_user_role({}).then(datas => {
				//console.log(datas)
				if(datas.status===true){
					//console.log(datas)
					this.setState({dataRole:datas.data})
				}
					
			});
		}
		this.setDevice=(token,e)=>{
			//console.log(school_id)
			agent.auth.setDevice({token:token,type:'web'}).then(datas => {
				//console.log(datas)
				if(datas.status===true){
					// console.log(datas)
				}
					
			});
		}
		this.get_configuration = () => {
			agent.admin.get_configuration({}).then(datas => {
				//console.log(datas)
				if (datas.status === true) {
					let data = datas.data;
					if (!data) return;
					let user_settings = data.user_settings;
					if (user_settings != undefined && user_settings != null) {
						let saviewcontracts = (user_settings.saviewcontracts!=undefined)?user_settings.saviewcontracts:false;
						let saviewschoolgroups = (user_settings.saviewschoolgroups!=undefined)?user_settings.saviewschoolgroups:false;
						let saupdateschoolgroups = (user_settings.saupdateschoolgroups!=undefined)?user_settings.saupdateschoolgroups:false;
						let saviewconfigurationschoolgroups = (user_settings.saviewconfigurationschoolgroups!=undefined)?user_settings.saviewconfigurationschoolgroups:false;
						let saupdateconfigurationschoolgroups = (user_settings.saupdateconfigurationschoolgroups!=undefined)?user_settings.saupdateconfigurationschoolgroups:false;
						let saviewglobaldepartment = (user_settings.saviewglobaldepartment!=undefined)?user_settings.saviewglobaldepartment:false;
						let saupdateglobaldepartment = (user_settings.saupdateglobaldepartment!=undefined)?user_settings.saupdateglobaldepartment:false;
						let saviewmastercoursecatalog = (user_settings.saviewmastercoursecatalog!=undefined)?user_settings.saviewmastercoursecatalog:false;
						let saupdatemastercoursecatalog = (user_settings.saupdatemastercoursecatalog!=undefined)?user_settings.saupdatemastercoursecatalog:false;
						let saviewsecuritytagsdictionary = (user_settings.saviewsecuritytagsdictionary!=undefined)?user_settings.saviewsecuritytagsdictionary:false;
						let saupdatesecuritytagsdictionary = (user_settings.saupdatesecuritytagsdictionary!=undefined)?user_settings.saupdatesecuritytagsdictionary:false;
						let saviewdynamicmultilingual = (user_settings.saviewdynamicmultilingual!=undefined)?user_settings.saviewdynamicmultilingual:false;
						let saupdatedynamicmultilingual = (user_settings.saupdatedynamicmultilingual!=undefined)?user_settings.saupdatedynamicmultilingual:false;
						let sacustomreportviewer = (user_settings.sacustomreportviewer!=undefined)?user_settings.sacustomreportviewer:false;
						let saviewadlshome = (user_settings.saviewadlshome!=undefined)?user_settings.saviewadlshome:false;
						let saupdateschoolsetup = (user_settings.saupdateschoolsetup!=undefined)?user_settings.saupdateschoolsetup:false;
						let sareadschoolsetup = (user_settings.sareadschoolsetup!=undefined)?user_settings.sareadschoolsetup:false;
						let samanageglobalmessages = (user_settings.samanageglobalmessages!=undefined)?user_settings.samanageglobalmessages:false;
						let saeditschool = (user_settings.saeditschool!=undefined)?user_settings.saeditschool:false;
						let sareadschool = (user_settings.sareadschool!=undefined)?user_settings.sareadschool:false;			
						let saupdatenotifications = (user_settings.saupdatenotifications!=undefined)?user_settings.saupdatenotifications:false;			
						let saupdatedataprocess = (user_settings.saupdatedataprocess!=undefined)?user_settings.saupdatedataprocess:false;
						let viewmessaging = user_settings.hasOwnProperty('viewmessaging') ? user_settings.viewmessaging : false;
						let readlearningpathtemplates = user_settings.hasOwnProperty('readlearningpathtemplates') ? user_settings.readlearningpathtemplates : false;
						let viewattendance = user_settings.hasOwnProperty('viewattendance') ? user_settings.viewattendance : false;
						let viewresources = user_settings.hasOwnProperty('viewresources') ? user_settings.viewresources : false;
						let viewgradebook = user_settings.hasOwnProperty('viewgradebook') ? user_settings.viewgradebook : false;			
			
						window.localStorage.setItem('user_settings', JSON.stringify(user_settings));
						this.setState({
							saviewcontracts: saviewcontracts,
							saviewschoolgroups: saviewschoolgroups,
							saupdateschoolgroups: saupdateschoolgroups,
							saviewconfigurationschoolgroups: saviewconfigurationschoolgroups,
							saupdateconfigurationschoolgroups: saupdateconfigurationschoolgroups,
							saviewglobaldepartment: saviewglobaldepartment,
							saupdateglobaldepartment: saupdateglobaldepartment,
							saviewmastercoursecatalog: saviewmastercoursecatalog,
							saupdatemastercoursecatalog: saupdatemastercoursecatalog,
							saviewsecuritytagsdictionary: saviewsecuritytagsdictionary,
							saupdatesecuritytagsdictionary: saupdatesecuritytagsdictionary,
							saviewdynamicmultilingual: saviewdynamicmultilingual,
							saupdatedynamicmultilingual: saupdatedynamicmultilingual,
							sacustomreportviewer: sacustomreportviewer,
							saviewadlshome: saviewadlshome,
							saupdateschoolsetup: saupdateschoolsetup,
							sareadschoolsetup: sareadschoolsetup,
							samanageglobalmessages: samanageglobalmessages,
							saeditschool: saeditschool,
							sareadschool: sareadschool,
							saupdatenotifications: saupdatenotifications,
							saupdatedataprocess: saupdatedataprocess,
							viewmessaging: viewmessaging,
							readlearningpathtemplates: readlearningpathtemplates,
							viewattendance: viewattendance,
							viewresources: viewresources,
							viewgradebook: viewgradebook,
						})
					}
				} else {
					if (host != 'localhost') {
						window.sessionStorage.setItem('previous_href', href);
						window.location.href = '/login'
					}
				}
			})
		}
		
		this.get_content_roles_sso=()=>{
			agent.admin.get_content_roles_sso({}).then(datas => {
				//console.log(datas)
				if(datas.status===true){
					let data=datas.data;
					this.setState({listAccess:data})
				}
					
			});
		}
		this.log_sso=(url,e)=>{
			let a= document.createElement('a');
			a.target= '_blank';
			a.href= url;
			a.click();
		}
		this.goSchoolSetup=(ev)=>{
			window.localStorage.setItem('school_setup_id', this.state.last_school_id);
			window.localStorage.setItem('school_setup_name', this.state.school_name);
			window.location.href='/school-information';
		}
		
	}
	onWindowFocus(){
		//console.log("tetst focus")
	}
	onWindowBlur(){
		//alert("tetst blur")
	}
	//---------------------------
	 
	componentWillReceiveProps(nextProps) {
		//console.log(nextProps)
		if (nextProps.common.redirectTo) {
		  const token = window.localStorage.getItem(TOKEN_NAME)
		  const nextRedirect = nextProps.common.redirectTo
		  if(token && nextRedirect === '/login'){ // login page
			nextProps.history.push('/')	
		  } else if(token){       		
			nextProps.history.push(nextProps.common.redirectTo)                  
		  } else {
			//nextProps.history.push('/login') 
		  }
		  
		 // this.props.onRedirect();
		}
	}	

	componentWillMount() {
		//-----------------------
		this.get_configuration();
		const cookies = new Cookies()
		//const token = cookies.get(TOKEN_NAME)
		const token=window.localStorage.getItem('TOKEN_NAME');
		if(token && token!==undefined  && token!==null && token!==''){			
			agent.setToken(token)
			//const data = JSON.parse(token)
			this.props.onLoad( token) 
			//this.props.onLoad( data.UserInfo , data.access_token)       
		}else{
			window.location.href='/login'
		}
		//let userInfo=cookies.get('USERLOG');console.log(userInfo)
		let userInfo=window.localStorage.getItem('USERLOGSUP');
		userInfo=JSON.parse(userInfo);
		console.log(userInfo)
		//let countApplication=window.localStorage.getItem('count_aplication')
		let count_messages=window.localStorage.getItem('count_messages');
		let username=window.localStorage.getItem('username');
		//let count_request=window.localStorage.getItem('count_request');
		if(userInfo!==''&& userInfo!==undefined){
			let fname=userInfo.fname||''
			let lname=userInfo.lname||''
			let mname=userInfo.mname||''
			let photo=userInfo.photo
			let last_role=userInfo.last_role
			let last_school_id=userInfo.last_school_id;
			let impersonate_data=userInfo.impersonate_data
			let impersonate_info=userInfo.impersonate_info
			let impersonate_role=userInfo.impersonate_role;
			let school_name=userInfo.school_name;
			let school_logo=userInfo.school_logo;
			
			let usname=(username!=undefined && username!=null && username!='')?username:lname+", "+fname+" "+mname;
			//console.log(userInfo)
			this.setState({username:usname,photo:photo,last_role:last_role,
							last_school_id:last_school_id,school_name:school_name,
						   },()=>{
					this.getUserSchools(parseInt(userInfo.last_school_id));
					this.getUserSchoolsAll(parseInt(userInfo.last_school_id));
					this.get_user_role();
					this.get_content_roles_sso();
				})
		}else{
			window.location.href='/login'
		}
		
		
		
		
	}	
	
	componentDidMount() {
		//this.get_count_new_objects();
		//==== Start Mobile menu js 
		$('.mobile-menu-icon').click(function(){
		  $('.mobile_main_menu_content .mu_accordion').slideToggle();
		});
		//==== End Mobile menu js 

		//==== Start Search Button Dropdown
		$('.mu_search_bar form button').click(function(){
		  $('.mu_dropdown').toggle();
		  return false;
		});
		$('body').click(function() {
		 $('.mu_dropdown').hide();
		});
		//==== End Search Button Dropdown

		//==== Start Alert Box Js
		$('.mu_alert_box_right a').click(function(){
		  $('.mu_alert_box').fadeOut();
		  return false;
		});
		$('.custom_alert_right a').click(function(){
		  $('.custom_alert_design').fadeOut();
		  return false;
		});
		//==== End Alert Box Js

		//==== Start Mobile Main Menu Js 
		$('.mobile-menu-icon').click(function(){
		  $('.header-bottom-content ul').slideToggle();
		});
		$('.mu_accordion').click(function(){
		  $('.mu_accordion').slideToggle();
		})
		//==== End Mobile Main Menu Js 

		//==== Start Progress Tracking Table Accordion Icon Js
		$(".rjdesign").click(function(e){
		  $(this).toggleClass("active");
		  e.preventDefault();
		});
		//==== End Progress Tracking Table Accordion Icon Js

		//==== Start Language Drowdown Js
		
	}
	componentWillUnmount() {
	  //window.removeEventListener('resize', this.handleWindowSizeChange);
	}
	handleChange(field, e){
		this.setState({[field]: e.target.value})
	}
	handleDoLogout(){
		agent.auth.logout({}).then(datas => {
			//console.log(datas)
		})
		window.localStorage.setItem('username','');
		let pathname=window.localStorage.getItem('pathname');
		const cooki = new Cookies()
		cooki.remove(TOKEN_NAME)
		this.props.onLogout()	
		//console.log(pathname)
		window.localStorage.setItem('join_class','');
		//window.localStorage.setItem('USERLOG','');
		window.localStorage.setItem('USERLOGSUP','');
		window.localStorage.setItem('TOKEN_NAME','')
		if(pathname!==undefined && pathname!=='' && pathname!==null){
			window.location.href = '/login'+pathname
		}else{
			window.location.href = '/login'
		}
		
	}
	generateColor () {
		return '#' +  Math.random().toString(16).substr(-6);
	}

	render() {
		const token = this.props.token
		const tab = this.props.tab
		let schools=this.state.schools
		let allschools=this.state.allschools		
		let rowcount=this.state.rowcount
		let school_logo=this.state.school_logo
		let school_name=this.state.school_name
		let photo=this.state.photo
		let username=this.state.username
		let dataRole=this.state.dataRole
		let listAccess=this.state.listAccess
		let customreportviewer=this.state.customreportviewer;
		sMenu.setLanguage(this.state.language);
		//console.log(tab)
		let pathname=window.location.pathname;
		pathname=pathname.split("/");
		let pathUrl=pathname[pathname.length-1];
		//console.log(pathUrl);
		var counttemp = 0;		
		//console.log(dataRole,listAccess)
		let hosts=window.location.hostname
		hosts= hosts.split(".")
		let hostis=(hosts!==undefined && hosts!==null && hosts[0]!==undefined)?hosts[0]:'';	
		
		let help_link=this.state.help_link
		let impersonate_data=this.state.impersonate_data;
		let saviewschoolgroups=this.state.saviewschoolgroups;
		let saupdateschoolgroups=this.state.saupdateschoolgroups;
		let saviewconfigurationschoolgroups=this.state.saviewconfigurationschoolgroups;
		let saupdateconfigurationschoolgroups=this.state.saupdateconfigurationschoolgroups;
		let saviewglobaldepartment=this.state.saviewglobaldepartment;
		let saupdateglobaldepartment=this.state.saupdateglobaldepartment;
		let saviewmastercoursecatalog=this.state.saviewmastercoursecatalog;
		let saupdatemastercoursecatalog=this.state.saupdatemastercoursecatalog;
		let saviewsecuritytagsdictionary=this.state.saviewsecuritytagsdictionary;
		let saupdatesecuritytagsdictionary=this.state.saupdatesecuritytagsdictionary;
		let saviewdynamicmultilingual=this.state.saviewdynamicmultilingual;
		let saupdatedynamicmultilingual=this.state.saupdatedynamicmultilingual;
		let saviewcontracts=this.state.saviewcontracts;
		let sacustomreportviewer=this.state.sacustomreportviewer;
		let saviewadlshome=this.state.saviewadlshome;
		let saupdateschoolsetup=this.state.saupdateschoolsetup;
		let sareadschoolsetup=this.state.sareadschoolsetup;
		let samanageglobalmessages=this.state.samanageglobalmessages;
		let saeditschool=this.state.saeditschool;
		let sareadschool=this.state.sareadschool;
		let saupdatedataprocess=this.state.saupdatedataprocess;
		let saupdatenotifications=this.state.saupdatenotifications;
		
		//saviewschoolgroups=(hostis!="localhost" && hostis!="staging")?true:saviewschoolgroups;
		//saupdateschoolgroups=(hostis!="localhost" && hostis!="staging")?true:saupdateschoolgroups;
		//saviewconfigurationschoolgroups=(hostis!="localhost" && hostis!="staging")?true:saviewconfigurationschoolgroups;
		//saupdateconfigurationschoolgroups=(hostis!="localhost" && hostis!="staging")?true:saupdateconfigurationschoolgroups;
		//saviewglobaldepartment=(hostis!="localhost" && hostis!="staging")?true:saviewglobaldepartment;
		//saupdateglobaldepartment=(hostis!="localhost" && hostis!="staging")?true:saupdateglobaldepartment;
		//saviewmastercoursecatalog=(hostis!="localhost" && hostis!="staging")?true:saviewmastercoursecatalog;
		//saupdatemastercoursecatalog=(hostis!="localhost" && hostis!="staging")?true:saupdatemastercoursecatalog;
		//saviewsecuritytagsdictionary=(hostis!="localhost" && hostis!="staging")?true:saviewsecuritytagsdictionary;
		//saupdatesecuritytagsdictionary=(hostis!="localhost" && hostis!="staging")?true:saupdatesecuritytagsdictionary;
		//saviewdynamicmultilingual=(hostis!="localhost" && hostis!="staging")?true:saviewdynamicmultilingual;
		//saupdatedynamicmultilingual=(hostis!="localhost" && hostis!="staging")?true:saupdatedynamicmultilingual;
		//saviewcontracts=(hostis!="localhost" && hostis!="staging")?true:saviewcontracts;
		//sacustomreportviewer=(hostis!="localhost" && hostis!="staging")?true:sacustomreportviewer;
		//saviewadlshome=(hostis!="localhost" && hostis!="staging")?true:saviewadlshome;
		//saupdateschoolsetup=(hostis!="localhost" && hostis!="staging")?true:saupdateschoolsetup;
		//sareadschoolsetup=(hostis!="localhost" && hostis!="staging")?true:sareadschoolsetup;
		//samanageglobalmessages=(hostis!="localhost" && hostis!="staging")?true:samanageglobalmessages;
		//saeditschool=(hostis!="localhost" && hostis!="staging")?true:saeditschool;
		//sareadschool=(hostis!="localhost" && hostis!="staging")?true:sareadschool;
		//saupdatedataprocess=(hostis!="localhost" && hostis!="staging")?true:saupdatedataprocess;
		//saupdatenotifications=(hostis!="localhost" && hostis!="staging")?true:saupdatenotifications;
		
			return (
				<header className="header-area superadmin">           
					<div className="header-top-area">
						<div className="container">
							<div className="header-top-content">
								<div className="header-top-left-content">
								
									<div className="header-logo-dropdown">
										
										<Dropdown>
										  <Dropdown.Toggle variant="success" id="dropdownMenuButton" className="dropdown-toggle shfocus logo-sch">
										  {
											(school_logo!=='')?<img className="h-80" src={(school_logo!=='')?school_logo:"/media/home/logo.png"} alt="Images" />:
											 <Avatar size="48" round={true} className="avatar-title" 
												color={'#00a2a7'} name={this.state.school_name} />
											}
											
												<h6 className="ml-3 mb-0 max-w-150px text-truncate" onClick={this.OpenModalSchoolGroupSchools.bind(this.state.rowcount,this.state.allschools,this)}>{school_name} </h6>
											
												<div className="down-icon" onClick={this.OpenModalSchoolGroupSchools.bind(this.state.rowcount,this.state.allschools,this)}><img src="/media/home/down-arrow.png" alt="Images" /></div>
										  </Dropdown.Toggle>

										  <Dropdown.Menu className="dropdown-menu scrollere dropdown-scroll">
										  	
													{ //console.log('schools.length='+schools.length)
														(rowcount !== null && rowcount !== undefined) ?
															(
																(rowcount <= 10) ? (
																	(schools !== null && schools !== undefined) ? schools.map((dt, i) => {
																		counttemp++;																	

																		return (
																				<Dropdown.Item href="" className={(this.state.last_school_id == parseFloat(dt.school_id)) ? "dropdown-item widths active" : "dropdown-item widths"} key={i} onClick={this.changeSchool.bind(this, dt.school_id)} >
																					<span className="symbol symbol-20 mr-6">
																						<img src={dt.school_logo} alt="" />
																					</span>
																					<span className="navi-text">{dt.school_name}</span>
																				</Dropdown.Item>
																		)

																	}) : ''
																) :
																<>
																
																</>
															) : ''
													}												  
										  </Dropdown.Menu>
										</Dropdown>
										
									</div>
									
									
									<div className="header-flag-dropdown" style={{'display':'none'}}>
										<select className="vodiapicker">
											<option value="en" className="test" data-thumbnail="/media/home/flag.png">English</option>
											<option value="en" className="test" data-thumbnail="/media/home/us.png">Us</option>
										</select>
										<div className="lang-select">
											<button className="btn-select" value=""></button>
											<div className="b">
												<ul id="a"></ul>
											</div>
										</div>
									</div>
								</div>
								<div className="header-top-center-content">
									{
										(sacustomreportviewer==true)?<a href='/report-center' className={(tab=='report-center')?'activeheader':''}><img src="/media/teacher/report.png" alt="Images" style={{'margin':'0'}}/> <span>{sMenu.reporting_center}</span></a>:''
									}
									{
									/*
									(this.state.readschoolsetup || this.state.updateschoolsetup) &&
										<a href="/school-information" className={(pathUrl == 'school-information') ? 'activeheader' : ''} style={{ 'marginLeft': '10px' }}><img src="/media/teacher/seting.png" alt="Images" style={{ 'margin': '0' }} /> <span>{sMenu.school_setup} </span></a>
									*/
									}
									
								</div>
								<div className="header-top-right-content">
									
									<div className="header-profile-dropdown header-logo-dropdown">
										
										<Dropdown>
										  <Dropdown.Toggle variant="success" id="dropdownMenuButton" className="dropdown-toggle shfocus drop-logo">
												<span className="profile-content">
													<span className="profile-image">
														<img src="/media/home/porfile.png" alt="Images" style={{'display':'none'}}/>
														
														{
															//(photo!=='' && photo!==null)?<img src={photo}/>:
															<Avatar size="35" round={true} className="avatar-title" 
																						color={'#00a2a7'} name={this.state.username} />
														}
													</span>
													<span className="profile-text">
														<h5>{(username.length>20)?username.substr(0, 20)+"...":this.state.username}</h5>
														<p>Super Admin</p>
													</span>
												</span>
												<div className="down-icon-two"><img src="/media/home/down-arrow.png" alt="Images" /></div>
										  </Dropdown.Toggle>

										  <Dropdown.Menu className="dropdown-menu adjust">
										   <Dropdown.Item className="dropdown-item width-two" onClick={this.handleDoLogout.bind(this)}>Log Out</Dropdown.Item>
											{(impersonate_data!=undefined && impersonate_data!=null && impersonate_data!='') && (
											<Dropdown.Item className={(this.state.language=='ar')?"dropdown-item width-two arabic":"dropdown-item width-two"} onClick={this.unimpersonate.bind(this)}>
												<div className="">
													<p style={{'color':'#5b9bd1'}}>{sMenu.end_impersonation_mode}</p>
													<p className={(this.state.language=='ar')?"dis-flex-rever":""}><i>{sMenu.impersonator}</i><i>:</i> <i>{this.state.impersonate_info}( {this.state.impersonate_role} )</i></p>
												</div>
											</Dropdown.Item>)
											}
											
											{
												(dataRole!==undefined && dataRole!==null && dataRole.length>1)?dataRole.map((ti,l)=>{
													return(
													<Dropdown.Item className={(this.state.language=='ar')?"dropdown-item width-two log-i-role arabic":"dropdown-item width-two log-i-role"} key={l} onClick={this.user_change_role.bind(this,ti.role)}>{sMenu.log_in_as} {(ti.role!==5)?ti.role_name:'Parent / Mentor'}</Dropdown.Item>
													)
												}):''
											}
											{
												(listAccess!=undefined && listAccess!=null && listAccess!='')?listAccess.map((ti,l)=>{
													return(
													<Dropdown.Item className={(this.state.language=='ar')?"dropdown-item width-two log-i-role arabic":"dropdown-item width-two log-i-role"} key={l} onClick={this.log_sso.bind(this,ti.sso_url)}>Access {ti.source_name} as {ti.role_name}</Dropdown.Item>
													)
												}):''
											}
										  </Dropdown.Menu>
										</Dropdown>
										
									</div>
									
									<div className="help-contetn">
										<a onClick={this.zendesksso.bind(this)} target="_blank">
											<img src="/media/home/help.png" alt="Images" />
											
										</a>
									</div>
								</div>
								
							</div>
						</div>
					</div>
					
					<div className="header-bottom-area">
						<div className="container" >						
							<div className="mobile-menu-icon">
								<div className="all-p-humber">
									<span></span>
									<span></span>
									<span></span>
									<span></span>
								</div>
							</div>							
							
							<div className="mobile-search-icon">
								<a href="#search"><img src="/media/home/search.png" alt="Images" /></a>
								<div id="search">
									<button type="button" className="close"><i className="fa fa-times" aria-hidden="true"></i></button>
									<form>
										<input type="search" value={this.state.search} placeholder="Search Keyword" onChange={this.handleChange.bind(this)}/>
										<button type="submit" className="btn btn-primary">Search</button>
									</form>
								</div>
							</div>
							
							<div className="header-bottom-content" style={{'marginRight':'-70px'}}>
								<ul>
								{
									(saviewadlshome==true)&&
									<li>
										<a className={(tab==='adsl-home')?"tablinks actives":"tablinks"} href="/adsl-home">
											<i className="fa fa-map-marker" style={{'marginRight':'2px'}}></i>
											ADLS Home
										</a>
									</li>
								}
									<li>
										<a className={(tab==='home')?"tablinks actives":"tablinks"} href="/home">
											<img src="/media/home/home.png" alt="Images" />
											Home
										</a>
									</li>
								{
									(sareadschoolsetup==true || saupdateschoolsetup==true)&&	
									<li>
										<a className={(tab==='school-information')?"tablinks actives":"tablinks"} onClick={this.goSchoolSetup.bind(this)} >											
											<img src="/media/student/trans_icon_4.png" alt="Images" className="ic-menu" style={{'height':'16px'}}/>
											Set Up
										</a>
									</li>
								}
									{(saviewcontracts==true) && 
									<li>
										<a className={(tab==='b2b-contract' || tab==='contract-comment-document')?"tablinks actives":"tablinks"} href={'/b2b-contract'} >		
											<img src="/media/parent/trans_icon_5.png" alt="Images" style={{'height':'15px'}}/>
											Contracts
										</a>
									</li>
									}
									<li>										
										<Dropdown>
										  <Dropdown.Toggle variant="success" id="dropdownMenuButton" className="dropdown-toggle shfocus drop-menu-it">
										  <a className={(tab==='global-user-directory')?"tablinks actives":"tablinks"} style={{'padding':'18px 10px', 'marginRight':'15px'}}>
												<img src="/media/home/s_student.png" alt="Images" className="ic-menu" style={{'height':'16px'}}/>
												User Management
												<div className="down-icon-two"><img src="/media/home/down-arrow.png" alt="Images" style={{'right':'-12px'}} /></div>
											</a>
												
										  </Dropdown.Toggle>

										  <Dropdown.Menu className="dropdown-menu adjust">
										  
											<Dropdown.Item className="dropdown-item width-two" href="/global-user-directory">Global User Directory</Dropdown.Item>
											 
										  </Dropdown.Menu>
										</Dropdown>
										
									</li>
									{(saviewschoolgroups==true || saupdateschoolgroups==true || saviewconfigurationschoolgroups==true || 
									  saupdateconfigurationschoolgroups==true || saviewglobaldepartment==true || saupdateglobaldepartment==true || 
									  saviewmastercoursecatalog==true || saupdatemastercoursecatalog==true || saviewsecuritytagsdictionary==true ||
									  saupdatesecuritytagsdictionary==true || saviewdynamicmultilingual==true || saupdatedynamicmultilingual==true ) && 
									<li>										
										<Dropdown>
										  <Dropdown.Toggle variant="success" id="dropdownMenuButton" className="dropdown-toggle shfocus drop-menu-it">

										  <a className={(tab==='schoolgroup' || tab==='security-tag-dictionary' || tab==='configuration-schoolgroup' || 
															tab==='multi-lingual' || tab==='global-department' || 
															tab==='master-course-catalog')?"tablinks actives":"tablinks"} style={{'padding':'18px 10px', 'marginRight':'15px'}}>

												<img src="/media/home/setting.png" alt="Images" className="ic-menu" style={{'height':'16px'}}/>
												Global Settings
												<div className="down-icon-two"><img src="/media/home/down-arrow.png" alt="Images" style={{'right':'-12px'}} /></div>
											</a>
												
										  </Dropdown.Toggle>

										  <Dropdown.Menu className="dropdown-menu adjust">
										  
											{(saviewschoolgroups==true || saupdateschoolgroups==true) && <Dropdown.Item className={(tab==='schoolgroup')?"dropdown-item width-two active":"dropdown-item width-two"} href="/schoolgroup">School Groups</Dropdown.Item>}
											{(saviewconfigurationschoolgroups==true || saupdateconfigurationschoolgroups==true) && <Dropdown.Item className={(tab === 'configuration-schoolgroup') ? "dropdown-item width-two active" : "dropdown-item width-two"} href="/configuration-schoolgroup">Configuration School Groups</Dropdown.Item>}
											{(saviewglobaldepartment==true || saupdateglobaldepartment==true) && <Dropdown.Item className={(tab === 'global-department') ? "dropdown-item width-two active" : "dropdown-item width-two"} href="/global-department">Global Department</Dropdown.Item>}
											{(saviewmastercoursecatalog==true || saupdatemastercoursecatalog==true) && <Dropdown.Item className={(tab==='master-course-catalog')?"dropdown-item width-two active":"dropdown-item width-two"} href="/master-course-catalog">Master Course Catalog</Dropdown.Item>}
											{(saviewsecuritytagsdictionary==true || saupdatesecuritytagsdictionary==true) && <Dropdown.Item className={(tab==='security-tag-dictionary')?"dropdown-item width-two active":"dropdown-item width-two"} href="/security-tag-dictionary">Security Tags Dictionary</Dropdown.Item>}
											{(saviewdynamicmultilingual==true || saupdatedynamicmultilingual==true) && <Dropdown.Item className={(tab==='multi-lingual')?"dropdown-item width-two active":"dropdown-item width-two"} href="/translations">Dynamic Multi-lingual</Dropdown.Item>}
											 
										  </Dropdown.Menu>
										</Dropdown>
										
									</li>
									}
									{(saupdatedataprocess==true) && 
									<li>										
										<Dropdown>
										  <Dropdown.Toggle variant="success" id="dropdownMenuButton" className="dropdown-toggle shfocus drop-menu-it">
										  <a className={(tab==='bulk-upload-student' || tab==='upload-section' || tab==='upload-course' || tab==='upload-other-grade' || tab==='upload-enrollments')?"tablinks actives":"tablinks"} style={{'padding':'18px 10px', 'marginRight':'15px'}}>
												<img src="/media/home/developer_board.png" alt="Images" />
												Data Process
												<div className="down-icon-two"><img src="/media/home/down-arrow.png" alt="Images" style={{'right':'-12px'}} /></div>
											</a>
												
										  </Dropdown.Toggle>

										  <Dropdown.Menu className="dropdown-menu adjust">
										  
											<Dropdown.Item className={(tab==='bulk-upload-student')?"dropdown-item width-two active":"dropdown-item width-two"} href="/bulk-upload-student">Upload Students</Dropdown.Item>
											<Dropdown.Item className={(tab==='upload-section')?"dropdown-item width-two active":"dropdown-item width-two"} href="/upload-section">Upload Sections</Dropdown.Item>
											<Dropdown.Item className={(tab==='upload-course')?"dropdown-item width-two active":"dropdown-item width-two"} href="/upload-course">Upload Courses</Dropdown.Item>
											<Dropdown.Item className={(tab==='upload-other-grade')?"dropdown-item width-two active":"dropdown-item width-two"} href="/upload-other-grade">Upload Other Grades</Dropdown.Item>
											<Dropdown.Item className={(tab==='upload-enrollments')?"dropdown-item width-two active":"dropdown-item width-two"} href="/upload-enrollments">Upload Enrollments</Dropdown.Item>
											 
										  </Dropdown.Menu>
										</Dropdown>
										
									</li>
									}
									{
									(saupdatenotifications==true) && 
									<li>										
										<Dropdown>
										  <Dropdown.Toggle variant="success" id="dropdownMenuButton" className="dropdown-toggle shfocus drop-menu-it">
										  <a className={(tab=='eventqueue' || tab=='notificationsrules' || tab=='notificationsqueue')?"tablinks actives":"tablinks"} style={{'padding':'18px 10px', 'marginRight':'15px'}}>
												<img src="/media/enrollment/datepiker.png" alt="Images" className="ic-menu" style={{'height':'16px'}}/>
												Notifications 
												<div className="down-icon-two"><img src="/media/home/down-arrow.png" alt="Images" style={{'right':'-12px'}} /></div>
											</a>
												
										  </Dropdown.Toggle>

										  <Dropdown.Menu className="dropdown-menu adjust">
										  
											<Dropdown.Item className={(tab=='eventqueue')?"dropdown-item width-two active":"dropdown-item width-two"} href="/eventqueue">Event Queue</Dropdown.Item>
											<Dropdown.Item className={(tab=='notificationsqueue')?"dropdown-item width-two active":"dropdown-item width-two"} href="/notificationsqueue">Notification Queue</Dropdown.Item>
											<Dropdown.Item className={(tab=='notificationsrules')?"dropdown-item width-two active":"dropdown-item width-two"} href="/notificationsrules">Notifications Rules</Dropdown.Item>
											<Dropdown.Item className={(tab=='object-state-rules')?"dropdown-item width-two active":"dropdown-item width-two"} href="/object-state-rules">Object State Notifications Rules</Dropdown.Item>
											
											 
										  </Dropdown.Menu>
										</Dropdown>
										
									</li>
									}
									{(sareadschool==true || saeditschool==true) &&
									<li>
										<a className={(tab==='schools')?"tablinks actives":"tablinks"} href="/schools">
											<img src="/media/parent/trans_icon_5.png" alt="Images" style={{'height':'15px'}}/>
											Schools
										</a>
									</li>
									}
									{(samanageglobalmessages==true) &&
									<li>
										<a className={(tab==='global-message')?"tablinks actives":"tablinks"} href="/global-message">
											<img src="/media/home/s_inbox.png" alt="Images" />
											Global Messages
										</a>
									</li>
									}
									<li style={{'display':'none'}}>
										<div className="dc_dots_area" >
										  <Dropdown>
											<Dropdown.Toggle variant="success" id="dropdownMenuButton11" className="dropdown-toggle shfocus">
											  <div className="down-icon"><img src="/media/list-resource/ar-dot.png" alt="Images"  /></div>
											</Dropdown.Toggle>
						
											<Dropdown.Menu className="dropdown-menu">
												<Dropdown.Item className={(tab=='schools')?"dropdown-item width-two active":"dropdown-item width-two"} href="/schools"><img src="/media/home/developer_board.png" alt="Images" /> Schools</Dropdown.Item>
												<Dropdown.Item className={(tab=='global-message')?"dropdown-item width-two active":"dropdown-item width-two"} href="/global-message"><img src="/media/home/developer_board.png" alt="Images" /> Global Messages</Dropdown.Item>
												
											</Dropdown.Menu>
										  </Dropdown>
						
										</div>
									</li>
									
									
									
									
								</ul>
							</div>
							

						   
							<div className="mobile_main_menu_content">
								<ul id="mu_accordion" className="mu_accordion">
									<li>
										<div className="link">
											<a className="tablinks actives"  href="/home">
												<img src="/media/home/home.png" alt="Images" />
												Home
											</a>
										</div>
									</li>

									
									
									
									<li>
										<div className="link">
											<a href="#" onClick={this.handleDoLogout.bind(this)}>Log out</a>
										</div>
										
									</li>
								</ul>
							</div>
						   <ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msg}/>
							<ModalSchoolGroupSchools isOpen={this.state.isOpenModalSchoolGroupSchools} close={this.close} listSchoolGroupSchools={this.state.allschools} changeSchool={this.changeSchool} nextthis={this}/>
						</div>
					</div>
					
				</header>
				
			)			
		
	}
}
class TableRow extends React.Component {
    render() {
        var row = this.props.row;
        return (
            <>
                {row.map(val => <>				
				<Dropdown.Item href="" className={"dropdown-item widths active"} >
					<span className="symbol symbol-20 mr-6">
						<img src={val.school_logo_temp} alt=""/>
					</span>
					<span className="navi-text">{val.school_name}</span>
				</Dropdown.Item>

				</>)}				
            </>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Header)
//export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));