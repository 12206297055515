import React, {Component} from 'react';
import { connect } from 'react-redux';
import agent from '../agent'
//import { Cookies } from 'react-cookie' 
//import { TOKEN_NAME, BASE_IMAGE } from '../constants/actionTypes';
import '../styles/main.css'
import '../styles/responsive.css';
import LocalizedStrings from 'react-localization';
import Menu from './language/menu.js';
import SystemCheckUp from './Home/SystemCheckUp';
import ModalConfirm from './common/ModalConfirmDelete';
import { AlertList } from "react-bs-notifier";
import { Cookies } from 'react-cookie';
import {TOKEN_NAME, LOGOUT} from '../constants/actionTypes'

const mapStateToProps = state => (
    Object.assign({}, state, {
		
		language_key: state.common.language_key,
	})      
);

const mapDispatchToProps = dispatch => ({               
	onLogout: () =>
		dispatch({type: LOGOUT}),
	
});
let sMenu=new LocalizedStrings(Menu);
class Footer extends Component{    
	constructor() {
        super();
		
        this.state = {	
			show_app_links:1
			,help_link:'',language:'en',
			show_system_checkup: false,isOpenModalCf:false,text:'',
			position: "bottom-right",
			alerts: [{id: (new Date()).getTime(),
				type: "success",
				message: 'Saved Successfully.'
			}],
			show_alert:false,tab:'',language:'en',user_id:'',isOpenModalCf:false,
			alert_error: [{id: (new Date()).getTime(),			
				type: "danger",
				message: '.'
			}],position: "bottom-right",show_alert_error:false,msg:'',
        }
		this.handleArchived=(e)=>{ 
			this.setState({isOpenModalCf:true,text:'Are you sure to Remove personal information & disable account?',})
		}
		this.archive_user=(e)=>{
			let user_id=[this.state.user_id];
			
			agent.admin.archive_user_current({user_ids:user_id}).then(datas => {
				//console.log(datas)
				if(datas.status===true){
					this.setState({show_alert_error:false,show_alert:true,isOpenModalCf:false,
									alerts: [{id: (new Date()).getTime(),			
								type: "success",
								message: 'Remove personal information & disable account Successfully.'
							}],},()=>{
										this.handleDoLogout();
									})
				}else{
					this.setState({show_alert_error:true,isOpenModalCf:false,
									alert_error: [{id: (new Date()).getTime(),			
									type: "danger",
									message: datas.message,
								}],},()=>{
					})
				}
				//this.setState({loaderspiner:false})
			})
			
		}
		this.close=()=>{
			this.setState({isOpenModalCf:false})
		}
		this.getSchoolbyIDs=(school_id)=>{
			//let school_id=this.state.student_id
			agent.auth.get_school_byid(school_id,{}).then(datas => {
				//console.log(datas)
				if(datas.status===true){
					let data=datas.data;
					let show_app_links=(data!==undefined && data!==null && data!=='')?data.show_app_links:''
					let help_link=(data!==undefined && data!==null && data!=='')?data.help_link:''
					this.setState({show_app_links:show_app_links,help_link:help_link},()=>{
						
					})
				}
			})
					
		}
		this.showSystemCheckUp = () => {
			this.setState({show_system_checkup: true});
		}
		this.hideSystemCheckUp = () => {
			this.setState({show_system_checkup: false});
		}
		this.zendesksso=()=>{
			agent.admin.zendesksso({}).then(datas => {				
				if(datas){					
					window.open(datas, '_blank');
				}
					
			});
		}
	}
	handleDoLogout(){
		
		let pathname=window.localStorage.getItem('pathname');
		const cooki = new Cookies()
		cooki.remove(TOKEN_NAME)
		this.props.onLogout()	
		//console.log(pathname)
		//window.localStorage.setItem('students','')
		window.localStorage.setItem('USERLOG','');
		window.localStorage.setItem('TOKEN_NAME','')
		if(pathname!==undefined && pathname!==''){
			window.location.href = '/login'+pathname
		}else{
			window.location.href = '/login'
		}
		window.localStorage.setItem('language','');
		
	}
    componentWillReceiveProps(nextProps) {
		if(nextProps && nextProps.language_key){
			this.setState({language:nextProps.language_key,})
		}
	}
	componentWillMount() {
		//const cookies = new Cookies()
		let language=window.localStorage.getItem('language');
		if(language!=undefined && language!=null && language!=''){
			this.setState({language:language});
		}
		let userInfo=window.localStorage.getItem('USERLOG');
		 if(userInfo!==undefined && userInfo!==null && userInfo!==''){			
			userInfo=JSON.parse(userInfo);		 
			let last_school_id=userInfo.last_school_id
			this.getSchoolbyIDs(last_school_id);
		 }	
	}	
	
	onAlertDismissed(alert) {
		const alerts = this.state.alerts;
		const alert_error = this.state.alert_error

		// find the index of the alert that was dismissed
		const idx = alerts.indexOf(alert);
		const idxE = alert_error.indexOf(alert);

		if (idx >= 0) {
			this.setState({
				// remove the alert from the array
				show_alert:false,show_alert_error:false,
				alerts: [...alerts.slice(0,idx), ...alerts.slice(idx + 1)],
				
			});
		}if (idxE >= 0) {
			this.setState({
				// remove the alert from the array
				show_alert:false,show_alert_error:false,
				alert_error: [...alert_error.slice(0,idxE), ...alert_error.slice(idxE + 1)]
			});
		}
	}
	
    render(){        
		let show_app_links=this.state.show_app_links;
		let help_link=this.state.help_link;
		sMenu.setLanguage(this.state.language);
			return (
				<footer className="footer-area">
					<div className="container">
						<div className="footer-content">
							<div className="single-footer-content">
								<div className="footer-logo">
									<a href="#"><img src="/media/home/footer-logo.png" alt="Images" /></a>
								</div>
								<div className="footer-links">
									{/*<ul>
										<li><a href="/home">Home</a></li>
										<li><a href="/discussions">Discussions</a></li>
										<li><a href="/schedule">My Schedule</a></li>
									</ul>*/}
									{(show_app_links==1)?
									<div className="mu_footer_btn" style={{'marginTop':'2px'}}>
										<a href="https://apps.apple.com/us/app/adls-assist/id1519151671"><img src="/media/resource-cards/footer_btn_1.png" /></a>
										<a href="https://play.google.com/store/apps/details?id=com.advantagesdls.app"><img src="/media/resource-cards/footer_btn_2.png" /></a>
										
									</div>
									:''
									}
								</div>
							</div>
							
							<div className="single-footer-content">
								<div className="footer-links">
								{/*<ul>
										<li><a href="#">My course enrollment</a></li>
										<li><a href="/learning">Learning Path</a></li>
										<li><a href="/progress-tracking">Progress Tracking</a></li>
								</ul>*/}
								</div>
							</div>
							
							<div className="single-footer-content">
								<div className="footer-links">
									{/*<ul>
										<li><a href="#">Account Options</a></li>
										<li><a href="/discussions">Discussions</a></li>
										<li><a href="/schedule">My Schedule</a></li>
									</ul>*/}
								</div>
							</div>
							
							<div className="single-footer-content">
								<div className="need-help-content">
									<div className="contact-button mb-2">
										<a href={'#'} onClick={this.zendesksso.bind(this)}>{sMenu.contact_support}</a>
									</div>
									<div className="contact-button" >
										<a href={'#'} onClick={this.showSystemCheckUp.bind(this)}>Run System Check-Up</a>
									</div>
									<div className="lear-foot">
										<a href="#" onClick={this.handleArchived.bind(this)} >Student removal of personal information</a>
									</div>
									
								</div>
							</div>
							
						</div>
						{this.state.show_system_checkup &&
							<SystemCheckUp onCallback={this.hideSystemCheckUp} />
						}

						<div className="copyright-text">
							<p>2021 © ASSIST / {sMenu.all_right_reserved} / <a target="_blank" href="/media/login/ASSIST_Privacy_Policy.pdf">{sMenu.privacy}</a> / <a target="_blank" href="/media/login/Terms_of_Use.pdf">{sMenu.terms_of_use}</a></p>
						</div>
					</div>
					<ModalConfirm isOpen={this.state.isOpenModalCf} close={this.close}  text={this.state.text} goAction={this.archive_user} language={this.state.language}/>
					{	
						(this.state.show_alert==true)?				
							<div id="alert-message">
								<AlertList
									position={this.state.position}
									alerts={this.state.alerts}
									//timeout={this.state.timeout}
									dismissTitle=""
									onDismiss={this.onAlertDismissed.bind(this)}
								/>

								
							</div>:''
					}
					{	
						(this.state.show_alert_error==true)?				
							<div id="alert-message">
								<AlertList
									position={this.state.position}
									alerts={this.state.alert_error}
									//timeout={this.state.timeout}
									headline="Error!"
									onDismiss={this.onAlertDismissed.bind(this)}
								/>

								
							</div>:''
					}
				</footer>					
			);			
		
    }
}

//export default Footer;

export default connect(mapStateToProps, mapDispatchToProps)(Footer)