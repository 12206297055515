import React, {Component} from 'react';
import { connect } from 'react-redux'
import { PAGE_NOT_FOUND_LOADED } from '../constants/actionTypes'
import Header from './Header'
import HeaderPR from './HeaderPR'
import HeaderTeacher from './HeaderTeacher'
import HeaderSuperAdmin from './HeaderSuperAdmin'
import Footer from './Footer'
import '../styles/main.css'
import '../styles/responsive.css'
import '../styles/style-teacher.css';
import '../styles/styleAdmin.css'
//import { Cookies } from 'react-cookie'

const mapDispatchToProps = dispatch => ({               
	onLoad: (tab) =>
		dispatch({ type: PAGE_NOT_FOUND_LOADED, tab }),		
});

class PageNotFound extends Component{    
    
	componentWillMount(){
		this.props.onLoad('page-not-found');
		
	}
	
    render(){  
		//const cookies = new Cookies()
		//let userInfo=cookies.get('USERLOG');//console.log(userInfo)
		let userInfo=window.localStorage.getItem('USERLOG');
		if(userInfo!=undefined && userInfo!=null && userInfo!=''){
			userInfo=JSON.parse(userInfo);
		}
		let last_role=(userInfo!=undefined && userInfo!=null && userInfo!='')?parseFloat(userInfo.last_role):'';
        return (
		<div className={(last_role===2)?"admin-page":(last_role===3)?"teacher-page":(last_role===5)?"parent-page":(last_role===2)?"admin-page":""}>
		{(last_role===3)?<HeaderTeacher/>:(last_role===5)?<HeaderPR />:(last_role===2)?<HeaderSuperAdmin />:(last_role===5)?<Header/>:''}
			<div className="container">
				<div className="col-md-12 page-404" style={{'height':'400px'}} >
					<div className="details">
						<h3 style={{'margin':'20px 0'}}>Oops! You're lost.</h3>
						<p> We can not find the page you're looking for. </p>
					</div>
				</div>
			</div>
			<Footer/>
		</div>	
        );
    }
}

export default connect(null, mapDispatchToProps)(PageNotFound)