import React from 'react';
import { connect } from 'react-redux'
import agent from '../../agent'
import '../../styles/components.css'
import '../../styles/main.css'
import '../../styles/responsive.css'
import '../../styles/styleAdmin.css'

import ReactLoading from 'react-loading';
import { AlertList } from "react-bs-notifier";
import $ from 'jquery';

const mapStateToProps = state => (
  Object.assign({}, state, {
  })
);

const mapDispatchToProps = dispatch => ({
});

class SystemCheckUp extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      delayTimer: null, school_id: 0,
      loaderspiner: false, academic_tracks: [],
      ipAddress: "", osName: "", browserSize: "", browserName: "", connectionSpeed: "",
      showAlert: false, position: "bottom-right", error_message: "", popupBlocked: "", screenSize: "",
      cookieEnabled: "",
      alerts: [{
        id: (new Date()).getTime(),
        type: "success",
        message: 'Saved Successfully.'
      }],
    }
    this.checkWebSocket = () => {
      // let has_ws = 0;
      // try {
      //   websocket = new WebSocket("ws:websocket.org");
      //   websocket.close('');
      // } catch (e) {
      //   //throws code 15 if has socket to me babies
      //   has_ws = 1;
      // }
      // console.log('has_ws :>> ', has_ws);
    }
    this.getIpAddress = () => {
      $.getJSON("https://api.ipify.org?format=json", (data) => {
        // console.log('ip_address :>> ', data.ip);
        this.setState({ ipAddress: data.ip })
      })
    }

    this.getScreenSize = () => {
      let screenSize = '';
      if (window.screen.width) {
        let width = (window.screen.width) ? window.screen.width : '';
        let height = (window.screen.height) ? window.screen.height : '';
        screenSize = width + " x " + height;
      }
      this.setState({ screenSize: screenSize })
    }
    this.getBrowserSize = () => {
      let browserSize = '';
      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      browserSize = vw + " x " + vh;
      this.setState({ browserSize: browserSize })
      return browserSize;
    }

    this.checkPopupBlocked = () => {
      let popupBlocked = window.open(null, "", "width=100, height=100");
      try {
        popupBlocked.close();
        this.setState({ popupBlocked: "No" })
        return false;
      } catch (e) {
        this.setState({ popupBlocked: "Yes" })
        return true;
      }
    }
    this.checkCookie = () => {
      let cookieEnabled = (navigator.cookieEnabled) ? true : false;

      if (typeof navigator.cookieEnabled == 'undefined' && !cookieEnabled) {
        document.cookie = 'testcookie';
        cookieEnabled = (document.cookie.indexOf('testcookie') != -1) ? true : false;
      }
      this.setState({ cookieEnabled: cookieEnabled ? "Yes" : "No" })
    }
    this.checkConnectionSpeed = () => {
      agent.home.check_connection_speed({}).then(datas => {
        if (datas.status === true) {
          let data = datas.data;
          this.setState({ connectionSpeed: data });
        }
      })
    }
    this.checkBrowser = () => {

      let unknown = '-';

      // browser
      let nVer = navigator.appVersion;
      let nAgt = navigator.userAgent;
      let browser = navigator.appName;
      let version = '' + parseFloat(navigator.appVersion);
      let majorVersion = parseInt(navigator.appVersion, 10);
      let nameOffset, verOffset, ix;

      // Opera
      if ((verOffset = nAgt.indexOf('Opera')) != -1) {
        browser = 'Opera';
        version = nAgt.substring(verOffset + 6);
        if ((verOffset = nAgt.indexOf('Version')) != -1) {
          version = nAgt.substring(verOffset + 8);
        }
      }
      // Opera Next
      if ((verOffset = nAgt.indexOf('OPR')) != -1) {
        browser = 'Opera';
        version = nAgt.substring(verOffset + 4);
      }
      // Legacy Edge
      else if ((verOffset = nAgt.indexOf('Edge')) != -1) {
        browser = 'Microsoft Legacy Edge';
        version = nAgt.substring(verOffset + 5);
      }
      // Edge (Chromium)
      else if ((verOffset = nAgt.indexOf('Edg')) != -1) {
        browser = 'Microsoft Edge';
        version = nAgt.substring(verOffset + 4);
      }
      // MSIE
      else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
        browser = 'Microsoft Internet Explorer';
        version = nAgt.substring(verOffset + 5);
      }
      // Chrome
      else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
        browser = 'Chrome';
        version = nAgt.substring(verOffset + 7);
      }
      // Safari
      else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
        browser = 'Safari';
        version = nAgt.substring(verOffset + 7);
        if ((verOffset = nAgt.indexOf('Version')) != -1) {
          version = nAgt.substring(verOffset + 8);
        }
      }
      // Firefox
      else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
        browser = 'Firefox';
        version = nAgt.substring(verOffset + 8);
      }
      // MSIE 11+
      else if (nAgt.indexOf('Trident/') != -1) {
        browser = 'Microsoft Internet Explorer';
        version = nAgt.substring(nAgt.indexOf('rv:') + 3);
      }
      // Other browsers
      else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
        browser = nAgt.substring(nameOffset, verOffset);
        version = nAgt.substring(verOffset + 1);
        if (browser.toLowerCase() == browser.toUpperCase()) {
          browser = navigator.appName;
        }
      }

      // trim the version string

      // system
      let os = unknown;
      let clientStrings = [
        { s: 'Windows 10/11', r: /(Windows 10.0|Windows NT 10.0)/ },
        { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
        { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
        { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
        { s: 'Windows Vista', r: /Windows NT 6.0/ },
        { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
        { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
        { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
        { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
        { s: 'Windows 98', r: /(Windows 98|Win98)/ },
        { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
        { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
        { s: 'Windows CE', r: /Windows CE/ },
        { s: 'Windows 3.11', r: /Win16/ },
        { s: 'Android', r: /Android/ },
        { s: 'Open BSD', r: /OpenBSD/ },
        { s: 'Sun OS', r: /SunOS/ },
        { s: 'Chrome OS', r: /CrOS/ },
        { s: 'Linux', r: /(Linux|X11(?!.*CrOS))/ },
        { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
        { s: 'Mac OS X', r: /Mac OS X/ },
        { s: 'Mac OS', r: /(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
        { s: 'QNX', r: /QNX/ },
        { s: 'UNIX', r: /UNIX/ },
        { s: 'BeOS', r: /BeOS/ },
        { s: 'OS/2', r: /OS\/2/ },
        { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ }
      ];
      for (let id in clientStrings) {
        let cs = clientStrings[id];
        if (cs.r.test(nAgt)) {
          os = cs.s;
          break;
        }
      }

      let osVersion = unknown;

      if (/Windows/.test(os)) {
        osVersion = /Windows (.*)/.exec(os)[1];
        os = 'Windows';
      }

      switch (os) {
        case 'Mac OS':
        case 'Mac OS X':
        case 'Android':
          osVersion = /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([\.\_\d]+)/.exec(nAgt)[1];
          break;

        case 'iOS':
          osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
          osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
          break;
      }
      let osName = os + " " + osVersion;
      let browserName = browser + " " + version;
      this.setState({ osName: osName, browserName: browserName });

    }
    this.showModalEror = (message) => {
      this.setState({ isOpenModal: true, msg: message })
    }
    this.hideEditSection = () => {
      this.props.onCallback();
    }
  }
  onAlertDismissed(alert) {
    const alerts = this.state.alerts;

    // find the index of the alert that was dismissed
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      this.setState({
        // remove the alert from the array
        alerts: [...alerts.slice(0, idx), ...alerts.slice(idx + 1)]
      });
    }
  }
  componentDidMount() {
    this.checkConnectionSpeed();
    this.checkCookie();
    this.checkPopupBlocked();
    this.getBrowserSize();
    this.getScreenSize();
    this.getIpAddress();
    this.checkBrowser();
  }

  render() {

    return (
      <React.Fragment>
        <div className="mandatory-viewing">
          <div className="container main-viewing">
            <div className="row">
              <div className="col-12">
                <div className="same-close-button-left mb-4">
                  <button onClick={this.hideEditSection.bind(this)}><img src="/media/resources/close.png" alt="Images" />Close</button>
                </div>
                <h3 className='mb-5'>Run System Check-Up</h3>
                <h5 className='mb-2'>You are using {this.state.browserName}</h5>
                <h5 className='mb-2'>Your Browser Test Results</h5>
                <h6 className='mb-3'>The following is an analysis of your browser and system settings.</h6>
                <table className="custom-table table-responsive-lg">
                  <thead className="custom-table-head">
                    <tr>
                      <th className="custom-table-th" width="40%">
                        <div className="w-100 d-flex align-items-start flex-column">
                          <div className='d-flex align-items-center justify-content-start mb-1'>
                            <div className="">Test</div>
                          </div>
                        </div>
                      </th>
                      <th className="custom-table-th">
                        <div className="w-100 d-flex align-items-start flex-column">
                          <div className='d-flex align-items-center justify-content-start mb-1'>
                            <div className="">Result</div>
                          </div>
                        </div>
                      </th>
                    </tr>
                    <tr style={{ height: '8px' }}>
                    </tr>
                  </thead>
                  <tbody className="custom-table-body" id="tbody-request">

                    <tr className="custom-table-row">
                      <td className="custom-table-td">
                        Operating System
                      </td>
                      <td className="custom-table-td">
                        {this.state.osName}
                      </td>
                    </tr>
                    <tr style={{ height: '8px' }}></tr>

                    <tr className="custom-table-row">
                      <td className="custom-table-td">
                        Connection Speed
                      </td>
                      <td className="custom-table-td">
                        {this.state.connectionSpeed}
                      </td>
                    </tr>
                    <tr style={{ height: '8px' }}></tr>

                    <tr className="custom-table-row">
                      <td className="custom-table-td">
                        Pop-Up Blocking
                      </td>
                      <td className="custom-table-td">
                        {this.state.popupBlocked}
                      </td>
                    </tr>
                    <tr style={{ height: '8px' }}></tr>

                    <tr className="custom-table-row">
                      <td className="custom-table-td">
                        IP Address
                      </td>
                      <td className="custom-table-td">
                        {this.state.ipAddress}
                      </td>
                    </tr>
                    <tr style={{ height: '8px' }}></tr>

                    <tr className="custom-table-row">
                      <td className="custom-table-td">
                        JavaScript Enabled
                      </td>
                      <td className="custom-table-td">
                        Yes
                      </td>
                    </tr>
                    <tr style={{ height: '8px' }}></tr>

                    <tr className="custom-table-row">
                      <td className="custom-table-td">
                        Cookies Enabled
                      </td>
                      <td className="custom-table-td">
                        {this.state.cookieEnabled}
                      </td>
                    </tr>
                    <tr style={{ height: '8px' }}></tr>

                    <tr className="custom-table-row">
                      <td className="custom-table-td">
                        Flash Version
                      </td>
                      <td className="custom-table-td">
                        No
                      </td>
                    </tr>
                    <tr style={{ height: '8px' }}></tr>

                    <tr className="custom-table-row">
                      <td className="custom-table-td">
                        Java Version
                      </td>
                      <td className="custom-table-td">
                        No
                      </td>
                    </tr>
                    <tr style={{ height: '8px' }}></tr>

                    <tr className="custom-table-row">
                      <td className="custom-table-td">
                        WebSockets Supported
                      </td>
                      <td className="custom-table-td">
                        Yes
                      </td>
                    </tr>
                    <tr style={{ height: '8px' }}></tr>

                    <tr className="custom-table-row">
                      <td className="custom-table-td">
                        Browser Size
                      </td>
                      <td className="custom-table-td">
                        {this.state.browserSize}
                      </td>
                    </tr>
                    <tr style={{ height: '8px' }}></tr>

                    <tr className="custom-table-row">
                      <td className="custom-table-td">
                        Screen Size
                      </td>
                      <td className="custom-table-td">
                        {this.state.screenSize}
                      </td>
                    </tr>
                    <tr style={{ height: '8px' }}></tr>
                  </tbody>
                </table>

                <div className="load-spiner fixed-loading">
                  {
                    (this.state.loaderspiner == true) ?
                      <ReactLoading type="spinningBubbles" color="#00BFFF" height={100} width={100} /> : ''
                  }
                </div>
                {
                  (this.state.showAlert == true) ?
                    <div id="alert-message">
                      <AlertList
                        position={this.state.position}
                        alerts={this.state.alerts}
                        //timeout={this.state.timeout}
                        dismissTitle=""
                        onDismiss={this.onAlertDismissed.bind(this)}
                      />
                    </div> : ''
                }
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(SystemCheckUp)