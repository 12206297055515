const languages = {
  en:{
	   error:"ERROR", 
	   ok:"Ok",
	   warning:"Warning",
	   Yes:"Yes",
	   No:"No",
	   reactivating_a_section_will_close_it_and_make_all:"Reactivating a Section will close it and make all student enrollment status Complete. Are you sure you want to reactivate this section '0002'?",
	   finalizing_a_section_will_close_it_and_make_all:"Finalizing a Section will close it and make all student enrollment status Complete. Are you sure you want to finalize this section '0002'?",
	   finalizing_this_global_section_will_cause:"Finalizing this global section will cause closure in all schools, and withdrawal of all students in them.Please click Yes if you want to proceed.",
	   finalizing_a_section_will_close_it:"Finalizing a Section will close it and make all student enrollment status Complete. Are you sure you want to finalize this sections?"
	   
 },
 ar: {
	   error:"خطأ", 
	   ok:"موافق",
	   warning:"تحذير",
	   Yes:"نعم",
	   No:"رقم",
	   reactivating_a_section_will_close_it_and_make_all:'ستؤدي إعادة تنشيط القسم إلى إغلاقه وإكمال حالة تسجيل الطلاب بالكامل. هل أنت متأكد أنك تريد إعادة تنشيط هذا القسم "0002"؟',
	   finalizing_a_section_will_close_it_and_make_all:'سيؤدي إنهاء القسم إلى إغلاقه وإكمال حالة تسجيل جميع الطلاب. هل أنت متأكد أنك تريد إنهاء هذا القسم "0002"؟',
	   finalizing_this_global_section_will_cause:"سيؤدي إنهاء هذا القسم العالمي إلى إغلاق جميع المدارس وسحب جميع الطلاب فيها. الرجاء النقر فوق 'نعم' إذا كنت تريد المتابعة.",
	   finalizing_a_section_will_close_it:"سيؤدي إنهاء القسم إلى إغلاقه وإكمال حالة تسجيل جميع الطلاب. هل أنت متأكد أنك تريد إنهاء هذه الأقسام؟"
	   
 },
 zh: {
	   error:"错误", 
	   ok:"好的",
	   warning:"警告",
	   Yes:"是的",
	   No:"不",
	   reactivating_a_section_will_close_it_and_make_all:"重新激活一个部分将关闭它并使所有学生注册状态完成。您确定要重新激活'0002'部分吗？",
	   finalizing_a_section_will_close_it_and_make_all:"完成一个部分将关闭它并使所有学生注册状态完成。您确定要完成'0002'部分吗？",
	   finalizing_this_global_section_will_cause:"完成此全局部分将导致所有学校关闭，并导致其中所有学生退学。如果您想继续，请单击“是”。",
	   finalizing_a_section_will_close_it:"完成一个部分将关闭它并使所有学生注册状态完成。您确定要完成此部分吗？"
 },
 fr: {
	   error:"ERREUR", 
	   ok:"D'accord",	   
	   warning:"Avertissement",
	   Yes:"Oui",
	   No:"Non",
	   reactivating_a_section_will_close_it_and_make_all:"La réactivation d'une section la fermera et rendra tous les statuts d'inscription des étudiants complets. Êtes-vous sûr de vouloir réactiver cette section '0002' ?",
	   finalizing_a_section_will_close_it_and_make_all:"La finalisation d'une section la fermera et rendra tous les statuts d'inscription des étudiants complets. Êtes-vous sûr de vouloir finaliser cette section '0002' ?",
	   finalizing_this_global_section_will_cause:"La finalisation de cette section globale entraînera la fermeture de toutes les écoles et le retrait de tous leurs élèves. Veuillez cliquer sur Oui si vous souhaitez continuer.",
	   finalizing_a_section_will_close_it:"La finalisation d'une section la fermera et rendra tous les statuts d'inscription des étudiants complets. Voulez-vous vraiment finaliser ces sections ?"	   
 },
 it: {
	   error:"ERRORE", 
	   ok:"Ok",
	   warning:"Avvertimento",
	   Yes:"sì",
	   No:"No",
	   reactivating_a_section_will_close_it_and_make_all:"La riattivazione di una Sezione la chiuderà e lo stato di iscrizione di tutti gli studenti sarà Completato. Sei sicuro di voler riattivare questa sezione '0002'?",
	   finalizing_a_section_will_close_it_and_make_all:"La finalizzazione di una sezione la chiuderà e lo stato di iscrizione di tutti gli studenti verrà completato. Sei sicuro di voler finalizzare questa sezione '0002'?",
	   finalizing_this_global_section_will_cause:"Il completamento di questa sezione globale comporterà la chiusura di tutte le scuole e il ritiro di tutti gli studenti. Fare clic su Sì se si desidera procedere.",
	   finalizing_a_section_will_close_it:"La finalizzazione di una sezione la chiuderà e lo stato di iscrizione di tutti gli studenti verrà completato. Sei sicuro di voler finalizzare questa sezione?"
 },
 kk: {
	   error:"ҚАТЕ", 
	   ok:"Жарайды ма",
	   warning:"Ескерту",
	   Yes:"Иә",
	   No:"Жоқ",
	   reactivating_a_section_will_close_it_and_make_all:"Бөлімді қайта белсендіру оны жабады және студенттің барлық тіркелу күйін Аяқтайды. Осы '0002' бөлімін қайта белсендіргіңіз келетініне сенімдісіз бе?",
	   finalizing_a_section_will_close_it_and_make_all:"Бөлімді аяқтау оны жабады және студенттің барлық тіркелу күйін аяқтайды. Осы '0002' бөлімін аяқтағыңыз келетініне сенімдісіз бе?",
	   finalizing_this_global_section_will_cause:"Осы жаһандық бөлімді аяқтау барлық мектептердің жабылуына және ондағы барлық студенттердің оқудан шығарылуына әкеледі. Жалғастырғыңыз келсе, «Иә» түймесін басыңыз.",
	   finalizing_a_section_will_close_it:"Бөлімді аяқтау оны жабады және студенттің барлық тіркелу күйін аяқтайды. Осы бөлімдерді аяқтағыңыз келетініне сенімдісіз бе?"
 },
 ko: {
	  error:"오류", 
	  ok:"확인",
	  warning:"경고",
	  Yes:"예",
	  No:"아니",
	  reactivating_a_section_will_close_it_and_make_all:"섹션을 재활성화하면 섹션이 닫히고 모든 학생 등록 ​​상태가 완료됩니다. 이 '0002' 섹션을 다시 활성화하시겠습니까?",
	  finalizing_a_section_will_close_it_and_make_all:"섹션을 마무리하면 섹션이 닫히고 모든 학생 등록 ​​상태가 완료됩니다. 이 '0002' 섹션을 완료하시겠습니까?",
	  finalizing_this_global_section_will_cause:"이 글로벌 섹션을 완료하면 모든 학교가 폐쇄되고 모든 학생이 탈퇴하게 됩니다.계속하려면 예를 클릭하십시오.",
	  finalizing_a_section_will_close_it:"섹션을 마무리하면 섹션이 닫히고 모든 학생 등록 ​​상태가 완료됩니다. 이 섹션을 완료하시겠습니까?",
 },
 pt: {
	   error:"ERRO", 
	   ok:"OK",
	   warning:"Aviso",
	   Yes:"Sim",
	   No:"Não",
	   reactivating_a_section_will_close_it_and_make_all:"A reativação de uma Seção irá fechá-la e tornar o status de inscrição de todos os alunos Concluído. Tem certeza de que deseja reativar esta seção '0002'?",
	   finalizing_a_section_will_close_it_and_make_all:"A finalização de uma Seção irá fechá-la e tornar o status de inscrição de todos os alunos Completo. Tem certeza de que deseja finalizar esta seção '0002'?",
	   finalizing_this_global_section_will_cause:"A finalização desta seção global causará o fechamento de todas as escolas e a retirada de todos os alunos nelas. Por favor, clique em Sim se quiser continuar.",
	   finalizing_a_section_will_close_it:"A finalização de uma Seção irá fechá-la e tornar o status de inscrição de todos os alunos Completo. Tem certeza de que deseja finalizar estas seções?"
 },
 ru: {
	   error:"ОШИБКА", 
	   ok:"Ok",
	   warning:"Предупреждение",
	   Yes:"Да",
	   No:"Нет",
	   reactivating_a_section_will_close_it_and_make_all:"При повторной активации секции она будет закрыта, а статус зачисления всех учащихся станет завершенным. Вы уверены, что хотите повторно активировать этот раздел '0002'?",
	   finalizing_a_section_will_close_it_and_make_all:"Завершение работы над разделом закроет его, и статус зачисления всех учащихся станет завершенным. Вы уверены, что хотите доработать этот раздел '0002'?",
	   finalizing_this_global_section_will_cause:"Завершение работы над этим глобальным разделом приведет к закрытию всех школ и исключению из них всех учащихся. Нажмите «Да», если хотите продолжить.",
	   finalizing_a_section_will_close_it:"Завершение работы над разделом закроет его, и статус зачисления всех учащихся станет завершенным. Вы уверены, что хотите завершить работу над этими разделами?"
 },
 es: {
	   error:"ERROR", 
	   ok:"Está bien",
	   warning:"Advertencia",
	   Yes:"Sí",
	   No:"No",
	   reactivating_a_section_will_close_it_and_make_all:"Reactivar una Sección la cerrará y hará que el estado de inscripción de todos los estudiantes sea Completo. ¿Estás seguro que quieres reactivar esta sección '0002'?",
	   finalizing_a_section_will_close_it_and_make_all:"Al finalizar una Sección, se cerrará y se completará el estado de inscripción de todos los estudiantes. ¿Está seguro de que desea finalizar esta sección '0002'?",
	   finalizing_this_global_section_will_cause:"La finalización de esta sección global provocará el cierre de todas las escuelas y el retiro de todos los estudiantes en ellas. Haga clic en Sí si desea continuar.",
	   finalizing_a_section_will_close_it:"Al finalizar una Sección, se cerrará y se completará el estado de inscripción de todos los estudiantes. ¿Está seguro de que desea finalizar estas secciones?"
 },
 vi: {
	  error:"LỖI", 
	  ok:"Được",	 
	  warning:"Cảnh báo",
	  Yes:"Đúng",
	  No:"Không",
	  reactivating_a_section_will_close_it_and_make_all:"Kích hoạt lại một Phần sẽ đóng nó và làm cho tất cả trạng thái đăng ký của học sinh là Hoàn thành. Bạn có chắc chắn muốn kích hoạt lại phần '0002' này không?",
	  finalizing_a_section_will_close_it_and_make_all:"Việc hoàn thiện một Phần sẽ đóng nó lại và làm cho tất cả trạng thái đăng ký của học sinh là Hoàn thành. Bạn có chắc chắn muốn hoàn tất phần '0002' này không?",
	  finalizing_this_global_section_will_cause:"Việc hoàn thiện phần toàn cầu này sẽ khiến tất cả các trường học phải đóng cửa và tất cả học sinh trong các trường đó phải rút lui. Vui lòng nhấp vào Có nếu bạn muốn tiếp tục.",
	  finalizing_a_section_will_close_it:"Việc hoàn thiện một Phần sẽ đóng nó lại và làm cho tất cả trạng thái đăng ký của học sinh là Hoàn thành. Bạn có chắc chắn muốn hoàn thành phần này không?"	 
 },
 hi:{
	   error:"गलती", 
	   ok:"ठीक",
	   warning:"चेतावनी",
	   Yes:"हाँ",
	   No:"नहीं",
	   reactivating_a_section_will_close_it_and_make_all:"किसी अनुभाग को पुनः सक्रिय करने से वह बंद हो जाएगा और सभी छात्र नामांकन स्थिति पूर्ण हो जाएगी। क्या आप वाकई इस अनुभाग '0002' को पुनः सक्रिय करना चाहते हैं?",
	   finalizing_a_section_will_close_it_and_make_all:"किसी अनुभाग को अंतिम रूप देने से वह बंद हो जाएगा और सभी छात्र नामांकन स्थिति पूर्ण हो जाएगी। क्या आप वाकई इस खंड '0002' को अंतिम रूप देना चाहते हैं?",
	   finalizing_this_global_section_will_cause:"इस वैश्विक अनुभाग को अंतिम रूप देने से सभी विद्यालय बंद हो जाएंगे, और उनमें सभी छात्रों की वापसी हो जाएगी। यदि आप आगे बढ़ना चाहते हैं तो कृपया हां पर क्लिक करें।",
	   finalizing_a_section_will_close_it:"किसी अनुभाग को अंतिम रूप देने से वह बंद हो जाएगा और सभी छात्र नामांकन स्थिति पूर्ण हो जाएगी। क्या आप वाकई इस अनुभाग को अंतिम रूप देना चाहते हैं?"
	   
 }
}

//export const languagesArray = Object.keys(languages)

export default languages
