/*
import { createStore } from 'redux';
import reducer from './reducer';


const store = createStore(reducer, window.STATE_FROM_SERVER)

export default store;
*/

/*import { applyMiddleware, createStore } from 'redux';
import { localStorageMiddleware } from './middleware';
import reducer from './reducer';

const getMiddleware = () => {
   return applyMiddleware(localStorageMiddleware);
}

const store = createStore(reducer, getMiddleware(), window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__())

export default store;*/



import { createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducer';

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducers,
  composeEnhancer(applyMiddleware(thunk)),
);

export default store;