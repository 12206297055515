import React from 'react'
import ReactDOM from 'react-dom';
import {FormControl, Row, Col, Button} from 'react-bootstrap';
//import InputMask from 'react-input-mask'; 
import $ from 'jquery';
import { Form, } from 'react-bootstrap'

import agent from '../../agent'

import { Cookies } from 'react-cookie';
import {LOGIN,TOKEN_NAME,CHANGE_TAB, LOGOUT, APP_LOAD, JOIN_CLASS_DATA} from '../../constants/actionTypes';
import '../../styles/modal.css'; 
import Avatar from 'react-avatar';
import Modal from 'react-bootstrap-modal';
import { connect } from 'react-redux';
import { 
  //BUILDING_LOADED,
  
}from '../../constants/actionTypes'; 
//import menu from '../common/menu.json'
//import { Cookies } from 'react-cookie'
//import { setLocale, getLocale, localizeKey } from 'react-redux-localization';

const mapStateToProps = state => (
    Object.assign({}, state, {
		languageKey: state.common.languageKey
	
    })      
);
const mapDispatchToProps = dispatch => ({
  /*loadCustomers: (data) => {
      dispatch({ type: CUSTOMER_BUILDING_LOADED, payload: data })  
  },
  */
});

class ModalErorr extends React.Component {
	
    constructor() {
        super();
		
        this.state = {			
			isOpen: false,
			listSchoolGroupSchools:[],
			keyword:'',
        }
		
		this.hideModal = ev => {
		  this.setState({
			isOpen: false,
		  });
		 this.props.close();
			
		};
		this.handleChangSchool = (school_id,e) => {
			//console.log(school_id);
		   //this.props.changeSchool(this,school_id);	  
			let hosts=window.location.hostname
			hosts= hosts.split(".")
			let hostis=(hosts!==undefined && hosts!==null && hosts[0]!==undefined)?hosts[0]:'';
			//let role = (hostis === "teacher") ? 3 : (hostis === "sadmin" || hostis === "portal") ? 2 : (hostis === "sa") ? 1 : 2;
			let role_login=window.localStorage.getItem('last_role');
		   agent.auth.changeSchool({school_id:school_id,role:role_login}).then(datas => {
				//console.log(datas)
				if(datas.status===true){
					//this.getUserSchools()
					/*const cookies = new Cookies()
					//const expires_in = data.expires_in / 60 / 60 / 24
					let dat = new Date()
					dat.setDate(dat.getDate());
					let userInfo=cookies.get('USERLOG');
						if(userInfo!==''){
							userInfo.school_id=school_id;
							userInfo.last_school_id=school_id;
						}
						//console.log(userInfo)
					cookies.set('USERLOG', userInfo)	
					window.location.reload();*/
					let UserInfo=datas.data;
					let last_role=parseInt(UserInfo.last_role);
					window.localStorage.setItem('last_role',last_role);
					const cookies = new Cookies()
					const expires_in = datas.expires_in / 60 / 60 / 24
					let dat = new Date()
					//dat.setDate(dat.getDate() + expires_in)	
					dat.setTime(dat.getTime() + (1*24*60*60*1000));	
					//cookies.set(TOKEN_NAME, UserInfo.token, {expires: dat})
					this.setCookie(TOKEN_NAME, UserInfo.token, 1)
					window.localStorage.setItem('TOKEN_NAME',UserInfo.token)
					cookies.set('USERLOG', UserInfo, {expires: dat})
					//this.setCookie('USERLOG', UserInfo, 1)
					//console.log(UserInfo)
					//window.localStorage.setItem('USERLOG',JSON.stringify(UserInfo))
					let name_log=(parseFloat(last_role)===1)?'USERLOGSUP':'USERLOG';
					window.localStorage.setItem(name_log,JSON.stringify(UserInfo));
					let name=UserInfo.fname;
					name=name.split(" ");
					window.localStorage.setItem('NAMEINFO',name[0])
					window.localStorage.setItem('EMAIL',UserInfo.email)
					agent.setToken(UserInfo.token)
					//this.props.onLogin('home',UserInfo.token);	
					//this.setState({school_name:UserInfo.school_name})	
					//this.getUserSchools(school_id);
					//this.getUserSchoolsAll(school_id);					
					//this.get_user_role();
					window.location.href = '/home';
				}else{
					this.setState({msg: datas.message,isOpenModal:true})
				}
					
			});
			this.hideModal();	
		//    this.setState({
		// 		isOpen: false,
		// 	});
		// 	this.props.close();
		  };
		  this.setCookie=(name,value,days)=> {
			const cookies = new Cookies()
			var expires = "";
			if (days) {
				var date = new Date();
				date.setTime(date.getTime() + (days*24*60*60*1000));
				//date.setTime(date.getTime() + (days*1*2*60*1000));
				expires = "; expires=" + date.toUTCString();
				console.log(date)
				cookies.set(name, value, {expires: date})
			}
			//document.cookie = name + "=" + (value || "")  + expires + "; path=/";
			
		}
		this.getUserSchoolsAll=(keyword)=>{
			//console.log('keyword=='+keyword);
			agent.auth.getSchoolGroupSchools({keyword:keyword}).then(datas => {
				if(datas.status===true){
					let results=datas.results;
					let rowcount = results.count;
					let rowdata = results.data;
					let rowdatacolumn = results.datacolumn;
					let rowrows = results.rows;
					//window.localStorage.setItem('SCHOOLLIST',JSON.stringify(rowdata))
					let school_name='';let school_logo='';let digital_seal='';
					let schoolsrowdata=rowdatacolumn.map(dt=>{
						// if(dt.school_id===id){
						// 	school_name=dt.school_name;
						// 	school_logo=dt.school_logo_temp;
						// 	digital_seal=dt.digital_seal_temp;
						// }
						return dt;
					})	
					//console.log('schoolsrowdata=');
					//console.log(schoolsrowdata);
					//if(rowcount>0)
					{
						this.setState({listSchoolGroupSchools:schoolsrowdata},()=>{
						
						})
					}
					
				}
			})
		}
		this.handleChangCheck=(field,e)=>{
			// let override=(e.target.checked)?1:'0';
			// this.setState({[field]:override})
		}
		this.handleChangeText=(field,e)=>{
			this.setState({[field]:e.target.value})
			//this.getUserSchoolsAll(e.target.value);
		}
		this.handleYes=()=>{
			let keyword = this.state.keyword;
			this.getUserSchoolsAll(keyword);
			// let params={
			// 	to:this.state.email,
			// 	subject:this.state.subject,
			// 	content:this.state.content,
			// 	cc:this.state.cc,
			// }
			// agent.admin.send_mail_to_user(params).then(datas => {
			// //console.log(datas)
			// 	if(datas.status===true){
			// 		this.props.close();
				
			// 	}else{
			// 		this.setState({msg:datas.message},()=>{})
			// 	}
			// })
		
			//
		}
		//---
				
    } 
	
	
	
    componentWillReceiveProps(nextProps) {
		// console.log('nextProps=')
		// console.log(nextProps)
		if(nextProps.languageKey){
			//this.setState({lguages:nextProps.languageKey});
		}
		if(nextProps){
			this.setState({isOpen:nextProps.isOpen,
				listSchoolGroupSchools:nextProps.listSchoolGroupSchools
			});
			
		}
		
    }	
	componentWillUpdate(nextProps, nextState){
		
		
		
	}
	componentDidMount() {
		
		
		
		
	}
	componentWillMount() {
		//this.getUserSchoolsAll('');

		// const cookies = new Cookies();
		// let userInfo=cookies.get('USERLOG');
		// if(userInfo!==undefined && userInfo!==''){
		// 	//console.log(userInfo)
		// 	let fullname=userInfo.fname+", "+userInfo.lname;
		// 	let email=userInfo.email;
		// 	this.setState({fullname_log:fullname,email_log:email})
		// 	this.getUserSchoolsAll('');
		// }
		
	}
 
  render() {
	  // const oderInfo=this.props.orderParams;//console.log(oderInfo)
	  let allschools=this.state.listSchoolGroupSchools
	 // console.log(allschools)
        return (<>
			<Modal
			  show={this.state.isOpen}
			  onHide={this.hideModal}
			  aria-labelledby=""
			  id="large"
			  //className="custom-modal"				  
			  dialogClassName="custom-modal"	
			  style={{'width':'100% !important'}}	  
			>
			  <Modal.Header closeButton>
				<Modal.Title id=''>
				<div className="row">						
					<div className="col-lg-8 modal-worker-title">
						<span className="modal-detail-order-title cappital-txt"><span style={{'color':'#b81900'}}>Schools</span></span>
					</div>
					<div className="col-lg-4 modal-worker-title" style={{'display':'flex'}}><span className="modal-detail-order-title cappital-txt"><span></span></span>
					<input className="txt-hi" type="text"  placeholder="Enter any text" name="keyword" value={this.state.keyword} onChange={this.handleChangeText.bind(this,'keyword')}/>
					<button type="button" className="btn btn-primary btn-sa" onClick={this.handleYes.bind(this)}>Search</button>
					</div>					
				</div>
				</Modal.Title>
			  </Modal.Header>
			  <Modal.Body>
					<>	
						{
						(allschools !== null && allschools !== undefined) ? allschools.map((dt, i) => {							
							var row = dt;
							var column1 = dt.column1;
							var column2 = dt.column2;
							var column3 = dt.column3;
							var column4 = dt.column4;

									
							var groups_name = row.groups_name;	
							//const count = row.filter(item => item.groups_name === '0').length;
							//console.log('column1===================================');
							//console.log(column1);
							return (<div key={i}>
								{(groups_name !== null)?<div className="row" ><div className="col-xl-12" style={{'paddingTop':'30px','paddingBottom':'10px'}}><b>{groups_name}</b></div></div>
								:''}
								<div className="row">

								<div className="col-xl-3">
								{
								(column1 !== null && column1 !== undefined) ?
								column1.map((val, ii) => 	{									
									return(
									<div className="" key={ii}>
									<a style={{'display':'flex'}} href="#" onClick={this.handleChangSchool.bind(this,val.school_id)}>
										<span className="symbol symbol-20 mr-6">
											{
											(val.school_logo_temp!=='' && val.school_logo_temp!==null)?
											<img src={val.school_logo_temp} alt={val.school_name}/>:
											<Avatar size="26" round={true} className="avatar-title" color={'#00a2a7'} name={val.school_name} />
											}
										</span>
										<span className="navi-text" style={{whiteSpace:'inherit'}}>{val.school_name}</span>
									</a>																	
									</div>
								)									
								}): ''}		
								</div>
																
								<div className="col-xl-3">
								{
								(column2 !== null && column2 !== undefined) ?
								column2.map((val, ii) => 	{									
									return(
									<div className="" key={ii}>
									<a style={{'display':'flex'}} href="#" onClick={this.handleChangSchool.bind(this,val.school_id)}>
										<span className="symbol symbol-20 mr-6">
											{
											(val.school_logo_temp!=='' && val.school_logo_temp!==null)?
											<img src={val.school_logo_temp} alt={val.school_name}/>:
											<Avatar size="26" round={true} className="avatar-title" color={'#00a2a7'} name={val.school_name} />
											}
										</span>
										<span className="navi-text" style={{whiteSpace:'inherit'}}>{val.school_name}</span>
									</a>																	
									</div>
								)									
								}): ''}		
								</div>

								<div className="col-xl-3">
								{
								(column3 !== null && column3 !== undefined) ?
								column3.map((val, ii) => 	{									
									return(
									<div className="" key={ii}>
									<a style={{'display':'flex'}} href="#" onClick={this.handleChangSchool.bind(this,val.school_id)}>
										<span className="symbol symbol-20 mr-6">
										{
											(val.school_logo_temp!=='' && val.school_logo_temp!==null)?
											<img src={val.school_logo_temp} alt={val.school_name}/>:
											<Avatar size="26" round={true} className="avatar-title" color={'#00a2a7'} name={val.school_name} />
											}
										</span>
										<span className="navi-text" style={{whiteSpace:'inherit'}}>{val.school_name}</span>
									</a>																	
									</div>
								)									
								}): ''}		
								</div>

								<div className="col-xl-3">
								{
								(column4 !== null && column4 !== undefined) ?
								column4.map((val, ii) => 	{									
									return(
									<div className="" key={ii}>
									<a style={{'display':'flex'}} href="#" onClick={this.handleChangSchool.bind(this,val.school_id)}>
										<span className="symbol symbol-20 mr-6">
										{
											(val.school_logo_temp!=='' && val.school_logo_temp!==null)?
											<img src={val.school_logo_temp} alt={val.school_name}/>:
											<Avatar size="26" round={true} className="avatar-title" color={'#00a2a7'} name={val.school_name} />
											}
										</span>
										<span className="navi-text" style={{whiteSpace:'inherit'}}>{val.school_name}</span>
									</a>																	
									</div>
								)									
								}): ''}		
								</div>

								</div>
								</div>									
							)
							
						}) : ''
						}
						
					</>
					
					
					<div className="row">
						<div className="col-lg-12 lblMessage" id="lblMessage" style={{'color':'red'}}>{this.state.msg}</div>
					</div>
			  </Modal.Body>
			  <Modal.Footer>
					<div className="col-lg-12" style={{'textAlign':'right','padding':'0'}}> 
						<Button  type="button"  className="btn-default btn-sa" onClick={this.hideModal} style={{'marginRight':'5px'}}> Cancel</Button>						
					</div>
			  </Modal.Footer>
			</Modal>
			</>); 
	 //}
	//return null;
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(ModalErorr); 
//export default ModalComfirmOrder
