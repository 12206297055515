import React from 'react';
import { Link } from 'react-router-dom'
//import { Link,withRouter, history} from 'react-router-dom'
//import { DropdownMenu, MenuItem } from 'react-bootstrap-dropdown-menu';
import { connect } from 'react-redux'
import agent from '../agent'
import { Cookies } from 'react-cookie'
import {LOGIN,TOKEN_NAME,CHANGE_TAB, LOGOUT, APP_LOAD, JOIN_CLASS_DATA, LANGUAGE_KEY} from '../constants/actionTypes'
import '../styles/main.css'
import '../styles/responsive.css'
import {Dropdown} from 'react-bootstrap'
import $ from 'jquery';
import Avatar from 'react-avatar';
//import { datadogRum } from '@datadog/browser-rum';
import firebase from '../firebase'
import Sound from './Home/sound';
import ModalErorr from './common/ModalErorr';
import Menu from './language/menu.js';
import LocalizedStrings from 'react-localization';
import ReactLoading from 'react-loading';


/*datadogRum.init({
    applicationId: 'eeb5d8b0-7f98-4f9b-8590-f008d29b4db6',
    clientToken: 'pubd25436f14cdf16ad87f370051b533be5',
    site: 'datadoghq.com',
    service:'test.advantages-dls.info',
    // Specify a version number to identify the deployed version of your application in Datadog 
    // version: '1.0.0',
    sampleRate: 100,
    trackInteractions: true
});*/
const mapStateToProps = state => (
    Object.assign({}, state, {
		paramsSave: state.home,
		token: state.common.token,
		tab: state.common.tab,
		join_class_data: state.common.join_class_data,
	})      
);

const mapDispatchToProps = dispatch => ({  
	onLogin: (redirectTo, token) => {
		dispatch({ type: LOGIN, redirectTo, token})  
	},
	onTabClick: (tab) => 
		dispatch({ type: CHANGE_TAB, tab }),
	setToken: (data)=>{ 
		dispatch({ type: TOKEN_NAME, data })
	},
	setJoinClass: (data)=>{ 
		dispatch({ type: JOIN_CLASS_DATA, data })
	},
	onLoad: (access_token) =>
		dispatch({ type: APP_LOAD, access_token }),
	onLogout: () =>
		dispatch({type: LOGOUT}),
	onResetLanguesKey: (data) => {
		dispatch({ type: LANGUAGE_KEY, data})  
	},
});
let timer = null;
let sMenu=new LocalizedStrings(Menu);
let host = window.location.hostname;
let href = window.location.href.includes('/login') ? '' : window.location.href;

class Header extends React.Component {
	constructor(){
		super()
		this.state = {
			search:'',username:'',schools:[],school_logo:'',school_name:'',photo:'',digital_seal:'',
			gradebook: 0, attendance: 0, learning_path: 0, email: 0, resources: 0,joinClass:{},time_zone:'',
			dataRole:[],user_id:'',last_role:'',isOpenModal:false,msg:'',impersonate_data:'',
			impersonate_info:'',impersonate_role:'',listAccess:[],school_id:'',
			help_link:'https://advantages-dls.zendesk.com/hc/en-us',language:'en',dataLangues:[],
			viewcontract: false, updatecontract: false,viewinvoice: false, updateinvoice: false, payinvoice: false,
			supportmultilingual: false,loaderspiner: false,viewmessaging: false,readlearningpathtemplates: false,viewattendance: false,viewresources: false,viewgradebook: false,
		}
		//---
		this.close=()=>{
			this.setState({isOpenModal:false})
		}
		this.zendesksso=()=>{
			agent.admin.zendesksso({}).then(datas => {				
				if(datas){					
					window.open(datas, '_blank');
				}
					
			});
		}
		this.getUserSchools=(id)=>{
			agent.auth.getUserSchools({}).then(datas => {
				if(datas.status===true){
					//let results=datas.results;
					let results=datas.data;
					let school_name='';let school_logo='';let digital_seal='';let help_link='';let school_id='';
					let external_transcript_handler_url='';
					let external_transcript_handler_help='';
					let schools=results.map(dt=>{
						if(dt.school_id===id){
							school_id=dt.school_id;
							school_name=dt.school_name;
							school_logo=dt.school_logo;
							digital_seal=dt.digital_seal;
							help_link=dt.help_link;
							external_transcript_handler_url=dt.external_transcript_handler_url;
							external_transcript_handler_help=dt.external_transcript_handler_help;
							window.localStorage.setItem('external_transcript_handler_url',external_transcript_handler_url);
							window.localStorage.setItem('external_transcript_handler_help',external_transcript_handler_help);
						}
						return dt;
					})
					this.setState({schools:schools,school_name:school_name,school_id:school_id,school_logo:school_logo,digital_seal:digital_seal,help_link:help_link},()=>{
					
					})
				}
			})
		}
		this.unimpersonate=(e)=>{
			this.setState({loaderspiner:true})
			agent.admin.unimpersonate({impersonate_data:this.state.impersonate_data,}).then(datas => {
				if(datas.status===true){
					
					let UserInfo=datas.data;
					let last_role=parseInt(UserInfo.last_role);
					window.localStorage.setItem('last_role',last_role);
					const cookies = new Cookies()
					const expires_in = datas.expires_in / 60 / 60 / 24
					let dat = new Date()
					//dat.setDate(dat.getDate() + expires_in)	
					dat.setTime(dat.getTime() + (1*24*60*60*1000));	
					//cookies.set(TOKEN_NAME, UserInfo.token, {expires: dat})
					this.setCookie(TOKEN_NAME, UserInfo.token, 1)
					window.localStorage.setItem('TOKEN_NAME',UserInfo.token)
					cookies.set('USERLOG', UserInfo, {expires: dat})
					this.setCookie('USERLOG', UserInfo, 1)
					//console.log(UserInfo)
					//window.localStorage.setItem('USERLOG',JSON.stringify(UserInfo))
					let name_log=(last_role===1)?'USERLOGSUP':'USERLOG';
					window.localStorage.setItem(name_log,JSON.stringify(UserInfo));
					let name=UserInfo.fname;
					name=name.split(" ");
					window.localStorage.setItem('NAMEINFO',name[0])
					window.localStorage.setItem('EMAIL',UserInfo.email)
					//agent.setToken(UserInfo.token)
					//this.props.onLogin('home',UserInfo.token);	
					//this.setState({school_name:UserInfo.school_name})	
					//this.getUserSchools(school_id);
					//this.get_user_role();
					//window.location.href = '/home';
					this.setState({loaderspiner:false})
					//setTimeout(() => {
						   var url=window.localStorage.getItem('url_before_imper');
						  url=(url!=undefined && url!='')?url:'/user-directory';
						  window.location.href = url;
						//},1000)
						
					window.localStorage.setItem('language','');
					
				}else{
					this.setState({msg: datas.message,isOpenModal:true,loaderspiner:false})
					setTimeout(() => {
						  window.localStorage.setItem('language','');
						  window.location.href = '/login';
						},7000)
						
				}
					
			});
		}
		
		this.changeSchool=(school_id,e)=>{
			let host=window.location.hostname
			host= host.split(".")
			let hosti=(host!==undefined && host!==null && host[0]!==undefined)?host[0]:'';
			let role = (hosti === "teacher") ? 3 : (hosti === "sadmin" || hosti === "portal") ? 2 : (hosti === "sa") ? 1 : 2;
			agent.auth.changeSchool({school_id:school_id,role:role}).then(datas => {
				//console.log(datas)
				if(datas.status===true){
					
					let UserInfo=datas.data;
					let last_role=parseInt(UserInfo.last_role);
					window.localStorage.setItem('last_role',last_role);
					/*if(last_role==2 && hosti!="sadmin" && hosti!="localhost" && hosti!='staging'){
						let sso_portal=UserInfo.sso_portal;
						let a= document.createElement('a');
						a.href= sso_portal;
						a.click();
					}else{*/
						const cookies = new Cookies()
						const expires_in = datas.expires_in / 60 / 60 / 24
						let dat = new Date()
						//dat.setDate(dat.getDate() + expires_in)	
						dat.setTime(dat.getTime() + (1*24*60*60*1000));	
						//cookies.set(TOKEN_NAME, UserInfo.token, {expires: dat})
						this.setCookie(TOKEN_NAME, UserInfo.token, 1)
						window.localStorage.setItem('TOKEN_NAME',UserInfo.token)
						cookies.set('USERLOG', UserInfo, {expires: dat})
						//this.setCookie('USERLOG', UserInfo, 1)
						//console.log(UserInfo)
						//window.localStorage.setItem('USERLOG',JSON.stringify(UserInfo))
						let name_log=(last_role===1)?'USERLOGSUP':'USERLOG';
						window.localStorage.setItem(name_log,JSON.stringify(UserInfo));
						let name=UserInfo.fname;
						name=name.split(" ");
						window.localStorage.setItem('NAMEINFO',name[0])
						window.localStorage.setItem('EMAIL',UserInfo.email)
						agent.setToken(UserInfo.token)
						this.props.onLogin('home',UserInfo.token);	
						this.setState({school_name:UserInfo.school_name})	
						this.getUserSchools(school_id);
						this.get_user_role();
						window.location.href = '/home';
					//}
				}else{
					this.setState({msg: datas.message,isOpenModal:true})
				}
					
			});
		}
		
		this.setDevice=(token,e)=>{
			//console.log(school_id)
			agent.auth.setDevice({token:token,type:'web'}).then(datas => {
				//console.log(datas)
				if(datas.status===true){
					//console.log(datas)
				}
					
			});
		}
		this.getTwentyFourHourTime=(amPmString)=> { 
			var d = new Date("1/1/2013 " + amPmString); 
			return d.getHours() + ':' + d.getMinutes(); 
		}
		this.setCookie=(name,value,days)=> {
			const cookies = new Cookies()
			var expires = "";
			if (days) {
				var date = new Date();
				date.setTime(date.getTime() + (days*24*60*60*1000));
				//date.setTime(date.getTime() + (days*1*2*60*1000));
				expires = "; expires=" + date.toUTCString();
				console.log(date)
				cookies.set(name, value, {expires: date})
			}
			//document.cookie = name + "=" + (value || "")  + expires + "; path=/";
			
		}
		/*this.user_change_role_bk=(role,e)=>{
			agent.auth.user_change_role({role:role}).then(datas => {
				//console.log(datas)
				if(datas.status===true){
					let UserInfo=datas.data;
					if(parseFloat(role)===4 || parseFloat(role)===5 ){
						const cookies = new Cookies()
						//const expires_in = data.expires_in / 60 / 60 / 24
						let dat = new Date()
						//dat.setDate(dat.getDate() + expires_in)	
						dat.setTime(dat.getTime() + (1*24*60*60*1000));	
						//cookies.set(TOKEN_NAME, UserInfo.token, {expires: dat})
						this.setCookie(TOKEN_NAME, UserInfo.token, 1)
						window.localStorage.setItem('TOKEN_NAME',UserInfo.token)
						cookies.set('USERLOG', UserInfo, {expires: dat})
						//this.setCookie('USERLOG', UserInfo, 1)
						//console.log(UserInfo)
						window.localStorage.setItem('USERLOG',JSON.stringify(UserInfo))
						let name=UserInfo.fname;
						name=name.split(" ");
						window.localStorage.setItem('NAMEINFO',name[0])
						window.localStorage.setItem('EMAIL',UserInfo.email)
					
						window.location.reload();
					}else if( parseFloat(role)===3){
						//this.auto_login(UserInfo.token);
						let url='https://teacher.adlsassist.com/login?token='+UserInfo.token;
						let a= document.createElement('a');
						a.href= url;
						a.click();
					}else{
						let sso_portal=UserInfo.sso_portal;
						let a= document.createElement('a');
						a.href= sso_portal;
						a.click();
					}
					
				}
					
			});
		}
		*/
		this.user_change_role=(role,e)=>{
			let host=window.location.hostname
			host= host.split(".")
			let hosti=(host!==undefined && host!==null && host[0]!==undefined)?host[0]:''
			//console.log(role,host)	
			
			agent.auth.user_change_role({role:role}).then(datas => {
				//console.log(datas)
				if(datas.status===true){
					let UserInfo=datas.data;
					let last_role=parseInt(UserInfo.last_role);
					window.localStorage.setItem('last_role',last_role);
					/*if(parseFloat(role)==2 && hosti!="sadmin" && hosti!="localhost" && hosti!='staging'){
						let sso_portal=UserInfo.sso_portal;
						let a= document.createElement('a');
						a.href= sso_portal;
						a.click();
					}else{*/
						const cookies = new Cookies()
						//const expires_in = data.expires_in / 60 / 60 / 24
						let dat = new Date()
						//dat.setDate(dat.getDate() + expires_in)	
						dat.setTime(dat.getTime() + (1*24*60*60*1000));	
						//cookies.set(TOKEN_NAME, UserInfo.token, {expires: dat})
						this.setCookie(TOKEN_NAME, UserInfo.token, 1)
						window.localStorage.setItem('TOKEN_NAME',UserInfo.token)
						cookies.set('USERLOG', UserInfo, {expires: dat})
						//this.setCookie('USERLOG', UserInfo, 1)
						//console.log(UserInfo)
						//window.localStorage.setItem('USERLOG',JSON.stringify(UserInfo))
						let name_log=(last_role===1)?'USERLOGSUP':'USERLOG';
						window.localStorage.setItem(name_log,JSON.stringify(UserInfo));
						let name=UserInfo.fname;
						name=name.split(" ");
						window.localStorage.setItem('NAMEINFO',name[0])
						window.localStorage.setItem('EMAIL',UserInfo.email)
					
						window.location.href = '/home';
					//}
				}
					
			});
		}
		
		
		
		this.auto_login=(token)=>{
			agent.auth.auto_login(token).then(data => {
				//console.log(data)
				if(data.status === true){
					let UserInfo=data.data;
					let last_role=parseInt(UserInfo.last_role);
					window.localStorage.setItem('last_role',last_role);
					const cookies = new Cookies()
					const expires_in = data.expires_in / 60 / 60 / 24
					let dat = new Date()
					//dat.setDate(dat.getDate() + expires_in)	
					dat.setTime(dat.getTime() + (1*24*60*60*1000));	
					//cookies.set(TOKEN_NAME, UserInfo.token, {expires: dat})
					this.setCookie(TOKEN_NAME, UserInfo.token, 1)
					window.localStorage.setItem('TOKEN_NAME',UserInfo.token)
					cookies.set('USERLOG', UserInfo, {expires: dat})
					//this.setCookie('USERLOG', UserInfo, 1)
					//console.log(UserInfo)
					//window.localStorage.setItem('USERLOG',JSON.stringify(UserInfo))
					let name_log=(last_role===1)?'USERLOGSUP':'USERLOG';
					window.localStorage.setItem(name_log,JSON.stringify(UserInfo));
					let name=UserInfo.fname;
					name=name.split(" ");
					window.localStorage.setItem('NAMEINFO',name[0])
					window.localStorage.setItem('EMAIL',UserInfo.email)
					agent.setToken(UserInfo.token)
					this.props.onLogin('home',UserInfo.token);
						
					window.location.href = '/home'
				}
			})
		}
		this.get_user_role=(token,e)=>{
			//console.log(school_id)
			agent.auth.get_user_role({}).then(datas => {
				//console.log(datas)
				if(datas.status===true){
					//console.log(datas)
					this.setState({dataRole:datas.data})
				}
					
			});
		}
		this.get_content_roles_sso=()=>{
			agent.admin.get_content_roles_sso({}).then(datas => {
				//console.log(datas)
				if(datas.status===true){
					let data=datas.data;
					this.setState({listAccess:data})
				}
					
			});
		}
		this.log_sso=(url,e)=>{
			let a= document.createElement('a');
			a.target= '_blank';
			a.href= url;
			a.click();
		}
		this.get_configuration=()=>{
			agent.admin.get_configuration({}).then(datas => {
				//console.log(datas)
				if(datas.status===true){
					let data=datas.data;
					if(!data) return;
					let user_settings=data.user_settings;
					if(user_settings!=undefined && user_settings!=null){
						window.localStorage.setItem('user_settings',JSON.stringify(user_settings));
					}
					let viewcontract = false;
					let updatecontract = false;
					let viewinvoice = false;
					let updateinvoice = false;
					let payinvoice = false;
					let supportmultilingual = false;
					let viewmessaging = false;
					let readlearningpathtemplates = false;
					let viewattendance = false;
					let viewresources = false;
					let viewgradebook = false;
					if (user_settings != '' && user_settings != undefined && user_settings != null){
						viewcontract = user_settings.hasOwnProperty('viewcontract') ? user_settings.viewcontract : false;
						updatecontract = user_settings.hasOwnProperty('updatecontract') ? user_settings.updatecontract : false;
						viewinvoice = user_settings.hasOwnProperty('viewinvoice') ? user_settings.viewinvoice : false;
						updateinvoice = user_settings.hasOwnProperty('updateinvoice') ? user_settings.updateinvoice : false;
						payinvoice = user_settings.hasOwnProperty('payinvoice') ? user_settings.payinvoice : false;
						viewmessaging = user_settings.hasOwnProperty('viewmessaging') ? user_settings.viewmessaging : false;
						readlearningpathtemplates = user_settings.hasOwnProperty('readlearningpathtemplates') ? user_settings.readlearningpathtemplates : false;
						viewattendance = user_settings.hasOwnProperty('viewattendance') ? user_settings.viewattendance : false;
						viewresources = user_settings.hasOwnProperty('viewresources') ? user_settings.viewresources : false;
						viewgradebook = user_settings.hasOwnProperty('viewgradebook') ? user_settings.viewgradebook : false;
					}
					this.setState({
						viewcontract: viewcontract,
						updatecontract: updatecontract,
						viewinvoice: viewinvoice,
						updateinvoice: updateinvoice,
						payinvoice: payinvoice,
						supportmultilingual: supportmultilingual,
						viewmessaging: viewmessaging,
						readlearningpathtemplates: readlearningpathtemplates,
						viewattendance: viewattendance,
						viewresources: viewresources,
						viewgradebook: viewgradebook,
					}, () => {})
					
				}else{
					if (host != 'localhost') {
						window.sessionStorage.setItem('previous_href', href);
						window.location.href = '/login'
					}
				}
			})
		}
		//--
		this.toggle=(e)=>{
			$(".b").toggle();
		}
		this.get_languages=()=>{
			let language=this.state.language;
			agent.home.get_languages({}).then(datas => {
				if(datas.status===true){
					//console.log(datas)
					this.setState({dataLangues:datas.data},()=>{
						let thiss=this;
						//==== Start Language Drowdown Js
						var langArray = [];
						var langArrayChoi = [];
						$('.vodiapicker option').each(function(){
							var img = $(this).attr("datathumbnail");
							var text = this.innerText;
							var value = $(this).val();
							var item = '<li><img src="'+ img +'" alt="" value="'+value+'"/><span>'+ text +'</span></li>';
							langArray.push(item);
							if(language==value){
								langArrayChoi.push(item);
								//console.log(langArrayChoi)
							}
						})
						$('#a').html(langArray);
						$('.btn-select').html(langArrayChoi[0]);
						$('.btn-select').attr('value', language);
						$('#a li').click(function(){
							var img = $(this).find('img').attr("src");
							var value = $(this).find('img').attr('value');
							var text = this.innerText;
							var item = '<li><img src="'+ img +'" alt="" /><span>'+ text +'</span></li>';
							$('.btn-select').html(item);
							$('.btn-select').attr('value', value);
							thiss.handleLanguageChange(value);
							$(".b").toggle();
							//alert(value)
						});

						$(".btn-select").click(function(){
							$(".b").toggle();
						});

						var sessionLang = localStorage.getItem('lang');
						if (sessionLang){
						  var langIndex = langArray.indexOf(sessionLang);
						  $('.btn-select').html(langArray[langIndex]);
						  $('.btn-select').attr('value', sessionLang);
						} else {
						  var langIndex = langArray.indexOf('ch');
						  //console.log(langIndex);
						  $('.btn-select').html(langArray[langIndex]);
						}
						//==== End Language Drowdown Js
					})
				}
					
			});
		}
		this.handleLanguageChange=(lang)=>{
			//console.log(lang)
			this.setState(prevState => ({
			  language: lang
			}))
			window.localStorage.setItem('language',lang);
			this.save_language(lang);
		}
		this.save_language=()=>{
			agent.home.save_language({language:this.state.language}).then(datas => {
				if(datas.status===true){
					this.props.onResetLanguesKey(this.state.language);
				}
			})
		}
		//--
	}
	onWindowFocus(){
		//console.log("tetst focus")
	}
	onWindowBlur(){
		//alert("tetst blur")
	}
	//---------------------------
	 
	componentWillReceiveProps(nextProps) {
		//console.log(nextProps)
		if (nextProps.common.redirectTo) {
		  const token = window.localStorage.getItem(TOKEN_NAME)
		  const nextRedirect = nextProps.common.redirectTo
		  if(token && nextRedirect === '/login' && nextProps.history!=undefined){ // login page
			nextProps.history.push('/')	
		  } else if(token && nextProps.history!=undefined){       		
			nextProps.history.push(nextProps.common.redirectTo)                  
		  } else if(nextProps.history!=undefined){
			nextProps.history.push('/login') 
		  }
		  
		 // this.props.onRedirect();
		}
		if(nextProps.join_class_data!==undefined && nextProps.join_class_data!==''&& nextProps.join_class_data!==null){
			let data=nextProps.join_class_data;
			//console.log(this.getTwentyFourHourTime(data.start_time))
			let stime=this.getTwentyFourHourTime(data.start_time);
			let start_time=stime.split(":")
			let sh=start_time[0];
			let sm=start_time[1];
			let start_mi=(parseFloat(sh)*60)+parseFloat(sm)
			data.start_mi=start_mi
			
			let etime=this.getTwentyFourHourTime(data.end_time);
			let end_time=etime.split(":")
			let eh=end_time[0];
			let em=end_time[1];
			let end_mi=(parseFloat(eh)*60)+parseFloat(em)+1
			data.end_mi=end_mi
			
			let currentDate=new Date();
			let timezone=(this.state.time_zone!=undefined && this.state.time_zone!='undefined' && this.state.time_zone!=null && this.state.time_zone!='')?this.state.time_zone:"UTC";
			//console.log(timezone)
			var tzd=currentDate.toLocaleString('en-US', { timeZone: timezone });
			let chour=new Date(tzd).getHours();
			let cmi=new Date(tzd).getMinutes();
			//let chour=currentDate.getHours();
			//let cmi=currentDate.getMinutes();
			let ctime=(chour*60)+cmi	
			data.cur_mi=ctime;	
			//data.checkMi=(ctime>=start_mi && ctime<=end_mi)?true:false
			data.checkMi=(ctime<=end_mi)?true:false
			//data.checkSound=(ctime<=end_mi)?true:false
			data.isSound=(ctime<=end_mi)?true:false
			
			this.setState({joinClass:data},()=>{
				$('.alert-join').fadeIn();
				$('.mu_alert_box').fadeIn();
				let thiss=this;
				
				setTimeout(()=>{
					let a= document.createElement('button');										
					a.click();
					timer=setTimeout(()=>{
						thiss.callJoinClass(data)
					},10000);
				},8000)
										
			})
		}else if(nextProps.join_class_data===null){
			this.setState({joinClass:{}})
		}
		
	}	
	isIpadPro() {
		var ratio = window.devicePixelRatio || 1;
		var screen = {
			width : window.screen.width * ratio,
			height : window.screen.height * ratio
		};
		//alert((screen.width === 2048 && screen.height === 2732) || (screen.width === 2732 && screen.height === 2048) || (screen.width === 1536 && screen.height === 2048) || (screen.width === 2048 && screen.height === 1536))
		//return (screen.width === 2048 && screen.height === 2732) || (screen.width === 2732 && screen.height === 2048) || (screen.width === 1536 && screen.height === 2048) || (screen.width === 2048 && screen.height === 1536);
		let isIOS = ((/iPad|iPhone|iPod/.test(navigator.platform) ||
						(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
						!window.MSStream)
		alert(isIOS+"12")
	}
	componentWillMount() {
		//this.isIpadPro();
		//-----------------------
		this.get_configuration();
		const cookies = new Cookies()
		//const token = cookies.get(TOKEN_NAME);
		const token=window.localStorage.getItem('TOKEN_NAME');
		if(token && token!==undefined  && token!==null && token!==''){			
			agent.setToken(token)
			//const data = JSON.parse(token)
			this.props.onLoad( token) 
			//this.props.onLoad( data.UserInfo , data.access_token)       
		}else{
			window.location.href='/login';
		}
		//let userInfo=cookies.get('USERLOG');console.log(userInfo)
		let userInfo=window.localStorage.getItem('USERLOG');
		userInfo=JSON.parse(userInfo);
		// console.log(userInfo)
		let languages=window.localStorage.getItem('language');
		let host=window.location.hostname
		host= host.split(".")
		let hosti=(host!==undefined && host!==null && host[0]!==undefined)?host[0]:''
		
		if(userInfo!==undefined && userInfo!=='' && userInfo!==null){
			let fname=userInfo.fname
			let lname=userInfo.lname
			let mname=userInfo.mname
			let photo=userInfo.photo
			let time_zone=userInfo.time_zone;
			let user_id=userInfo.user_id;
			let last_role=userInfo.last_role;
			let impersonate_data=userInfo.impersonate_data
			let impersonate_info=userInfo.impersonate_info
			let impersonate_role=userInfo.impersonate_role;
			let language=userInfo.language;
			
			//console.log(userInfo)
			if(languages!=undefined && languages!=null && languages!='' && (hosti=='staging' || hosti=="localhost")){
				this.setState({language:languages},()=>{
					this.get_languages();
					window.localStorage.setItem('language',languages);
				});
			}else if(language!=undefined && language!=null && language!='' && (hosti=='staging' || hosti=="localhost")){
				this.setState({language:language},()=>{
					this.get_languages();
					window.localStorage.setItem('language',language);
				});
			}else{
				this.get_languages();
			}
			
			this.setState({
				username: lname + ", " + fname + " " + mname, photo: photo, time_zone: time_zone, user_id: user_id, last_role: last_role,
				impersonate_data: impersonate_data,
				impersonate_info: impersonate_info,
				impersonate_role: impersonate_role,
			}, () => {
				this.getUserSchools(parseInt(userInfo.last_school_id));
				this.get_user_role();
				//this.get_content_roles_sso();
			})
			let hosts=window.location.hostname
			hosts= hosts.split(".")
			let hostis=(hosts!==undefined && hosts!==null && hosts[0]!==undefined)?hosts[0]:''
			if(hostis!="portal" && hostis!="teacher"){
				this.get_content_roles_sso();
			}	
		}else{
			window.location.href='/login'
		}
		
		
		
		
	}	
	
	componentDidMount() {
		let isSafari = navigator.vendor.match(/apple/i) &&
             !navigator.userAgent.match(/crios/i) &&
             !navigator.userAgent.match(/fxios/i) &&
             !navigator.userAgent.match(/Opera|OPT\//);

			if (isSafari) {
			  console.log("Safari browser is used");
			} else {
			  console.log("Other browser is used")
			}

		let isIOS = ((/iPad|iPhone|iPod/.test(navigator.platform) ||
						(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) &&
						!window.MSStream);
						
		
		let thiss=this;
		try {
			const isSupportedBrowser = firebase.messaging.isSupported();
			if (!isIOS && !isSafari && isSupportedBrowser) {
					//if(!isIOS && !isSafari){
			
						const messaging = firebase.messaging();
						 messaging
						   .requestPermission()
						   .then(function () {
							// MsgElem.innerHTML = "Notification permission granted." 
							 console.log("Notification permission granted.");

							 // get the token in the form of promise
							 return messaging.getToken()
						   })
						   .then(function(token) {
							 // print the token on the HTML page
							// TokenElem.innerHTML = "Device token is : <br>" + token
							thiss.setDevice(token)
							//console.log(token)
						   })
						   .catch(function (err) {
						   //ErrElem.innerHTML = ErrElem.innerHTML + "; " + err
						   console.log("Unable to get permission to notify.", err);
						 });
					 
					
						let enableForegroundNotification = true;
						messaging.onMessage(function (payload) {
							console.log('Message received. ', payload);
							let data=payload.data
							if(data.notification_type==='join_class'){
								thiss.props.setJoinClass(data);
								window.localStorage.setItem('join_class',JSON.stringify(data))
							}
							//NotisElem.innerHTML =
							   // NotisElem.innerHTML + JSON.stringify(payload);

						   /* if (enableForegroundNotification) {
								let notification = payload.notification;
								navigator.serviceWorker
									.getRegistrations()
									.then((registration) => {
										registration[0].showNotification(notification.title);
									});
							}*/
						});
						
						window.addEventListener('push', function (event) {

							console.log("event:push",event)
						})
						
					//}
					
		
				}else{
					
					console.log('Firebase not supported this browser');
					return null;
				}
				
			} catch (err) {
				console.log(err);
				return null;
			}
	
		/*messaging.getToken({ vapidKey: 'BKRvEvE42CDCOEvuI_ynr1Oo5xxe3sU9OVkG6ZjfeQB_cgbwk9Iw7xvKtKb37p9WHjAfQS4s1GHvO2hk8GOE8x0' }).then((currentToken) => {
			if (currentToken) {
			  // Send the token to your server and update the UI if necessary
			  console.log(currentToken)

			} else {
			  // Show permission request UI
			  console.log('No registration token available. Request permission to generate one.');
			  // ...
			}
		  }).catch((err) => {
			console.log('An error occurred while retrieving token. ', err);
			// ...
		  });*/
			

   
   
   
   
		
		
		//==== Start Mobile menu js 
		$('.mobile-menu-icon').click(function(){
		  $('.mobile_main_menu_content .mu_accordion').slideToggle();
		});
		//==== End Mobile menu js 

		//==== Start Search Button Dropdown
		$('.mu_search_bar form button').click(function(){
		  $('.mu_dropdown').toggle();
		  return false;
		});
		$('body').click(function() {
		 $('.mu_dropdown').hide();
		});
		//==== End Search Button Dropdown

		//==== Start Alert Box Js
		$('.mu_alert_box_right a').click(function(){
		  $('.mu_alert_box').fadeOut();
		  return false;
		});
		$('.custom_alert_right a').click(function(){
		  $('.custom_alert_design').fadeOut();
		  return false;
		});
		//==== End Alert Box Js

		//==== Start Mobile Main Menu Js 
		$('.mobile-menu-icon').click(function(){
		  $('.header-bottom-content ul').slideToggle();
		});
		$('.mu_accordion').click(function(){
		  $('.mu_accordion').slideToggle();
		})
		//==== End Mobile Main Menu Js 

		//==== Start Progress Tracking Table Accordion Icon Js
		$(".rjdesign").click(function(e){
		  $(this).toggleClass("active");
		  e.preventDefault();
		});
		//==== End Progress Tracking Table Accordion Icon Js

		//==== Start Hide/Show Password Js
		$(".toggle-password").click(function() {
		  $(this).toggleClass("fa-eye fa-eye");
		  var input = $($(this).attr("toggle"));
		  if (input.attr("type") == "password") {
			input.attr("type", "text");
		  } else {
			input.attr("type", "password");
		  }
		});
		//==== Start Hide/Show Password Js

		//==== Start Recommendation Option Content Js
		$(".single-request-recommendation-option-content").click(function(){
		  $(".single-request-recommendation-option-content").removeClass("show");
		  $(this).addClass('show');
		});
		$(".request-recommendation-option-content:nth-child(2) .single-request-recommendation-option-area").click(function(){
		  $(".cross-button-image").toggle();
		});
		//==== End Recommendation Option Content Js

		//==== Start Popup Custom Js
		$(".st_single_action a").click(function(){
		  // return false;
		});
		$(".action_st_btn_img a.enrollment-show").click(function(){
		  $(".request-enrollment-area").fadeIn(10);
		});
		$(".same-close-button-left button,.single-same-button-design button.cancel").click(function(){
		  $(".request-enrollment-area").fadeOut(10);
		});

		$(".action_st_btn_img a.resources").click(function(){
		  $(".resources-area").fadeIn(10);
		});
		$(".same-close-button-left button").click(function(){
		  $(".resources-area").fadeOut(10);
		});

		$(".action_st_btn_img a.transcript").click(function(){
		  $(".request-transcript-area").fadeIn(10);
		});
		$(".same-close-button-left button").click(function(){
		  $(".request-transcript-area").fadeOut(10);
		});

		$(".action_st_btn_img a.recommendation").click(function(){
		  $(".request-recommendation-area").fadeIn(10);
		});
		$(".same-close-button-left button,.single-same-button-design button.cancel").click(function(){
		  $(".request-recommendation-area").fadeOut(10);
		});
		//==== Start Language Drowdown Js
		var langArray = [];
		$('.vodiapicker option').each(function(){
			var img = $(this).attr("data-thumbnail");
			var text = this.innerText;
			var value = $(this).val();
			var item = '<li><img src="'+ img +'" alt="" value="'+value+'"/><span>'+ text +'</span></li>';
			langArray.push(item);
		})
		$('#a').html(langArray);
		$('.btn-select').html(langArray[0]);
		$('.btn-select').attr('value', 'en');
		$('#a li').click(function(){
			var img = $(this).find('img').attr("src");
			var value = $(this).find('img').attr('value');
			var text = this.innerText;
			var item = '<li><img src="'+ img +'" alt="" /><span>'+ text +'</span></li>';
			$('.btn-select').html(item);
			$('.btn-select').attr('value', value);
			thiss.handleLanguageChange(value);
			$(".b").toggle();
		});

		$(".btn-select").click(function(){
			$(".b").toggle();
		});

		var sessionLang = localStorage.getItem('lang');
		if (sessionLang){
		  var langIndex = langArray.indexOf(sessionLang);
		  $('.btn-select').html(langArray[langIndex]);
		  $('.btn-select').attr('value', sessionLang);
		} else {
		  var langIndex = langArray.indexOf('ch');
		  //console.log(langIndex);
		  $('.btn-select').html(langArray[langIndex]);
		}
		//==== End Language Drowdown Js
		
		

	}
	componentWillUnmount() {
	  //window.removeEventListener('resize', this.handleWindowSizeChange);
	  clearTimeout(timer);
	}
	callJoinClass(join_class_data){
		let data=join_class_data
		//console.log(join_class_data)
		if(data!==undefined){
		//let stime=this.convert_to_24h(data.start_time);
		let stime=this.getTwentyFourHourTime(data.start_time);
		let start_time=stime.split(":")
			let sh=start_time[0];
			let sm=start_time[1];
			let start_mi=(parseFloat(sh)*60)+parseFloat(sm)
			data.start_mi=start_mi
			
			//let etime=this.convert_to_24h(data.end_time);
			let etime=this.getTwentyFourHourTime(data.end_time);
			let end_time=etime.split(":")
			let eh=end_time[0];
			let em=end_time[1];
			let end_mi=(parseFloat(eh)*60)+parseFloat(em)+1
			data.end_mi=end_mi
			
			let currentDate=new Date();
			let timezone=(this.state.time_zone!=undefined && this.state.time_zone!='undefined' && this.state.time_zone!==null && this.state.time_zone!=='')?this.state.time_zone:"UTC"
			var tzd=currentDate.toLocaleString('en-US', { timeZone: timezone });
			let chour=new Date(tzd).getHours();
			let cmi=new Date(tzd).getMinutes();	
			//let chour=currentDate.getHours();
			//let cmi=currentDate.getMinutes();
			let ctime=(chour*60)+cmi	
			data.cur_mi=ctime;	
			//data.checkMi=(ctime>=start_mi && ctime<=end_mi)?true:false
			data.checkMi=(ctime<=end_mi)?true:false
			//data.checkSound=(ctime<=end_mi)?true:false
			data.isSound=(ctime<=end_mi)?true:false
			data.checkSound=false;
			this.setState({joinClass:data},()=>{
				//$('.alert-join').fadeIn();
				//$('.mu_alert_box').fadeIn();
				let thiss=this;
				timer=setTimeout(()=>{
					//thiss.callJoinClass(data)
				},3 * 60 * 1000);
				
				setTimeout(()=>{
					let a= document.createElement('button');										
					a.click();
				},6000)
										
			})
		}
	}
	handleChange(field, e){
		this.setState({[field]: e.target.value})
	}
	handleDoLogout(){
		/*var cookies = document.cookie.split(";");

		for (var i = 0; i < cookies.length; i++) {
			var cookie = cookies[i];
			var eqPos = cookie.indexOf("=");
			var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
			document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
		}*/
		agent.auth.logout({}).then(datas => {
			//console.log(datas)
		})
		let pathname=window.localStorage.getItem('pathname');
		const cooki = new Cookies()
		cooki.remove(TOKEN_NAME)
		this.props.onLogout()	
		//console.log(pathname)
		
		window.localStorage.setItem('join_class','');
		window.localStorage.setItem('USERLOG','');
		window.localStorage.setItem('TOKEN_NAME','');
		if(pathname!==undefined && pathname!=='' && pathname!==null){
			window.location.href = '/login'+pathname
		}else{
			window.location.href = '/login'
		}
		window.localStorage.setItem('language','');
		
	}
	generateColor () {
		return '#' +  Math.random().toString(16).substr(-6);
	}

	render() {
		const token = this.props.token
		const tab = this.props.tab
		let schools=this.state.schools
		let school_logo=this.state.school_logo
		let school_name=this.state.school_name
		let school_id=this.state.school_id
		let photo=this.state.photo
		let username=this.state.username;
		let impersonate_data=this.state.impersonate_data;
		//console.log(school_logo)
		let joinClass=this.state.joinClass;
		//console.log(joinClass)
		if(joinClass!==undefined && joinClass!==''){
			
			//console.log(joinClass.checkSound,joinClass.checkMi)
		}
		
		let dataRole=this.state.dataRole
		let listAccess=this.state.listAccess
		let help_link=this.state.help_link;
		let dataLangues=this.state.dataLangues;
		let supportmultilingual=this.state.supportmultilingual;
		sMenu.setLanguage(this.state.language);
		let host=window.location.hostname
		host= host.split(".")
		let hosti=(host!==undefined && host!==null && host[0]!==undefined)?host[0]:'';
		let satusschools=['Completed','Dropped-financial','Dropped-inactivity','Expelled','Graduated','Inactive','Withdrawn','Transferred','Visibility','Temp Lock: Testing','Completed-Financial Hold','Hold']
		if(hosti!="localhost" && hosti!="staging" && hosti!="sa" && dataRole!==undefined && dataRole!==null && dataRole.length>1){
			dataRole=dataRole.filter(ti=>ti.role!=1);
		}else{
			dataRole=dataRole;
		}
		//---------
			return (
				<header className="header-area">
					
					<div className="header-top-area">
						<div className="container">
							<div className={(this.state.language=='ar')?'header-top-content arabic':'header-top-content'}>
								<div className={(this.state.language=='ar')?'header-top-left-content arabic':'header-top-left-content'}>
									{
										//(joinClass!==undefined && joinClass.isSound!==undefined && joinClass.isSound===true)?
										(joinClass!==undefined && joinClass.checkSound!==undefined && joinClass.checkSound===true)?
										<Sound />:''
										//<Sound />
									}
									<div className="header-logo-dropdown">
										
										<Dropdown className={(this.state.language=='ar')?"arabic":""}>
										  <Dropdown.Toggle variant="success" id="dropdownMenuButton" className="dropdown-toggle shfocus logo-sch">
										  {
											(school_logo!=='')?<img className="h-80" src={(school_logo!=='')?school_logo:"/media/home/logo.png"} alt="Images" />:
											 <Avatar size="48" round={true} className="avatar-title" 
												color={'#00a2a7'} name={this.state.school_name} />
											}
											
												<h6 className="ml-3 mb-0 max-w-150px text-truncate">{school_name} </h6>
											
												<div className="down-icon"><img src="/media/home/down-arrow.png" alt="Images" /></div>
										  </Dropdown.Toggle>

										  <Dropdown.Menu className="dropdown-menu">
										  {
											  (schools!==null && schools!==undefined)?schools.map((dt,i)=>{
												  let ustatus=dt.user_status;
												  let message_status=(ustatus!=undefined && ustatus!=null && ustatus!='' && ustatus.message_status!=undefined)?ustatus.message_status:''
												  let status=(ustatus!=undefined && ustatus!=null && ustatus!='')?ustatus.status:''
												  let filschool=satusschools.filter(dt=>dt==status)
												   //console.log(message_status)
												  //if(filschool!=undefined && filschool!=null && filschool.length==0){
													  return(
														<Dropdown.Item href="" className={(parseFloat(dt.school_id)==parseFloat(school_id))?"dropdown-item widths active":"dropdown-item widths"} key={i} onClick={this.changeSchool.bind(this,dt.school_id)} >
															<div>
																<span className="symbol symbol-20 mr-6">
																	<img src={dt.school_logo} alt=""/>
																</span>
																<span className="navi-text-school">{dt.school_name}</span>
																<span className="navi-status-scholl" style={{'color':(ustatus.access==true && ustatus!=undefined && ustatus!=null && ustatus!='')?'green':'red'}}>({status})</span>
																{(message_status!=undefined && message_status!=null && message_status!='')?<span className="massage-status"></span>:''}
															</div>
															{/*<span className="shoo-status-mes">{message_status}</span>*/}
														</Dropdown.Item>
													  )
												  //}
											  }):''
										  }
											
										  </Dropdown.Menu>
										</Dropdown>
										
									</div>
									
									
									{/*<div className="header-flag-dropdown" style={{'display':'none'}}>
										<select className="vodiapicker">
											<option value="en" className="test" data-thumbnail="/media/home/flag.png">English</option>
											<option value="en" className="test" data-thumbnail="/media/home/us.png">Us</option>
										</select>
										<div className="lang-select">
											<button className="btn-select" value=""></button>
											<div className="b">
												<ul id="a"></ul>
											</div>
										</div>
									</div>*/}
								</div>

								<div className={(this.state.language=='ar')?'header-top-right-content arabic':'header-top-right-content'}>
									{/*Start Custom Flug Select (hosti!='staging' && hosti!="localhost")*/}
									<div className="header-flag-dropdown" style={{'display':(supportmultilingual!=true)?'none':''}}>
										<select className="vodiapicker">
										{
											(dataLangues!=undefined && dataLangues!=null)?dataLangues.map((dt,i)=>{
												return(
													<option value={dt.value} key={i} className="test" datathumbnail={dt.flag} >{dt.label}</option>
												)
											}):
											<option value="en" className="test" datathumbnail="/media/home/flag.png">English</option>
										}
											
										</select>
										<div className="lang-select">
											<button className="btn-select" value="" onClick={this.toggle.bind(this)}></button>
											<div className="b">
												<ul id="a"></ul>
											</div>
										</div>
									</div>
									{/*End Custom Flug Select*/}
									<div className="load-spiner">
										{
											(this.state.loaderspiner==true)?
											<ReactLoading type="spinningBubbles" color="#00BFFF" height={100} width={100} />:''
										}
									</div>
									<div className="header-profile-dropdown header-logo-dropdown">
										
										<Dropdown>
										  <Dropdown.Toggle variant="success" id="dropdownMenuButton" className="dropdown-toggle shfocus drop-logo logo-sch">
												<span className="profile-content">
													<span className="profile-image">
														<img src="/media/home/porfile.png" alt="Images" style={{'display':'none'}}/>
														
														{
															(photo!=='' && photo!==null)?<img src={photo}/>:
															<Avatar size="35" round={true} className="avatar-title" 
																						color={'#00a2a7'} name={this.state.username} />
														}
													</span>
													<span className="profile-text">
														<h5>{(username.length>20)?username.substr(0, 20)+"...":this.state.username}</h5>
														<p>{sMenu.student} (ID# {this.state.user_id})</p>
													</span>
												</span>
												<div className="down-icon-two"><img src="/media/home/down-arrow.png" alt="Images" /></div>
										  </Dropdown.Toggle>

										   <Dropdown.Menu className="dropdown-menu adjust">
											<Dropdown.Item className="dropdown-item width-two" href="/students">{sMenu.academic_information}</Dropdown.Item>
											<Dropdown.Item className="dropdown-item width-two" href="/setting">{sMenu.settings}</Dropdown.Item>
											{(impersonate_data!=undefined && impersonate_data!=null && impersonate_data!='') && (
											<Dropdown.Item className="dropdown-item width-two" onClick={this.unimpersonate.bind(this)}>
												<div className="">
													<p style={{'color':'#5b9bd1'}}>{sMenu.end_impersonation_mode}</p>
													<p>{sMenu.impersonator}: {this.state.impersonate_info}( {this.state.impersonate_role} )</p>
												</div>
											</Dropdown.Item>)
											}
											{
												(dataRole!==undefined && dataRole!==null && dataRole.length>1)?dataRole.map((ti,l)=>{
													return(
													<Dropdown.Item style={{'display':(hosti!="localhost" && hosti!="staging" && hosti!="sa" && parseFloat(ti.role)==1)?'none':''}} className="dropdown-item width-two log-i-role" key={l} onClick={this.user_change_role.bind(this,ti.role)}>{sMenu.log_in_as} {(ti.role!==5)?ti.role_name:'Parent / Mentor'}</Dropdown.Item>
													)
												}):''
											}
											{
												(listAccess!=undefined && listAccess!=null && listAccess!='')?listAccess.map((ti,l)=>{
													return(
													<Dropdown.Item className="dropdown-item width-two log-i-role" key={l} onClick={this.log_sso.bind(this,ti.sso_url)}>{sMenu.access} {ti.source_name} {sMenu.as} {ti.role_name}</Dropdown.Item>
													)
												}):''
											}
											<Dropdown.Item className="dropdown-item width-two" onClick={this.handleDoLogout.bind(this)}>{sMenu.log_out}</Dropdown.Item>
										  </Dropdown.Menu>
										</Dropdown>
										
									</div>
									
									<div className="help-contetn">
										<a onClick={this.zendesksso.bind(this)} target="_blank">
											<img src="/media/home/help.png" alt="Images" />
											
										</a>
									</div>
								</div>
								
							</div>
						</div>
					</div>
					
					<div className="header-bottom-area">
						<div className="container">						
							<div className="mobile-menu-icon">
								<div className="all-p-humber">
									<span></span>
									<span></span>
									<span></span>
									<span></span>
								</div>
							</div>							
							
							<div className="mobile-search-icon">
								<a href="#search"><img src="/media/home/search.png" alt="Images" /></a>
								<div id="search">
									<button type="button" className="close"><i className="fa fa-times" aria-hidden="true"></i></button>
									<form>
										<input type="search" value={this.state.search} placeholder="Search Keyword" onChange={this.handleChange.bind(this)}/>
										<button type="submit" className="btn btn-primary">Search</button>
									</form>
								</div>
							</div>
							
							<div className="header-bottom-content">
								<ul className={(this.state.language=='ar')?'arabic':''}>
									<li>
										<a className={(tab==='home')?"tablinks actives":"tablinks"} href="/home">
											<img src="/media/home/home.png" alt="Images" />
											{sMenu.home}
										</a>
									</li>

									<li>
										<a className={(tab==='learning')?"tablinks actives":"tablinks"} href="/learning">
											<img src="/media/home/developer_board.png" alt="Images" />
											{sMenu.learning}
										</a>
									</li>

									<li>
										<a className={(tab==='schedule')?"tablinks actives":"tablinks"}  href="/schedule">
											<img src="/media/home/icon.png" alt="Images" />
											{sMenu.schedule}
										</a>
									</li>
									<li>
										<a className={(tab==='progress-tracking')? "tablinks actives":"tablinks"} href={(this.state.readlearningpathtemplates==true)? "/progress-tracking":"/progress-tracking-current"}>
											<img src="/media/home/equalizer.png" alt="Images" />
											{sMenu.progress_tracking}
										</a>
									</li>
									{this.state.viewresources == true && (
									<li>
										<a className={(tab==='resources')?"tablinks actives":"tablinks"} href="/resources">
											<img src="/media/home/inbox.png" alt="Images" />
											{sMenu.resources}
											<span style={{'display':'none'}}>14</span>
										</a>
									</li>
									)}
									{this.state.viewmessaging == true && (
									<li>
										<a className={(tab==='messages')?"tablinks actives":"tablinks"} href="/messages-inbox">
											<i className="fa fa-envelope msg-ic"></i>
											{sMenu.inbox}
										</a>
									</li>
									)}
									{(this.state.viewcontract == true || this.state.updatecontract == true || this.state.viewinvoice == true || this.state.updateinvoice == true || this.state.payinvoice == true) && (
										<li>
											<Dropdown>
												<Dropdown.Toggle variant="success" id="dropdownMenuButton" className="dropdown-toggle shfocus drop-menu-it">
													<a className={(tab === 'student-contract' || tab == 'student-invoice') ? "tablinks actives" : "tablinks"} style={{ 'padding': '18px 10px', 'marginRight': '15px' }}>
														<img src="/media/teacher/icon-request.png" alt="Images" className="ic-menu" style={{ 'height': '16px' }} />
														{sMenu.contracts}/{sMenu.invoices}
														<div className="down-icon-two"><img src="/media/home/down-arrow.png" alt="Images" style={{ 'width': '12px', 'height': '8px', 'right': '-11px', 'top': '31px' }} /></div>
													</a>

												</Dropdown.Toggle>
												<Dropdown.Menu className="dropdown-menu adjust">
													{(this.state.viewcontract == true || this.state.updatecontract == true) && (
														<Dropdown.Item className="dropdown-item width-two" href="/student-contract">{sMenu.contracts}</Dropdown.Item>
													)}
													{(this.state.viewinvoice == true || this.state.updateinvoice == true || this.state.payinvoice == true) && (
														<Dropdown.Item className="dropdown-item width-two" href="/student-invoice">{sMenu.invoices}</Dropdown.Item>
													)}
												</Dropdown.Menu>
											</Dropdown>
										</li>										
									)}
									{(hosti=="localhost" || hosti=="staging")?
										<li>
											<div className="dc_dots_area" >
											  <Dropdown>
												<Dropdown.Toggle variant="success" id="dropdownMenuButton11" className="dropdown-toggle shfocus">
												  <div className="down-icon"><img src="/media/list-resource/ar-dot.png" alt="Images"  /></div>
												</Dropdown.Toggle>
							
												<Dropdown.Menu className="dropdown-menu">
													
													<Dropdown.Item className="dropdown-item width-two" href="/request-assign-user"> Request</Dropdown.Item>
												
												</Dropdown.Menu>
											  </Dropdown>
							
											</div>
										</li>:''
									}
								</ul>
							</div>
							

						   
							<div className="mobile_main_menu_content">
								<ul id="mu_accordion" className={(this.state.language=='ar')?"mu_accordion arabic":"mu_accordion"}>
									<li>
										<div className="link">
											<a className="tablinks actives"  href="/home">
												<img src="/media/home/home.png" alt="Images" />
												{sMenu.home}
											</a>
										</div>
									</li>

									<li>
										<div className="link">
											<a className="tablinks"  href="/learning">
												<img src="/media/home/developer_board.png" alt="Images" />
												{sMenu.learning} 
											</a>
										</div>
										
									</li>
									<li>
										<div className="link">
											<a className="tablinks"  href="/schedule">
												<img src="/media/home/icon.png" alt="Images" />
												{sMenu.schedule}
											</a>
										</div>
									</li>
									<li>
										<div className="link">
											<a className="tablinks"  href={(this.state.readlearningpathtemplates==true)?"/progress-tracking":"/progress-tracking-current"}>
												<img src="/media/home/equalizer.png" alt="Images" />
												{sMenu.progress_tracking}
											</a>
										</div>
									</li>
									{this.state.viewresources == true && (
									<li>
										<div className="link">
											<a className="tablinks"  href="/resources">
												<img src="/media/home/inbox.png" alt="Images" />
												{sMenu.resources}
												
											</a>
										</div>
									</li>
									)}
									{this.state.viewmessaging == true && (
									<li>
										<div className="link">
											<a className="tablinks"  href="/messages-inbox">
												<i className="fa fa-envelope ic-mbi"></i>
												{sMenu.inbox}
												
											</a>
										</div>
										
									</li>
									)}
									<li>
										<div className="link">
											<a href="/setting">{sMenu.settings}</a>
										</div>
									</li>
									<li>
										<div className="link">
											<a href="/students">{sMenu.academic_information}</a>
										</div>
									</li>
									<li>
										<div className="link">
											<a href="#" onClick={this.handleDoLogout.bind(this)}>{sMenu.log_out}</a>
										</div>
									</li>
								</ul>
							</div>
						   <ModalErorr isOpen={this.state.isOpenModal} close={this.close} text={this.state.msg} id="mssage-status"/>
						</div>
					</div>
					
				</header>
				
			)			
		
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
//export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));