
import React, { useState } from "react";

import useInterval from "./useInterval";
//import sound1 from "/media/message-with-intro.mp3";
import useSound from "use-sound";

const AppBar = () => {
  //let sound1='/media/message-with-intro.mp3';
  //let sound1='/media/rungring.mp3';
  let sound1='/media/goes-without-saying-608.mp3';
  const [isRunning, setIsRunning] = useState(true);
  const [checked, setChecked] = useState(true);
  const [count, setCount] = useState(0);
  const [play] = useSound(sound1, { volume: 0.2 });
 /* React.useEffect(() => {
		  setChecked(true);
	  }, [true])*/
//console.log(checked)
  useInterval(
    () => {
      setCount(count + 1);
     // if (checked) {
      if (count<1) {
        play();
      }
    },
    //isRunning ? 5000 : null
    isRunning ? 3000 : null
  );

  const reset = () => {
    setIsRunning(false);
    setCount(0);
  };

  const toggle = () => {
    setChecked(!checked);
  };

  return (
    <nav className="appbar" onLoad={toggle}>
	{/* <div className="toggle">
		
        <CheckBox handleChange={toggle} checked={checked} />
</div>*/}
      <span className="notification">
        
        {!!count && <span className="badge" style={{color:'1#fff'}}>{count}</span>}
      </span>
    </nav>
  );
};
{/*const FanfareButton = () => {
	let sound1='/media/goes-without-saying-608.mp3';
  const [play, { stop }] = useSound(sound1);

  return (
    <button onMouseEnter={() => play()} onMouseLeave={() => stop()}>
      <span role="img" aria-label="trumpet">
        🎺
      </span>
    </button>
  );
};*/}
export default AppBar;
